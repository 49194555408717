import React, { Component } from 'react'
import { Table, Tag, Tabs, Typography, Input, Space, Button, DatePicker, Form, Row, Col, Card, Statistic } from 'antd';
import { URL } from '../../../../Util/Config'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Text } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

class recommend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: {},
            summary: {
                deposit: 0,
                withdraw: 0,
                income: 0,
            },
            columns1: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'user_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.user_id - b.user_id,
                },
                {
                    title: 'username (ผู้ถูกแนะนำ)',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'ยอดฝากรวม',
                    dataIndex: 'deposit',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='green'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'ยอดถอนรวม',
                    dataIndex: 'withdraw',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='red'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'กำไรจากการเล่น',
                    dataIndex: 'income',
                    align: 'right',
                    render: (text, record) => (
                        text < 0 ?
                            <Tag color='red'>{text} บาท</Tag>
                            :
                            <Tag color='green'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'วัน/เวลา ที่สมัคร',
                    dataIndex: 'created_at',
                },
            ],
            data1: [],

            columns2: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'user_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.user_id - b.user_id,
                },
                {
                    title: 'username (ผู้ถูกแนะนำ)',
                    dataIndex: 'user_username',
                },
                {
                    title: 'ค่าตอบแทนการแนะนำที่ได้รับ',
                    dataIndex: 'log_affiliate_amount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='green'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'วัน/เวลา ที่ได้รับค่าตอบแทน',
                    dataIndex: 'created_at',
                },
            ],
            data2: [],

            columns3: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'user_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.user_id - b.user_id,
                },
                {
                    title: 'username (ผู้ถูกแนะนำ)',
                    dataIndex: 'user_username',
                    render: (text, record) => (
                        <>{record.user_username}, {record.user_name} ({record.user_phone})</>
                    )
                },
                {
                    title: 'วัน/เวลา ที่สมัคร',
                    dataIndex: 'created_at',
                },
            ],
            data3: [],

            pagination: {
                pagination: {
                    current: 1,
                    pageSize: 20,
                }
            },
            detail: {},
            sum_winlose: 0,
            loader_table1: true,
            loader_table2: true,
            loader_table3: true,
        };
    }

    componentDidMount() {
        if (this.props.user_id) {
            this.getProfile()
        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    getProfile() {
        if (this.props.user_advisor) {
            this.getData()
        }
        this.getAffiliate()
        this.setTable(dateStart, dateEnd)
    }

    setTable(startDate, endDate, params = {}) {
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getDataWinlose(startDate, endDate, params);
    }

    async getData() {
        let url = `${URL}/api/v1/user/advisor/${this.props.user_advisor}`
        await axios.get(url)
            .then(res => {
                const data = res.data;
                this.setState({ 
                    data3: data.result, 
                    loader_table3: false
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    async getDataWinlose(startDate, endDate, params) {
        if (params.pagination === undefined) {
            params = this.state.pagination
        } 
        this.setState({ loader_table1: true })
        let url = `${URL}/api/v1/user/partner/winlose/userid/${this.props.user_id}/page/${params.pagination.current}/start/${startDate}/end/${endDate}`
        await axios.post(url)
            .then(res => {
                const data = res.data;
                this.setState({ 
                    data1: data.result, 
                    loader_table1: false, 
                    detail: data.sum 
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    getAffiliate() {
        fetch(`${URL}/api/v1/log/affiliate/advisor/${this.props.user_id}`)
            .then((response) => response.json())
            .then((json) => this.setState({ data2: json.result, loader_table2: false }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    handleTableChange(newPagination, filters, sorter) {
        this.setTable(this.state.user_id, dateStart, dateEnd, {
          sortField: sorter.field,
          sortOrder: sorter.order,
          pagination: newPagination,
          ...filters,
        });
    };

    render() {
        return (
            <>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="เพื่อนที่แนะนำ" key="3">
                        <Row gutter={[16, 16]}>
                            <Col flex="1 1 250px">
                                <Card>
                                    <Statistic
                                        title="จำนวนเพื่อนที่แนะนำทั้งหมด"
                                        value={this.state.data3.length}
                                        valueStyle={{ color: 'green' }}
                                        suffix="คน"
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Table columns={this.state.columns3} dataSource={this.state.data3} loading={this.state.loader_table3} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.user_id} />
                    </TabPane>

                    <TabPane tab="ได้เสียเพื่อนที่แนะนำ" key="1">
                        <Form layout="inline">
                            <Form.Item label="ค้นหายอดได้เสียตามช่วงเวลาที่กำหนด">
                                <RangePicker
                                    style={{ width: '100%' }}
                                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    format={dateFormat}
                                    onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
                            </Form.Item>
                        </Form>
                        <br />
                        <Row gutter={[16, 16]}>
                            <Col flex="1 1 250px">
                                <Card>
                                    <Statistic
                                        title="ยอดฝากรวมของเพื่อนทั้งหมด"
                                        value={this.state.detail.deposit}
                                        valueStyle={{ color: 'green' }}
                                        suffix="บาท"
                                    />
                                </Card>
                            </Col>
                            <Col flex="1 1 250px">
                                <Card>
                                    <Statistic
                                        title="ยอดถอนรวมของเพื่อนทั้งหมด"
                                        value={this.state.detail.withdraw}
                                        valueStyle={{ color: 'red' }}
                                        suffix="บาท"
                                    />
                                </Card>
                            </Col>
                            <Col flex="1 1 250px">
                                <Card>
                                    <Statistic
                                        title="กำไรรวมของเพื่อนทั้งหมด"
                                        value={this.state.detail.income}
                                        valueStyle={{ color: '#EF9900' }}
                                        suffix="บาท"
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Table 
                            columns={this.state.columns1} 
                            dataSource={this.state.data1} 
                            loading={this.state.loader_table1} 
                            pagination={this.state.pagination.pagination}
                            scroll={{ x: 1000 }} 
                            rowKey={record => record.user_id} 
                            onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
                        />
                    </TabPane>

                    <TabPane tab="ได้ค่าตอบแทนจากการแนะนำ" key="2">
                        <Text type="danger">**เงื่อนไขการแนะนำเพื่อน : สมาชิก (ผู้ถูกแนะนำ) ต้องรับโปรโมชั่นที่รองรับแนะนำเพื่อนก่อน สมาชิก (ผู้แนะนำ) จึงสามารถได้รับเครดิตฟรี</Text>
                        <Table columns={this.state.columns2} dataSource={this.state.data2} loading={this.state.loader_table2} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.user_id} />
                    </TabPane>
                </Tabs>
            </>
        )
    }
}

export default withRouter(recommend)