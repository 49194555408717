import React, { Component } from 'react';
import { Table, Card, Tag, Alert, Row, Col, message, Form, Pagination, DatePicker } from 'antd';
import { URL } from '../../../../Util/Config';
import axios from 'axios';
import DateTime from 'node-datetime';
import moment from 'moment';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-1)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

export default class bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'วัน/เวลา โอนเงิน',
          dataIndex: 'txnDateTime',
          // render: (text, record) => (
          //     <p>{formateDateTime2digit(text)}</p>
          // )
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'txnAmount',
          align: 'right',
          render: (text, record) => (
            record.txnDebitCreditFlag === 'C' ? <Tag color='green'>{text} บาท</Tag> : <Tag color='red'>{text} บาท</Tag>
          )
        },
        {
          title: 'ธุรกรรม',
          dataIndex: 'txnDebitCreditFlag',
          render: (text, record) => (
            text === 'C' ? <Tag color='green'>ฝาก</Tag> : <Tag color='red'>ถอน</Tag>
          )
        },
        {
          title: 'ข้อสังเกต',
          dataIndex: 'txnRemark',
        }
      ],
      data: [],
      pageNumber: 1
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_name: user.user.name }, () => {
      this.setData(this.state.pageNumber, dateStart, dateEnd)
    })
  }

  setData(pageNumber, startDate, endDate) {
    dateStart = startDate
    dateEnd = endDate

    var dt = DateTime.create(startDate);
    startDate = dt.format('Y-m-d');
    dt = DateTime.create(endDate);
    endDate = dt.format('Y-m-d');

    this.setState({ pageNumber: pageNumber }, () => {
      this.getData(pageNumber, startDate, endDate)
    })
  }

  async getData(pageNumber, startDate, endDate) {
    var body = {
      pageNumber,
      startDate,
      endDate
    }

    await axios.post(URL + "/api/v1/scb/transaction/all", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ data: data.result })
        } else {
          message.error(data.message)
        }
      })
      .catch(error => {
        console.log(error)
        message.error('เกิดข้อผิดพลาด!!')
      })
  }

  render() {
    return (
      <>
        <Card>
          <Alert
            message="แจ้งเตือน"
            description="ข้อมูลจากธนาคารโดยตรงอ้างอิงจากแอพ scb easy"
            type="warning"
            showIcon
          />
          <br />
          <Form layout="inline">
            <Form.Item label="ค้นหาตามวันที่ระบบุ" style={{ width: '50%' }}>
              <RangePicker
                style={{ width: '100%' }}
                defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                format={dateFormat}
                onChange={(e) => this.setData(this.state.pageNumber, e[0]._d, e[1]._d)} />
            </Form.Item>
          </Form>
          <br />
          <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ position: [] }} scroll={{ x: 1000 }} rowKey={record => record.txnDateTime} />
          <br />
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Pagination current={this.state.pageNumber} pagination={{ pageSize: 10 }} onChange={(value) => this.setData(value, dateStart, dateEnd)} total={50} />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
