import React, { Component } from "react";
import {
  Tag,
  Tabs,
  Typography,
  Card,
  Divider,
  Row,
  Col,
  Button,
  Switch,
  Table,
  Tooltip,
  Modal,
  Form,
  InputNumber,
  Input,
  Space,
  Statistic,
  DatePicker
} from "antd";
import { SettingOutlined, EditOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { URL } from "../../../../Util/Config";
import axios from "axios";
import x0p from "x0popup";
import DateTime from 'node-datetime';
import moment from 'moment';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD HH:mm:ss';
var dateStart = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 00:00:00`).format('Y/m/d H:M:S');
var dateEnd = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 23:59:59`).format('Y/m/d H:M:S');

export default class affiliate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plainOptions: [
        { label: "ไม่จำกัด", value: "0" },
        { label: "จำกัด", value: "1" },
      ],
      columns: [
        {
          title: "ชื่อโปรโมชั่น",
          dataIndex: "discount_name",
          render: (text, record) => <Text style={{ width: 2 }}>{text}</Text>,
        },
        {
          title: "ประเภท",
          dataIndex: "discount_type",
          render: (text, record) =>
            text === "เปอร์เซ็นต์" ? (
              <Tag color="purple">{text}</Tag>
            ) : (
              <Tag color="geekblue">{text}</Tag>
            ),
        },
        {
          title: "สถานะ",
          dataIndex: "discount_status",
          // align: 'right',
          render: (text, record) =>
            record.discount_status === "เปิด" ? (
              <Button
                style={{ backgroundColor: "#76D7C4" }}
                shape="circle"
                disabled
              >
                เปิด
              </Button>
            ) : (
              <Button
                style={{ backgroundColor: "#F1948A" }}
                shape="circle"
                disabled
              >
                ปิด
              </Button>
            ),
        },
        {
          title: "โบนัส (ค่าตอบแทนจากการแนะนำเพื่อน)",
          dataIndex: "discount_affiliate_bonus",
          // align: 'right',
          render: (text, record) => (
            <Tag color="green">
              {text} {record.discount_type}
            </Tag>
          ),
        },
        {
          title: "โบนัสสูงสุด",
          dataIndex: "discount_affiliate_bonus_max",
          render: (text, record) => <Tag color="orange">{text} บาท</Tag>,
        },
        {
          title: "",
          dataIndex: "",
          render: (text, record) => (
            <>
              <Tag color="cyan">การแนะนำ {record.total_amount} ครั้ง</Tag>
              <Tag color="orange">เครดิตที่แจก {record.total_bonus} เครดิต</Tag>
            </>
          ),
        },
        {
          title: "",
          dataIndex: "discount_id",
          render: (text, record) =>
            this.state.admin_type === "G" ? null : (
              <>
                <Tooltip title="แก้ไข">
                  <Button
                    style={{ marginRight: 5 }}
                    type="primary"
                    shape="circle"
                    onClick={() => this.showDiscount(text)}
                    icon={<EditOutlined />}
                  ></Button>
                </Tooltip>
              </>
            ),
        },
      ],
      data: [],

      columns2: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          defaultSortOrder: "descend",
          sorter: (a, b) => a.row_num - b.row_num,
        },
        {
          title: "username (ผู้แนะนำ)",
          dataIndex: "user_username",
          ...this.getColumnSearchProps("user_username"),
        },
        {
          title: "username (ผู้ถูกแนะนำ)",
          dataIndex: "user_advisor",
          ...this.getColumnSearchProps("user_username"),
        },
        {
          title: "ค่าตอบแทนการแนะนำที่ได้รับ",
          dataIndex: "log_affiliate_amount",
          align: "right",
          render: (text, record) => <Tag color="green">{text} บาท</Tag>,
        },
        {
          title: "วัน/เวลา ที่ได้รับค่าตอบแทน",
          dataIndex: "created_at",
        },
      ],
      data2: [],

      affiliate: {
        affiliate_amount: 0,
        affiliate_type: "บาท",
        affiliate_status: "ปิด",
      },
      isModalEditVisible: false,
      dataDiscount: {},
      edit_discount_affiliate_bonus: 0,
      edit_discount_affiliate_bonus_max: 0,
      admin_type: null,
      summary: {},
      summary2: {},
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      admin_type: user.user.type,
      admin_token: user.accessToken,
    });

    this.getAffiliate();
    this.getData();
    this.getData3();
    this.setData(dateStart, dateEnd);
  }

  setData(startDate, endDate, params = {}) {
    dateStart = startDate
    dateEnd = endDate

    // var dt = DateTime.create(startDate);
    // startDate = dt.format('Y-m-d ');
    // dt = DateTime.create(endDate);
    // endDate = dt.format('Y-m-d');

    this.getData2(dateStart, dateEnd);
    this.getData4(dateStart, dateEnd);
  }

  // ------------------------------------------------------------------ START FILTER -----------------------------------------------------------
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  // ------------------------------------------------------------------ END FILTER -----------------------------------------------------------

  getAffiliate() {
    fetch(`${URL}/api/v1/affiliate`)
      .then((response) => response.json())
      .then((json) => this.setState({ affiliate: json.result }))
      // .then(() => console.log(this.state.affiliate))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  getData() {
    fetch(`${URL}/api/v1/affiliate/discount`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  // ประวัติค่าตอบแทน
  async getData2(start, end) {
    var body = {
      start, end
    }
    await axios.post(`${URL}/api/v1/log/affiliate/all`, body)
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ data2: data.result })
        } else {
          console.log(data.message)
        }
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  // การ์ดสรุป แบบเวลา
  async getData4(start, end) {
    var body = {
      start, end
    }
    await axios.post(`${URL}/api/v1/affiliate/summary`, body)
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ summary2: data.result })
        } else {
          console.log(data.message)
        }
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  // การ์ดสรุป
  async getData3() {
    await axios.post(`${URL}/api/v1/affiliate/summary`)
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ summary: data.result })
        } else {
          console.log(data.message)
        }
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  async updateStatusAffiliate(status) {
    x0p({
      title: "ยืนยัน!!",
      text: `คุณต้องการ "${
        status === true ? "เปิด" : "ปิด"
      }" ใช้งานโปรแนะนำเพื่อนใช่มั้ย??`,
      icon: "info",
      buttons: [
        {
          type: "cancel",
          text: "ยกเลิก",
        },
        {
          type: "info",
          text: "ยืนยัน",
          showLoading: true,
        },
      ],
    }).then(async (alert) => {
      if (alert.button === "info") {
        const body = {
          status: status === true ? "เปิด" : "ปิด",
        };
        await axios
          .post(`${URL}/api/v1/affiliate/update/status`, body, {
            headers: {
              Authorization: `Bearer ${this.state.admin_token}`,
            },
          })
          .then((res) => {
            const data = res.data;
            if (data.status) {
              this.setState({
                affiliate: {
                  ...this.state.affiliate,
                  affiliate_status: status === true ? "เปิด" : "ปิด",
                },
              });
              x0p("สำเร็จ", null, "ok", false);
            } else {
              x0p("", data.message, "error", false);
            }
          })
          .catch((error) => {
            console.log(error);
            x0p("เกิดข้อผิดพลาด!!", null, "error", false);
          });
      }
    });
  }

  async showDiscount(id) {
    await axios
      .get(`${URL}/api/v1/discount/id/${id}`)
      .then((res) => {
        const data = res.data;
        if (data.status) {
          this.setState({
            dataDiscount: data.result,
            edit_discount_affiliate_bonus: data.result.discount_affiliate_bonus,
            edit_discount_affiliate_bonus_max:
              data.result.discount_affiliate_bonus_max,
            isModalEditVisible: true,
          });
          console.log();
        } else {
          console.log("เกิดข้อผิดพลาด showDiscount");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async editDiscount() {
    const dataDiscount = this.state.dataDiscount;

    const body = {
      // ใช้ข้อมูลเดิม
      name: dataDiscount.discount_name,
      type: dataDiscount.discount_type,
      amount: dataDiscount.discount_amount,
      min_deposit: dataDiscount.discount_min_deposit,
      bonus_max: dataDiscount.discount_bonus_max,
      turnover: dataDiscount.discount_turnover,
      type_turn: dataDiscount.discount_type_turn,
      type_game: dataDiscount.discount_type_game,
      games: dataDiscount.discount_games,
      start_date: dataDiscount.discount_start_date,
      end_date: dataDiscount.discount_end_date,
      status: dataDiscount.discount_status,
      max_withdraw: dataDiscount.discount_max_withdraw,
      level: dataDiscount.discount_level,
      affiliate: dataDiscount.discount_affiliate,
      condition: dataDiscount.discount_condition,
      coupon_limit: dataDiscount.coupon_limit,
      image: dataDiscount.discount_image,
      id: dataDiscount.discount_id,

      // แก้ไขแค่นี้
      affiliate_bonus: this.state.edit_discount_affiliate_bonus,
      affiliate_bonus_max: this.state.edit_discount_affiliate_bonus_max,
    };

    await axios
      .post(URL + "/api/v1/discount/edit", body, {
        headers: {
          Authorization: `Bearer ${this.state.admin_token}`,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.status) {
          x0p("สำเร็จ", null, "ok", false);
          this.getData();
          this.setState({ isModalEditVisible: false });
        } else {
          x0p("", data.message, "error", false);
        }
      })
      .catch((error) => {
        console.log(error);
        x0p("เกิดข้อผิดพลาด!!", null, "error", false);
      });
  }

  render() {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card>
              <Statistic
                title="การแนะนำเพื่อน"
                value={this.state.summary.total_count}
                valueStyle={{ color: "#1990FF" }}
                suffix="ครั้ง"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="ค่าตอบ(เครดิตที่แจก)ทั้งหมด"
                value={this.state.summary.total_amount}
                valueStyle={{ color: "#EF9900" }}
                suffix="เครดิต"
              />
            </Card>
          </Col>
        </Row>
        <br />

        <Card>
          <Row style={{ marginBottom: 20 }}>
            <Col span={20} style={{ textAlign: "right" }}>
              <Divider orientation="left">
                <SettingOutlined /> ตั่งค่า แอฟฟิเนท
              </Divider>
            </Col>
            <Col span={4} style={{ textAlign: "right", marginTop: 15 }}>
              สถานะการใช้งาน :{" "}
              <Switch
                checkedChildren="เปิด"
                unCheckedChildren="ปิด"
                checked={
                  this.state.affiliate.affiliate_status === "เปิด"
                    ? true
                    : false
                }
                onChange={(e) => this.updateStatusAffiliate(e)}
                disabled={this.state.admin_type === "G" ? true : false}
              />
            </Col>
          </Row>

          <h6>เงื่อนไขการแนะนำเพื่อนรับโปร</h6>
          <p>
            สมาชิกสารมารถชวนเพื่อนได้ไม่จำกัด
            ค่าตอบแทนการชวนเพื่อนขึ้นอยู่กับโปรโมชั่นที่ได้ตั้งค่าไว้
          </p>
          <p>
            และเพื่อนที่ถูกชวนจะต้องรับโปรโมชั่น (โปรโมชั่นรองรับแนะนำเพื่อน)
            ก่อนสมาชิกจึงจะได้รับเครดิต
          </p>
          <p>หลังจากเพื่อนรับโปรฯ จะได้รับค่าตอบแทนทันที</p>
          <br />

          <h6></h6>
          <Tabs type="card">
            <Tabs.TabPane
              tab="รายการโปรโมชั่น ที่รองรับแนะนำเพื่อน"
              key="item-1"
            >
              <Table
                columns={this.state.columns}
                dataSource={this.state.data}
                pagination={{ pageSize: 25 }}
                rowKey={(record) => record.discount_name}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ประวัติการได้รับค่าตอบแทน" key="item-3">
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="การแนะนำเพื่อน"
                      value={this.state.summary2.total_count}
                      valueStyle={{ color: "#1990FF" }}
                      suffix="ครั้ง"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <Statistic
                      title="ค่าตอบ(เครดิตที่แจก)ทั้งหมด"
                      value={this.state.summary2.total_amount}
                      valueStyle={{ color: "#EF9900" }}
                      suffix="เครดิต"
                    />
                  </Card>
                </Col>
              </Row>
              <Row justify="end">
                <Col span={12}>
                  <Form layout='horizontal'>
                    <Form.Item label="ค้นหาประวัติการแนะนำตามช่วงเวลา">
                      <RangePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                        onChange={(e) => this.setData(new Date(e[0]._d).toLocaleString('sv-SE'), new Date(e[1]._d).toLocaleString('sv-SE'))}
                        defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        showTime={{ hideDisabledOptions: true }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Text type="danger">
                **เงื่อนไขการแนะนำเพื่อน : สมาชิก (ผู้ถูกแนะนำ)
                ต้องรับโปรโมชั่นที่รองรับแนะนำเพื่อนก่อน สมาชิก (ผู้แนะนำ)
                จึงสามารถได้รับเครดิตฟรี
              </Text>
              <Table
                columns={this.state.columns2}
                dataSource={this.state.data2}
                // pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
                rowKey={(record) => record.log_affiliate_id}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>

        {/* Modal แก้ไข */}
        <Modal
          title="แก้ไขค่าตอบแทน"
          style={{ top: 20 }}
          open={this.state.isModalEditVisible}
          onOk={() => this.editDiscount()}
          maskClosable={false}
          onCancel={() =>
            this.setState({
              isModalEditVisible: false,
            })
          }
        >
          <Form layout="vertical" onFinish={() => this.editDiscount()}>
            <Form.Item
              label="โบนัส"
              required
              extra={
                this.state.dataDiscount.discount_type === "เปอร์เซ็นต์"
                  ? "จำนวนโบนัสเป็นเปอร์เซ็นต์ของยอดฝาก"
                  : ""
              }
            >
              <InputNumber
                addonAfter={
                  this.state.dataDiscount.discount_type === "เปอร์เซ็นต์"
                    ? "%"
                    : "บาท"
                }
                value={this.state.edit_discount_affiliate_bonus}
                style={{
                  width:
                    this.state.dataDiscount.discount_type === "เปอร์เซ็นต์"
                      ? "40%"
                      : "100%",
                }}
                onChange={(value) =>
                  this.setState({ edit_discount_affiliate_bonus: value })
                }
              />
              {this.state.dataDiscount.discount_type === "เปอร์เซ็นต์" ? (
                <InputNumber
                  addonBefore="สูงสุด"
                  addonAfter="บาท"
                  value={this.state.edit_discount_affiliate_bonus_max}
                  style={{ width: "60%" }}
                  onChange={(value) =>
                    this.setState({ edit_discount_affiliate_bonus_max: value })
                  }
                />
              ) : null}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
