import React, { Component } from 'react';
import { Table, Row, Col, Statistic, Input, Button, Space, Tag, message, DatePicker, Typography, Image } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import { URL } from '../../../../Util/Config';
import { withRouter } from 'react-router'
import { formateDateTimeDMYT } from '../../../../Util/formatDate';
import moment from 'moment';
import DateTime from 'node-datetime';
import axios from 'axios';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD HH:mm:ss';
var dateStart = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 00:00:00`).format('Y/m/d H:M:S');
var dateEnd = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 23:59:59`).format('Y/m/d H:M:S');

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

class discountuse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalCouponVisible: false,
      discount: {},
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.row_num - b.row_num,
        },
        {
          title: 'ผู้รับโปรโมชั่น',
          dataIndex: 'title',
          ...this.getColumnSearchProps('title'),
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'ฝากแรกรับโปรฯ',
          dataIndex: 'log_discount_deposit_amount',
          render: (text, record) => (
            text ? <Tag color="green">{text}</Tag> : ''
          ),
        },
        {
          title: 'สถานะ',
          dataIndex: 'log_discount_status',
          ...this.getColumnSearchProps('log_discount_status'),
          render: (text, record) => (
            text === 'สำเร็จ' ? <Tag color="green">{text}</Tag> : text === 'ยกเลิก' ? <Tag color="red">{text}</Tag> : text === 'ใช้งานแล้ว' ? <Tag color="orange">{text}</Tag> : <Tag color="blue">{text}</Tag>
          ),
        },
        {
          title: 'ถอนเครดิตออก',
          dataIndex: 'log_discount_withdraw_amount',
          render: (text, record) => (
            text ? <Tag color="red">{text}</Tag> : ''
          ),
        },
        {
          title: 'วันเวลาที่รับโปรโมชั่น',
          dataIndex: 'created_at',
          ...this.getColumnSearchProps('created_at'),
        },
      ],
      data: [],
      summary: '',
      amount: '',
      detail: {
        discount_image: ''
      }
    };
  }

  componentDidMount() {
    if (this.props.discount_id) {
      this.getData(dateStart, dateEnd)
    } else {
      message.error('ไม่มีข้อมูล ส่วนลดนี้')
      this.props.history.push("/adminManage/promotion/discount");
    }
  }

  async getData(start, end) {
    var id = this.props.discount_id
    let body = {
      start: start,
      end: end
    }
    await axios.post(`${URL}/api/v1/discount/use/${id}`, body)
      .then(res => {
        var data = res.data
        if (data.status) {      
          this.setState({ data: data.result.data, summary: data.result.summary, amount: data.result.data.length, detail: data.result.detail })
        } else console.log(data)
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 

  render() {
    return (
      <>
        <Row className='justify-content-between'>
          <Col>
            <Title level={3}>โปรโมชั่น: {this.state.detail.discount_name} <Tag color={this.state.detail.discount_status === 'เปิด' ? 'green' : 'red'}>{this.state.detail.discount_status}</Tag></Title>
          </Col>
          <Col>
            {/* <Title level={3}><Paragraph copyable>{this.state.detail.coupon_code}</Paragraph></Title> */}
          </Col>
        </Row>
        {this.state.detail.discount_image !== '' ?
          <div className='text-center'>
            <Image src={this.state.detail.discount_image.includes('http') ? this.state.detail.discount_image : URL + '/public/images/promotion/' + this.state.detail.discount_image} width={100} />
            <br/>
            <br/>
          </div>
          : null
        }

        <Row gutter={[16, 0]}>
          <Col span={8}>
            <DescriptionItem title="ประเภท" content={this.state.detail.discount_type} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="ระดับเครดิต" content={this.state.detail.discount_level} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="ฝากขั้นต่ำ" content={this.state.detail.discount_min_deposit} บาท />
          </Col>
          <Col span={8}>
            <DescriptionItem title="โบนัสที่ได้รับ" content={this.state.detail.discount_amount} เครดิต />
          </Col>
          <Col span={8}>
            <DescriptionItem title="โบนัสสูงสุด" content={this.state.detail.discount_bonus_max} เครดิต />
          </Col>
          <Col span={8}>
            <DescriptionItem title="ถอนสูงสุด" content={this.state.detail.discount_max_withdraw_title} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="เทิร์นโอเวอร์" content={<>{this.state.detail.discount_turnover} เท่า <Tag></Tag></>} /> 
          </Col>
          <Col span={8}>
            <DescriptionItem title="ประเภทเกมที่รองรับ" content={this.state.detail.discount_type_game === '0' ? 'ทั้งหมด' : this.state.detail.discount_type_game === '1' ? 'สล็อต' : 'คาสิโน'} />
          </Col>
          <Col span={8}>
          <DescriptionItem title="เงื่อนไข" content={this.state.detail.discount_condition === '0' ? 'ใช้ได้ครั้งเดียว/คน' : this.state.detail.discount_condition === '1' ? 'ใช้ได้วันล่ะครั้ง/คน' : 'ใช้ได้วันล่ะหลายครั้ง/คน'} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="โปรโมชั่นแนะนำเพื่อน" content={this.state.detail.discount_affiliate === '0' ? 'รองรับแนะนำเพื่อน' : 'ไม่รองรับแนะนำเพื่อน'} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="จำนวนคูปอง" content={this.state.detail.coupon_limit > 0 ? this.state.detail.coupon_limit : 'ไม่จำกัด'} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="แสดงหน้าเว็บ" content={this.state.detail.discount_image !== '' ? 'แสดงหน้าเว็บ' : 'ไม่แสดงหน้าเว็บ'} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="วัน/เวลาที่เริ่มจัดโปร" content={this.state.detail.discount_start_date ? formateDateTimeDMYT(this.state.detail.discount_start_date) : ''} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="วัน/เวลาที่สิ้นสุดจัดโปร" content={this.state.detail.discount_end_date ? formateDateTimeDMYT(this.state.detail.discount_end_date) : ''} />
          </Col>
        </Row>

        <br />
        <RangePicker
          format={dateFormat}
          onChange={(e) => this.getData(new Date(e[0]._d).toLocaleString('sv-SE'), new Date(e[1]._d).toLocaleString('sv-SE'))}
          defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
          showTime={{ hideDisabledOptions: true }}
        />
        <br/>

        <Row gutter={[16, 16]}>
          <Col flex="1 1 200px">
            <Statistic
              title="แจกโปรฯ"
              value={this.state.amount}
              valueStyle={{ color: '#EF9900' }}
              suffix="ครั้ง"
            />
          </Col>
          <Col flex="1 1 200px">
            <Statistic
              title="ยอดฝากแรกโปรฯ"
              value={this.state.summary.total_deposit}
              valueStyle={{ color: 'green' }}
              suffix="บ."
            />
          </Col>
          <Col flex="1 1 200px">
            <Statistic
              title="ยอดเครดิตที่แจก"
              value={this.state.summary.total_bonus}
              valueStyle={{ color: '#EF9900' }}
              suffix="C."
            />
          </Col>
          <Col flex="1 1 200px">
            <Statistic
              title="ยอดถอนจากโปรฯ"
              value={this.state.summary.total_withdraw}
              valueStyle={{ color: '#CF1321' }}
              suffix="บ."
            />
          </Col>
          <Col flex="1 1 200px">
            <Statistic
              title="กำไรจากโปรฯ"
              value={this.state.summary.total_income}
              valueStyle={{ color: this.state.summary.total_income > 0 ? 'green' : 'red' }}
              suffix="บ."
            />
          </Col>
        </Row>
        <br />

        <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
      </>
    );
  }
}
export default withRouter(discountuse);