import React, { Component } from 'react'
import { Statistic, Card, Row, Col, DatePicker, Divider, Typography, Table, Tag, Button } from 'antd';
import { URL } from '../../../Util/Config';
import { Line, Column } from '@ant-design/charts';
import DateTime from 'node-datetime';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config_chart1: {
        data: [],
        xField: 'datetime',
        yField: 'amount',
        seriesField: 'unit',
        // color: ['#117864', '#943126'],
        xAxis: {
          // type: 'time',
          mask: 'DD-MM-YYYY',
        },
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                return ''.concat(s, ',');
              });
            },
          },
        },
        label: {},
        point: {
          size: 3,
          shape: 'diamond',
          style: {
            fill: 'white',
            stroke: '#5B8FF9',
            lineWidth: 1,
          },
        },
        tooltip: { showMarkers: false },
        state: {
          active: {
            style: {
              shadowColor: 'yellow',
              shadowBlur: 4,
              stroke: 'transparent',
              fill: 'red',
            },
          },
        },
        theme: {
          geometries: {
            point: {
              diamond: {
                active: {
                  style: {
                    shadowColor: '#FCEBB9',
                    shadowBlur: 2,
                    stroke: '#F6BD16',
                  },
                },
              },
            },
          },
        },
        interactions: [{ type: 'marker-active' }],
        legend: { position: 'top' },
      },
      amountUserAll: '',
      amountRegister30DayAgo: '',
      detailChart1: {
        total: 0
      },
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'วันที่สมัครสมาชิก',
          dataIndex: 'created_at',
        },
        {
          title: 'ผู้ใช้งาน',
          dataIndex: 'title',
        },
        {
          title: 'ช่องทางสมัคร',
          dataIndex: 'user_channel',
        },
        {
          title: 'ผู้แนะนำ',
          dataIndex: 'advisor',
        },
        {
          title: 'ยอดฝากรวม',
          dataIndex: 'deposit',
          align: 'right',
          render: (text, record) => (
            <>
              <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          )
        },
        {
          title: 'ยอดถอนรวม',
          dataIndex: 'withdraw',
          align: 'right',
          render: (text, record) => (
            <>
              <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          )
        },
        {
          title: 'ได้กำไรจากการเล่น',
          dataIndex: 'income',
          align: 'right',
          render: (text, record) => (
            <>
              {text > 0 ?
                <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
                :
                <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
              }
            </>
          )
        }
      ],
      data: [],
      visibleRegisterDetail: false,

      config_chart2: {
        data: [],
        xField: 'datetime',
        yField: 'amount',
        seriesField: 'user_channel',
        xAxis: {
          mask: 'DD-MM-YYYY',
        },
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                return ''.concat(s, ',');
              });
            },
          },
        },
        isGroup: 'true',
        columnStyle: {
          radius: [20, 20, 0, 0],
          padding: 0
        },
      },
      detailChart2: [],
      ipt_channel: ''
    };
  }

  componentDidMount() {
    this.setChart(dateStart, dateEnd)

    this.getAmountUserAll()
    this.getAmountRegister30DayAgo()
  }

  setChart(startDate, endDate) {
    // console.log(startDate, endDate)
    var dt = DateTime.create(startDate);
    startDate = dt.format('Y-m-d');
    dt = DateTime.create(endDate);
    endDate = dt.format('Y-m-d');

    this.getChart(startDate, endDate)
    this.getDataChannel(startDate, endDate)
    this.getSummaryChart(startDate, endDate)
  }

  // กราฟ เครดิตฟรี
  getChart(startDate, endDate) {
    fetch(`${URL}/api/v1/dashboard/member/chart/start/${startDate}/end/${endDate}`)
      .then((response) => response.json())
      .then((json) => this.setState({ config_chart1: { ...this.state.config_chart, data: json.result } }))
      // .then(() => console.log(this.state.config_chart))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ข้อมูลรายละเอียด กราฟ 1
  getSummaryChart(startDate, endDate) {
    fetch(`${URL}/api/v1/dashboard/member/summary/start/${startDate}/end/${endDate}`)
      .then((response) => response.json())
      .then((json) => this.setState({ detailChart1: json.result }))
      // .then(() => console.log(this.state.detailChart))
      .catch((error) => {
        console.log('getTransactionWithdrawTotal', error);
      });
  }

  getAmountUserAll() {
    fetch(`${URL}/api/v1/user/amount/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ amountUserAll: json.result }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getAmountRegister30DayAgo() {
    fetch(`${URL}/api/v1/user/amount/register/30dayago`)
      .then((response) => response.json())
      .then((json) => this.setState({ amountRegister30DayAgo: json.result }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getDataChannel(startDate, endDate) {
    fetch(`${URL}/api/v1/dashboard/member/channel/start/${startDate}/end/${endDate}`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ config_chart2: { ...this.state.config_chart2, data: json.result.chart }, detailChart2: json.result.summary })
        // console.log(json)
      })
      // .then(() => console.log(this.state.config_chart))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  setDetail2() {
    let list = this.state.detailChart2.map((item, index) =>
      <Col span={12}>
        <DescriptionItem title={item.user_channel} content={<><Text style={{ color: '#884EA0' }}>{(item.amount).toLocaleString()}</Text> คน</>} />
      </Col>
    );
    return (list)
  }

  setRegisterDetail(date) {
    const D = new Date(date);
    this.setState({ visibleRegisterDetail: true })
    fetch(`${URL}/api/v1/dashboard/transaction/register/${D.toLocaleDateString('sv-SE')}`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ data: json.result })
        // console.log(json)
      })
      // .then(() => console.log(this.state.config_chart))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  render() {
    return (
      <>
        <Divider orientation="left"><Title level={4}>สรุปยอดเปิดยูส</Title></Divider>
        <Row gutter={[16, 16]}>
          <Col flex="1 1 250px">
            <Card>
              <Statistic
                title="สมาชิกทั้งหมด"
                value={this.state.amountUserAll}
                valueStyle={{ color: '#4385F4' }}
                suffix="คน"
              />
            </Card>
          </Col>
          <Col flex="1 1 250px">
            <Card>
              <Statistic
                title="สมาชิกใหม่ 30 ย้อนหลัง"
                value={this.state.amountRegister30DayAgo}
                valueStyle={{ color: '#4385F4' }}
                suffix="คน"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Card style={{ marginBottom: 30 }}>
          <Title level={5}>สถิติ สมัครสมาชิก</Title>
          <RangePicker
            style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
            defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
            format={dateFormat}
            onChange={(e) => this.setChart(e[0]._d, e[1]._d)}
          />
          <Row>
            <Col span={24}>
              <DescriptionItem title="ยอดสมัครรวม" content={<><Text style={{ color: '#6495ED' }}>{(this.state.detailChart1.total).toLocaleString()}</Text> คน</>} />
            </Col>
          </Row>
          <Line 
            {...this.state.config_chart1} 
            onReady={(plot) => {
              plot.on('plot:click', (evt) => {
                const { x, y } = evt;
                // const { xField } = plot.options;
                const tooltipData = plot.chart.getTooltipItems({ x, y });
                if (tooltipData.length > 0) {
                  this.setRegisterDetail(tooltipData[0].title)
                }
              });
            }}
          />

          <br />
          <br />
          {this.state.visibleRegisterDetail ? 
            <>
              <Title level={5} >รายละเอียดข้อมูลผู้สมัครสมาชิก</Title>
              <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 5 }} scroll={{ x: 1200 }} rowKey={record => record.row_num} />
            </>
            : <></>
          }
          <br />
          <br />
          <br />


          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Title level={5} >สถิติช่องทางสมัครของลูกค้า</Title>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => this.props.history.push("/adminManage/announce")}>เพิ่มช่องทาง</Button>
            </Col>
          </Row>
          <Row>
            {this.setDetail2()}
          </Row>
          <Column {...this.state.config_chart2} />
        </Card>
      </>
    )
  }
}

export default withRouter(index)