import React, { Component } from 'react'
import { Statistic, Card, Row, Col, Divider, Typography, DatePicker } from 'antd';
import { URL } from '../../../Util/Config';
import moment from 'moment';
// import DateTime from 'node-datetime';
import axios from 'axios';

const dateFormat = 'YYYY/MM/DD';
const timeFormat = 'YYYY/MM/DD HH:mm:ss';
var date = new Date().toLocaleDateString('sv-SE')
var timeStart = `${new Date().toLocaleDateString('sv-SE')} 00:00:00`
var timeEnd = `${new Date().toLocaleDateString('sv-SE')} 23:59:59`

const { Title } = Typography;
const { RangePicker } = DatePicker;

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper m-0 text-light">
        <p className="site-description-item-profile-p-label m-0 text-light">{title}: {content}</p>
    </div>
);

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transfer: [
                {
                    "amount": 0,
                    "name": "กำไรรวม",
                    "count": 0,
                    "type": "กำไร",
                },
                {
                    "amount": 0,
                    "name": "ยอดฝากรวม",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "แอดมิน",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "SCB",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "TWL",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "KBank",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "Payment",
                    "count": 0,
                    "type": "ฝาก"
                },
                {
                    "amount": 0,
                    "name": "ยอดถอนรวม",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "SCB",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "TWL",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "KBank",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "แอดมิน",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "ถอนไม่โอนเงิน",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "Payment",
                    "count": 0,
                    "type": "ถอน"
                },
                {
                    "amount": 0,
                    "name": "active",
                    "count": 0,
                    "type": "active"
                }
            ],
            register: [
                {
                    "amount": 0,
                    "count": 0,
                    "unite": "ยอดสมัคร"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unite": "ยอดสมาชิกใหม่ฝาก"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unite": "จำนวนสมาชิกใหม่ฝาก"
                }
            ],
            promotion: {
                "count": 0,
                "bonus": 0,
                "withdraw": 0,
                "deposit": 0,
                "total_income": 0
            },
            point: [
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "ฝากรับเพชร"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "กงล้อนำโชค"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "ล็อคอินรายวัน"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "แลกเพชร/แอดมินเติม"
                }
            ],
            event: [
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "กงล้อนำโชค"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "ล็อคอินรายวัน"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "เพชรแลกเครดิต"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "โบนัสแลกเครดิต"
                }
            ],
            bonus: [
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "คืนยอดเสีย"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "คืนยอดเสียแนะนำเพื่อน"
                },
                {
                    "amount": 0,
                    "count": 0,
                    "unit": "คืนยอดเล่น"
                }
            ]
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_id: user.user.id, admin_name: user.user.name }, () => {
            this.getData(`${new Date().toLocaleDateString('sv-SE')} 00:00:00`, `${new Date().toLocaleDateString('sv-SE')} 23:59:59`)
        })
    }

    async getData(start, end) {
        let body = { 
            start: start,
            end: end
        }
        console.log(body)
        console.log(`${URL}/api/v1/dashboard/daily`)
        console.log(`Bearer ${this.state.admin_token}`)
        await axios.post(`${URL}/api/v1/dashboard/daily`, body, {
            headers: {
              'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
        .then(res => {
            var data = res.data
            if (data.status) {
                console.log(data.result.transfer)
                this.setState({ 
                    transfer: data.result.transfer, 
                    register: data.result.register, 
                    promotion: data.result.promotion, 
                    event: data.result.event, 
                    point: data.result.point,
                    bonus: data.result.bonus
                })
            } else console.log(data)
        })
        .catch((error) => {
            console.log('fetch data failed', error);
        });
    }

    setDate(dt) {
        date = dt
        this.getData(`${date} ${new Date(timeStart).toLocaleTimeString('sv-SE')}`, `${date} ${new Date(timeEnd).toLocaleTimeString('sv-SE')}`)
    }
    
    setTime(start, end) {
        timeStart = new Date(start).toLocaleString('sv-SE')
        timeEnd = new Date(end).toLocaleString('sv-SE')
        this.getData(`${date} ${new Date(start).toLocaleTimeString('sv-SE')}`, `${date} ${new Date(end).toLocaleTimeString('sv-SE')}`)
    }

    render() {
        return (
            <>
                <Divider orientation="left"><Title level={4}>สรุปยอดรายวัน</Title></Divider>

                ระบุวันที่: 
                <DatePicker 
                    format={dateFormat} 
                    onChange={(e) => {this.setDate(new Date(e._d).toLocaleDateString('sv-SE'))}}
                    defaultValue={moment(date, dateFormat)}
                />
                <RangePicker 
                    picker="time" 
                    onChange={(e) => this.setTime(e[0]._d, e[1]._d)}
                    defaultValue={[moment(timeStart, timeFormat), moment(timeEnd, timeFormat)]}/>

                {/* <RangePicker 
                    format={dateFormat}
                    onChange={(e) => this.getData(new Date(e[0]._d).toLocaleString('sv-SE'), new Date(e[1]._d).toLocaleString('sv-SE'))}
                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                    showTime={{ hideDisabledOptions: true }}
                /> */}
                <br/>
                <br/>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card   
                            className='text-center' 
                        >
                             <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <h2 className='m-1' style={{ color: this.state.transfer[0].amount >= 0 ? '#3f8600' : '#cf1322' }}>฿ {(this.state.transfer[0].amount).toLocaleString()}</h2>
                                    <p className='text-muted'>ยอดกำไรรวมทั้งหมด</p>  
                                </Col>
                                <Col span={12}>
                                    <h2 className='m-1 text-muted'>{(this.state.transfer[14].count).toLocaleString()} คน</h2>
                                    <p className='text-muted m-0'>Active</p>  
                                    <small className='text-muted'>จำนวนผู้ทำรายการ</small>  
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card   
                            className='text-center text-light' 
                            bodyStyle={{ background: '#198753' }} 
                            title={<>
                                <h2 className='m-1 text-muted'>฿ {(this.state.transfer[1].amount).toLocaleString()}</h2>
                                <p className='text-success'>ยอดฝากรวมทั้งหมด ({(this.state.transfer[1].count).toLocaleString()} รายการ)</p>
                            </>} 
                            bordered={false}
                        >
                            <Row gutter={[0, 0]}>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="SCB" content={<>{(this.state.transfer[3].amount).toLocaleString()} <small>({(this.state.transfer[3].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="Truewallet" content={<>{(this.state.transfer[4].amount).toLocaleString()} <small>({this.state.transfer[4].count} รายการ)</small></>} />
                                    <DescriptionItem title="Payment" content={<>{(this.state.transfer[6].amount).toLocaleString()} <small>({this.state.transfer[6].count} รายการ)</small></>} />
                                </Col>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="KBank" content={<>{(this.state.transfer[5].amount).toLocaleString()} <small>({(this.state.transfer[5].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="แอดมิน" content={<>{(this.state.transfer[2].amount).toLocaleString()} <small>({(this.state.transfer[2].count).toLocaleString()} รายการ)</small></>} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card   
                            className='text-center text-light' 
                            bodyStyle={{ background: '#DC3444' }} 
                            title={<>
                                <h2 className='m-1 text-muted'>฿ {(this.state.transfer[7].amount).toLocaleString()}</h2>
                                <p className='text-danger'>ยอดถอนรวมทั้งหมด ({(this.state.transfer[7].count).toLocaleString()} รายการ)</p>
                            </>} 
                            bordered={false}
                        >
                            <Row gutter={[0, 0]}>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="SCB" content={<>{(this.state.transfer[8].amount).toLocaleString()} <small>({(this.state.transfer[8].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="Truewallet" content={<>{(this.state.transfer[9].amount).toLocaleString()} <small>({(this.state.transfer[9].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="Payment" content={<>{(this.state.transfer[13].amount).toLocaleString()} <small>({(this.state.transfer[12].count).toLocaleString()} รายการ)</small></>} />
                                </Col>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="KBank" content={<>{(this.state.transfer[10].amount).toLocaleString()} <small>({(this.state.transfer[10].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="แอดมิน" content={<>{(this.state.transfer[11].amount).toLocaleString()} <small>({(this.state.transfer[11].count).toLocaleString()} รายการ)</small></>} />
                                    <DescriptionItem title="ถอนไม่โอนเงิน" content={<>{(this.state.transfer[12].amount).toLocaleString()} <small>({(this.state.transfer[11].count).toLocaleString()} รายการ)</small></>} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card   
                            className='text-center text-light' 
                            bodyStyle={{ background: '#7852B2' }} 
                            title={<>
                                <h2 className='m-1 text-muted'>฿ {(this.state.register[1].amount).toLocaleString()}</h2>
                                <p className='' style={{ color: '#7852B2' }}>ยอดสมาชิกใหม่ฝาก ({(this.state.register[1].count).toLocaleString()} รายการ)</p>
                            </>} 
                            bordered={false}
                        >
                            <Row gutter={[16, 16]} className='m-0'>
                                <Col span={12}>
                                    <Statistic className="m-0" valueStyle={{ color: 'white' }} title={<p className='m-0 text-light'>สมัครใหม่</p>} value={(this.state.register[0].amount).toLocaleString()} suffix="คน"/>
                                </Col>
                                <Col span={12}>
                                    <Statistic className="m-0" valueStyle={{ color: 'white' }} title={<p className='m-0 text-light'>สมัครใหม่และฝาก</p>} value={(this.state.register[2].amount).toLocaleString()} suffix="คน"/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card
                            className='text-center text-light' 
                            bodyStyle={{ background: '#10CAF0' }} 
                            title={<>
                                <h2 className='m-1 text-muted'><small>C</small> {(this.state.promotion.bonus).toLocaleString()}</h2>
                                <p className='text-muted m-0'>({(this.state.promotion.count).toLocaleString()} รายการ)</p>
                                <p className='text-info'>ยอดแจกเครดิต โปรโมชั่น</p>
                            </>} 
                            bordered={false}
                        >
                            <DescriptionItem title="ยอดฝาก" content={(this.state.promotion.deposit).toLocaleString()} />
                            <DescriptionItem title="ยอดถอน" content={(this.state.promotion.withdraw).toLocaleString()} />
                            <DescriptionItem title="กำไร" content={(this.state.promotion.total_income).toLocaleString()} />
                        </Card>
                    </Col>
                    
                    <Col span={6}>
                        <Card
                            className='text-center text-light' 
                            bodyStyle={{ background: '#10CAF0' }} 
                            title={<>
                                <h2 className='m-1 text-muted'><small>C</small> {(this.state.event[0].amount + this.state.event[1].amount + this.state.event[2].amount + this.state.event[3].amount).toLocaleString()}</h2>
                                <p className='text-muted m-0'>({(this.state.event[0].count + this.state.event[1].count + this.state.event[2].count + this.state.event[3].count).toLocaleString()} รายการ)</p>
                                <p className='text-info'>ยอดแจกเครดิต กิจกรรมต่างๆ</p>
                            </>} 
                            bordered={false}
                        >
                            <Row gutter={[0, 0]}>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="กงล้อนำโชค" content={<>{(this.state.event[0].amount).toLocaleString()}<small>({(this.state.event[0].count).toLocaleString()})</small></>} />
                                    <DescriptionItem title="ล็อคอินรายวัน" content={<>{(this.state.event[1].amount).toLocaleString()}<small>({(this.state.event[1].count).toLocaleString()})</small></>} />
                                </Col>
                                <Col flex="1 1 200px" className='text-left'>
                                    <DescriptionItem title="เพชรแลกเครดิต" content={<>{(this.state.event[2].amount).toLocaleString()}<small>({(this.state.event[2].count).toLocaleString()})</small></>} />
                                    <DescriptionItem title="โบนัสแลกเครดิต" content={<>{(this.state.event[3].amount).toLocaleString()}<small>({(this.state.event[3].count).toLocaleString()})</small></>} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card
                            className='text-center text-light' 
                            bodyStyle={{ background: '#10CAF0' }} 
                            title={<>
                                <h2 className='m-1 text-muted'><small>P</small> {(this.state.point[0].amount + this.state.point[1].amount + this.state.point[2].amount + this.state.point[3].amount).toLocaleString()}</h2>
                                <p className='text-muted m-0'>({(this.state.point[0].count + this.state.point[1].count + this.state.point[2].count + this.state.point[3].count).toLocaleString()} รายการ)</p>
                                <p className='text-info'>ยอดแจกเพชร</p>
                            </>} 
                            bordered={false}
                        >
                            <Col flex="1 1 200px" className='text-left'>
                                <DescriptionItem title="ฝากรับเพชร" content={<>{(this.state.point[0].amount).toLocaleString()} <small>({(this.state.point[0].count).toLocaleString()} รายการ)</small></>} />
                                <DescriptionItem title="กงล้อนำโชค" content={<>{(this.state.point[1].amount).toLocaleString()} <small>({(this.state.point[1].count).toLocaleString()} รายการ)</small></>} />
                            </Col>
                            <Col flex="1 1 200px" className='text-left'>
                                <DescriptionItem title="ล็อคอินรายวัน" content={<>{(this.state.point[2].amount).toLocaleString()} <small>({(this.state.point[2].count).toLocaleString()} รายการ)</small></>} />
                                <DescriptionItem title="แลกเพชร/แอดมินเติม" content={<>{(this.state.point[3].amount).toLocaleString()} <small>({(this.state.point[3].count).toLocaleString()} รายการ)</small></>} /> 
                            </Col>
                        </Card>
                    </Col>

                    <Col span={6}>
                        <Card
                            className='text-center text-light' 
                            bodyStyle={{ background: '#10CAF0' }} 
                            title={<>
                                <h2 className='m-1 text-muted'><small>B</small> {(this.state.bonus[0].amount + this.state.bonus[1].amount + this.state.bonus[2].amount).toLocaleString()}</h2>
                                <p className='text-muted m-0'>({(this.state.bonus[0].count + this.state.bonus[1].count + this.state.bonus[2].count).toLocaleString()} รายการ)</p>
                                <p className='text-info'>ยอดแจกโบนัส</p>
                            </>} 
                            bordered={false}
                        >
                            <DescriptionItem title="คืนยอดเสีย" content={<>{(this.state.bonus[0].amount).toLocaleString()} <small>({(this.state.bonus[0].count).toLocaleString()} รายการ)</small></>} />
                            <DescriptionItem title="คืนยอดเล่นสะสม" content={<>{(this.state.bonus[2].amount).toFixed(2).toLocaleString()} <small>({(this.state.bonus[2].count).toLocaleString()} รายการ)</small></>} />
                            <DescriptionItem title="คืนยอดเสียแนะนำเพื่อน" content={<>{(this.state.bonus[1].amount).toLocaleString()} <small>({(this.state.bonus[1].count).toLocaleString()} รายการ)</small></>} />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
