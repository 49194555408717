import React, { Component } from 'react';
import { Alert, PageHeader, Card, Button, Table, Spin, Image, Typography, Tag, Modal, message } from 'antd';
import { SafetyOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { URL, WEB_NAME } from '../../../Util/Config'
import axios from 'axios';
import x0p from 'x0popup';

const { Text, Title } = Typography;

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          width: 150,
          //   ...this.getColumnSearchProps('user_username'),
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          width: 300,
          //   ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          //   ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ติดต่อ',
          dataIndex: 'user_phone',
        },
        {
          title: '',
          dataIndex: '',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
              <>
                <Button type="primary" icon={<SafetyOutlined />} onClick={() => this.postCheckAccount(record.user_id)}>ตรวจสอบบัญชี</Button>
                <Button type="primary" ghost icon={<SafetyOutlined />} onClick={() => this.postConfrimAccount(record.user_id)}>บัญชีถูกต้อง</Button>
                <Button danger icon={<DeleteOutlined />} onClick={() => this.postRemove(text)}> ลบข้อมูล</Button>
              </>
          ),
        }
      ],
      data: [],

      columns2: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          width: 150,
          //   ...this.getColumnSearchProps('user_username'),
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          width: 300,
          //   ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          //   ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ติดต่อ',
          dataIndex: 'user_phone',
        },
        {
          title: '',
          dataIndex: 'user_id',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
              <>
                <Button onClick={() => this.setState({ visableModalBank: true, user_id: text })} icon={<EditOutlined />} > แก้ข้อมูลบัญชีธนาคาร</Button><br/>
                <Button type="primary" ghost icon={<SafetyOutlined />} onClick={() => this.postConfrimAccount(record.user_id)}>ยืนยันบัญชีถูกต้อง</Button><br/>
                <Button danger onClick={() => this.postRemove(text)} icon={<DeleteOutlined />}> ลบข้อมูล</Button>
              </>

          ),
        }
      ],
      data2: [],
      loading: true,
      banklist: [],
      fields: {},
      errors: {},
      admin_type: null,
      admin_token: null
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

    this.getData()
    this.getUserNotpass()
    this.getBank()

    setTimeout(() => {
      this.getSCBBalance()
    }, 1000);
  }

  getData() {
    fetch(`${URL}/api/v1/user/verifyIdentity/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      // .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getSCBBalance() {
    const accountSCB = JSON.parse(localStorage.getItem('accountSCB'))
    if (accountSCB) {
      if (accountSCB.status) {
        this.setState({ loading: false })
      }
    }
  }

  async postCheckAccount(user_id) {
    x0p({
      title: 'ยืนยันตัวตน!!',
      text: ``,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: user_id
        }
        await axios.post(URL + "/api/v1/user/check/account", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(async (res) => {
            var data = res.data
            await this.wait()
            if (data.status) {
              x0p(data.message, null, 'ok', false);
            } else if (data.message === 'เลขที่บัญชีไม่ถูกต้อง') {
              this.getUserNotpass()
              x0p(data.message, null, 'error', false);
            } else {
              x0p(data.message, null, 'error', false);
            }
            this.getData()
          })
          .catch(error => {
            console.log(error)
            console.log('การเชื่อมต่อผิดพลาด Update')
            x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
          })
      }
    })
  }

  async wait() {
    return new Promise(resolve => setTimeout(resolve, 15000 + Math.floor(Math.random() * 10000)));
  }

  getUserNotpass() {
    fetch(`${URL}/api/v1/user/verifyIdentity/notpass`)
      .then((response) => response.json())
      .then((json) => this.setState({ data2: json.result }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  contactSubmit() {
    if (this.handleValidation()) {
      this.updateUser()
    } else {
      message.error('กรุณาตรวจสอบข้อมูลให้ถูกต้อง!!');
    }
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["banknumber"] && fields["banknumber"] !== '') {
      formIsValid = false;
      errors["banknumber"] = "กรุณากรอกข้อมูลเลขบัญชีธนาคาร";
    }

    if (typeof fields["banknumber"] !== "undefined" && fields["banknumber"] !== '') {
      if (!fields["banknumber"].match(/\d+/)) {
        formIsValid = false;
        errors["banknumber"] = "กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง กรอกได้เฉพาะตัวเลข (0-9) เท่านั้น";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  // GET BANK ดึงข้อมูลธนาคาร
  async getBank() {
    await axios.get(URL + "/api/v1/bank/all")
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({ banklist: data.result, loading: false })
          this.handleChange(data.result[0].bank_id, "bankid")
        } else {
          // alert(data.status + " : " + data.message)
          console.log('ข้อมูลไม่ถูกต้อง getBank')
          console.log(data)
        }
      })
      .catch(error => {
        console.log(error);
        message.error('การเชื่อมต่อผิดพลาด Get Bank!!');
      })
  }

  handleChange(value, field) {
    // console.log("value : ", value)
    // console.log("filed : ", field)
    let fields = this.state.fields;
    fields[field] = value;
    this.setState({ fields });
  }

  // Select รายชื่อธนาคาร
  bankList() {
    const bankList = this.state.banklist
    if (bankList) {
      let list = bankList.map((item, index) =>
        <option key={index} value={item.bank_id}>{item.bank_name_th}</option>
      );
      return (list)
    }
  }

  // แก้ไขข้อมูลธนาคาร
  async updateUser() {
    const fields = this.state.fields
    const body = {
      user_id: this.state.user_id,
      bankID: fields["bankid"],
      bankNumber: fields["banknumber"],
      // telphone: fields["telphone"],
      // telphone: this.state.userProfile.user_phone 
    }
    await axios.post(URL + "/api/v1/user/update", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        // console.log(res.data)
        var data = res.data
        if (data.status) {
          message.success('ทำรายการเสร็จสิ้น!!');
          this.setState({ visableModalBank: false })
          this.getData()
          this.getUserNotpass()
          this.sendMessageSucces(`คุณได้ทำการเปลี่ยนบัญชีธนาคาร \nเลขบัญชี: ${fields["banknumber"]}`)

        } else if (data.message === 'เลขที่บัญชีไม่ถูกต้อง') {
          let errors = {};
          errors["banknumber"] = "เลขบัญชีธนาคารไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง!!";
          this.setState({ errors: errors });
          message.error('เลขบัญชีธนาคารไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง!!');
        } else {
          message.error(data.message);
        }
        this.getData()
        this.getUserNotpass()
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด Update')
        this.setState({ loading: false })
      })
  }

  // ลบข้อมูลของยูสเซอร์
  async postRemove(user_id) {
    x0p({
      title: 'ลบข้อมูล บัญชีธนาคาร เบอร์ติดต่อ ของยูสนี้!!',
      text: ``,
      icon: 'warning',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'warning',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'warning') {
        console.log(URL + `/api/v1/user/remove/${user_id}`)
        await axios.get(URL + `/api/v1/user/remove/${user_id}`, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            if (data.status) {
              x0p(data.message, null, 'ok', false);
              this.getData()
              this.getUserNotpass()
            } else {
              x0p(data.message, null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error);
            message.error('การเชื่อมต่อผิดพลาด PostRemove!!');
          })
      }
    })
  }

  // ยืนยันบัญชีจากแอดมิน
  async postConfrimAccount(user_id) {
    x0p({
      title: 'ยืนยันบัญชีด้วยแอดมิน',
      text: `หากยืนยันโดยไม่ตรวจสอบจากธนาคาร อาจะไม่ฝาก หรือถอน อัตโนมัติได้ ขึ้นอยู่กับความถูกต้องของข้อมูล`,
      icon: 'warning',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'warning',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      const body = {
        user_id: user_id
      }
      await axios.post(URL + "/api/v1/user/check/account/admin", body, {
        headers: {
          'Authorization': `Bearer ${this.state.admin_token}`,
        }
      })
        .then(async (res) => {
          const data = res.data
          if (data.status) {
            this.getData()
            this.getUserNotpass()
            x0p(data.message, null, 'ok', false);
          } else {
            x0p(data.message, null, 'error', false);
          } 
        })
        .catch(error => {
          console.log("postConfrimAccount")
          x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
        })  
    })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'สมาชิกรอยืนยันตัวตน',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="สมาชิกรอยืนยันตัวตน"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />

        <Alert
          banner
          message="ยืนยันข้อมูลบัญชีธนาคารให้สมาชิกใหม่"
          description="รายการสมาชิกใหม่ที่ยังไม่ได้ยืนยันข้อมูลบัญชีธนาคาร สาเหตุที่ต้องยืนยันที่หลังเนื่องจากระบบธนาคาร (ATUO) เกิดข้อผิดพลาดไม่สามารถตรวจสอบข้อมูลได้ จึงต้องมีการยืนยันตนทีหลัง"
          type="warning"
        />
        <br />

        {this.state.loading ?
          <>
            <Alert
              message="AUTO หลุด"
              description="ไม่สามารถเชื่อมระบบธนาคารได้เพื่อตรวจสอบบัญชีสมาชิก"
              type="error"
              showIcon
            />
            <br />
          </>
          : <></>
        }

        <Card>
          <Spin spinning={this.state.loading ? true : false}>
            <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
          </Spin>
        </Card>

        <br />
        <br />
        <Card>
          <Title level={4}>สมาชิกยืนยันตัวตนไม่ผ่าน <Tag color="red">(บัญชีธนาคารไม่ถูกต้อง)</Tag></Title>
          <br />
          <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 10 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
        </Card>

        {/* แก้ไขบัญชีธนาคาร */}
        <Modal
          title="แก้ไขข้อมูลบัญชีธนาคาร"
          open={this.state.visableModalBank}
          onCancel={() => this.setState({ visableModalBank: false })}
          footer={[
            <Button type='primary' ghost onClick={() => {
              this.handleChange('', "banknumber")
              this.contactSubmit()
            }}>
              ไม่ระบุบัญชี
            </Button>,
            <Button key="back" onClick={() => this.setState({ visableModalBank: false })}>
              ยกเลิก
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => this.contactSubmit()}>
              ยืนยันแก้ไข
            </Button>,
          ]}
        >
          <div class="col-12">
            <label for="banknumber" class="form-label">ธนาคาร</label>
            <select class="form-select" aria-label="Default select example" name="bank_id" onChange={(e) => this.handleChange(e.target.value, "bankid")} >
              {this.bankList()}
            </select>
          </div>

          <div class="col-12" style={{ marginTop: 10 }}>
            <label for="banknumber" class="form-label">เลขบัญชีธนาคาร</label>
            <div class="input-group has-validation">
              <span class="input-group-text"><i class="material-icons">account_balance</i></span>
              <input type="text" class="form-control" pattern="[0-9]" id="banknumber" name="banknumber" onKeyUp={(e) => this.handleChange(e.target.value, "banknumber")} />
              <div class="invalid-feedback">
                กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง
              </div>
            </div>
            <div class="error" className="form-text text-danger">{this.state.errors["banknumber"]}</div>
          </div>
        </Modal>
      </>
    );
  }
}
