import React, { Component } from 'react';
import { Divider, Row, Col, Switch, InputNumber, Button, Statistic, Select, Typography, Form, Table, Input, Tag, Modal, Image, Tabs, Space, Alert, Card, Upload } from 'antd';
import { SettingOutlined, SearchOutlined, StarFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL } from '../../../../Util/Config'
import x0p from 'x0popup';
import axios from 'axios';
// import TypeGame from '../howto/typeGame';
import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UploadOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

export default class returnloss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnlossAffiliate: {
                returnloss_network_type: 'เครดิต',
            },
            network_timeStart: '00:00:00',
            network_timeEnd: '00:01:00',
            network_day: [],
            columns: [
                {
                    title: 'เลเวล',
                    dataIndex: 'returnloss_network_level_x',
                },
                {
                    title: 'จำนวนเพื่อนขั้นต่ำ',
                    dataIndex: 'returnloss_network_amount_affiliate',
                    render: (text, record) => (
                        <>{text} คน</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย',
                    dataIndex: 'returnloss_network_amount',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                // {
                //     title: 'โบนัสขั้นต่ำ',
                //     dataIndex: 'returnloss_network_min',
                //     render: (text, record) => (
                //         <>{text} บาท</>
                //     )
                // },
                {
                    title: 'โบนัสสูงสุด',
                    dataIndex: 'returnloss_network_max',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'icon',
                    dataIndex: 'returnloss_network_icon',
                    render: (text, record) => (
                        <Image src={text.includes('http') ? text : URL + '/public/images/return/returnNetwork/' + text} width={30} />
                    )
                },
                {
                    title: '',
                    dataIndex: 'returnloss_network_id',
                    render: (text, record, index) => (
                        this.state.admin_type === 'G' ? null :
                            record.returnloss_network_level_x === '1'
                                ?
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossNetworkEditVisible: true,
                                            edit_amount_affiliate_min: 1,
                                            edit_amount_affiliate_max: this.state.data[index + 1] !== undefined ? parseInt(this.state.data[index + 1].returnloss_network_amount_affiliate) - 1 : 1000000,
                                            edit_amount_min: 1,
                                            edit_amount_max: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount) - 0.1 : 100,
                                            edit_amount_min_slot: 1,
                                            edit_amount_max_slot: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_slot) - 0.1 : 100,
                                            edit_amount_min_baccarat: 1,
                                            edit_amount_max_baccarat: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossNetworkEdit: record
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" disabled><DeleteOutlined /> ลบ</Button>
                                </>
                                :
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossNetworkEditVisible: true,
                                            edit_amount_affiliate_min: parseInt(this.state.data[index - 1].returnloss_network_amount_affiliate) + 1,
                                            edit_amount_affiliate_max: this.state.data[index + 1] !== undefined ? parseInt(this.state.data[index + 1].returnloss_network_amount_affiliate) - 1 : 1000000,
                                            edit_amount_min: parseFloat(this.state.data[index - 1].returnloss_network_amount) + 0.1,
                                            edit_amount_max: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount) - 0.1 : 100,
                                            edit_amount_min_slot: parseFloat(this.state.data[index - 1].returnloss_network_amount_slot) + 0.1,
                                            edit_amount_max_slot: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_slot) - 0.1 : 100,
                                            edit_amount_min_baccarat: parseFloat(this.state.data[index - 1].returnloss_network_amount_baccarat) + 0.1,
                                            edit_amount_max_baccarat: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossNetworkEdit: record,
                                            level_x: record.returnloss_network_level_x,
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" onClick={() => this.postRemoveReturnlossNetwork(text)}><DeleteOutlined /> ลบ</Button>
                                </>
                    ),
                },
            ],
            columnsWL: [
                {
                    title: 'เลเวล',
                    dataIndex: 'returnloss_network_level_x',
                },
                {
                    title: 'จำนวนเพื่อนขั้นต่ำ',
                    dataIndex: 'returnloss_network_amount_affiliate',
                    render: (text, record) => (
                        <>{text} คน</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย (สล็อต)',
                    dataIndex: 'returnloss_network_amount_slot',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย (คาสิโน)',
                    dataIndex: 'returnloss_network_amount_baccarat',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                // {
                //     title: 'โบนัสขั้นต่ำ',
                //     dataIndex: 'returnloss_network_min',
                //     render: (text, record) => (
                //         <>{text} บาท</>
                //     )
                // },
                {
                    title: 'โบนัสสูงสุด',
                    dataIndex: 'returnloss_network_max',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'icon',
                    dataIndex: 'returnloss_network_icon',
                    render: (text, record) => (
                        <Image src={text.includes('http') ? text : URL + '/public/images/return/returnNetwork/' + text} width={30} />
                    )
                },
                {
                    title: '',
                    dataIndex: 'returnloss_network_id',
                    render: (text, record, index) => (
                        this.state.admin_type === 'G' ? null :
                            record.returnloss_network_level_x === '1'
                                ?
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossNetworkEditVisible: true,
                                            edit_amount_affiliate_min: 1,
                                            edit_amount_affiliate_max: this.state.data[index + 1] !== undefined ? parseInt(this.state.data[index + 1].returnloss_network_amount_affiliate) - 1 : 1000000,
                                            edit_amount_min: 1,
                                            edit_amount_max: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount) - 0.1 : 100,
                                            edit_amount_min_slot: 1,
                                            edit_amount_max_slot: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_slot) - 0.1 : 100,
                                            edit_amount_min_baccarat: 1,
                                            edit_amount_max_baccarat: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossNetworkEdit: record
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" disabled><DeleteOutlined /> ลบ</Button>
                                </>
                                :
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossNetworkEditVisible: true,
                                            edit_amount_affiliate_min: parseInt(this.state.data[index - 1].returnloss_network_amount_affiliate) + 1,
                                            edit_amount_affiliate_max: this.state.data[index + 1] !== undefined ? parseInt(this.state.data[index + 1].returnloss_network_amount_affiliate) - 1 : 1000000,
                                            edit_amount_min: parseFloat(this.state.data[index - 1].returnloss_network_amount) + 0.1,
                                            edit_amount_max: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount) - 0.1 : 100,
                                            edit_amount_min_slot: parseFloat(this.state.data[index - 1].returnloss_network_amount_slot) + 0.1,
                                            edit_amount_max_slot: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_slot) - 0.1 : 100,
                                            edit_amount_min_baccarat: parseFloat(this.state.data[index - 1].returnloss_network_amount_baccarat) + 0.1,
                                            edit_amount_max_baccarat: this.state.data[index + 1] !== undefined ? parseFloat(this.state.data[index + 1].returnloss_network_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossNetworkEdit: record,
                                            level_x: record.returnloss_network_level_x,
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" onClick={() => this.postRemoveReturnlossNetwork(text)}><DeleteOutlined /> ลบ</Button>
                                </>
                    ),
                },
            ],
            data: [],
            isModalReturnlossNetworkEditVisible: false,
            isModalReturnlossNetworkAddVisible: false,
            dataReturnlossNetworkEdit: {},
            dataReturnlossNetworkAdd: {},
            level_x: 1,
            level_y: 1,
            add_amount_affiliate_min: null,
            add_amount_min: null,
            add_amount_min_slot: null,
            add_amount_min_baccarat: null,
            edit_amount_affiliate_min: null,
            edit_amount_affiliate_max: 1,
            edit_amount_min: 1,
            edit_amount_min_slot: 1,
            edit_amount_min_baccarat: 1,
            edit_amount_max: 1,
            edit_amount_max_slot: 1,
            edit_amount_max_baccarat: 1,

            columns2: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'ชื่อผู้ใช้งาน',
                    dataIndex: 'user_title',
                    ...this.getColumnSearchProps('user_title'),
                    render: (text, record) => (
                        <div>{record.user_advisor} {text}</div>
                    )
                },
                {
                    title: 'จำนวนเพื่อนที่แนะนำ',
                    dataIndex: 'amount_recommend',
                    sorter: (a, b) => a.amount_recommend - b.amount_recommend,
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} คน</Tag>
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: '',
                    sorter: (a, b) => a.returnloss_network_amount - b.returnloss_network_amount,
                    render: (text, record) => (
                        record.type === 'เครดิต' ?
                            <Tag color='orange'>{record.amount}</Tag>
                            :
                            <Tag color='orange'>{record.amount_game}</Tag>
                    )
                }
            ],
            data2: [],

            columns3: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'log_returnloss_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.returnloss_id - b.returnloss_id,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'username',
                    dataIndex: 'user_username',
                    ...this.getColumnSearchProps('user_username'),
                    render: (text, record) => (
                        <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
                    )
                },
                {
                    title: 'ยอดเสียของเพื่อนที่แนะนำ',
                    dataIndex: 'log_returnloss_loss',
                    render: (text, record) => (
                        <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'โบนัสที่ได้รับ',
                    dataIndex: 'log_returnloss_amount',
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} โบนัส</Tag>
                    )
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'log_returnloss_note',
                    render: (text, record) => (
                        text
                    )
                },
                {
                    title: 'วัน/เวลา ที่ได้รับโบนัส',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data3: [],
            summary: {},
            admin_type: null,
            admin_token: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.getReturnlossAffiliate(1)
        this.getHistory()
        this.getUser()
        this.getSummary()
    }

    getSummary() {
        fetch(`${URL}/api/v1/affiliate/returnloss/summary`)
            .then((response) => response.json())
            .then((json) => this.setState({
                summary: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getHistory() {
        fetch(`${URL}/api/v1/returnloss/log/type/1`)
            .then((response) => response.json())
            .then((json) => this.setState({
                data3: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getUser() {
        fetch(`${URL}/api/v1/returnloss/affiliate/user`)
            .then((response) => response.json())
            .then((json) => this.setState({
                data2: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getReturnlossAffiliate(level_y) {
        fetch(`${URL}/api/v1/returnloss/affiliate/levely/${level_y}`)
            .then((response) => response.json())
            .then((json) => this.setState({
                data: json.result,
                returnlossAffiliate: json.result[0],
                network_day: json.result[0].returnloss_network_day.split(','),
                network_timeStart: json.result[0].returnloss_network_time.split('-')[0],
                network_timeEnd: json.result[0].returnloss_network_time.split('-')[1],
                add_amount_affiliate_min: parseInt(json.result[json.result.length - 1].returnloss_network_amount_affiliate) + 1,
                add_amount_min: parseFloat(json.result[json.result.length - 1].returnloss_network_amount) + 0.1,
                add_amount_min_slot: parseFloat(json.result[json.result.length - 1].returnloss_network_amount_slot) + 0.1,
                add_amount_min_baccarat: parseFloat(json.result[json.result.length - 1].returnloss_network_amount_baccarat) + 0.1,
                dataReturnlossNetworkAdd: {
                    ...this.state.dataReturnlossNetworkAdd,
                    returnloss_network_amount_affiliate: parseInt(json.result[json.result.length - 1].returnloss_network_amount_affiliate) + 1,
                    returnloss_network_amount: parseFloat(json.result[json.result.length - 1].returnloss_network_amount) + 0.1,
                },
            }))
            // .then(() => console.log(this.state.dataReturnlossNetworkAdd))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // เปิด-ปิดระบบ
    statusAffiliateChange(status) {
        this.postAffiliateUpdate(status ? 'เปิด' : 'ปิด')
    }

    postAffiliateUpdate(status) {
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการแก้ไขการคืนยอดเสียใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    type: this.state.returnlossAffiliate.returnloss_network_type,
                    amount_day: this.state.returnlossAffiliate.returnloss_network_amount_day,
                    status: status !== undefined ? status : this.state.returnlossAffiliate.returnloss_network_status,
                    day: this.state.network_day.toString(),
                    time: this.state.network_timeStart + '-' + this.state.network_timeEnd,
                }
                await axios.post(`${URL}/api/v1/returnloss/affiliate/update/status`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.setState({ returnlossAffiliate: { ...this.state.returnlossAffiliate, returnloss_network_status: status !== undefined ? status : this.state.returnlossAffiliate.returnloss_network_status } })
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    postUpdateReturnlossNetwork() {
        const typeTurn = this.state.returnlossAffiliate.returnloss_network_type
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const formData = new FormData();
                formData.append('level_y', this.state.dataReturnlossNetworkEdit.returnloss_network_level_y);
                formData.append("level_x", this.state.dataReturnlossNetworkEdit.returnloss_network_level_x);
                formData.append("amount_affiliate", this.state.dataReturnlossNetworkEdit.returnloss_network_amount_affiliate);
                formData.append("amount", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkEdit.returnloss_network_amount : this.state.dataReturnlossNetworkEdit.returnloss_network_amount_slot,);
                formData.append("amount_slot", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkEdit.returnloss_network_amount : this.state.dataReturnlossNetworkEdit.returnloss_network_amount_slot,);
                formData.append("amount_baccarat", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkEdit.returnloss_network_amount : this.state.dataReturnlossNetworkEdit.returnloss_network_amount_baccarat,);
                formData.append("min", this.state.dataReturnlossNetworkEdit.returnloss_network_min,);
                formData.append("max", this.state.dataReturnlossNetworkEdit.returnloss_network_max,);
                formData.append("image", this.state.dataReturnlossNetworkEdit.returnloss_network_icon);

                const res = await fetch(`${URL}/api/v1/returnloss/affiliate/update`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                  }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnlossAffiliate(this.state.level_y)
                    this.setState({ isModalReturnlossNetworkEditVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postAddReturnlossNetwork() {
        const typeTurn = this.state.returnlossAffiliate.returnloss_network_type
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const formData = new FormData();
                formData.append('level_y', this.state.level_y);
                formData.append("level_x", this.state.level_x);
                formData.append("amount_affiliate", this.state.dataReturnlossNetworkAdd.returnloss_network_amount_affiliate);
                formData.append("amount", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkAdd.returnloss_network_amount : this.state.dataReturnlossNetworkAdd.returnloss_network_amount_slot);
                formData.append("amount_slot", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkAdd.returnloss_network_amount : this.state.dataReturnlossNetworkAdd.returnloss_network_amount_slot);
                formData.append("amount_baccarat", typeTurn === 'เครดิต' ? this.state.dataReturnlossNetworkAdd.returnloss_network_amount : this.state.dataReturnlossNetworkAdd.returnloss_network_amount_baccarat);
                formData.append("min", this.state.dataReturnlossNetworkAdd.returnloss_network_min);
                formData.append("max", this.state.dataReturnlossNetworkAdd.returnloss_network_max);
                formData.append("image", this.state.dataReturnlossNetworkAdd.returnloss_network_icon);

                const res = await fetch(`${URL}/api/v1/returnloss/affiliate/add`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                  }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnlossAffiliate(this.state.level_y)
                    this.setState({ isModalReturnlossNetworkAddVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postRemoveReturnlossNetwork(returnloss_network_id) {
        x0p({
            title: 'ยืนยันลบ!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    returnloss_network_id: returnloss_network_id
                }
                await axios.post(`${URL}/api/v1/returnloss/affiliate/remove`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getReturnlossAffiliate(this.state.level_y)
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    OnChangeType(value) {
        // if (value === 'เล่นได้เสีย') {
        //     this.setState({ returnlossAffiliate: { ...this.state.returnlossAffiliate, returnloss_network_type: value, returnloss_network_amount_day: 1 } })

        // } else {
            this.setState({ returnlossAffiliate: { ...this.state.returnlossAffiliate, returnloss_network_type: value } })
        // }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเสียแนะนำเพื่อนทั้งหมด"
                                value={this.state.summary.total_count}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="โบนัส(เครดิตที่แจก)ทั้งหมด"
                                value={this.state.summary.total_amount}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="เครดิต"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเสียแนะนำเพื่อนวันนี้"
                                value={this.state.summary.total_count_tody}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="โบนัส(เครดิตที่แจก)วันนี้"
                                value={this.state.summary.total_amount_tody}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="เครดิต"
                            />
                        </Card>
                    </Col>
                </Row>
                <br />

                <Card>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={20} style={{ textAlign: 'right' }}>
                            <Divider orientation="left"><SettingOutlined /> ตั่งค่า คืนยอดเสียของเพื่อนที่แนะนำ</Divider>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right', marginTop: 15 }}>
                            สถานะการใช้งาน :  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.returnlossAffiliate.returnloss_network_status === 'เปิด' ? true : false} onChange={(e) => this.statusAffiliateChange(e)} disabled={this.state.admin_type === 'G' ? true : false} />
                        </Col>
                    </Row>

                    <Row justify="start">
                        <Col span={12}>
                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="เงื่อนไข">
                                    <p className='m-0'>ลูกค้าจะได้รับ <span className='text-danger'>"โบนัส"</span> (เหรียญโบนัส สามารถแลกเป็นเครดิตทีหลัง) ตามช่วงวันที่ระบุ</p>
                                    <p className='m-0'>ระบบจะคำนวณโบนัส ให้สมาชิก ช่วงเวลา 13:00 - 18:00 น.</p>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="ประเภทการคืนยอดเสีย" extra={this.state.returnlossAffiliate.returnloss_network_type === 'เครดิต' ? `คำนวณจาก ((ยอดถอน - (ยอดฝาก - ยอดฝากจากโปรฯ)) - ยอดคงเหลือ) = โบนัสคืนยอดเสีย` : 'คำนวณจากข้อมูลที่เก็บจาก AG โดยตรง'}>
                                    <Select value={this.state.returnlossAffiliate.returnloss_network_type} onChange={(value) => this.OnChangeType(value)}>
                                        <Option value="เล่นได้เสีย" disabled>ยอดเทิร์นเล่นได้เสียจริง</Option>
                                        <Option value="เครดิต">ยอดเทิร์นเครดิต</Option>
                                    </Select>
                                </Form.Item>

                                <Form labelCol={{ span: 8 }}>
                                <Form.Item label="การแจกโบนัส" extra={this.state.returnlossAffiliate.returnloss_network_amount_day === "7" ? 'คืนยอดทุกวันอาทิตย์ (อาทิตย์-เสาร์ ที่ผ่านมา)' : 'คืนยอดทุกวัน (ข้อมูลวันก่อน)'}>
                                        {/* <InputNumber
                                            value={this.state.returnlossAffiliate.returnloss_network_amount_day}
                                            extra='จำนวนวันในการรับโบนัส เช่น 1วันรับ/ครั้ง, 7วันรับ/ครั้ง, 15วันรับ/ครั้ง'
                                            // disabled={this.state.returnlossAffiliate.returnloss_network_type === 'เล่นได้เสีย' ? true : false}
                                            min={1}
                                            max={30}
                                            addonAfter="วัน/ครั้ง"
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.setState({ returnlossAffiliate: { ...this.state.returnlossAffiliate, returnloss_network_amount_day: value } })}
                                        /> */}
                                        <Select 
                                            value={this.state.returnlossAffiliate.returnloss_network_amount_day} 
                                            onChange={(value) => this.setState({ returnlossAffiliate: { ...this.state.returnlossAffiliate, returnloss_network_amount_day: value } })}
                                            disabled
                                        >
                                            <Option value="1">รายวัน</Option>
                                            <Option value="7">รายอาทิตย์</Option>
                                        </Select>
                                    </Form.Item>
                                </Form>

                                {/* <Form labelCol={{ span: 8 }}>
                                    <Form.Item label="วันที่สามารถรับได้">
                                        <Checkbox.Group style={{ width: '100%' }} value={this.state.network_day} onChange={(value) => this.setState({ network_day: value })}>
                                            <Checkbox value="0">อาทิตย์ (Sun)</Checkbox>
                                            <Checkbox value="1">จันทร์ (Mon)</Checkbox>
                                            <Checkbox value="2">อังคาร (Tue)</Checkbox>
                                            <Checkbox value="3">พุธ (Wed)</Checkbox>
                                            <Checkbox value="4">พฤหัสบดี (Thu)</Checkbox>
                                            <Checkbox value="5">ศุกร์ (Fri)</Checkbox>
                                            <Checkbox value="6">เสาร์ (Sat)</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Form>

                                <Form labelCol={{ span: 8 }}>
                                    <Form.Item label="เวลาที่สามารถรับได้">
                                        <RangePicker
                                            value={[moment(`2021-07-13 ${this.state.network_timeStart}`), moment(`2021-07-13 ${this.state.network_timeEnd}`)]}
                                            picker="time"
                                            onChange={(value) => this.setState({ network_timeStart: value[0]._d.toLocaleTimeString('it-IT'), network_timeEnd: value[1]._d.toLocaleTimeString('it-IT') })}
                                        />
                                    </Form.Item>
                                </Form> */}

                                <Form labelCol={{ span: 8 }}>
                                    <Form.Item label=" " colon={false}>
                                        <Button type="primary" onClick={() => this.postAffiliateUpdate()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
                                    </Form.Item>
                                </Form>
                            </Form>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="1"
                        tabPosition='left'
                        onEdit={(v) => console.log(v)}
                        type="editable-card"
                    // items={new Array(30).fill(null).map((_, i) => {
                    //   const id = String(i);
                    //   return {
                    //     label: `สายชั้นขั้นบันได-${id}`,
                    //     key: id,
                    //     disabled: i === 28,
                    //     children: `Content of tab ${id}`,
                    //   };
                    // })}
                    >
                        <Tabs.TabPane tab="สายชั้นที่: 1" key="1">
                            <Row style={{ marginBottom: 20 }}>
                                <Col span={12}>
                                    <Button onClick={() => this.setState({ isModalReturnlossNetworkAddVisible: true, level_x: this.state.data.length + 1 })} style={{ marginRight: 10 }} disabled={this.state.admin_type === 'G' ? true : false}><PlusCircleOutlined /> เพิ่มเลเวล</Button>
                                </Col>
                            </Row>
                            <Table columns={this.state.returnlossAffiliate.returnloss_network_type === 'เครดิต' ? this.state.columns : this.state.columnsWL} dataSource={this.state.data} />
                        </Tabs.TabPane>
                    </Tabs>

                    <br />
                    <br />
                    <Tabs defaultActiveKey="2">
                        <Tabs.TabPane tab="ราชื่อสมาชิก" key="1">
                            <Alert
                                message={`แสดงข้อมูลรายชื่อสมาชิกที่มีเพื่อนที่แนะนำมากกว่า ${this.state.data.length > 1 ? this.state.data[0].returnloss_network_amount_affiliate : ''} คน`}
                                type="warning"
                            />
                            <br />
                            <Table columns={this.state.columns2} dataSource={this.state.data2} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ประวัติการรับยอดเสียคืน" key="2">
                            <Alert message="แสดงข้อมูลภายใน 1 เดือนเท่านั้น" type="warning" />
                            <br />
                            <Table columns={this.state.columns3} dataSource={this.state.data3} />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>

                {/* Modal เพิ่มช่วงเลาเวลการคืนยอดเสียแนะนำเพื่อน */}
                <Modal title="เพิ่มช่วงเลาเวลการคืนยอดเสียแนะนำเพื่อน" open={this.state.isModalReturnlossNetworkAddVisible} onOk={() => this.postAddReturnlossNetwork()} onCancel={() => this.setState({ isModalReturnlossNetworkAddVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="ชั้นที่" required>
                            <InputNumber
                                value={this.state.level_y}
                                onChange={(value) => this.setState({ level_y: value })}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label="เลเวล" required>
                            <InputNumber
                                value={this.state.level_x}
                                onChange={(value) => this.setState({ level_x: value })}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>จำนวนเพื่อนขั้นต่ำ <Tag color="red">ขั้นต่ำ {this.state.add_amount_affiliate_min} คน</Tag></>} required>
                            <InputNumber
                                defaultValue={this.state.add_amount_affiliate_min}
                                min={this.state.add_amount_affiliate_min}
                                max={1000000}
                                onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_amount_affiliate: value } })}
                                style={{ width: '100%' }}
                                addonAfter="คน"
                            />
                        </Form.Item>

                        {this.state.returnlossAffiliate.returnloss_network_type === 'เครดิต' ?
                            <Form.Item label={<>% การคืนยอดเสีย <Tag color="red">ขั้นต่ำ {this.state.add_amount_min}%</Tag></>} required>
                                <InputNumber
                                    defaultValue={this.state.add_amount_min}
                                    min={this.state.add_amount_min}
                                    onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_amount: value } })}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Form.Item>
                            :
                            <>
                                <Form.Item label={<>% การคืนยอดเสีย (สล็อต) <Tag color="red">ขั้นต่ำ {this.state.add_amount_min_slot}%</Tag></>} required>
                                    <InputNumber
                                        defaultValue={this.state.add_amount_min_slot}
                                        min={this.state.add_amount_min_slot}
                                        onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_amount_slot: value } })}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                    />
                                </Form.Item>

                                <Form.Item label={<>% การคืนยอดเสีย (คาสิโน) <Tag color="red">ขั้นต่ำ {this.state.add_amount_min_baccarat}%</Tag></>} required>
                                    <InputNumber
                                        defaultValue={this.state.add_amount_min_baccarat}
                                        min={this.state.add_amount_min_baccarat}
                                        onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_amount_baccarat: value } })}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                    />
                                </Form.Item>
                            </>
                        }

                        {/* <Form.Item label={<>โบนัสขั้นต่ำ รับยอดเสีย</>} required>
                            <InputNumber
                                value={this.state.dataReturnlossNetworkAdd.returnloss_network_min}
                                onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_min: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item> */}

                        <Form.Item label={<>โบนัสสูงสุด รับยอดเสีย</>} required>
                            <InputNumber
                                value={this.state.dataReturnlossNetworkAdd.returnloss_network_max}
                                min={this.state.add_bonus_max}
                                onChange={(value) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_max: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item>

                        {/* <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 25×25px 30×30px 35×35px"
                            required
                        >
                            <Input placeholder="ลิ้ง URL รูปภาพโปรโมชั่น" value={this.state.dataReturnlossNetworkAdd.returnloss_network_icon} onChange={(e) => this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_icon: e.target.value } })} />
                        </Form.Item>
                        <Image src={this.state.dataReturnlossNetworkAdd.returnloss_network_icon} style={{ width: '20%' }} /> */}

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.webp,.gif'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnlossNetworkAdd: { ...this.state.dataReturnlossNetworkAdd, returnloss_network_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Modal แก้ไขการคืนยอดเสียแนะนำเพื่อน */}
                <Modal title="แก้ไขการคืนยอดเสียแนะนำเพื่อน" open={this.state.isModalReturnlossNetworkEditVisible} onOk={() => this.postUpdateReturnlossNetwork()} onCancel={() => this.setState({ isModalReturnlossNetworkEditVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="ชั้นที่" required>
                            <Input
                                value={this.state.dataReturnlossNetworkEdit.returnloss_network_level_y}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label="เลเวล" required>
                            <Input
                                value={this.state.dataReturnlossNetworkEdit.returnloss_network_level_x}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>จำนวนเพื่อนขั้นต่ำ {this.state.dataReturnlossNetworkEdit.returnloss_network_level_x !== '1' ? <><Tag color='red'>ขั้นต่ำ {this.state.edit_amount_affiliate_min} คน</Tag><Tag color='red'>สูงสุด {this.state.edit_amount_affiliate_max.toLocaleString()} คน</Tag></> : ''} </>} required>
                            <InputNumber
                                value={this.state.dataReturnlossNetworkEdit.returnloss_network_amount_affiliate}
                                min={this.state.edit_amount_affiliate_min}
                                max={this.state.edit_amount_affiliate_max}
                                disabled={this.state.dataReturnlossNetworkEdit.returnloss_network_level_x === '1' ? true : false}
                                style={{ width: '100%' }}
                                addonAfter="คน"
                                onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_amount_affiliate: value } })}
                            />
                        </Form.Item>

                        {this.state.returnlossAffiliate.returnloss_network_type === 'เครดิต' ?
                            <Form.Item label={<>% การคืนยอดเสีย <Tag color='red'>ขั้นต่ำ {this.state.edit_amount_min}%</Tag> <Tag color='red'>สูงสุด {this.state.edit_amount_max}%</Tag></>} required>
                                <InputNumber
                                    value={this.state.dataReturnlossNetworkEdit.returnloss_network_amount}
                                    min={this.state.edit_amount_min}
                                    max={this.state.edit_amount_max}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                    onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_amount: value } })}
                                />
                            </Form.Item>
                            :
                            <>
                                <Form.Item label={<>% การคืนยอดเสีย (สล็อต)<Tag color='red'>ขั้นต่ำ {this.state.edit_amount_min_slot}%</Tag> <Tag color='red'>สูงสุด {this.state.edit_amount_max_slot}%</Tag></>} required>
                                    <InputNumber
                                        value={this.state.dataReturnlossNetworkEdit.returnloss_network_amount_slot}
                                        min={this.state.edit_amount_min_slot}
                                        max={this.state.edit_amount_max_slot}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                        onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_amount_slot: value } })}
                                    />
                                </Form.Item>
                                <Form.Item label={<>% การคืนยอดเสีย (คาสิโน)<Tag color='red'>ขั้นต่ำ {this.state.edit_amount_min_baccarat}%</Tag> <Tag color='red'>สูงสุด {this.state.edit_amount_max_baccarat}%</Tag></>} required>
                                    <InputNumber
                                        value={this.state.dataReturnlossNetworkEdit.returnloss_network_amount_baccarat}
                                        min={this.state.edit_amount_min_baccarat}
                                        max={this.state.edit_amount_max_baccarat}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                        onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_amount_baccarat: value } })}
                                    />
                                </Form.Item>
                            </>
                        }

                        {/* <Form.Item label='โบนัสขั้นต่ำ การรับยอดเสีย' required>
                            <InputNumber
                                value={this.state.dataReturnlossNetworkEdit.returnloss_network_min}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                                onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_min: value } })}
                            />
                        </Form.Item> */}

                        <Form.Item label="โบนัสสูงสุด การรับยอดเสีย" required>
                            <InputNumber
                                value={this.state.dataReturnlossNetworkEdit.returnloss_network_max}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                                onChange={(value) => this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_max: value } })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.webp,.gif'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnlossNetworkEdit: { ...this.state.dataReturnlossNetworkEdit, returnloss_network_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>

                    </Form>
                </Modal>
            </>
        );
    }
}
