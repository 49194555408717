import React, { Component } from 'react';
import { Table, Row, Col, Statistic, Card, Tag } from 'antd';
import { URL } from '../../../../Util/Config'
import { withRouter } from 'react-router-dom'

class promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'log_discount_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.log_discount_id - b.log_discount_id,
                },
                {
                    title: 'โปรโมชั่น',
                    dataIndex: 'discount_name',
                },
                {
                    title: 'ยอดฝากแรก',
                    dataIndex: 'log_discount_deposit_amount',
                    align: 'right',
                    render: (text, record) => (text ? text : 0 + ' บาท')
                },
                {
                    title: 'เครดิตที่ได้รับ',
                    dataIndex: 'discount_net_amount',
                    align: 'right',
                    render: (text, record) => (text ? text : 0)
                },
                {
                    title: 'สถานะ',
                    dataIndex: 'log_discount_status',
                    render: (text, record) => {
                        switch (text) {
                            case 'รอใช้งาน':
                                return (<Tag color='blue'>{text}</Tag>)
                            // break;

                            case 'ใช้งานแล้ว':
                                return (<Tag color='gold'>{text}</Tag>)
                            // break;

                            case 'สำเร็จ':
                                return (<Tag color='green'>{text}</Tag>)
                            // break;

                            case 'ยกเลิก':
                                return (<Tag color='red'>{text}</Tag>)
                            // break;

                            default: return;
                        }
                    }
                },
                {
                    title: 'ถอนเครดิตออก',
                    dataIndex: 'log_discount_withdraw_amount',
                    align: 'right',
                    render: (text, record) => (text ? text : 0 + ' บาท')
                },
                {
                    title: 'วัน/เวลา ที่รับโปรโมชั่น',
                    dataIndex: 'promotion_start_date',
                },
                {
                    title: 'วัน/เวลา ที่สำเร็จโปรโมชั่น',
                    dataIndex: 'promotion_end_date',
                    render: (text, record) => (
                        text === 'ยังไม่สำเร็จ' ? <Tag color='volcano'>{text}</Tag> : text
                    )
                }
            ],
            userProfile: {
                user_name: '',
                user_username: '',
                user_password: '',
                bank_abbrev_th: '',
                user_banknumber: '',
            },
            summary: {
                log_total_withdraw: null,
                log_total_deposit: null,
                log_total_bonus: null,
            }
        }
    }

    componentDidMount() {
        if (this.props.user_id) {
            this.getData(this.props.user_id)
            this.setProfile()
        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    getData(user_id) {
        fetch(`${URL}/api/v1/log/discount/userid/${user_id}`)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result.data, summary: json.result.summary }))
            // .then(() => console.log(this.state.summary))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    setProfile() {
        const userDetail = JSON.parse(localStorage.getItem('userDetail'))
        const userPromotion = JSON.parse(localStorage.getItem('userPromotion'))
        this.setState({ userProfile: userDetail, userPromotion: userPromotion })
    }

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดฝากจากโปรฯทั้งหมด"
                                value={this.state.summary.log_total_deposit}
                                valueStyle={{ color: 'green' }}
                            // suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="เครดิตจากโปรฯที่ได้รับทั้งหมด"
                                value={this.state.summary.log_total_bonus}
                                valueStyle={{ color: '#EF9900' }}
                            // suffix=""
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ถอนเครดิตจากโปรฯทั้งหมด"
                                value={this.state.summary.log_total_withdraw}
                                valueStyle={{ color: 'red' }}
                            // suffix=""
                            />
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.log_discount_id} />
            </>
        )
    }
}

export default withRouter(promotion)