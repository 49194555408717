import React, { Component } from 'react';
import { Card, Row, Col, PageHeader, Avatar, Statistic, Tabs, Button, Image } from 'antd';
import Setting from './setting';
import { URL, WEB_NAME } from '../../../Util/Config';

const { Meta } = Card;
const { TabPane } = Tabs;

export default class turnover extends Component {
  constructor(props) {
    super(props);
    this.state = {
        configSMS: {
            sms_otp_status: false
        },
        smsBalance: null
    };
  }

    async componentDidMount() { 
        this.getBalance()
    }

    async getBalance() {
        fetch(`${URL}/api/v1/sms/balance`)
            .then((response) => response.json())
            .then((json) => {
                if (json.status) {
                    this.setState({ smsBalance: json.result })
                } 
                console.log(json)
            })
            .catch((error) => {
                console.log('getBalance', error);
            });
    }

  render() {
    const routes = [
        {
            breadcrumbName: WEB_NAME,
        },
        {
            breadcrumbName: 'SMS / OTP',
        }
    ]
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="SMS / OTP"
                breadcrumb={{ routes }}
            />

            <br />
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Card>
                        <Meta
                            avatar={<Avatar src="/images/sms/favicon.ico" style={{ width: 50, height: 50 }} />}
                            // title="Card title"
                            description={<Statistic
                                title={<>ยอดเครดิตใน SMS <span className='text-danger'>(หมดอายุ {this.state.smsBalance ? new Date(this.state.smsBalance.expireDate).toLocaleString('sv-SE') : ''})</span></>}
                                value={this.state.smsBalance ? this.state.smsBalance.balance : 'ไม่สามารถตรวจสอบได้'}
                                valueStyle={{ color: '#1990FF' }}
                                suffix={this.state.smsBalance ? 'เครดิต' : ''}
                            />}
                        />
                    </Card>
                </Col>
                
                <Col span={8}>
                </Col>
                <Col span={8} className='text-center'>
                    <a href='https://godysms.com/' target='_blank'><Image src='/images/sms/logo.png' width={150} className='mb-3' preview={false}/></a>
                    <Button type='primary' style={{ backgroundColor: "#7366F0" }} block href='https://godysms.com/' target='_blank'>สมัคร SMS / OTP</Button>
                    
                </Col>
            </Row>
            <br />
            <Card>
                <Tabs type="card">
                    <TabPane tab="ตั้งค่า SMS / OTP" key="1">
                        <Setting />
                    </TabPane>
                    <TabPane tab="ส่ง SMS" key="2" disabled>
                    </TabPane>
                </Tabs>
            </Card>
        </>
    );
  }
}
