import React, { Component } from 'react';
import { Divider, Row, Col, Switch, InputNumber, Button, Select, Typography, Form, Table, Input, Tag, Modal, Image, Tabs, Space, Alert, Card, Statistic, Upload } from 'antd';
import { SettingOutlined, SearchOutlined, StarFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL } from '../../../../Util/Config'
import x0p from 'x0popup';
import axios from 'axios';
// import TypeGame from '../howto/typeGame';
import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UploadOutlined,
} from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

export default class returnloss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnloss: {
                returnloss_status: 'ปิด',
                returnloss_amount: 0,
                returnloss_type: 'เครดิต'
            },
            games: [],
            timeStart: '00:00:00',
            timeEnd: '00:01:00',
            day: [],
            ReturnlossColumns: [
                {
                    title: 'เลเวล',
                    dataIndex: 'returnloss_level',
                },
                {
                    title: 'ยอดฝากสะสมขั้นต่ำ',
                    dataIndex: 'returnloss_min_deposit',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย',
                    dataIndex: 'returnloss_amount',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                {
                    title: 'โบนัสขั้นต่ำ',
                    dataIndex: 'returnloss_min',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'โบนัสสูงสุด',
                    dataIndex: 'returnloss_max',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'icon',
                    dataIndex: 'returnloss_icon',
                    render: (text, record) => (
                        <Image src={text.includes('http') ? text : URL + '/public/images/return/returnloss/' + text} width={30} />
                    )
                },
                {
                    title: '',
                    dataIndex: 'returnloss_id',
                    render: (text, record, index) => (
                        this.state.admin_type === 'G' ? null :
                            record.returnloss_level === '1'
                                ?
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossEditVisible: true,
                                            returnloss_edit_min_deposit_min: 1,
                                            returnloss_edit_min_deposit_max: this.state.ReturnlossData[index + 1] !== undefined ? parseInt(this.state.ReturnlossData[index + 1].returnloss_min_deposit) - 1 : 1000000,
                                            returnloss_edit_amount_min: 1,
                                            returnloss_edit_amount_max: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount) - 0.1 : 100,
                                            returnloss_edit_amount_min_slot: 1,
                                            returnloss_edit_amount_max_slot: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_slot) - 0.1 : 100,
                                            returnloss_edit_amount_min_baccarat: 1,
                                            returnloss_edit_amount_max_baccarat: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossEdit: record
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" disabled><DeleteOutlined /> ลบ</Button>
                                </>
                                :
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossEditVisible: true,
                                            returnloss_edit_min_deposit_min: parseInt(this.state.ReturnlossData[index - 1].returnloss_min_deposit) + 1,
                                            returnloss_edit_min_deposit_max: this.state.ReturnlossData[index + 1] !== undefined ? parseInt(this.state.ReturnlossData[index + 1].returnloss_min_deposit) - 1 : 1000000,
                                            returnloss_edit_amount_min: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount) + 0.1,
                                            returnloss_edit_amount_max: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount) - 0.1 : 100,
                                            returnloss_edit_amount_min_slot: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount_slot) + 0.1,
                                            returnloss_edit_amount_max_slot: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_slot) - 0.1 : 100,
                                            returnloss_edit_amount_min_baccarat: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount_baccarat) + 0.1,
                                            returnloss_edit_amount_max_baccarat: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossEdit: record,
                                            ReturnLevel: record.returnloss_level,
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" onClick={() => this.postRemoveReturnloss(text)}><DeleteOutlined /> ลบ</Button>
                                </>
                    ),
                },
            ],
            ReturnlossColumnsWL: [
                {
                    title: 'เลเวล',
                    dataIndex: 'returnloss_level',
                },
                {
                    title: 'ยอดฝากสะสมขั้นต่ำ',
                    dataIndex: 'returnloss_min_deposit',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย (สล็อต)',
                    dataIndex: 'returnloss_amount_slot',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                {
                    title: '% การคืนยอดเสีย (คาสิโน)',
                    dataIndex: 'returnloss_amount_baccarat',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                // {
                //     title: 'โบนัสขั้นต่ำ',
                //     dataIndex: 'returnloss_min',
                //     render: (text, record) => (
                //         <>{text} บาท</>
                //     )
                // },
                {
                    title: 'โบนัสสูงสุด',
                    dataIndex: 'returnloss_max',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'icon',
                    dataIndex: 'returnloss_icon',
                    render: (text, record) => (
                        <Image src={text.includes('http') ? text : URL + '/public/images/return/returnloss/' + text} width={30} />
                    )
                },
                {
                    title: '',
                    dataIndex: 'returnloss_id',
                    render: (text, record, index) => (
                        this.state.admin_type === 'G' ? null :
                            record.returnloss_level === '1'
                                ?
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossEditVisible: true,
                                            returnloss_edit_min_deposit_min: 1,
                                            returnloss_edit_min_deposit_max: this.state.ReturnlossData[index + 1] !== undefined ? parseInt(this.state.ReturnlossData[index + 1].returnloss_min_deposit) - 1 : 1000000,
                                            returnloss_edit_amount_min: 1,
                                            returnloss_edit_amount_max: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount) - 0.1 : 100,
                                            returnloss_edit_amount_min_slot: 1,
                                            returnloss_edit_amount_max_slot: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_slot) - 0.1 : 100,
                                            returnloss_edit_amount_min_baccarat: 1,
                                            returnloss_edit_amount_max_baccarat: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossEdit: record
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" disabled><DeleteOutlined /> ลบ</Button>
                                </>
                                :
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnlossEditVisible: true,
                                            returnloss_edit_min_deposit_min: parseInt(this.state.ReturnlossData[index - 1].returnloss_min_deposit) + 1,
                                            returnloss_edit_min_deposit_max: this.state.ReturnlossData[index + 1] !== undefined ? parseInt(this.state.ReturnlossData[index + 1].returnloss_min_deposit) - 1 : 1000000,
                                            returnloss_edit_amount_min: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount) + 0.1,
                                            returnloss_edit_amount_max: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount) - 0.1 : 100,
                                            returnloss_edit_amount_min_slot: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount_slot) + 0.1,
                                            returnloss_edit_amount_max_slot: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_slot) - 0.1 : 100,
                                            returnloss_edit_amount_min_baccarat: parseFloat(this.state.ReturnlossData[index - 1].returnloss_amount_baccarat) + 0.1,
                                            returnloss_edit_amount_max_baccarat: this.state.ReturnlossData[index + 1] !== undefined ? parseFloat(this.state.ReturnlossData[index + 1].returnloss_amount_baccarat) - 0.1 : 100,
                                            dataReturnlossEdit: record,
                                            ReturnLevel: record.returnloss_level,
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" onClick={() => this.postRemoveReturnloss(text)}><DeleteOutlined /> ลบ</Button>
                                </>
                    ),
                },
            ],
            ReturnlossData: [],
            ReturnLevel: 1,
            isModalReturnlossEditVisible: false,
            isModalReturnlossAddVisible: false,
            dataReturnlossEdit: {},
            dataReturnlossAdd: {},
            returnloss_add_amount_min: null,
            returnloss_add_amount_min_slot: null,
            returnloss_add_amount_min_baccarat: null,
            returnloss_min_deposit: null,
            returnloss_edit_min_deposit_min: null,
            returnloss_edit_min_deposit_max: 0,
            returnloss_edit_amount_min: 0,
            returnloss_edit_amount_min_slot: 0,
            returnloss_edit_amount_min_baccarat: 0,
            returnloss_edit_amount_max: 0,
            returnloss_edit_amount_max_slot: 0,
            returnloss_edit_amount_max_baccarat: 0,

            columns2: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'ชื่อผู้ใช้งาน',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'ยอดฝากสะสม',
                    dataIndex: 'deposit',
                    sorter: (a, b) => a.deposit - b.deposit,
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} บาท</Tag>
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: 'returnloss_amount',
                    sorter: (a, b) => a.returnloss_amount - b.returnloss_amount,
                    render: (text, record) => (
                        <Tag color='orange'>{text}%</Tag>
                    )
                }
            ],
            data2: [],

            columns3: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'log_returnloss_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.returnloss_id - b.returnloss_id,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'username',
                    dataIndex: 'user_username',
                    ...this.getColumnSearchProps('user_username'),
                    render: (text, record) => (
                        <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
                    )
                },
                {
                    title: 'ยอดเสีย',
                    dataIndex: 'log_returnloss_loss',
                    render: (text, record) => (
                        <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'โบนัสที่ได้รับ',
                    dataIndex: 'log_returnloss_amount',
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} โบนัส</Tag>
                    )
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'log_returnloss_note',
                    render: (text, record) => (
                        text
                    )
                },
                {
                    title: 'วัน/เวลา ที่ได้รับโบนัส',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data3: [],
            summary: {},
            admin_type: null,
            admin_token: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.getReturnloss()
        this.getHistory()
        this.getUserLevel2Up()
        this.getSummary()
    }

    getSummary() {
        fetch(`${URL}/api/v1/returnloss/summary`)
            .then((response) => response.json())
            .then((json) => this.setState({
                summary: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getHistory() {
        fetch(`${URL}/api/v1/returnloss/log/type/0`)
            .then((response) => response.json())
            .then((json) => this.setState({
                data3: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getUserLevel2Up() {
        fetch(`${URL}/api/v1/returnloss/user/level2up`)
            .then((response) => response.json())
            .then((json) => this.setState({
                data2: json.result,
            }))
            // .then(() => console.log(this.state.data2))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getReturnloss() {
        fetch(`${URL}/api/v1/returnloss`)
            .then((response) => response.json())
            .then((json) => this.setState({
                returnloss: json.result[0],
                ReturnlossData: json.result,
                day: json.result[0].returnloss_day.split(','),
                timeStart: json.result[0].returnloss_time.split('-')[0],
                timeEnd: json.result[0].returnloss_time.split('-')[1],
                returnloss_add_amount_min: parseFloat(json.result[json.result.length - 1].returnloss_amount) + 0.1,
                returnloss_add_amount_min_slot: parseFloat(json.result[json.result.length - 1].returnloss_amount_slot) + 0.1,
                returnloss_add_amount_min_baccarat: parseFloat(json.result[json.result.length - 1].returnloss_amount_baccarat) + 0.1,
                returnloss_add_min_deposit: parseInt(json.result[json.result.length - 1].returnloss_min_deposit) + 1,
                dataReturnlossAdd: {
                    ...this.state.dataReturnlossAdd,
                    returnloss_min_deposit: parseInt(json.result[json.result.length - 1].returnloss_min_deposit) + 1,
                    returnloss_amount: parseFloat(json.result[json.result.length - 1].returnloss_amount) + 0.1,
                },
            }))
            // .then(() => console.log(this.state.ReturnlossData))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    postUpdate(status) {
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการแก้ไขคืนยอดเสียใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    type: this.state.returnloss.returnloss_type,
                    amount_day: this.state.returnloss.returnloss_amount_day,
                    status: status !== undefined ? status : this.state.returnloss.returnloss_status,
                    day: this.state.day.toString(),
                    time: this.state.timeStart + '-' + this.state.timeEnd,
                }
                await axios.post(`${URL}/api/v1/returnloss/update/status`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getReturnloss()
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    // เปิด-ปิดระบบ
    statusChange(status) {
        this.postUpdate(status ? 'เปิด' : 'ปิด')
    }

    postAddReturnloss() {
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const formData = new FormData();
                formData.append('min_deposit', this.state.dataReturnlossAdd.returnloss_min_deposit);
                formData.append("amount", this.state.dataReturnlossAdd.returnloss_amount);
                formData.append("amount_slot", this.state.dataReturnlossAdd.returnloss_amount_slot);
                formData.append("amount_baccarat", this.state.dataReturnlossAdd.returnloss_amount_baccarat);
                formData.append("min", this.state.dataReturnlossAdd.returnloss_min);
                formData.append("max", this.state.dataReturnlossAdd.returnloss_max);
                formData.append("image", this.state.dataReturnlossAdd.returnloss_icon);
                console.log(this.state.dataReturnlossAdd)

                const res = await fetch(`${URL}/api/v1/returnloss/add`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnloss()
                    this.setState({ isModalReturnlossAddVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postUpdateReturnloss() {
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const formData = new FormData();
                formData.append('level', this.state.dataReturnlossEdit.returnloss_level);
                formData.append("min_deposit", this.state.dataReturnlossEdit.returnloss_min_deposit);
                formData.append("amount", this.state.dataReturnlossEdit.returnloss_amount);
                formData.append("amount_slot", this.state.dataReturnlossEdit.returnloss_amount_slot);
                formData.append("amount_baccarat", this.state.dataReturnlossEdit.returnloss_amount_baccarat);
                formData.append("min", this.state.dataReturnlossEdit.returnloss_min);
                formData.append("max", this.state.dataReturnlossEdit.returnloss_max);
                formData.append("image", this.state.dataReturnlossEdit.returnloss_icon);

                const res = await fetch(`${URL}/api/v1/returnloss/update`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                  }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnloss()
                    this.setState({ isModalReturnlossEditVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postRemoveReturnloss(returnloss_id) {
        x0p({
            title: 'ยืนยันลบ!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    returnloss_id: returnloss_id
                }
                await axios.post(`${URL}/api/v1/returnloss/remove`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getReturnloss()
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    OnChangeType(value) {
        // if (value === 'เล่นได้เสีย') {
        //     this.setState({ returnloss: { ...this.state.returnloss, returnloss_type: value } })

        // } else {
            this.setState({ returnloss: { ...this.state.returnloss, returnloss_type: value, } })
        // }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเสียทั้งหมด"
                                value={this.state.summary.total_count}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="แจกโบนัสทั้งหมด"
                                value={this.state.summary.total_amount}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="แต้ม"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเสียวันนี้"
                                value={this.state.summary.total_count_today}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="แจกโบนัสวันนี้"
                                value={this.state.summary.total_amount_today}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="แต้ม"
                            />
                        </Card>
                    </Col>
                </Row>
                <br />

                <Card>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={20} style={{ textAlign: 'right' }}>
                            <Divider orientation="left"><SettingOutlined /> ตั่งค่า คืนยอดเสีย</Divider>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right', marginTop: 15 }}>
                            สถานะการใช้งาน :  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.returnloss.returnloss_status === 'เปิด' ? true : false} onChange={(e) => this.statusChange(e)} disabled={this.state.admin_type === 'G' ? true : false} />
                        </Col>
                    </Row>
                    <Row justify="start">
                        <Col span={12}>
                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="เงื่อนไข">
                                    <p className='m-0'>ลูกค้าจะได้รับ <span className='text-danger'>"โบนัส"</span> (เหรียญโบนัส สามารถแลกเป็นเครดิตทีหลัง) ตามช่วงวันที่ระบุ</p>
                                    <p className='m-0'>ระบบจะคำนวณโบนัส ให้สมาชิก ช่วงเวลา 01:00 - 09:00 น.</p>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item
                                    label="ประเภทการคืนยอดเสีย"
                                    extra={this.state.returnloss.returnloss_type === 'เครดิต' ? 'คำนวณจาก ((ยอดถอน - (ยอดฝาก - ยอดฝากโปรฯ)) - ยอดคงเหลือ) = โบนัสคืนยอดเสีย' : 'คำนวณจากข้อมูลที่เก็บจาก AG โดยตรง'}>
                                    <Select value={this.state.returnloss.returnloss_type} onChange={(value) => this.OnChangeType(value)}>
                                        <Option value="เล่นได้เสีย" disabled>ยอดเทิร์นเล่นได้เสียจริง</Option>
                                        <Option value="เครดิต">ยอดเทิร์นเครดิต</Option>
                                    </Select>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                            <Form.Item label="การแจกโบนัส" extra={this.state.returnloss.returnloss_amount_day === '7' ? 'คืนยอดทุกวันอาทิตย์ (อาทิตย์-เสาร์ ที่ผ่านมา)' : 'คืนยอดทุกวัน (ข้อมูลวันก่อน)'}>
                                    <Select 
                                        value={this.state.returnloss.returnloss_amount_day} 
                                        onChange={(value) => this.setState({ returnloss: { ...this.state.returnloss, returnloss_amount_day: value } })}
                                    >
                                        <Option value="1">รายวัน</Option>
                                        <Option value="7">รายอาทิตย์</Option>
                                    </Select>
                                    {/* <InputNumber
                                        value={this.state.returnloss.returnloss_amount_day}
                                        min={1}
                                        max={30}
                                        // disabled={this.state.returnloss.returnloss_type === 'เล่นได้เสีย' ? true : false}
                                        addonAfter="วัน/ครั้ง"
                                        style={{ width: '100%' }}
                                        onChange={(value) => this.setState({ returnloss: { ...this.state.returnloss, returnloss_amount_day: value } })}
                                    /> */}
                                </Form.Item>
                            </Form>

                            {/* <Form labelCol={{ span: 8 }}>
                                <Form.Item label="วันที่สามารถรับได้">
                                    <Checkbox.Group style={{ width: '100%' }} value={this.state.day} onChange={(value) => this.setState({ day: value })}>
                                        <Checkbox value="0">อาทิตย์ (Sun)</Checkbox>
                                        <Checkbox value="1">จันทร์ (Mon)</Checkbox>
                                        <Checkbox value="2">อังคาร (Tue)</Checkbox>
                                        <Checkbox value="3">พุธ (Wed)</Checkbox>
                                        <Checkbox value="4">พฤหัสบดี (Thu)</Checkbox>
                                        <Checkbox value="5">ศุกร์ (Fri)</Checkbox>
                                        <Checkbox value="6">เสาร์ (Sat)</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="เวลาที่สามารถรับได้">
                                    <RangePicker
                                        value={[moment(`2021-07-13 ${this.state.timeStart}`), moment(`2021-07-13 ${this.state.timeEnd}`)]}
                                        picker="time"
                                        onChange={(value) => this.setState({ timeStart: value[0]._d.toLocaleTimeString('it-IT'), timeEnd: value[1]._d.toLocaleTimeString('it-IT') })}
                                    />
                                </Form.Item>
                            </Form> */}

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label=" " colon={false}>
                                    <Button type="primary" onClick={() => this.postUpdate()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                    <Button onClick={() => this.setState({ isModalReturnlossAddVisible: true, ReturnLevel: this.state.ReturnlossData.length + 1 })} style={{ marginRight: 10 }} disabled={this.state.admin_type === 'G' ? true : false}><PlusCircleOutlined /> เพิ่มเลเวล</Button>
                    <br />
                    <br />
                    <Table columns={this.state.returnloss.returnloss_type === 'เครดิต' ? this.state.ReturnlossColumns : this.state.ReturnlossColumnsWL} dataSource={this.state.ReturnlossData} />

                    <br />
                    <br />
                    <Tabs defaultActiveKey="2">
                        <Tabs.TabPane tab="ราชื่อสมาชิก" key="1" disabled={this.state.ReturnlossData.length > 1 ? false : true}>
                            <Alert
                                message={<><p>แสดงข้อมูลรายชื่อสมาชิกที่มียอดฝากสะสมมากกว่า {this.state.ReturnlossData.length > 1 ? this.state.ReturnlossData[1].returnloss_min_deposit : ''} บาท </p><p>ยอดฝากสะสมเก็บข้อมูลภายใน 1 เดือนเท่านั้น</p></>}
                                type="warning"
                            />
                            <br />
                            <Table columns={this.state.columns2} dataSource={this.state.data2} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ประวัติการรับยอดเสียคืน" key="2">
                            <Alert message="แสดงข้อมูลภายใน 1 เดือนเท่านั้น" type="warning" />
                            <br />
                            <Table columns={this.state.columns3} dataSource={this.state.data3} />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>

                {/* Modal เพิ่มช่วงเลาเวลการคืนยอดเสีย */}
                <Modal title="เพิ่มช่วงเลาเวลการคืนยอดเสีย" open={this.state.isModalReturnlossAddVisible} onOk={() => this.postAddReturnloss()} onCancel={() => this.setState({ isModalReturnlossAddVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="เลเวล" required>
                            <InputNumber
                                value={this.state.ReturnLevel}
                                onChange={(value) => this.setState({ ReturnLevel: value })}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>ยอดฝากขั้นต่ำ <Tag color="red">ขั้นต่ำ {this.state.returnloss_add_min_deposit} บาท</Tag></>} required>
                            <InputNumber
                                defaultValue={this.state.returnloss_add_min_deposit}
                                min={this.state.returnloss_add_min_deposit}
                                max={1000000}
                                onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_min_deposit: value } })}
                                style={{ width: '100%' }}
                                addonAfter="บาท"
                            />
                        </Form.Item>

                        {this.state.returnloss.returnloss_type === 'เครดิต' ?
                            <Form.Item label={<>% การคืนยอดเสีย <Tag color="red">ขั้นต่ำ {this.state.returnloss_add_amount_min}%</Tag></>} required>
                                <InputNumber
                                    defaultValue={this.state.returnloss_add_amount_min}
                                    min={this.state.returnloss_add_amount_min}
                                    onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_amount: value } })}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                />
                            </Form.Item>
                            :
                            <>
                                <Form.Item label={<>% การคืนยอดเสีย (สล็อต) <Tag color="red">ขั้นต่ำ {this.state.returnloss_add_amount_min_slot}%</Tag></>} required>
                                    <InputNumber
                                        defaultValue={this.state.returnloss_add_amount_min_slot}
                                        min={this.state.returnloss_add_amount_min_slot}
                                        onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_amount_slot: value } })}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                    />
                                </Form.Item>
                                <Form.Item label={<>% การคืนยอดเสีย (คาสิโน) <Tag color="red">ขั้นต่ำ {this.state.returnloss_add_amount_min_baccarat}%</Tag></>} required>
                                    <InputNumber
                                        defaultValue={this.state.returnloss_add_amount_min_baccarat}
                                        min={this.state.returnloss_add_amount_min_baccarat}
                                        onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_amount_baccarat: value } })}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                    />
                                </Form.Item>
                            </>
                        }


                        {/* <Form.Item label={<>โบนัสขั้นต่ำ รับยอดเสีย</>} required>
                            <InputNumber
                                value={this.state.dataReturnlossAdd.returnloss_min}
                                onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_min: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item> */}

                        <Form.Item label={<>โบนัสสูงสุด รับยอดเสีย</>} required>
                            <InputNumber
                                value={this.state.dataReturnlossAdd.returnloss_max}
                                min={this.state.add_bonus_max}
                                onChange={(value) => this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_max: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item>

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                            required
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.gif,.webp'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnlossAdd: { ...this.state.dataReturnlossAdd, returnloss_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Modal แก้ไขการคืนยอดเสีย */}
                <Modal title="แก้ไขการคืนยอดเสีย" open={this.state.isModalReturnlossEditVisible} onOk={() => this.postUpdateReturnloss()} onCancel={() => this.setState({ isModalReturnlossEditVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="เลเวล" required>
                            <Input
                                value={this.state.dataReturnlossEdit.returnloss_level}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>ยอดฝากขั้นต่ำ {this.state.dataReturnlossEdit.returnloss_level !== '1' ? <><Tag color='red'>ขั้นต่ำ {this.state.returnloss_edit_min_deposit_min} บาท</Tag><Tag color='red'>สูงสุด {this.state.returnloss_edit_min_deposit_max.toLocaleString()} บาท</Tag></> : ''} </>} required>
                            <InputNumber
                                value={this.state.dataReturnlossEdit.returnloss_min_deposit}
                                min={this.state.returnloss_edit_min_deposit_min}
                                max={this.state.returnloss_edit_min_deposit_max}
                                disabled={this.state.dataReturnlossEdit.returnloss_level === '1' ? true : false}
                                style={{ width: '100%' }}
                                addonAfter="คน"
                                onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_min_deposit: value } })}
                            />
                        </Form.Item>

                        {this.state.returnloss.returnloss_type === 'เครดิต' ?
                            <Form.Item label={<>% การคืนยอดเสีย <Tag color='red'>ขั้นต่ำ {this.state.returnloss_edit_amount_min}%</Tag> <Tag color='red'>สูงสุด {this.state.returnloss_edit_amount_max}%</Tag></>} required>
                                <InputNumber
                                    value={this.state.dataReturnlossEdit.returnloss_amount}
                                    min={this.state.returnloss_edit_amount_min}
                                    max={this.state.returnloss_edit_amount_max}
                                    style={{ width: '100%' }}
                                    addonAfter="%"
                                    onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_amount: value } })}
                                />
                            </Form.Item>
                            :
                            <>
                                <Form.Item label={<>% การคืนยอดเสีย (สล็อต)<Tag color='red'>ขั้นต่ำ {this.state.returnloss_edit_amount_min_slot}%</Tag> <Tag color='red'>สูงสุด {this.state.returnloss_edit_amount_max_slot}%</Tag></>} required>
                                    <InputNumber
                                        value={this.state.dataReturnlossEdit.returnloss_amount_slot}
                                        min={this.state.returnloss_edit_amount_min_slot}
                                        max={this.state.returnloss_edit_amount_max_slot}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                        onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_amount_slot: value } })}
                                    />
                                </Form.Item>
                                <Form.Item label={<>% การคืนยอดเสีย (คาสิโน)<Tag color='red'>ขั้นต่ำ {this.state.returnloss_edit_amount_min_baccarat}%</Tag> <Tag color='red'>สูงสุด {this.state.returnloss_edit_amount_max_baccarat}%</Tag></>} required>
                                    <InputNumber
                                        value={this.state.dataReturnlossEdit.returnloss_amount_baccarat}
                                        min={this.state.returnloss_edit_amount_min_baccarat}
                                        max={this.state.returnloss_edit_amount_max_baccarat}
                                        style={{ width: '100%' }}
                                        addonAfter="%"
                                        onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_amount_baccarat: value } })}
                                    />
                                </Form.Item>
                            </>
                        }

                        {/* <Form.Item label='โบนัสขั้นต่ำ การรับยอดเสีย' required>
                            <InputNumber
                                value={this.state.dataReturnlossEdit.returnloss_min}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                                onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_min: value } })}
                            />
                        </Form.Item> */}

                        <Form.Item label="โบนัสสูงสุด การรับยอดเสีย" required>
                            <InputNumber
                                value={this.state.dataReturnlossEdit.returnloss_max}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                                onChange={(value) => this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_max: value } })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.webp,.gif'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnlossEdit: { ...this.state.dataReturnlossEdit, returnloss_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}
