import React, { Component } from 'react';
import { Table, Card, Input, Tag, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import { URL } from '../../../../Util/Config'

export default class promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
        columns: [
            {
              title: 'ลำดับ',
              dataIndex: 'log_discount_id',
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.log_discount_id - b.log_discount_id,
            },
            {
              title: 'ชื่อผู้ใช้งาน',
              dataIndex: 'user_username',
              ...this.getColumnSearchProps('user_username'),
              render: (text, record) => (
                <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }}/> : ''}</p>
              )
            },
            {
              title: 'ชื่อจริง-นามสกุล',
              dataIndex: 'user_name',
              ...this.getColumnSearchProps('user_name'),
            },
            {
              title: 'ชื่อโปรโมชั่น',
              dataIndex: 'discount_name',
            },
            {
              title: 'สถานะ',
              dataIndex: 'log_discount_status',
              render: (text, record) => {
                switch (text) {
                  case 'รอใช้งาน':
                      return (<Tag color='blue'>{text}</Tag>)
                  // break;

                  case 'ใช้งานแล้ว':
                      return (<Tag color='gold'>{text}</Tag>)
                  // break;

                  case 'สำเร็จ':
                      return (<Tag color='green'>{text}</Tag>)
                  // break;

                  case 'ยกเลิก':
                      return (<Tag color='red'>{text}</Tag>)
                  // break;

                  default : return;
                }
              }
            },
            {
              title: 'เคดิตที่ได้รับ',
              dataIndex: 'discount_net_amount',
              render: (text, record) => (
                record.log_discount_status === 'รอใช้งาน' ? '' : <Tag color='green'>{text ? text : 0} บาท</Tag>
              )
            },
            {
              title: 'วัน/เวลา ที่เริ่มโปรโมชั่น',
              dataIndex: 'promotion_start_date',
            },
            {
              title: 'วัน/เวลา ที่สำเร็จโปรโมชั่น',
              dataIndex: 'promotion_end_date',
              render: (text, record) => (
                text === 'ยังไม่สำเร็จ' ? <Tag color='volcano'>{text}</Tag> : text
              )
            }
        ],
        data: [],
    };
  }

  componentDidMount(){
    this.getData()
  }

  getData(){
    fetch(`${URL}/api/v1/log/discount/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result }))
      .then(() => console.log(this.state.data))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 


  render() {
    return (
      <>
        <Card>
          <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.row_num}/>
        </Card>
      </>
    );
  }
}
