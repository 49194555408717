import React, { Component } from 'react'
import { Typography, Table, Card, Input, Button, Space, Tag, Row, Col, Statistic } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { URL } from '../../../../../Util/Config'
import { withRouter } from 'react-router-dom'

const { Text } = Typography;

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.log_daily_id}</small></Text></div>
                    )
                },
                {
                    title: 'ประเภทรางวัล',
                    dataIndex: 'log_daily_type',
                    filters: [
                        {
                            text: 'เครดิต',
                            value: 'เครดิต',
                        },
                        {
                            text: 'เพชร',
                            value: 'เพชร',
                        },
                        {
                            text: 'ไม่ระบุ',
                            value: 'ไม่ระบุ',
                        },
                    ],
                    onFilter: (value, record) => record.log_daily_type.indexOf(value) === 0,
                    render: (text, record) => (
                        text === 'เครดิต' ?
                            <Tag color='gold'>{text}</Tag>
                            :
                            text === 'เพชร' ?
                                <Tag color='cyan'>{text}</Tag>
                                :
                                <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'จำนวนที่ได้รับ',
                    dataIndex: 'log_daily_amount',
                    align: 'right',
                    sorter: (a, b) => a.log_daily_amount - b.log_daily_amount,
                    render: (text, record) => (
                        <Tag color='green'>{text}</Tag>
                    )
                },
                {
                    title: 'วัน/เวลาที่รับรางวัล',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data: [],
            summary: [],
            userProfile: {},
        }
    }

    componentDidMount() {
        if (this.props.user_id) {
            this.getData(this.props.user_id)
        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    getData(user_id) {
        fetch(`${URL}/api/v1/daily/log/user_id/${user_id}`)
          .then((response) => response.json())
          .then((json) => this.setState({ data: json.result.list, summary: json.result.summary }))
          .then(() => console.log(this.state.detail))
          .catch((error) => {
            console.log('fetch log failed', error);
          });
    }

    setCardSummary() {
        let list = this.state.summary.map((item, index) =>
          <Col flex="1 1 250px">
            <Card>
              <Statistic
                title={item.unit === 'ไม่ระบุ' ? `${item.unit}ทั้งหมด` : `${item.unit}ที่แจกทั้งหมด`}
                value={item.amount}
                valueStyle={{ color: '#531CAA' }}
                suffix={item.unit === 'ไม่ระบุ' ? 'ครั้ง' : item.unit}
              />
            </Card>
          </Col>
        );
        return (list)
    }

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    {this.setCardSummary()}
                </Row>
                <br />
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} rowKey={record => record.row_num} />
            </>
        )
    }
}

export default withRouter(index)