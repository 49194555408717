import React, { Component } from 'react';
import { Card, PageHeader, Tabs } from 'antd';
import DepositAuto from './deposit/depositAuto';
import DepositManual from './deposit/depositMaul';
import { WEB_NAME } from '../../../Util/Config';
import Balance from '../dashboard/balance';

const { TabPane } = Tabs;

export default class deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'แจ้งฝากเครดิต',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายการแจ้งฝากรออนุมัติ"
          breadcrumb={{ routes }}
        />

        <Balance />
        <br />
        <Card>
          <Tabs type="card">
            <TabPane tab="ฝากอัตโนมัติ (Auto)" key="1">
              <DepositAuto />
            </TabPane>
            <TabPane tab="ฝากแนบสลิป (Manual)" key="2">
              <DepositManual />
            </TabPane>
          </Tabs>
        </Card>
      </>
    )
  }
}