import React, { Component } from 'react'
import { Card, Row, Col, DatePicker, Divider, Typography } from 'antd';
import { URL } from '../../../Util/Config';
import { Line } from '@ant-design/charts';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config_chart1: {
                data: [],
                xField: 'datetime',
                yField: 'amount',
                seriesField: 'transaction_creditflag',
                color: function color(_ref) {
                    var type = _ref.transaction_creditflag;
                    return type === 'ฝาก' ? '#117864' : type === 'ถอน' ? '#943126' : type === 'ฝากมือ' ? '#A3E4D7' : '#EDBB99';
                },
                lineStyle: function lineStyle(_ref2) {
                    var type = _ref2.transaction_creditflag;
                    if (type === 'ฝากมือ') {
                        return {
                            lineDash: [4, 4],
                            opacity: 1,
                        };
                    }
                    if (type === 'ถอนมือ') {
                        return {
                            lineDash: [4, 4],
                            opacity: 1,
                        };
                    }
                },
                yAxis: {
                    label: {
                        formatter: function formatter(v) {
                            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                                return ''.concat(s, ',');
                            });
                        },
                    },
                },
                label: {},
                point: {
                    size: 3,
                    shape: 'diamond',
                    style: {
                        fill: 'white',
                        stroke: '#979A9A',
                        lineWidth: 1,
                    },
                },
                tooltip: { showMarkers: false },
                state: {
                    active: {
                        style: {
                            shadowColor: 'yellow',
                            shadowBlur: 4,
                            stroke: 'transparent',
                            fill: 'red',
                        },
                    },
                },
                theme: {
                    geometries: {
                        point: {
                            diamond: {
                                active: {
                                    style: {
                                        shadowColor: '#FCEBB9',
                                        shadowBlur: 2,
                                        stroke: '#F6BD16',
                                    },
                                },
                            },
                        },
                    },
                },
                interactions: [{ type: 'marker-active' }],
                legend: { position: 'top' },
            },
            config_chart2: {
                data: [],
                xField: 'datetime',
                yField: 'amount',
                seriesField: 'channel',
                color: function color(_ref) {
                    var type = _ref.channel;
                    return type === 'SCB' ? '#6750A4' : type === 'True Wallet' ? '#F5831F' : '#A3E4D7';
                },
                yAxis: {
                    label: {
                        formatter: function formatter(v) {
                            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                                return ''.concat(s, ',');
                            });
                        },
                    },
                },
                label: {},
                point: {
                    size: 3,
                    shape: 'diamond',
                    style: {
                        fill: 'white',
                        stroke: '#979A9A',
                        lineWidth: 1,
                    },
                },
                tooltip: { showMarkers: false },
                state: {
                    active: {
                        style: {
                            shadowColor: 'yellow',
                            shadowBlur: 4,
                            stroke: 'transparent',
                            fill: 'red',
                        },
                    },
                },
                theme: {
                    geometries: {
                        point: {
                            diamond: {
                                active: {
                                    style: {
                                        shadowColor: '#FCEBB9',
                                        shadowBlur: 2,
                                        stroke: '#F6BD16',
                                    },
                                },
                            },
                        },
                    },
                },
                interactions: [{ type: 'marker-active' }],
                legend: { position: 'top' },
            },
            deposit30DayAgo: '',
            withdraw30DayAgo: '',
            summaryToday: '',
            summary30DayAgo: '',
            incomeTotal: '',
            incomeTotalToday: '',
            detailChart: {
                deposit_total: 0,
                manual_deposit_total: 0,
                withdraw_total: 0,
                manual_withdraw_total: 0,
                auto_total: 0,
                manual_total: 0,
                income_total: 0
            },
            channelList: []
        };
    }

    componentDidMount() {
        this.setChart(dateStart, dateEnd)

        // this.getDeposit30DayAgo()
        // this.getWithdraw30DayAgo()
        // this.getDepositToday()
        // this.getWithdrawToday()
        // this.getSummaryToday()
        // this.getSummary30DayAgo()
    }

    setChart(startDate, endDate) {
        // console.log(startDate, endDate)
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getChart(startDate, endDate)
        this.getSummaryChart(startDate, endDate)
        this.getChartTransactionChannel(startDate, endDate)
    }

    getChart(start, end) {
        fetch(`${URL}/api/v1/transaction/chart/start/${start}/end/${end}`)
            .then((response) => response.json())
            .then((json) => this.setState({ config_chart1: { ...this.state.config_chart1, data: json.result } }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // ข้อมูลรายละเอียด กราฟ 1
    getSummaryChart(startDate, endDate) {
        fetch(`${URL}/api/v1/transaction/summary/start/${startDate}/end/${endDate}`)
            .then((response) => response.json())
            .then((json) => this.setState({ detailChart: json.result }))
            // .then(() => console.log(this.state.detailChart))
            .catch((error) => {
                console.log('getTransactionWithdrawTotal', error);
            });
    }

    getDeposit30DayAgo() {
        fetch(`${URL}/api/v1/transaction/deposit/30dayago`)
            .then((response) => response.json())
            .then((json) => this.setState({ deposit30DayAgo: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getWithdraw30DayAgo() {
        fetch(`${URL}/api/v1/transaction/withdraw/30dayago`)
            .then((response) => response.json())
            .then((json) => this.setState({ withdraw30DayAgo: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getDepositToday() {
        fetch(`${URL}/api/v1/transaction/deposit/today`)
            .then((response) => response.json())
            .then((json) => this.setState({ depositToday: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getWithdrawToday() {
        fetch(`${URL}/api/v1/transaction/withdraw/today`)
            .then((response) => response.json())
            .then((json) => this.setState({ withdrawToday: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getSummaryToday() {
        fetch(`${URL}/api/v1/transaction/summary/today`)
            .then((response) => response.json())
            .then((json) => this.setState({ summaryToday: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getSummary30DayAgo() {
        fetch(`${URL}/api/v1/transaction/summary/30dayago`)
            .then((response) => response.json())
            .then((json) => this.setState({ summary30DayAgo: parseFloat(json.result).toFixed(2) }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getChartTransactionChannel(start, end) {
        fetch(`${URL}/api/v1/dashboard/transaction/channel/start/${start}/end/${end}`)
            .then((response) => response.json())
            .then((json) => this.setState({ config_chart2: { ...this.state.config_chart2, data: json.result.chart }, channelList: json.result.summary }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }


    TransactionChannelList() {
        const channelList = this.state.channelList
        let list = channelList.map((item, index) =>
            <Col span={12}>
                <DescriptionItem title={item.channel} content={<Text style={{ color: '#117864' }}>{item.amount.toLocaleString()} บาท</Text>} />
            </Col>
        );
        return (list)
    }
    // รายการช่องทางการฝาก


    render() {
        return (
            <>
                <Divider orientation="left"><Title level={4}>สรุปยอด ฝาก-ถอน</Title></Divider>
                {/* <Row gutter={[16, 16]}>
                    <Col flex="1 4 200px">
                        <Card>
                            <Statistic
                                title="ยอดฝาก 30 ย้อนหลัง"
                                value={this.state.deposit30DayAgo}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 4 200px">
                        <Card>
                            <Statistic
                                title="ยอดถอน 30 ย้อนหลัง"
                                value={this.state.withdraw30DayAgo}
                                valueStyle={{ color: '#cf1322' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 4 200px">
                        <Card>
                            <Statistic
                                title="ยอดฝากวันนี้"
                                value={this.state.depositToday}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 4 200px">
                        <Card>
                            <Statistic
                                title="ยอดถอนวันนี้"
                                value={this.state.withdrawToday}
                                valueStyle={{ color: '#cf1322' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="รายได้รวม 30 ย้อนหลัง"
                                value={this.state.summary30DayAgo}
                                valueStyle={{ color: this.state.summary30DayAgo >= 0 ? '#3f8600' : '#cf1322' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="รายได้รวมวันนี้"
                                value={this.state.summaryToday}
                                valueStyle={{ color: this.state.summaryToday >= 0 ? '#3f8600' : '#cf1322' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                </Row>
                <br /> */}
                <Card style={{ marginBottom: 30 }}>
                    <Title level={5} >สถิติ ฝาก-ถอน</Title>
                    <RangePicker
                        style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
                        defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                        format={dateFormat}
                        onChange={(e) => this.setChart(e[0]._d, e[1]._d)} />
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="ยอดฝาก" content={<Text type="success">{parseFloat(this.state.detailChart.deposit_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="ยอดฝากมือ" content={<Text type="success">{parseFloat(this.state.detailChart.manual_deposit_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="ยอดถอน" content={<Text type="danger">{parseFloat(this.state.detailChart.withdraw_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="ยอดถอนมือ" content={<Text type="danger">{parseFloat(this.state.detailChart.manual_withdraw_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="รายได้รวม (ฝากถอน AUTO)" content={<Text type={this.state.detailChart.auto_total >= 0 ? 'success' : 'danger'}>{parseFloat(this.state.detailChart.auto_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="รายได้รวม (ฝากถอน Manual)" content={<Text type={this.state.detailChart.manual_total >= 0 ? 'success' : 'danger'}>{parseFloat(this.state.detailChart.manual_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem title="รายได้รวมทั้งหมด" content={<Text type={this.state.detailChart.income_total >= 0 ? 'success' : 'danger'}>{parseFloat(this.state.detailChart.income_total.toFixed(2)).toLocaleString()}</Text>} />
                        </Col>
                    </Row>
                    <Line {...this.state.config_chart1} />

                    <br />
                    <br />
                    <Title level={5} >สถิติ ช่องทางการฝากเงิน</Title>
                    <Row>
                        {this.TransactionChannelList()}
                    </Row>
                    <Line {...this.state.config_chart2} />
                </Card>
            </>
        )
    }
}
