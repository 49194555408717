import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import Login from './Components/menage/login';
import Home from './Components/menage/home';
import G2FA from './Components/menage/G2FA';
import Partner from './Components/menage/partner/index';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/login" render={(props) => (
            <div style={{ backgroundColor: "#334461", minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Login />
            </div>
          )}
          />

          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Route path="/partner" render={(props) => (
            <Partner />
          )}
          />
          
          <Route path="/adminManage" render={(props) => (
            <Home />
          )}
          />
          
          <Route path="/humanverify" render={(props) => (
            <G2FA />
          )}
          />

        </Switch>
      </div>
    </Router>
  );
}

export default App;