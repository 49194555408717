import React, { Component } from 'react'
import { Card, Row, Col, DatePicker, Divider, Typography } from 'antd';
import { URL } from '../../../Util/Config';
import { Column } from '@ant-design/charts';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config_chart: {
        data: [],
        xField: 'datetime',
        yField: 'amount',
        seriesField: 'unit',
        xAxis: {
          // type: 'time',
          mask: 'DD-MM-YYYY',
        },
        yAxis: {
          label: {
            formatter: function formatter(v) {
              return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                return ''.concat(s, ',');
              });
            },
          },
        },
        isGroup: 'true',
        columnStyle: {
          radius: [20, 20, 0, 0],
          padding: 0
        },
      },
      amountUserAll: '',
      amountRegister30DayAgo: '',
      detailChart: [],
    };
  }

  componentDidMount() {
    this.setChart(dateStart, dateEnd)
  }

  setChart(startDate, endDate) {
    // console.log(startDate, endDate)
    var dt = DateTime.create(startDate);
    startDate = dt.format('Y-m-d');
    dt = DateTime.create(endDate);
    endDate = dt.format('Y-m-d');

    this.getChart(startDate, endDate)
  }

  // กราฟ เครดิตฟรี
  getChart(startDate, endDate) {
    fetch(`${URL}/api/v1/dashboard/creditfree/chart/start/${startDate}/end/${endDate}`)
      .then((response) => response.json())
      .then((json) => this.setState({ config_chart: { ...this.state.config_chart, data: json.result.list }, detailChart: json.result.summary }))
      // .then(() => console.log(this.state.config_chart))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  setDetail() {
    let list = this.state.detailChart.map((item, index) =>
      <Col span={12}>
        <DescriptionItem title={item.unit} content={<><Text style={{ color: '#EF9900' }}>{(item.amount).toLocaleString()}</Text> เครดิต</>} />
      </Col>
    );
    return (list)
  }

  render() {
    return (
      <>
        <Divider orientation="left"><Title level={4}>สรุปยอดแจกเครดิตฟรี</Title></Divider>
        <Card style={{ marginBottom: 30 }}>
          <Title level={5} >สถิติ การแจกเครดิตฟรี (รวมจากโปรโมชั่น, คืนยอดเสีย, กิจกรรมเสริม)</Title>
          <RangePicker
            style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
            defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
            format={dateFormat}
            onChange={(e) => this.setChart(e[0]._d, e[1]._d)} />
          <Row>
            {/* {this.setDetail()} */}
          </Row>
          <Column {...this.state.config_chart} />
        </Card>
      </>
    )
  }
}
