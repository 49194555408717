import React, { Component } from 'react';
import { Layout, Menu, Row, Col, Avatar, Image, Tag, Dropdown, Space, Input, Form, Modal } from 'antd';
import { Switch, Route, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'

import { WEB_NAME } from "../../../Util/Config";

import '../../../Style/home.css';
import {
  LogoutOutlined,
  EditOutlined,
  DownOutlined,
} from '@ant-design/icons';

// import Summary from './summary';
import Member from './member';
import Winlose from './winlose';
import Dashboard from './dashboard';

const { Header, Sider, Content, Footer } = Layout;

const menuContact = (
  <Menu>
    <Menu.Item>
      <a href='https://lin.ee/YsHkZOc' target='_blank'>
        <Image src='/images/LINE_logo.png' width={23} /> Line
      </a>
    </Menu.Item>
    <Menu.Item>
      <a href='https://t.me/fxike' target='_blank'>
        <Image src='/images/telagram_logo.png' width={23} /> Telegram
      </a>
    </Menu.Item>
  </Menu>
);

class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner_token: null,
      partner: {
        P_id: '',
        P_username: '',
        P_coderefer: '',
      },
      partner_password_edit: '',
      isModalEditPartnerVisible: false,
    };
  }

  async componentDidMount() {
    this.checkLogin()
  }

  async checkLogin() {
    if (!localStorage.getItem('P_user')) {
      this.props.history.push("/login");
    } else {
      const user = JSON.parse(localStorage.getItem('P_user'))
      this.setState({ partner: user.P_user, partner_token: user.P_accessToken }, () => console.log(this.state.partner))
    }
  }

  logout() {
    clearInterval(this.myInterval);

    localStorage.clear();
    this.props.history.push("/login");
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const menuProfile = (
      <Menu>
        <Menu.Item onClick={() => this.setState({ isModalEditPartnerVisible: true })}>
            <EditOutlined /> แก้ไขโปรไฟล์
        </Menu.Item>
        <Menu.Item onClick={() => this.logout()}>
            <LogoutOutlined /> ออกจากระบบ
        </Menu.Item>
      </Menu>
    );
    
    return (
      <>
        <Layout>
          <Sider breakpoint="lg">
            <div className="logo" style={{ margin: 16, fontSize: 20 }} >
              <h5 style={{ color: 'white' }}>{WEB_NAME}</h5>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['1']}>
              <Menu.Item key="1" icon={<img src='https://cdn-icons-png.flaticon.com/512/2328/2328966.png' width={20} />}>
                <Link to={`/partner`}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<img src='https://cdn-icons-png.flaticon.com/512/2328/2328966.png' width={20} />}>
                <Link to={`/partner/member`}>รายชื่อสมาชิก</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<img src='https://cdn-icons-png.flaticon.com/512/2328/2328966.png' width={20} />}>
                <Link to={`/partner/winlose`}>ได้เสีย</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <Row>
                <Col span={12} style={{ paddingLeft: 30 }}>
                  <Dropdown overlay={menuContact}>
                    <a onClick={e => e.preventDefault()}>
                      <Space className='text-dark'>
                        แจ้งปัญหาหรือสอบถาม
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </Col>

                <Col span={12} style={{ textAlign: 'right', paddingRight: 40 }}>
                  <Tag style={{ marginRight: 10 }}>Commition {this.state.partner.P_percent}%</Tag>
                  {this.state.partner.P_username}
                  <Dropdown overlay={menuProfile}>
                    <a onClick={e => e.preventDefault()}>
                      <span className='text-dark' style={{ fontSize: 18, marginRight: 10 }}>{this.state.admin ? this.state.admin.name : ''}</span>
                      <Avatar src="https://cdn-icons-png.flaticon.com/512/8991/8991253.png" shape="square" size="large"/>
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: '0px 10px', padding: 5, minHeight: 800 }}>
              <Switch>
                <Route exact path={`/partner/`}>
                    <Dashboard coderefer={this.state.partner.P_coderefer}/>
                </Route>
                <Route exact path={`/partner/member`}>
                    <Member />
                </Route>
                <Route exact path={`/partner/winlose`}>
                    <Winlose />
                </Route>
              </Switch>
            </Content>
            <Footer style={{ textAlign: 'center' }}></Footer>
          </Layout>
        </Layout>

        {/* Modal แก้ไขแอดมิน */}
        <Modal title="แก้ไขโปรไฟล์" open={this.state.isModalEditPartnerVisible} onOk={() => this.updateAdmin()} onCancel={() => this.setState({ isModalEditPartnerVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="username" required>
              <Input value={this.state.partner.P_username} disabled />
            </Form.Item>
            <Form.Item label="password" extra="หากไม่ต้องการเปลี่ยนให้ใส่ค่าว่าง" required>
              <Input.Password
                defaultValue={this.state.partner.password}
                onChange={(e) => this.setState({ partner_password_edit: e.target.value })}
                placeholder="แก้ไขรหัสผ่าน"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default withRouter(home)