import React, { Component } from 'react'
import Summary from './summary'
import Notify from './notify'
import Transaction from './transaction'
import Ranking from './ranking'
import FreeCredit from './freecredit'
import Member from './member'
import Balance from './balance'

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <Notify />
                <div style={{ marginTop: 50 }}></div>
                <Balance />
                <div style={{ marginTop: 50 }}></div>
                <Summary />
                <div style={{ marginTop: 50 }}></div>
                <Transaction />
                <div style={{ marginTop: 50 }}></div>
                <Ranking />
                <div style={{ marginTop: 50 }}></div>
                <FreeCredit />
                <div style={{ marginTop: 50 }}></div>
                <Member/>
            </>
        )
    }
}
