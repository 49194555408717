import React, { Component } from 'react';
import { Layout, Menu, Row, Col, Button, Badge, Avatar, Image, Tabs, Typography, message, Dropdown, Space, Input, Form, Modal } from 'antd';
import { Switch, Route, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import x0p from 'x0popup';
import axios from 'axios';

import Partner from './partner/manage';
import Dashboard from './dashboard/index';
import Income from './dashboard/income/';
import Member from './member/member';
import MemberStatistics from './member/statistics/index';
import MemberVerifyIdentity from './member/verifyIdentity';
import TransactionDeposit from './transaction/deposit';
import TransactionWithdraw from './transaction/withdraw';
import TransactionBank from './transaction/bank/index';
import ErrorWithdraw from './error/withdrawError';
import ErrorDeposit from './error/depositError';
import PromotionAffiliate from './promotion/affiliate/index';
import PromotionDiscount from './promotion/discount/index';
import PromotionDiscountUse from './promotion/discount/discount_use'
import PromotionCouponByDisount from './promotion/discount/coupon';
import PromotionRanking from './promotion/ranking/index';
import Games from './games/index';
import SMS from './sms/index';
import Announce from './announce';
import Howto from './howto/index';
import Master from './master/index';
import MasterDetail from './master/detail';
import StaffAdmin from './staff/admin';
import StaffTurnover from './staff/turnover';
import EventCheckIn from './event/checkin/index';
import EventMiniGame from './event/minigame/index';
import EventPoint from './event/point/index';
import EventEntertain from './event/entertain/index';
import EventBonus from './event/bonus';
import SettingFront from './setting/front';
import SettingSystem from './setting/system';
import { URL, WEB_NAME } from "../../Util/Config";
import { Howl, Howler } from 'howler';
import SoundNotify from '../../sound/woodplankflicks.mp3'

import LogCredit from './staff/history/credit'
import LogPoint from './staff/history/point'
import LogPromotion from './staff/history/promotion'
import LogOther from './staff/history/other'
import LogMember from './staff/history/member'

import '../../Style/home.css';
import {
  HistoryOutlined,
  MessageOutlined,
  SketchOutlined,
  AuditOutlined,
  LogoutOutlined,
  EditOutlined,
  DownOutlined,
  DollarCircleOutlined,
  ShopOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header, Sider, Content, Footer } = Layout;
const { Title } = Typography;

const sound = new Howl({ src: SoundNotify })

const menuContact = (
  <Menu>
    <Menu.Item>
      <a href='https://lin.ee/YsHkZOc' target='_blank'>
        <Image src='/images/LINE_logo.png' width={23} /> Line
      </a>
    </Menu.Item>
    <Menu.Item>
      <a href='https://t.me/fxike' target='_blank'>
        <Image src='/images/telagram_logo.png' width={23} /> Telegram
      </a>
    </Menu.Item>
  </Menu>
);

class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: [1,2,3],
      admin_token: null,
      admin: {
        id: '',
        type: '',
        rule: '',
        username: '',
        name: '',
        password: '',
      },
      admin_password_edit: '',
      admin_fullname_edit: '',
      DrawerSettingVisible: false,
      accBalance: '',
      accountSCB: {
        accountNo: '',
        accountName: '',
        accountBalance: ''
      },
      accountKBANK: {
        accountNo: '',
        accountName: '',
        availableBalance: ''
      },
      accountTruewallet: {
        truewallet_phone: '',
        truewallet_status: 'ปิด',
        accountBalance: ''
      },
      amountDepositError: '',
      amountWithdrawError: '',
      amountVerifyIdentity: '',
      systemAuto: false,
      collapsed: false,
      isModalEditAdminVisible: false,
      isModalLogAdminVisible: false,
      soundStatus: true,
    };
  }

  async componentDidMount() {
    this.checkLogin()
  }

  async checkLogin() {
    if (!localStorage.getItem('user')) {
      this.props.history.push("/login");
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      const soundStatus = localStorage.getItem('soundStatus')
      if (soundStatus === "false") {
        this.setState({ soundStatus: false })
      }

      this.setState({ admin: { ...user.user, rule: user.user.rule.split(',') }, admin_fullname_edit: user.user.name, admin_token: user.accessToken }, () => {
        this.noti()
  
        this.myInterval = setInterval(() => {
          this.noti()
        }, 30000);
        console.log(this.state.admin)
      })
      
    }
  }

  async noti() {
    this.getAdmin()
    if (this.state.admin.rule.includes('1')) {
      await this.getAmountWithdrawError()
    }
    if (this.state.admin.rule.includes('2')) {
      await this.getAmountDepositError()
    }
    // await this.getAmountVerifyIdentity()
  }

  logout() {
    clearInterval(this.myInterval);

    localStorage.clear();
    this.props.history.push("/login");
  }

  toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  getAdmin() {
    if (!this.state.admin_token) return this.props.history.push("/login");

    fetch(`${URL}/api/v1/admin/username/${this.state.admin.username}`, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then((json) => {
        if (!json.status) {
          clearInterval(this.myInterval);

          message.error(json.message);
          localStorage.clear();
          this.props.history.push("/login");
        }
      })
      // .then(() => console.log(this.state.summaryMonth))
      .catch((error) => {
        console.log('getAdmin', error);
      });
  }

  async getAmountDepositError() {
    fetch(`${URL}/api/v1/transaction/error/deposit/amount`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ amountDepositError: json.result })
        if (json.result > 0) {
          if (this.state.soundStatus) {
            sound.play()
          }
        } else {
          if (this.state.soundStatus) {
            sound.pause()
          }
        }
      })
      // .then(() => console.log(this.state.summaryMonth))
      .catch((error) => {
        console.log('getAmountDepositError', error);
      });
  }

  async getAmountWithdrawError() {
    fetch(`${URL}/api/v1/transaction/error/withdraw/amount`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ amountWithdrawError: json.result })
        if (json.result > 0) {
          if (this.state.soundStatus) {
            sound.play()
          }
        } else {
          if (this.state.soundStatus) {
            sound.pause()
          }
        }
      })
      // .then(() => console.log(this.state.summaryMonth))
      .catch((error) => {
        console.log('getAmountWithdrawError', error);
      });
  }

  // async getAmountVerifyIdentity() {
  //   fetch(`${URL}/api/v1/user/verifyIdentity/count`)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (!json.status && json.message === 'ระบบ AUTO ปิดใช้งานค่ะ') {
  //         this.setState({ systemAuto: false })
  //       } else if (json.status) {
  //         this.setState({ amountVerifyIdentity: json.result, systemAuto: true })
  //         if (json.result > 0) {
  //           if (this.state.soundStatus) {
  //             sound.play()
  //           }
  //         } else {
  //           if (this.state.soundStatus) {
  //             sound.pause()
  //           }
  //         }
  //       }
  //     })
  //     // .then(() => console.log(this.state.summaryMonth))
  //     .catch((error) => {
  //       console.log('getAmountWithdrawError', error);
  //     });
  // }

  showDrawerSetting() {
    this.setState({ DrawerSettingVisible: true })
  }

  async updateAdmin() {
    const { id, rule, type } = this.state.admin

    if (this.state.admin_fullname_edit !== '') {
      x0p({
        title: 'ยืนยันการเปลี่ยนแปลงข้อมูล!!',
        icon: 'info',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ]
      }).then(async (alert) => {
        if (alert.button === 'info') {
          const user = JSON.parse(localStorage.getItem('user'))
          if (!user) return this.props.history.push("/adminManage/login");

          const body = {
            admin_id: id,
            status: '0',
            new_password: this.state.admin_password_edit,
            fullname: this.state.admin_fullname_edit,
            rule: rule.toString(),
            type: type
          }
          await axios.post(URL + "/api/v1/admin/update", body, {
            headers: {
              'Authorization': `Bearer ${user.accessToken}`,
            }
          })
            .then(res => {
              var data = res.data
              if (data.status) {
                x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
                this.setState({ isModalEditAdminVisible: false })
              } else {
                x0p('', data.message, 'error', false);
              }
            })
            .catch(error => {
              console.log(error)
              x0p('การเชื่อมต่อผิดพลาด updateAdmin', null, 'error', false);
            })
        }
      })
    } else {
      message.error('กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน');
    }
  }

  async setSound(value) {
    this.setState({ soundStatus: !value })
    localStorage.setItem("soundStatus", !value)
  }

  render() {
    Howler.volume(1);

    const menuProfile = (
      <Menu>
        <Menu.Item onClick={() => this.setState({ isModalEditAdminVisible: true })}>
            <EditOutlined /> แก้ไขโปรไฟล์
        </Menu.Item>
        <Menu.Item onClick={() => this.setState({ isModalLogAdminVisible: true })}>
            <HistoryOutlined /> ประวัติการใช้งาน
        </Menu.Item>
        <Menu.Item onClick={() => this.logout()}>
            <LogoutOutlined /> ออกจากระบบ
        </Menu.Item>
      </Menu>
    );
    
    return (
      <>
        <Layout>
          {/* <Sider trigger={null} collapsible collapsed={this.state.collapsed}> */}
          <Sider
            breakpoint="lg"
          // collapsedWidth="0"
          // style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
          // onBreakpoint={broken => { console.log(broken) }}
          // onCollapse={(collapsed, type) => { console.log(collapsed, type) }}
          >
            <div className="logo" style={{ margin: 16, fontSize: 20 }} >
              <h5 style={{ color: 'white' }}>{WEB_NAME}</h5>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['1']}>
              <Menu.Item key="1" icon={<img src='/images/icon/619153.png' width={20} />}>
                <Link to={`/adminManage/`}>หน้าหลักระบบ</Link>
              </Menu.Item>
              {this.state.admin.rule.includes('11') ?
                <Menu.Item key="14" icon={<img src='/images/icon/2328966.png' width={20} />}>
                  <Link to={`/adminManage/income`}>สรุปรายได้</Link>
                </Menu.Item>
                : <></>
              }
              {/* {this.state.systemAuto ? */}
                {/* <Menu.Item key="2" icon={<img src='/images/icon/2808383.png' width={20} />}>
                  <Link to={`/adminManage/member/verifyIdentity`}>สมาชิกรอยืนยันตัวตน {this.state.amountVerifyIdentity > 0 ? <Badge count={this.state.amountVerifyIdentity} size="small" overflowCount={10}></Badge> : <></>}</Link>
                </Menu.Item> */}
                {/* : <></>
              } */}
              {this.state.admin.rule.includes('1') ?
                <Menu.Item key="3" icon={<img src='/images/icon/3534066.png' width={20} />}>
                  <Link to={`/adminManage/error/withdraw`}>รายการแจ้งถอน {this.state.amountWithdrawError > 0 ? <Badge count={this.state.amountWithdrawError} size="small" overflowCount={10}></Badge> : <></>}</Link>
                </Menu.Item>
                : <></>
              }
              {this.state.admin.rule.includes('2') ?
                <Menu.Item key="4" icon={<img src='/images/icon/3534066.png' width={20} />}>
                  <Link to={`/adminManage/error/deposit`}>รายการแจ้งฝาก {this.state.amountDepositError > 0 ? <Badge count={this.state.amountDepositError} size="small" overflowCount={10}></Badge> : <></>}</Link>
                </Menu.Item>
                : <></>
              }
              {this.state.admin.rule.includes('3') ?
                <>
                  <SubMenu key="5" icon={<img src='/images/icon/1825357.png' width={20} />} title="ประวัติ ฝาก-ถอน">
                    <Menu.Item key="5.1"><Link to={`/adminManage/transaction/deposit`}>ประวัติการฝากเงิน</Link></Menu.Item>
                    <Menu.Item key="5.2"><Link to={`/adminManage/transaction/withdraw`}>ประวัติการถอนเงิน</Link></Menu.Item>
                    {/* <Menu.Item key="5.3"><Link to={`/adminManage/history/creditfree`}>ประวัติการเติมเครดิตฟรี</Link></Menu.Item> */}
                    <Menu.Item key="5.4"><Link to={`/adminManage/transaction/bank`}>ประวัติจากธนาคาร</Link></Menu.Item>
                  </SubMenu>
                </>
                : <></>
              }
              <SubMenu key="6" icon={<img src='/images/icon/4025457.png' width={20} />} title="จัดการสมาชิก">
                {this.state.admin.rule.includes('4') ?
                  <Menu.Item key="6.1"><Link to={`/adminManage/member/member`}></Link>รายชื่อสมาชิก</Menu.Item>
                  : <></>
                }
                {this.state.admin.rule.includes('9') ?
                  <Menu.Item key="6.2"><Link to={`/adminManage/staff/admin`}>รายชื่อพนักงาน</Link></Menu.Item>
                  : <></>
                }
              </SubMenu>
              
              
              {this.state.admin.rule.includes('5') ?
                <SubMenu key="9" icon={<img src='/images/icon/3596052.png' width={20} />} title="โปรโมชั่น / คืนยอดเสีย">
                  <Menu.Item key="9.1"><Link to={`/adminManage/promotion/discount`}>โปรโมชั่น</Link></Menu.Item>
                  <Menu.Item key="9.2"><Link to={`/adminManage/promotion/ranking`}>Ranking (คืนยอด)</Link></Menu.Item>
                  <Menu.Item key="9.3"><Link to={`/adminManage/promotion/affiliate`}>Affiliate (แนะนำเพื่อน)</Link></Menu.Item>
                </SubMenu>
                : <></>
              }
              {this.state.admin.rule.includes('6') ?
                <SubMenu key="10" icon={<img src='/images/icon/3277431.png' width={20} />} title="กิจกรรมเสริม">
                  <Menu.Item key="10.1"><Link to={`/adminManage/event/bonus`}>โบนัส(แต้มสะสม)</Link></Menu.Item>
                  <Menu.Item key="10.2"><Link to={`/adminManage/event/point`}>เพชร(แต้มสะสม)</Link></Menu.Item>
                  <Menu.Item key="10.3"><Link to={`/adminManage/event/checkin`}>กิจกรรมเช็คอิน</Link></Menu.Item>
                  <Menu.Item key="10.4"><Link to={`/adminManage/event/minigame`}>มินิเกมส์</Link></Menu.Item>
                  <Menu.Item key="10.5"><Link to={`/adminManage/event/entertain`}>ความบันเทิง</Link></Menu.Item>
                  <Menu.Item key="10.6"><a href={`https://pg-th.com`} target='_bank'>สูตรสล็อต Javis</a></Menu.Item>
                </SubMenu>
                : <></>
              }
              {this.state.admin.rule.includes('7') ?
                <Menu.Item key="11" icon={<img src='/images/icon/1156949.png' width={20} />}>
                  <Link to={`/adminManage/announce`}>
                    ประกาศ / ช่องสมัคร
                  </Link>
                </Menu.Item>
                : <></>
              }
              {this.state.admin.rule.includes('12') ?
                <Menu.Item key="12" icon={<img src='/images/icon/1365369.png' width={20} />}>
                  <Link to={`/adminManage/partner`}>
                    พันธมิตร
                  </Link>
                </Menu.Item>
                : <></>
              }
              {this.state.admin.rule.includes('13') ?
                <Menu.Item key="16" icon={<img src='/images/icon/joystick.png' width={20}/>}>
                  <Link to={`/adminManage/games`}>
                    เกมส์
                  </Link>
                </Menu.Item>
                : <></>
              }
              {this.state.admin.rule.includes('10') ?
                <Menu.Item key="17" icon={<img src='/images/icon/sms.png' width={20}/>}>
                  <Link to={`/adminManage/sms`}>
                    SMS / OTP
                  </Link>
                </Menu.Item>
               : <></>
              }
              {/* <Menu.Item key="13" icon={<img src='/images/icon/1669668.png' width={20} />} onClick={() => this.showDrawerSetting()}>
                ข้อมูลบัญชี
              </Menu.Item> */}
              {this.state.admin.rule.includes('8') ?
                <SubMenu key="15" icon={<img src='/images/icon/738853.png' width={20} />} title="ตั้งค่า">
                    <Menu.Item key="15.1"><Link to={`/adminManage/setting/front`}>ตั้งค่าหน้าเว็บ</Link></Menu.Item> 
                    
                    {this.state.admin.type === 'M' ?
                      <Menu.Item key="15.2"><Link to={`/adminManage/setting/system`}>ตั้งค่าระบบหลังบ้าน</Link></Menu.Item>
                      : <></>
                    }
                </SubMenu>
                : <></>
              }
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <Row>
                <Col span={12} style={{ paddingLeft: 30 }}>
                  <Link to={`/adminManage/howto`} style={{ marginRight: 20 }} className='text-dark'><img src='/images/icon/671829.png' width={20} /> วิธีใช้งาน</Link>
                  <Dropdown overlay={menuContact}>
                    <a onClick={e => e.preventDefault()}>
                      <Space className='text-dark'>
                        แจ้งปัญหาหรือสอบถาม
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                  {/* { this.state.soundStatus === true ?
                    // <Button type="link" className='text-dark' style={{ marginLeft: 20 }} onClick={() => this.setSound(this.state.soundStatus)}> เปิดเสียง {this.state.soundStatus} <img src='/images/icon/10947887.png' width={20} /></Button>
                  :  */}
                    <Button type="link" className='text-dark' style={{ marginLeft: 20 }} onClick={() => this.setSound(this.state.soundStatus)}>  {this.state.soundStatus === false ? <>ปิดเสียง <img src='/images/icon/10947974.png' width={20} /></> : <>เปิดเสียง <img src='/images/icon/10947887.png' width={20} /></>} </Button>
                  {/* } */}
                </Col>

                <Col span={12} style={{ textAlign: 'right', paddingRight: 40 }}>
                  <Dropdown overlay={menuProfile}>
                    <a onClick={e => e.preventDefault()}>
                      <span className='text-dark' style={{ fontSize: 18, marginRight: 10 }}>{this.state.admin ? this.state.admin.name : ''}</span>
                      <Avatar src="/images/icon/9307766.png" shape="square" size="large"/>
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: '0px 10px', padding: 5, minHeight: 800 }}>
              <Switch>
                <Route exact path={`/adminManage/`}>
                  <Dashboard />
                </Route>
                <Route exact path={`/adminManage/income`}>
                  <Income />
                </Route>

                <Route path={`/adminManage/error/withdraw`}>
                  <ErrorWithdraw />
                </Route>
                {/* <Route path={`/adminManage/error/deposit/nomember`}>
                      <ErrorDepositNoMember />
                    </Route>
                    <Route path={`/adminManage/error/deposit/wait`}>
                      <ErrorDepositWait />
                    </Route> */}
                <Route path={`/adminManage/error/deposit`}>
                  <ErrorDeposit />
                </Route>

                <Route path={`/adminManage/transaction/deposit`}>
                  <TransactionDeposit />
                </Route>
                <Route path={`/adminManage/transaction/withdraw`}>
                  <TransactionWithdraw />
                </Route>
                <Route path={`/adminManage/transaction/bank`}>
                  <TransactionBank />
                </Route>

                <Route path={`/adminManage/member/member`}>
                  <Member />
                </Route>
                <Route path={`/adminManage/member/statistics`}>
                  <MemberStatistics />
                </Route>
                <Route path={`/adminManage/member/verifyIdentity`}>
                  <MemberVerifyIdentity />
                </Route>

                <Route path={`/adminManage/promotion/affiliate`}>
                  <PromotionAffiliate />
                </Route>
                <Route path={`/adminManage/promotion/discount/use/:id`}>
                  <PromotionDiscountUse />
                </Route>
                <Route path={`/adminManage/promotion/discount`}>
                  <PromotionDiscount />
                </Route>
                <Route path={`/adminManage/promotion/coupon/by/discount/:id`}>
                  <PromotionCouponByDisount />
                </Route>
                <Route path={`/adminManage/promotion/ranking`}>
                  <PromotionRanking />
                </Route>

                <Route path={`/adminManage/staff/admin`}>
                  <StaffAdmin />
                </Route>
                <Route path={`/adminManage/staff/turnover`}>
                  <StaffTurnover />
                </Route>

                <Route path={`/adminManage/announce`}>
                  <Announce />
                </Route>

                <Route path={`/adminManage/setting/front`}>
                  <SettingFront />
                </Route>
                <Route path={`/adminManage/setting/system`}>
                  <SettingSystem />
                </Route>

                <Route path={`/adminManage/master/detail/:username`}>
                  <MasterDetail />
                </Route>
                <Route path={`/adminManage/master`}>
                  <Master />
                </Route>

                <Route path={`/adminManage/event/checkin`}>
                  <EventCheckIn />
                </Route>
                <Route path={`/adminManage/event/minigame`}>
                  <EventMiniGame />
                </Route>
                <Route path={`/adminManage/event/point`}>
                  <EventPoint />
                </Route>
                <Route path={`/adminManage/event/entertain`}>
                  <EventEntertain />
                </Route>
                <Route path={`/adminManage/event/bonus`}>
                  <EventBonus />
                </Route>

                <Route path={`/adminManage/howto`}>
                  <Howto />
                </Route>

                <Route path={`/adminManage/partner`}>
                  <Partner />
                </Route>

                <Route path={`/adminManage/games`}>
                  <Games />
                </Route>

                <Route path={`/adminManage/sms`}>
                  <SMS />
                </Route>

              </Switch>
            </Content>
            <Footer style={{ textAlign: 'center' }}></Footer>
          </Layout>
        </Layout>

        {/* ข้อมูลธนาคาร */}
        {/* { this.state.DrawerSettingVisible ? 
          <Drawer
            title="ข้อมูลบัญชี"
            placement="right"
            width={420}
            closable={true}
            onClose={() => this.setState({ DrawerSettingVisible: false })}
            open={this.state.DrawerSettingVisible}
          >
            <Title level={5}>Agent</Title>
            <Row>
              <Col span={24}>
                <DescriptionItem title="ชื่อเว็บ" content={<a href={AGENT_WEB_LINK} target="_blank">{AGENT_WEB}</a>} />
              </Col>
              <Col span={24}>
                <DescriptionItem title="เครดิตคงเหลือ" content={`${this.state.accBalance} บาท`} />
              </Col>
            </Row>
            
            <Divider />

            {this.state.bankAuto.map((item, index) => (
              <>
                <Title level={5}>บัญชีธนาคาร AUTO</Title>
                <Image
                  width={50}
                  src="/images/logo_bank/SCB.png"
                />
                <Row>
                  <Col span={24}>
                    <DescriptionItem title="สถานะ" content={item.accountNo ? <Tag color="green">ปกติ</Tag> : <Tag color="red">ไม่สามารถตรวจสอบได้</Tag>} />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <DescriptionItem title="ธนาคาร" content="ไทยพาณิช" />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem title="เลขบัญชี" content={item.accountNo} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DescriptionItem title="ชื่อบัญชี" content={item.accountName} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <DescriptionItem title="ยอดเงินในบัญชี" content={index === 0 ? <Tag>{this.state.accountSCB.accountBalance}</Tag> : <Tag onClick={() => this.getAccSCBBalanceId(item.bank_auto_id)}>ตรวจสอบยอด</Tag>} />
                  </Col>
                </Row>
                <Divider />
              </>
            ))}

            <Image
              width={50}
              src="/images/logo_bank/KBANK.png"
            />
            <Row>
              <Col span={24}>
                <DescriptionItem title="สถานะ" content={this.state.accountKBANK.availableBalance ? <Tag color="green">ปกติ</Tag> : <Tag color="red">ไม่สามารถตรวจสอบได้</Tag>} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem title="ธนาคาร" content="กสิกรไทย" />
              </Col>
              <Col span={12}>
                <DescriptionItem title="เลขบัญชี" content={this.state.accountKBANK.accountNo} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="ชื่อบัญชี" content={this.state.accountKBANK.accountName} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem title="ยอดเงินในบัญชี" content={`${this.state.accountKBANK.availableBalance} บาท`} />
              </Col>
            </Row>
            <Divider />

            <Title level={5}>ทรูวอลเล็ต</Title>
            <Image
              width={50}
              src="/images/logo_bank/TWL.png"
            />
            <Row>
              <Col span={24}>
                <DescriptionItem title="เบอร์รับเงินวอลเล็ต" content={this.state.accountTruewallet.truewallet_phone} />
              </Col>
              <Col span={24}>
                <DescriptionItem title="ยอดเงินในวอลเล็ต" content={`${this.state.accountTruewallet.accountBalance} บาท`} />
              </Col>
              <Col span={24}>
                <DescriptionItem title="สถานะ" content={this.state.accountTruewallet.truewallet_status === 'เปิด' ? <Tag color="green">เปิด</Tag> : <Tag color="red">ปิด</Tag>} />
              </Col>
            </Row>
            <Divider />
          </Drawer>
          : <></>
        } */}

        {/* Modal แก้ไขแอดมิน */}
        <Modal title="แก้ไขแอดมิน" open={this.state.isModalEditAdminVisible} onOk={() => this.updateAdmin()} onCancel={() => this.setState({ isModalEditAdminVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="username" required>
              <Input value={this.state.admin.username} disabled />
            </Form.Item>
            <Form.Item label="password" extra="หากไม่ต้องการเปลี่ยนให้ใส่ค่าว่าง" required>
              <Input.Password
                defaultValue={this.state.admin.password}
                onChange={(e) => this.setState({ admin_password_edit: e.target.value })}
                placeholder="แก้ไขรหัสผ่าน"
              />
            </Form.Item>
            <Form.Item label="ชื่อแอดมิน" required>
              <Input
                defaultValue={this.state.admin.name}
                onChange={(e) => this.setState({ admin_fullname_edit: e.target.value })}
              />
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal Log แอดมิน */}
        <Modal
          title={<><Title level={5}><AuditOutlined /> ประวัติการทำรายการ</Title></>}
          style={{ top: 20, minWidth: 1050 }}
          open={this.state.isModalLogAdminVisible}
          footer={null}
          onCancel={() => this.setState({ isModalLogAdminVisible: false })}
        >
          <Tabs type="card">
            <Tabs.TabPane tab={<><DollarCircleOutlined />ประวัติการเงิน</>} key="1">
              <LogCredit admin_id={this.state.admin.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><SketchOutlined />ประวัติแจกเพชร</>} key="2">
              <LogPoint admin_id={this.state.admin.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><EditOutlined />แก้ไขข้อมูลสมาชิก</>} key="3">
              <LogMember admin_id={this.state.admin.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><ShopOutlined />ประวัติการจัดการโปรฯ และการแจกโปรฯ</>} key="4">
              <LogPromotion admin_id={this.state.admin.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={<><FileSearchOutlined />อื่นๆ</>} key="5">
              <LogOther admin_id={this.state.admin.id} />
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      </>
    );
  }
}

export default withRouter(home)