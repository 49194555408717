import React, { Component } from 'react';
import { Table, Card, Input, Button, Image, Tag, Divider, Col, Row, Typography, Modal, Form, message, InputNumber, Select, Alert, PageHeader, Spin, Tooltip, Switch, Tabs, Collapse, Statistic } from 'antd';
import { PrinterOutlined, PlusCircleOutlined, DollarCircleOutlined, StockOutlined, EditOutlined, StarFilled, SketchOutlined, ShopOutlined, UsergroupAddOutlined, TransactionOutlined } from '@ant-design/icons';
import axios from 'axios';
import { URL, WEB_NAME } from '../../../Util/Config'
import sha1 from 'js-sha1';
import x0p from 'x0popup';
import { withRouter } from 'react-router'
import LogCredit from './detail/credit'
import LogEvent from './detail/event/index'
import LogPromotion from './detail/promotion'
import LogRecommend from './detail/recommend'
import LogReturn from './detail/return/index'
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import ReactToPrint from 'react-to-print';
// import logo from '../../../image/logo_bank/baac.svg';

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;
const { Panel } = Collapse;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const headersCSV = [
  { label: "ลำดับ", key: "row_num" },
  { label: "ผู้ใช้งาน", key: "user_username" },
  { label: "ชื่อ", key: "user_name" },
  { label: "เบอร์ติดต่อ", key: "user_phone" },
  { label: "ยอดฝากสะสม", key: "deposit" },
  { label: "บัญชีธนาคาร", key: "bank_abbrev_th" },
  { label: "เลขบัญชี", key: "user_banknumber" },
  { label: "สถานะ", key: "user_status" },
  { label: "สมัครเมื่อวันที่", key: "created_at" },
  { label: "ใช้งานล่าสุดเมื่อวันที่", key: "updated_at" },
  { label: "ทำธุรกรรมล่าสุด (วัน)", key: "last_day" },
];

class member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalAddMemberVisible: false,
      isModalAddMemberPrintVisible: false,
      visibleDrawer: false,
      visiblePhone: false,
      visibleBankAccount: false,
      visableModalBank: false,
      visableModalBalanc: false,
      isModalSecuVisible: false,
      isModalPromotionVisible: false,
      isModalNewPasswordVisible: false,
      isModalNewPhoneVisible: false,
      visableModalPoint: false,
      banklist: [],
      userCopyList: [],
      userProfile: {},
      fields: {},
      errors: {},
      admin_id: '',
      admin_type: '',
      admin_token: '',
      admin_username: '',
      admin_password: '',
      inp_newPassword: '',
      inp_newPhone: '',
      inp_point: 0,
      loading1: false,
      columns1: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          // sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: () => (
            <>ชื่อผู้ใช้งาน <StarFilled style={{ color: this.state.star ? 'orange' : 'gainsboro' }} onClick={() => this.searchStar(!this.state.star)} /></>
          ),
          dataIndex: 'user_username',
          width: 150,
          // ...this.getColumnSearchProps('user_username'),
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          width: 300,
          // ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          // ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ติดต่อ',
          dataIndex: 'user_phone',
          // ...this.getColumnSearchProps('user_phone'),
        },
        {
          title: 'รายละเอียด',
          dataIndex: '',
          render: (text, record) => (
            <Button type="primary" icon={<EditOutlined />} onClick={() => this.setProfile(record)}>รายละเอียด</Button>
          ),
        }
      ],
      data1: [],
      userPromotion: null,
      balanceType: 'ฝาก',
      balanceNumber: 1,
      balanceNote: '',
      min_turn: '0',
      min_balance: '0',
      starIcon: false,
      user_status: '',
      pagination: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
      searchText: '',
      columns2: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          // sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          width: 150,
          // ...this.getColumnSearchProps('user_username'),
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          width: 300,
          // ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          // ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ติดต่อ',
          dataIndex: 'user_phone',
          // ...this.getColumnSearchProps('user_phone'),
        },
        {
          title: 'รายละเอียด',
          dataIndex: '',
          render: (text, record) => (
            <Button type="primary" icon={<EditOutlined />} onClick={() => this.setProfile(record)}>รายละเอียด</Button>
          ),
        }
      ],
      data2: [],
      loading2: false,
      user_test: '',
      columns3: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          defaultSortOrder: 'descend',
          // sorter: (a, b) => a.row_num - b.row_num,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          width: 150,
          // ...this.getColumnSearchProps('user_username'),
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          width: 300,
          // ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          // ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'เบอร์ติดต่อ',
          dataIndex: 'user_phone',
          // ...this.getColumnSearchProps('user_phone'),
        },
        {
          title: 'รายละเอียด',
          dataIndex: '',
          render: (text, record) => (
            <Button type="primary" icon={<EditOutlined />} onClick={() => this.setProfile(record)}>รายละเอียด</Button>
          ),
        }
      ],
      data3: [],
      loading3: false,
      gamesTest: [],
      visableModalDetail: true,
      star: false,
      show: false,
      returnloss: {},
      returnlossNetwork: {},
      returnloss_bonus: null,
      returnloss_network_bonus: null,
      returnover: {},
      loadingCSV: false,
      dataCSV: [],
      form_add: {
        bank_status: false,
        user_copy_phone: '',
        user_copy_password: '',
        user_copy_bank_id: '002',
        user_copy_bank_number: '',
        user_copy_bank_account: '',
        user_copy_credit: '0',
        user_copy_point: '0',
        user_copy_min_turn: '0',
        user_copy_min_balance: '0',
        user_copy_promotion: ''
      },
      promotionList: [],
      data_print: {},
      returnover_bonus: '',
      returnover_turnover: ''
    };
    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_id: user.user.id, admin_name: user.user.name }, async () => {
      await this.getData()
      // this.getDataSuspend()
      // this.getDataTest()

      this.getAmountUserAll()
      this.getAmountRegister30DayAgo()
      this.getAmountRegisterToday()
      // this.getBank()
    })
  }

  searchStar(star) {
    this.setState({ star: star }, () => {
      this.getData()
    })
  }

  // SUMMARY
  getAmountUserAll() {
    fetch(`${URL}/api/v1/user/amount/all`)
      .then((response) => response.json())
      .then((json) => this.setState({ amountUserAll: json.result }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getAmountRegister30DayAgo() {
    fetch(`${URL}/api/v1/user/amount/register/30dayago`)
      .then((response) => response.json())
      .then((json) => this.setState({ amountRegister30DayAgo: json.result }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  getAmountRegisterToday() {
    fetch(`${URL}/api/v1/user/amount/register/today`)
      .then((response) => response.json())
      .then((json) => this.setState({ amountRegisterToday: json.result[0].amount }))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // EXPORT CSV
  downloadReport = async () => {
    this.setState({ loadingCSV: true })
    await axios.get(`${URL}/api/v1/user/export`).then((res) => {
      if (res.data.status) {
        this.setState({ loadingCSV: false, dataCSV: res.data.result }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      } else {
        this.setState({
          loadingCSV: false
        }, () => {
          message.error('ไม่สามารถดาวโหลดไฟล์ได้')
        });
      }
    }).catch(() => {
      this.setState({
        loadingCSV: false
      }, () => {
        message.error('ไม่สามารถดาวโหลดไฟล์ได้')
      });
    });
  }

  async getData(params = {}) {
    if (params.pagination === undefined) {
      params = this.state.pagination
    }
    this.setState({ loading1: true })
    var url = `${URL}/api/v1/user/page/${params.pagination.current}`
    if (this.state.searchText !== '') {
      url = url + `/search/${this.state.searchText}`
    }
    if (this.state.star) {
      url = url + `/star/${this.state.star}`
    }
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then(({ result, total }) => {
        this.setState({
          data1: result,
          loading1: false,
          pagination: {
            pagination: {
              ...params.pagination,
              total: total,
            }
          },
        })
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  };

  // ยูสที่ถูกระงับ
  async getDataSuspend() {
    this.setState({ loading2: true })
    var url = `${URL}/api/v1/user/suspend`
    fetch(url)
      .then((response) => response.json())
      .then(({ result }) => {
        this.setState({
          data2: result,
          loading2: false,
        })
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ยูสทดสอบ
  async getDataTest() {
    this.setState({ loading3: true })
    var url = `${URL}/api/v1/user/tester`
    fetch(url)
      .then((response) => response.json())
      .then(({ result, gamestest }) => {
        this.setState({
          data3: result,
          loading3: false,
          gamesTest: gamestest
        })
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  handleTableChange(newPagination, filters, sorter) {
    this.getData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  async setProfile(value) {
    await this.getProfile(value.user_id)
    this.getPromotion(value.user_id)
    this.getBalance(value.user_username)
    this.getBalancePG(value.user_username)
    this.getCountRecommend(value.user_username)
    this.getReturnloss(value.user_id)
    // this.getWinLost(value.user_username)
  }

  // ดึงข้อมูลสมาชิก
  async getProfile(user_id) {
    await axios.get(`${URL}/api/v1/user/user_id/${user_id}`)
    .then(res => {
      const data = res.data
      if (data.status) {
        const value = data.result
        this.setState({ visibleDrawer: true, userProfile: value, min_turn: value.user_min_turn, min_balance: value.user_min_balance, starIcon: value.user_tag === '0' ? false : true, user_status: value.user_status, user_test: value.user_test === '0' ? false : true })
      } 
    })
    .catch(error => {
      console.log(error)
      console.log('การเชื่อมต่อผิดพลาด getProfile')
    })
  }

  // ตรวจสอบโปรโมชั่น
  async getPromotion(user_id) {
    const body = {
      user_id: user_id
    }
    await axios.post(`${URL}/api/v1/promotion/by/user`, body)
      .then(res => {
        const data = res.data
        // console.log('getPromotion --> ', data)
        if (data.status && data.result.log_discount_status != null) {
          this.setState({ userPromotion: data.result })
          this.setState({ userPromotion: { ...this.state.userPromotion, totalPlay: data.result.total_play } })

        } else {
          this.setState({ userPromotion: null })
        }
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด getPromotion')
      })
  }

  // ตรวจสอบยอดเงินในระบบ
  async getBalance(username) {
    const body = { username: username }
    await axios.post(`${URL}/api/v1/agent/member/balance`, body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        const data = res.data
        // console.log('getBalance --> ', data)
        if (data.status) {
          this.setState({ userProfile: { ...this.state.userProfile, balance: data.result } })
          // console.log(this.state.userProfile)
        } else {
          console.log('err getBalance')
        }
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด getBalance')
      })
  }

  // ตรวจสอบยอดเงินในระบบ
  async getBalancePG(username) {
    const body = { username: username }
    await axios.post(`${URL}/api/v1/agent-pg/member/balance`, body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        const data = res.data
        // console.log('getBalance --> ', data)
        if (data.status) {
          this.setState({ userProfile: { ...this.state.userProfile, balancePG: data.result } })
          // console.log(this.state.userProfile)
        } else {
          console.log('err getBalance')
        }
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด getBalance')
      })
  }

  // จำนวนแนะนำเพื่อน
  async getCountRecommend(username) {
    fetch(`${URL}/api/v1/user/advisor/count/by/username/${username}`)
      .then((response) => response.json())
      .then((json) => this.setState({ userProfile: { ...this.state.userProfile, amountRecommend: json.result.amount_recommend, advisor_title: json.result.advisor } }))
      // .then(() => console.log(this.state.userProfile))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ยอด แพ้/ชนะ
  // async getWinLost(username) {
  //   const body = { username: username }
  //   await axios.post(`${URL}/api/v1/agent/member/check/winlose`, body)
  //   .then(res => {
  //       const data = res.data
  //       if(data.status){
  //         this.setState({ userProfile: { ...this.state.userProfile,  winLost: data.result } })
  //       } else {
  //         console.log('err getWinLost')
  //       }
  //   })
  //   .catch(error => {
  //       console.log(error)
  //       console.log('การเชื่อมต่อผิดพลาด getWinLost')
  //   })
  // }

  // -------------------------------------------------------------- START Validat FORM EDIT BANK ------------------------------------------
  // GET BANK ดึงข้อมูลธนาคาร
  async getBank() {
    await axios.get(URL + "/api/v1/bank/all")
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({ banklist: data.result, loading1: false })
          // this.handleChange(data.result[0].bank_id, "bankid")
        } else {
          // alert(data.status + " : " + data.message)
          console.log('ข้อมูลไม่ถูกต้อง getBank')
          console.log(data)
        }
      })
      .catch(error => {
        console.log(error);
        message.error('การเชื่อมต่อผิดพลาด Get Bank!!');
      })
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //account bank
    if (!fields["banknumber"] && fields["banknumber"] !== '') {
      formIsValid = false;
      errors["banknumber"] = "กรุณากรอกข้อมูลเลขบัญชีธนาคาร";
    }

    if (typeof fields["banknumber"] !== "undefined" && fields["banknumber"] !== '' && fields["banknumber"] !== null) {
      if (!fields["banknumber"].match(/\d+/)) {
        formIsValid = false;
        errors["banknumber"] = "กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง กรอกได้เฉพาะตัวเลข (0-9) เท่านั้น";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit() {
    if (this.handleValidation()) {
      this.setState({ loading1: true })
      // console.log('success')
      this.updateUser()
    } else {
      message.error('กรุณาตรวจสอบข้อมูลให้ถูกต้อง!!');
    }
  }

  handleChange(value, field) {
    // console.log("value : ", value)
    // console.log("filed : ", field)
    let fields = this.state.fields;
    fields[field] = value;
    this.setState({ fields });
  }

  // Select รายชื่อธนาคาร
  bankList() {
    const bankList = this.state.banklist
    let list = bankList.map((item, index) =>
      <option key={index} value={item.bank_id}>{item.bank_name_th}</option>
    );
    return (list)
  }

  // Update USER แก้ไขข้อมูลธนาคาร
  async updateUser() {
    const fields = this.state.fields
    const body = {
      user_id: this.state.userProfile.user_id,
      bankID: fields["bankid"],
      bankNumber: fields["banknumber"],
      bankName: fields["bankname"]
      // telphone: fields["telphone"],
      // telphone: this.state.userProfile.user_phone 
    }
    await axios.post(URL + "/api/v1/user/update", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.getData()
          message.success('ทำรายการเสร็จสิ้น!!');
          this.setState({ visibleDrawer: false, visableModalBank: false })
          this.sendMessageSucces(`คุณได้ทำการเปลี่ยนบัญชีธนาคาร \nเลขบัญชี: ${fields["banknumber"]}`)
        } else if (data.message === 'เลขที่บัญชีไม่ถูกต้อง') {
          let errors = {};
          errors["banknumber"] = "เลขบัญชีธนาคารไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง!!";
          this.setState({ errors: errors });
          message.error('เลขบัญชีธนาคารไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง!!');
        } else {
          message.error(data.message);
        }
      })
      .catch(error => {
        console.log(error)
        console.log('การเชื่อมต่อผิดพลาด Update')
        this.setState({ loading1: false })
      })
  }

  async sendMessageSucces(ms) {
    const body = {
      userID: this.state.userProfile.user_userId,
      message: ms,
    }
    await axios.post(URL + "/api/v1/line/messages/push", body)
      .then(res => {
        var data = res.data
        if (data.status) {
          console.log('ส่งข้อความ แจ้งเตือนสมาชิกสำเร็จ!!')
        } else {
          console.log('ไม่สามารถส่งข้อความ แจ้งเตือนสมาชิกใหม่!!')
        }
      })
      .catch(error => {
        console.log(error)
        message.error('การเชื่อมต่อผิดพลาด Insert!!');
      })
  }
  // -------------------------------------------------------------- END Validat ------------------------------------------------------------------------

  // ยืนยันตัวตน
  checkSecurity() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (this.state.admin_password !== '') {

      if (sha1(this.state.admin_password) === user.user.password) {
        message.success('ยืนยันตัวตนสำเร็จ!!')
        this.setState({ isModalSecuVisible: false, admin_password: '' })
        return true
      } else {
        message.error('username / password ไม่ถูกต้อง')
      }

    } else {
      message.error('กรุณากรอก username และ password')
    }
  }

  contactSubmitBalanc() {
    // if(this.checkSecurity()){
    // swal({
    //   title: "ยืนยันการทำรายการ!!",
    //   text: ``,
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    // .then((willDelete) => {
    // if (willDelete) {
    switch (this.state.balanceType) {
      case 'ฝาก':
        this.deposit()
        break;

      case 'ถอน':
        this.withdraw()
        break;

      case 'แจ้งถอน':
        this.withdraw2()
        break;

      case 'แจกเครดิตฟรี':
        this.depositCreditFree()
        break;

      case 'ถอนเครดิตฟรี':
        this.withdrawCreditFree()
        break;

      default:
        message.error('เกิดข้อผิดพลาด balanceType ผิด')
        break;
    }
    // }
    // });
    // }
  }

  // ฝากเครดิต
  async deposit() {
    x0p({
      title: 'แจ้งเตือน!!',
      text: `ฝากเครดิตให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${parseInt(this.state.balanceNumber)}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          amount: parseInt(this.state.balanceNumber),
          note: this.state.balanceNote,
        }
        await axios.post(URL + "/api/v1/agent/member/deposit", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            // console.log(res.data)
            var data = res.data
            if (data.status) {
              this.getData()
              // this.sendMessageSucces(`คุณได้รับเครดิต ${parseInt(this.state.balanceNumber)}`)
              x0p(data.message, null, 'ok', false);
              this.setState({ visableModalBalanc: false, visibleDrawer: false })
            } else if (data.message === 'ท่านได้เติมเงินเกินวงเงินของระบบ กรุณาติดต่อ แอดมิน!!') {
              x0p('วงเงินในระบบ AG ไม่เพียงพอ!!', null, 'error', false);
            } else {
              console.log(data)
              x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด member/deposit', null, 'error', false);
          })
      }
    })
  }

  // ฝากเครดิตฟรี
  async depositCreditFree() {
    x0p({
      title: 'แจ้งเตือน!!',
      text: `เติมเครดิตฟรีให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${parseInt(this.state.balanceNumber)}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          amount: parseInt(this.state.balanceNumber),
          creditflag: 'CF',
          note: this.state.balanceNote,
          // event: `ทำรายการ "แจกเครดิตฟรี" ให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${this.state.balanceNumber} บาท`
        }
        await axios.post(URL + "/api/v1/agent/member/deposit", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            // console.log(res.data)
            var data = res.data
            if (data.status) {
              this.getData()
              // this.sendMessageSucces(`คุณได้รับเครดิต ${parseInt(this.state.balanceNumber)}`)
              x0p(data.message, null, 'ok', false);
              this.setState({ visableModalBalanc: false, visibleDrawer: false })
            } else if (data.message === 'ท่านได้เติมเงินเกินวงเงินของระบบ กรุณาติดต่อ แอดมิน!!') {
              x0p('วงเงินในระบบ AG ไม่เพียงพอ!!', null, 'error', false);
            } else {
              console.log(data)
              x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด member/deposit', null, 'error', false);
          })
      }
    })
  }

  // ถอนเครดิต
  async withdraw() {
    x0p({
      title: 'แจ้งเตือน!!',
      text: `ถอนเครดิตให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${parseInt(this.state.balanceNumber)}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          amount: parseInt(this.state.balanceNumber),
          note: this.state.balanceNote,
        }
        await axios.post(URL + "/api/v1/agent/member/withdraw", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            // console.log(res.data)
            var data = res.data
            if (data.status) {
              this.getData()
              // this.sendMessageSucces(`คุณได้ถอนเครดิต ${parseInt(this.state.balanceNumber)}`)
              x0p(data.message, null, 'ok', false);
              this.setState({ visableModalBalanc: false, visibleDrawer: false })
            } else if (data.message === 'ยอดการถอนเกินวงเงินในระบบ กรุณาติดต่อแอดมิน!!') {
              x0p('ยอดการถอนเกินวงเงินในระบบ AG !!', null, 'error', false);
            } else {
              console.log(data)
              x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด member/withdraw', null, 'error', false);
          })
      }
    })
  }

  // แจ้งถอนเครดิต (รออนุมัติโอนเงิน)
  async withdraw2() {
    x0p({
      title: 'แจ้งเตือน!!',
      text: `แจ้งถอนเครดิตให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${parseInt(this.state.balanceNumber)}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          amount: parseInt(this.state.balanceNumber),
          note: this.state.balanceNote,
          channel: 'Bank, TWL',
          admin: true,
        }
        await axios.post(URL + "/api/v4/transfer/withdraw", body)
          .then(res => {
            var data = res.data
            if (data.status) {
              this.getData()
              x0p(data.message, null, 'ok', false);
              this.setState({ visableModalBalanc: false, visibleDrawer: false })
            } else {
              console.log(data)
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด member/withdraw', null, 'error', false);
          })
      }
    })
  }

  async withdrawCreditFree() {
    x0p({
      title: 'แจ้งเตือน!!',
      text: `ถอนเครดิต (ไม่รวมยอดโอน) ให้สมาชิก ${this.state.userProfile.user_username} จำนวน ${parseInt(this.state.balanceNumber)}`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const admin = JSON.parse(localStorage.getItem('user'))
        const body = {
          user_agentId: this.state.userProfile.user_agentId,
          username: this.state.userProfile.user_username,
          amount: parseInt(this.state.balanceNumber)
        }
        await axios.post(URL + "/api/v1/agent/member/withdraw", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            var data = res.data
            if (data.status) {
              this.getData()
              // this.sendMessageSucces(data.message)
              x0p(data.message, null, 'ok', false);
              this.setState({ visableModalBalanc: false, visibleDrawer: false })
              this.addTransaction(`ถอน เครดิต(ไม่รวมยอดโอน)ผ่านแอดมิน ${admin.user.name}`, this.state.balanceNumber, 'ถอนเครดิตฟรี')
            } else if (data.message === 'ยอดการถอนเกินวงเงินในระบบ กรุณาติดต่อแอดมิน!!') {
              x0p('ยอดการถอนเกินวงเงินในระบบ AG !!', null, 'error', false);
            } else {
              console.log(data)
              x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด member/withdraw', null, 'error', false);
          })
      }
    })
  }

  async useCoupon() {
    x0p({
      title: 'ใช้โค้ดโปรโมชั่น',
      type: 'info',
      inputType: 'text',
      inputPlaceholder: 'โค้ดโปรโมชั่น',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ],
      inputPromise: function (button, value) {
        var p = new Promise(function (resolve, reject) {
          if (value === '')
            resolve('กรุณากรอกโค้ด!!');
          resolve(null);
        });
        return p;
      }
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          username: this.state.userProfile.user_username,
          code: alert.text
        }
        axios.post(URL + "/api/v1/discount/coupon/use", body)
          .then(res => {
            const data = res.data
            if (data.status) {
              this.setState({ visibleDrawer: false })
              x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
              this.sendMessageSucces(data.message)
            } else {
              x0p(data.message, null, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  async usePromotion() {
    if (this.state.userPromotion.discount_type === 'บาท') {
      x0p({
        title: 'ยืนยันใช้งานโปรฯ',
        text: 'สมาชิกจะได้รับเครดิตฟรี พร้อมใช้งานโปรฯ',
        type: 'info',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ]
      }).then(async (alert) => {
        if (alert.button === 'info') {
          this.postUsePromotion(this.state.userPromotion.discount_min_deposit)
        }
      })

    } else {
      x0p({
        title: 'ยืนยันใช้งานโปรฯ',
        type: 'info',
        inputType: 'text',
        inputPlaceholder: 'ระบุยอดที่สมาชิกฝากมา',
        buttons: [
          {
            type: 'cancel',
            text: 'ยกเลิก',
          },
          {
            type: 'info',
            text: 'ยืนยัน',
            showLoading: true
          }
        ],
        // inputColor: '#F29F3F',
        inputPromise: function (button, value) {
          var p = new Promise(function (resolve, reject) {
            if (value === '' || isNaN(value))
              resolve('ระบุเป็นตัวเลข!');
            resolve(null);
          });
          return p;
        }
      }).then(async (alert) => {
        if (alert.button === 'info') {
          this.postUsePromotion(alert.text)
        }
      })
    }
  }

  async postUsePromotion(amount) {
    const body = {
      amount: amount,
      log_discount_id: this.state.userPromotion.log_discount_id
    }

    axios.post(URL + "/api/v1/promotion/use", body)
      .then(res => {
        const data = res.data
        if (data.status) {
          this.setState({ visibleDrawer: false })
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
          // this.sendMessageSucces(data.message)
        } else {
          x0p(data.message, null, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
      })
  }

  // ยกเลิกโปรไม่รียูส ไม่ตัดเครดิต
  async clearPromotion(log_discount_id) {
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการยกเลิกโปรโมชั่นให้กับสมาชิก..ใช้มั้ย?`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          log_discount_id: log_discount_id,
        }
        axios.post(URL + "/api/v1/promotion/cancel", body)
          .then(res => {
            if (res.data) {
              this.setState({ visibleDrawer: false })
              x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
            } else {
              x0p('', res.data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  // ยกเลิกโปรรียูส ตัดเครดิต
  async clearPromotionV2(log_discount_id) {
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการยกเลิกโปรโมชั่นให้กับสมาชิก..ใช้มั้ย?`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          log_discount_id: log_discount_id,
          admin: true
        }
        axios.post(URL + "/api/v1/promotion/cancel/member", body)
          .then(res => {
            if (res.data.status) {
              this.setState({ visibleDrawer: false })
              x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
            } else {
              x0p('', res.data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  async addTransaction(note, amount, type) {
    const admin = JSON.parse(localStorage.getItem('user'))
    var creditflag = 'ไม่ระบุ'
    switch (type) {
      case 'ฝาก':
        creditflag = 'CM'
        break;

      case 'ถอน':
        creditflag = 'DM'
        break;

      case 'แจกเครดิตฟรี':
        creditflag = 'CF'
        break;

      default: break;

      // case 'ถอนเครดิตฟรี':
      //   creditflag = 'DF'
      //   break;
    }
    const body = {
      amount: amount,
      remark: note,
      creditflag: creditflag,
      note: note,
      user_id: this.state.userProfile.user_id,
      admin_id: admin.user.id
    }
    await axios.post(`${URL}/api/v1/transaction/add`, body)
      .then(res => {
        const data = res.data
        if (data.status) {
          x0p('ทำรายการเสร็จสิ้น!!', null, 'ok')
        } else {
          x0p('เกิดข้อผิดพลาด!!', null, 'error')
        }
      })
      .catch(error => {
        x0p('เกิดข้อผิดพลาด!!', null, 'error')
        console.log(error)
      })
  }

  // รับยอดเสียคืน // ยังไม่แก้
  async gotLost() {
    x0p({
      title: 'ยืนยัน!!',
      text: `ต้องการรับยอดเสียคืนใช่มั้ย?`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {

        const body = {
          username: this.state.userProfile.user_username,
        }
        await axios.post(`${URL}/api/v1/promotion/use/returnloss`, body)
          .then(res => {
            const data = res.data
            x0p('', data.message, data.status ? 'ok' : 'error', false)
          })
          .catch(error => {
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false)
            console.log(error)
          })

      }
    })
  }

  // ข้อมูลคืนยอดเสีย และคินยอดเสียแนะนำเพื่อน
  async getReturnloss(user_id) {
    fetch(`${URL}/api/v1/returnloss/user_id/${user_id}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.status) {
          this.setState({ returnloss: json.result.returnloss, returnlossNetwork: json.result.returnloss_network, returnover: json.result.returnover })
        }
      })
      // .then((json) => console.log(json))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  // ตรวจสอบโบนัสคืนยอดเสีย
  async getBonusReturnloss(user_id) {
    const body = {
      user_id: user_id
    }
    await axios.post(URL + "/api/v1/returnloss/check", body)
      .then(res => {
        if (res.data.status) {
          this.setState({ returnloss_bonus: res.data.result.bonus })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading1: false })
      })
  }

  // ตรวจสอบโบนัสคืนยอดเสียแนะนำเพื่อน
  async getBonusReturnlossNetwork(user_id) {
    const body = {
      user_id: user_id
    }
    await axios.post(URL + "/api/v1/affiliate/returnloss/check", body)
      .then(res => {
        if (res.data.status) {
          this.setState({ returnloss_network_bonus: res.data.result.bonus })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading1: false })
      })
  }

  // ตรวจสอบโบนัสคืนยอดเล่นสะสม
  async getBonusReturnOver(user_id) {
    const body = {
      user_id: user_id
    }
    await axios.post(URL + "/api/v1/returnover/check", body)
      .then(res => {
        if (res.data.status) {
          this.setState({ returnover_bonus: res.data.result.bonus, returnover_turnover: res.data.result.turnover })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading1: false })
      })
  }

  // เปลี่ยนรหัสผ่าน
  async submitChangePassword() {
    x0p({
      title: 'ยืนยัน!!',
      text: `ต้องการเปลี่ยนรหัสผ่านใช่มั้ย?`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          newPassword: this.state.inp_newPassword,
          admin_id: this.state.admin_id,
          ip: localStorage.getItem('ip')
        }
        await axios.post(`${URL}/api/v1/user/change/password`, body)
          .then(res => {
            const data = res.data
            x0p('', data.message, data.status ? 'ok' : 'error', false)
          })
          .catch(error => {
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false)
            console.log(error)
          })

      }
    })
  }

  // เปลี่ยนเบอร์ติดต่อ
  async submitChangePhone() {
    x0p({
      title: 'ยืนยัน!!',
      text: `ต้องการเปลี่ยนเบอร์ติดต่อใช่มั้ย?`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          phone: this.state.inp_newPhone,
          admin_id: this.state.admin_id,
          ip: localStorage.getItem('ip')
        }
        await axios.post(`${URL}/api/v1/user/update/phone`, body)
          .then(res => {
            const data = res.data
            this.getData()
            x0p('', data.message, data.status ? 'ok' : 'error', false)
            this.setState({ isModalNewPhoneVisible: false })
          })
          .catch(error => {
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false)
            console.log(error)
          })
      }
    })
  }

  // ตั้งค่ายอดเทิร์น ยอดคงเหลือขั้นต่ำรายคน
  async setMinTurnAndMinCredit() {
    x0p({
      title: 'ยืนยัน!!',
      text: `ยืนยันการตั้งค่าธุรกรรมขั้นต่ำ`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          min_turn: this.state.min_turn,
          min_balance: this.state.min_balance,
        }
        await axios.post(URL + "/api/v1/user/setting/mintransaction", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            var data = res.data
            if (data.status) {
              this.getData()
              x0p(data.message, null, 'ok', false);

            } else {
              console.log(data)
              x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด setMinTurnAndMinCredit', null, 'error', false);
          })
      }
    })
  }

  // ติดดาวให้สมาชิก
  async tagStar() {
    this.setTag(!this.state.starIcon ? '1' : '0')
  }

  // ตั้งค่า Tag
  async setTag(tag) {
    const body = {
      user_id: this.state.userProfile.user_id,
      tag: tag,
    }
    await axios.post(URL + "/api/v1/user/setting/tag", body)
      .then(res => {
        var data = res.data
        if (data.status) {
          this.getData()
          this.setState({ starIcon: !this.state.starIcon })

        } else {
          console.log(data)
          x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด setTag', null, 'error', false);
      })
  }

  // ตั้งค่า status
  async setStatus(user_status) {
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการตั้งสถานะ "${user_status === true ? 'ปกติ' : 'ระงับ'}" ให้กับผู้ใช้นี้ใช่มั้ย??`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        user_status = user_status === true ? 'รอยืนยันตัวตน' : 'ระงับ'
        const body = {
          user_id: this.state.userProfile.user_id,
          status: user_status
        }

        await axios.post(`${URL}/api/v1/user/update/status`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            if (data.status) {
              x0p('สำเร็จ', null, 'ok', false);
              this.setState({ user_status: user_status })
              this.getData()
              this.getDataSuspend()
            } else {
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  // ตั้งค่า user test
  async setStatusTest(user_test) {
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการตั้งสถานะ "${user_test === true ? 'ทดสอบ' : 'ปกติ'}" ให้กับผู้ใช้นี้ใช่มั้ย??`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          user_id: this.state.userProfile.user_id,
          status: user_test === true ? '1' : '0'
        }

        await axios.post(`${URL}/api/v1/user/update/status/tester`, body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            if (data.status) {
              x0p('สำเร็จ', null, 'ok', false);
              this.setState({ user_test: user_test })
              this.getData()
              this.getDataTest()
            } else {
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  // แก้ไข point
  async postUpdatePoint() {
    x0p({
      title: 'ยืนยัน!!',
      text: ``,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const admin = JSON.parse(localStorage.getItem('user'))
        const body = {
          user_id: this.state.userProfile.user_id,
          point: this.state.inp_point,
          admin_id: admin.user.id
        }
        await axios.post(`${URL}/api/v1/user/update/point`, body)
          .then(res => {
            const data = res.data
            if (data.status) {
              x0p('สำเร็จ', null, 'ok', false);
              this.setState({ visableModalPoint: false, visibleDrawer: false })
              this.getData()
            } else {
              x0p('', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
          })
      }
    })
  }

  onSearch() {
    this.getData({
      pagination: {
        current: 1,
        pageSize: 20,
      }
    })
  }

  setGamesTest() {
    let list = this.state.gamesTest.map((item, index) =>
      <Col span={3}>
        <Image src={item.image} width={100} />
        <p className='m-0'>[{item.provider}]</p>
        <p>{item.name}</p>
      </Col>
    );
    return (list)
  }

  // check bank ไม่ใช้แล้ว
  // async checkBank() {
  //   const { user_copy_bank_id, user_copy_bank_number, bank_status } = this.state.form_add

  //   if (bank_status) {
  //     return this.setState({
  //       form_add: {
  //         ...this.state.form_add,
  //         user_copy_bank_account: '',
  //         user_copy_bank_number: '',
  //         bank_status: false
  //       }
  //     })
  //   }

  //   const body = {
  //     bank_id: user_copy_bank_id,
  //     bank_number: user_copy_bank_number,
  //   }
  //   await axios.post(URL + "/api/v1/scb/account/check", body, {
  //     headers: {
  //       'Authorization': `Bearer ${this.state.admin_token}`,
  //     }
  //   })
  //     .then(res => {
  //       var data = res.data
  //       console.log(data)
  //       if (data.status) {
  //         this.setState({ form_add: { ...this.state.form_add, user_copy_bank_account: data.result.accountToName, bank_status: true } })
  //         x0p('', data.message, 'ok', false);
  //       } else {
  //         x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       x0p('การเชื่อมต่อผิดพลาด checkBank', null, 'error', false);
  //     })
  // }

  // user coppy
  async postAllUserCopy() {
    await axios.get(URL + "/api/v1/user/copy/all")
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ userCopyList: data.result })
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด postAllUserCopy', null, 'error', false);
      })
  }

  async postAddUserCopy() {
    const { user_copy_password, user_copy_credit, user_copy_point, user_copy_promotion,
      user_copy_min_turn, user_copy_min_balance, user_copy_advisor } = this.state.form_add
    x0p({
      title: 'ชื่อรูปแบบ',
      type: 'info',
      inputType: 'text',
      inputPlaceholder: 'กรอกชื่อรูปแบบ',
      inputColor: '#F29F3F',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ],
      inputPromise: function (button, value) {
        var p = new Promise(function (resolve, reject) {
          resolve(null);
        });
        return p;
      },
    }, async function (button, text) {
      if (button === 'info') {
        const body = {
          name: text,
          password: user_copy_password,
          credit: user_copy_credit,
          point: user_copy_point,
          promotion: user_copy_promotion,
          min_turn: user_copy_min_turn,
          min_balance: user_copy_min_balance,
          advisor: user_copy_advisor,
        }

        await axios.post(URL + "/api/v1/user/copy/add", body)
          .then(res => {
            var data = res.data
            if (data.status) {
              x0p('', data.message, 'ok', false);
            } else {
              x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด postAddUserCopy', null, 'error', false);
          })
      }
    });
  }

  async postRemoveUserCopy(id) {
    await axios.get(URL + "/api/v1/user/copy/remove/" + id)
      .then(res => {
        var data = res.data
        if (data.status) {
        } else {
          x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด postRemoveUserCopy', null, 'error', false);
      })
  }

  // Select รายชื่อธนาคาร
  TagUserCopyList() {
    const userCopyList = this.state.userCopyList
    let list = userCopyList.map((item, index) =>
      <Tag closable
        onClick={(e) => this.setUserCopy(item)}
        onClose={(e) => this.postRemoveUserCopy(item.user_copy_id)}>
        {item.user_copy_name}
      </Tag>
    );
    return (list)
  }

  setUserCopy(dataSet) {
    this.setState({
      form_add: {
        ...this.state.form_add,
        user_copy_password: dataSet.user_copy_password,
        user_copy_credit: dataSet.user_copy_credit,
        user_copy_point: dataSet.user_copy_point,
        user_copy_min_turn: dataSet.user_copy_min_turn,
        user_copy_min_balance: dataSet.user_copy_min_balance,
        user_copy_advisor: dataSet.user_copy_advisor,
        user_copy_promotion: dataSet.user_copy_promotion
      }
    })
  }

  async getPromotionOpen() {
    await axios.get(URL + "/api/v1/discount/open")
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ promotionList: data.result })
        } else {
          x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด getPromotionOpen', null, 'error', false);
      })
  }

  PromotionList() {
    const promotionList = this.state.promotionList
    let list = promotionList.map((item, index) =>
      <option key={index} value={item.discount_id}>{item.discount_name}</option>
    );
    return (list)
  }

  async PostCreateMember() {
    x0p({
      title: 'ยืนยันสมัครสมาชิก!!',
      text: ``,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const form_add = this.state.form_add
        const body = {
          password: form_add.user_copy_password,
          phone: form_add.user_copy_phone,
          bank_id: form_add.user_copy_bank_id,
          bank_account: form_add.user_copy_bank_account,
          bank_number: form_add.user_copy_bank_number,
          bank_status: form_add.user_copy_bank_number != '' ? 1 : 0,
          credit: form_add.user_copy_credit,
          point: form_add.user_copy_point,
          promotion_id: form_add.user_copy_promotion,
          min_turn: form_add.user_copy_min_turn,
          min_balance: form_add.user_copy_min_balance,
          advisor: form_add.user_copy_advisor,
        }
        await axios.post(URL + "/api/v1/user/add/byadmin", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            var data = res.data
            if (data.status) {
              this.setState({ data_print: data.result, isModalAddMemberPrintVisibleL: false, isModalAddMemberPrintVisible: true })
              this.getData()
              x0p('', data.message, 'ok', false);
            } else {
              x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด PostCreateMember', null, 'error', false);
          })
      }
    })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'รายการสมาชิกทั้งหมด',
      },
    ]

    let starIcon = this.state.starIcon ? 'orange' : 'gainsboro'

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายการสมาชิกทั้งหมด"
          breadcrumb={{ routes }}
        // subTitle="This is a subtitle"
        />

        <Row gutter={[16, 16]} className='justify-content-between'>
          <Col flex="1 4 200px">
            <Card>
              <Statistic
                title="สมาชิกทั้งหมด"
                value={this.state.amountUserAll}
                valueStyle={{ color: '#3f8600' }}
                suffix="คน"
              />
            </Card>
          </Col>
          <Col flex="1 4 200px">
            <Card>
              <Statistic
                title="สมาชิกใหม่ 30 ย้อนหลัง"
                value={this.state.amountRegister30DayAgo}
                valueStyle={{ color: '#cf1322' }}
                suffix="คน"
              />
            </Card>
          </Col>
          <Col flex="1 4 200px">
            <Card>
              <Statistic
                title="สมาชิกใหม่วันนี้"
                value={this.state.amountRegisterToday}
                valueStyle={{ color: '#cf1322' }}
                suffix="คน"
              />
            </Card>
          </Col>
          <Col flex="1 4 200px">
            <div>
              <button className='btn btn-success btn-block' type="button" onClick={() => this.downloadReport()}>
                <Image src='https://cdn-icons-png.flaticon.com/512/4911/4911248.png' width={25} /> {this.state.loadingCSV ? 'Loading csv...' : 'Export File (ข้อมูลสมาชิกทั้งหมด)'}
              </button>
              <CSVLink
                headers={headersCSV}
                filename={`${WEB_NAME}_member_${new Date().toLocaleString('sv-SE')}.csv`}
                data={this.state.dataCSV}
                ref={this.csvLinkEl}
              />
            </div>
            <br />
            <div>
              <Link to={`/adminManage/member/statistics`}><Button block size='large'><StockOutlined />สถิติข้อมูลสมาชิก</Button></Link>
            </div>
          </Col>
        </Row>

        <br />

        <Card>
          <Tabs defaultActiveKey="1" type="card" onChange={(e) => {
            if (e === '2') {
              this.getDataSuspend()
            }
          }}>
            <Tabs.TabPane tab="ทั้งหมด" key="1">
              <>
                <Row justify="space-between">
                  <Col>
                    <button
                      className='btn btn-success btn-sm'
                      onClick={() => {
                        this.getBank()
                        this.handleChange('002', "bankid")
                        this.postAllUserCopy()
                        this.setState({ isModalAddMemberVisible: true })
                        this.getPromotionOpen()
                      }}>
                      <PlusCircleOutlined /> เพิ่มสมาชิก
                    </button>
                  </Col>
                  <Col>
                    <Search placeholder="ค้นหาข้อมูล ชื่อ, ยูส, เบอร์ติดต่อ, เลขบัญชี" value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} style={{ width: 400 }} onSearch={() => this.onSearch()} />
                  </Col>
                </Row>
                <br />
                <Table
                  loading={this.state.loading1}
                  columns={this.state.columns1}
                  dataSource={this.state.data1}
                  pagination={this.state.pagination.pagination}
                  scroll={{ x: 500 }}
                  rowKey={record => record.row_num}
                  onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
                />
              </>
            </Tabs.TabPane>
            <Tabs.TabPane tab="ระงับการใช้งาน" key="2">
              <Table
                loading={this.state.loading2}
                columns={this.state.columns2}
                dataSource={this.state.data2}
                scroll={{ x: 500 }}
                rowKey={record => record.row_num}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ยูสทดสอบ" key="3" disabled>
              <br />
              <Alert
                message=""
                description={<><p className='m-0'>ยูสทดสอบใช้สำหรับเข้าเล่นเกมทดสอบ (เฉพาะเกมที่รองรับ) เกมที่ทดสอบจะไม่ใช้เครดิตจริงในการเล่น เล่นได้เล่นเสียไม่จริง เหมาะสำหรับการอัดคลิปยิงแอด ทำโฆษณาต่างๆ</p><p className='m-0'>หากเข้าเกมที่ไม่รองรับจะเป็นเครดิตจริง ยอดเล่นได้เสียจริงๆ</p></>}
                type="info"
                showIcon
              />
              <br />
              <Collapse accordion onChange={(e) => console.log(e)}>
                <Panel header="รายการเกมที่รองรับ" key="1">
                  <Row gutter={[8, 8]} className="text-center">
                    {this.setGamesTest()}
                  </Row>
                </Panel>
              </Collapse>
              <br />
              <Table
                loading={this.state.loading3}
                columns={this.state.columns3}
                dataSource={this.state.data3}
                scroll={{ x: 500 }}
                rowKey={record => record.row_num}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
        {
          this.state.visibleDrawer ?
            <>
              {/* แก้ไขบัญชีธนาคาร */}
              <Modal
                title="แก้ไขข้อมูลบัญชีธนาคาร"
                open={this.state.visableModalBank}
                onCancel={() => this.setState({ visableModalBank: false })}
                footer={[
                  <Button type='primary' ghost onClick={() => {
                    this.handleChange('', "banknumber")
                    this.contactSubmit()
                  }}>
                    ไม่ระบุบัญชี
                  </Button>,
                  <Button key="back" onClick={() => this.setState({ visableModalBank: false })}>
                    ยกเลิก
                  </Button>,
                  <Button key="submit" type="primary" onClick={(e) => this.contactSubmit()}>
                    ยืนยันแก้ไข
                  </Button>,
                ]}
              >
                <div class="col-12">
                  <label for="banknumber" class="form-label">ธนาคาร</label>
                  <select class="form-select" aria-label="Default select example" name="bank_id" onChange={(e) => this.handleChange(e.target.value, "bankid")} >
                    <option key={0} value={this.state.userProfile.bank_id}>{this.state.userProfile.bank_name_th}</option>
                    {this.bankList()}
                  </select>
                </div>

                <div class="col-12" style={{ marginTop: 10 }}>
                  <label for="banknumber" class="form-label">เลขบัญชีธนาคาร</label>
                  <div class="input-group has-validation">
                    <span class="input-group-text"><i class="material-icons">account_balance</i></span>
                    <input type="text" class="form-control" pattern="[0-9]" id="banknumber" name="banknumber" defaultValue={this.state.userProfile.user_banknumber} onKeyUp={(e) => this.handleChange(e.target.value, "banknumber")} />
                    <div class="invalid-feedback">
                      กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง
                    </div>
                  </div>
                  <div class="error" className="form-text text-danger">{this.state.errors["banknumber"]}</div>
                </div>

                <div className="col-12" style={{ marginTop: 10 }}>
                  <label for="banknumber" className="form-label">ชื่อบัญชี</label>
                  <input type='text' class="form-control" onChange={(e) => this.handleChange(e.target.value, "bankname")} defaultValue={this.state.userProfile.user_name}/>
                </div>
              </Modal>

              {/* แก้ไขเครดิต */}
              <Modal
                title="ฝาก - ถอนเครดิต"
                open={this.state.visableModalBalanc}
                onOk={() => this.contactSubmitBalanc()}
                onCancel={() => this.setState({ visableModalBalanc: false })}
              >
                <Form>
                  <p>เครดิตคงเหลือ {parseFloat(this.state.userProfile.balance).toFixed(2)} บาท</p>
                  <Form.Item label="ประเภท" required>
                    <Select style={{ width: '100%' }} onChange={(val) => this.setState({ balanceType: val })} value={this.state.balanceType}>
                      <Option value="ฝาก">ฝากเครดิต</Option>
                      <Option value="ถอน">ถอนเครดิต (ไม่โอนเงิน)</Option>
                      <Option value="แจ้งถอน">แจ้งถอนเครดิต (รอยืนยันโอนเงิน)</Option>
                      <Option value="แจกเครดิตฟรี">แจกเครดิตฟรี</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="จำนวนเงิน" required>
                    <InputNumber style={{ width: '100%' }} size="large" min={1} max={1000000} defaultValue={this.state.balanceNumber} stringMode onChange={(val) => this.setState({ balanceNumber: val })} />
                  </Form.Item>
                  <Form.Item label="หมายเหตุ">
                    <Input style={{ width: '100%' }} size="large" defaultValue={this.state.balanceNote} onChange={(e) => this.setState({ balanceNote: e.target.value })} />
                  </Form.Item>
                </Form>
              </Modal>

              {/* แก้ไขเพชร */}
              <Modal
                title="เติม - ถอนเพชร"
                open={this.state.visableModalPoint}
                onOk={() => this.postUpdatePoint()}
                onCancel={() => this.setState({ visableModalPoint: false })}
              >
                <Form layout="vertical">
                  <p>เพชรคงเหลือ {parseFloat(this.state.userProfile.user_point)}</p>
                  <Form.Item label="จำนวนเงินที่ต้องการเพิ่ม/ลด" extra="ใส่เครื่องหมาย - ด้านหน้าหากต้องการลบออก เช่น -150" required>
                    <InputNumber style={{ width: '100%' }} size="large" placeholder='ใส่เครื่องหมาย - ด้านหน้าหากต้องการลบออก เช่น -150' onChange={(val) => this.setState({ inp_point: val })} />
                  </Form.Item>
                </Form>
              </Modal>

              {/* เปลี่ยนรหัสผ่าน */}
              <Modal title="เปลี่ยนรหัสผ่าน" open={this.state.isModalNewPasswordVisible} onOk={() => this.submitChangePassword()} onCancel={() => this.setState({ isModalNewPasswordVisible: false })}>
                <Form layout="vertical">
                  <Form.Item label="รหัสผ่านใหม่" required>
                    <Input.Password onChange={(e) => this.setState({ inp_newPassword: e.target.value })} />
                  </Form.Item>
                </Form>
              </Modal>

              {/* เปลี่ยนเบอร์ติดต่อ */}
              <Modal
                title="เปลี่ยนติดต่อ"
                open={this.state.isModalNewPhoneVisible}
                onCancel={() => this.setState({ isModalNewPhoneVisible: false })}
                footer={[
                  <Button type='primary' ghost onClick={() => {
                    this.setState({ inp_newPhone: '' }, () => {
                      this.submitChangePhone()
                    })
                  }}>
                    ไม่ระบุเบอร์ติดต่อ
                  </Button>,
                  <Button key="back" onClick={() => this.setState({ isModalNewPhoneVisible: false })}>
                    ยกเลิก
                  </Button>,
                  <Button key="submit" type="primary" onClick={(e) => this.submitChangePhone()}>
                    ยืนยันแก้ไข
                  </Button>,
                ]}
              >
                <Form layout="vertical">
                  <Form.Item label="เบอร์ใหม่" required>
                    <Input value={this.state.inp_newPhone} onChange={(e) => this.setState({ inp_newPhone: e.target.value })} />
                  </Form.Item>
                </Form>
              </Modal>

              {/* ยืนยันตัวตน */}
              <Modal title="ยืนยันตัวตน" open={this.state.isModalSecuVisible} onOk={() => this.contactSubmitBalanc()} onCancel={() => this.setState({ isModalSecuVisible: false })}>
                <Alert
                  message={`ยืนยันทำรายการ ${this.state.balanceType}`}
                  description={`คุณต้องการทำรายการ ${this.state.balanceType} ให้กับสมาชิก ${this.state.userProfile.user_username} จำนวน ${this.state.balanceNumber} บาท ใช่หรือมั้ย?? \n\nกรุณากรอก password เพื่อยืนยันตัวตน`}
                  type="warning"
                  style={{ marginBottom: 20 }}
                />
                <Form layout="vertical">
                  {/* <Form.Item label="username" required>
                <Input onChange={(e) => this.setState({ admin_username: e.target.value })}/>
              </Form.Item> */}
                  <Form.Item label="password" required>
                    <Input.Password onChange={(e) => this.setState({ admin_password: e.target.value })} />
                  </Form.Item>
                </Form>
              </Modal>

              {/* รายละเอียด */}
              <Modal
                title={<><Title level={5}><EditOutlined /> รายละเอียด</Title></>}
                style={{ top: 20, minWidth: 1050 }}
                open={this.state.visibleDrawer}
                footer={null}
                onCancel={() => this.setState({ visibleDrawer: false })}
              >
                {/* ข้อมุลเบื้องต้น */}
                <>
                  <Row className='justify-content-between'>
                    <Col>
                      <p className="site-description-item-profile-p"><Title level={5}>ข้อมูลสมาชิก <Tag>{this.state.userProfile.user_id ? this.state.userProfile.user_id : ''}</Tag></Title></p>
                    </Col>
                    <Col style={{ marginTop: -10 }}>
                      <h4><StarFilled style={{ color: starIcon }} onClick={() => this.tagStar()} /></h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <DescriptionItem title="ชื่อสมาชิก" content={this.state.userProfile ? this.state.userProfile.user_name : ''} />
                    </Col>
                    <Col span={8}>
                      <DescriptionItem title="username" content={this.state.userProfile ? this.state.userProfile.user_username : ''} />
                    </Col>
                    {this.state.admin_type === 'S' || this.state.admin_type === 'M' ?
                      <Col span={8} style={{ marginTop: -7 }}>
                        <DescriptionItem title="password" content={this.state.userProfile ? <>******<Tooltip title="แก้ไขรหัสผ่าน" onClick={() => this.setState({ isModalNewPasswordVisible: true })}><Button type="link" disabled={this.state.admin_type === 'G' ? true : false}><EditOutlined /></Button></Tooltip></> : ''} />
                      </Col>
                      : <></>
                    }
                    <Col span={8}>
                      <DescriptionItem title="เบอร์ติดต่อ" content={this.state.userProfile ? <>{this.state.userProfile.user_phone} <Tooltip title="แก้ไขเบอร์ติดต่อ" onClick={() => this.setState({ isModalNewPhoneVisible: true, inp_newPhone: this.state.userProfile.user_phone })}><Button type="link" disabled={this.state.admin_type === 'G' ? true : false}><EditOutlined /></Button></Tooltip></> : ''} />
                    </Col>
                    <Col span={8}>
                      <DescriptionItem title="วันที่สมัครสมาชิก" content={this.state.userProfile ? this.state.userProfile.created_at : ''} />
                    </Col>
                    <Col span={8}>
                      <DescriptionItem title="เข้าใช้งานล่าสุด" content={this.state.userProfile ? new Date(this.state.userProfile.user_online * 1000).toLocaleString('sv-SE') : ''} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      สถานะ: <Switch checkedChildren="ปกติ" unCheckedChildren="ระงับ" checked={this.state.user_status === 'ระงับ' ? false : true} onChange={(value) => this.setStatus(value)} disabled={this.state.admin_type === 'G' ? true : false} />
                    </Col>
                    <Col span={8}>
                      ยูสทดสอบ: <Switch checkedChildren="ยูสทดสอบ" unCheckedChildren="ไม่ใช่" checked={this.state.user_test} onChange={(value) => this.setStatusTest(value)} disabled />
                    </Col>
                    <Col span={8}>
                      <DescriptionItem title="ช่องทางสมัคร" content={this.state.userProfile.user_channel} />
                    </Col>
                  </Row>
                </>

                {/* ข้อมูลธนาคาร / เครดิต / เงื่อนไขเพิ่มเติม */}
                <Divider />
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <p className="site-description-item-profile-p"><Title level={5}>ข้อมูลบัญชีธนาคาร</Title></p>
                    <Row>
                      <Col span={12}>
                        <DescriptionItem title="ธนาคาร" content={this.state.userProfile ? this.state.userProfile.bank_abbrev_th : ''} />
                      </Col>
                      <Col span={12}>
                        <DescriptionItem title="สถานะ" content={<Tag>{this.state.userProfile ? this.state.userProfile.user_status : ''}</Tag>} />
                      </Col>
                    </Row>
                    <DescriptionItem title="เลขบัญชี" content={this.state.userProfile ? this.state.userProfile.user_banknumber : ''} />
                    <DescriptionItem title="ชื่อบัญชี" content={this.state.userProfile ? this.state.userProfile.user_name : ''} />
                    <Button block icon={<EditOutlined />} onClick={() => {
                      this.getBank()
                      let fields = { bankid: this.state.userProfile.bank_id, banknumber: this.state.userProfile.user_banknumber, bankname:  this.state.userProfile.user_name }
                      this.setState({ fields: fields, visableModalBank: true })
                    }} disabled={this.state.admin_type === 'G' ? true : false}>แก้ไข</Button>
                  </Col>
                  <Col span={8}>
                    <p className="site-description-item-profile-p"><Title level={5}>เครดิตสมาชิก</Title></p>
                    <Spin spinning={this.state.userProfile.balance !== undefined ? false : true}>
                      <DescriptionItem title="เครดิตคงเหลือ (Betflix)" className='' content={this.state.userProfile.balance} />
                      <DescriptionItem title="เครดิตคงเหลือ (PG)" className='' content={this.state.userProfile.balancePG} />
                      {this.state.admin_type === 'S' || this.state.admin_type === 'M' ?
                        <Button
                          block
                          onClick={() => this.setState({ visableModalBalanc: true })}
                          disabled={this.state.userPromotion != null ? this.state.userPromotion.log_discount_status === 'ใช้งานแล้ว' ? true : false : false}
                        >
                          ฝาก - ถอน
                        </Button>
                        : <div></div>
                      }
                    </Spin>
                    <br />
                    <DescriptionItem title="โบนัส" className='mb-1' content={<>{this.state.userProfile.user_bonus} <span className='text-muted'>(โบนัส หรือแต้มสะสมใช้แลกเครดิต)</span></>} />
                    <DescriptionItem title="เพชร" className='' content={<>{this.state.userProfile.user_point} <span className='text-muted'>(เพชร หรือแต้มสะสมใช้หมุนกงล้อ)</span></>} />
                    <Button block onClick={() => this.setState({ visableModalPoint: true })} disabled={this.state.admin_type === 'G' ? true : false}>เติม - ถอน</Button>
                  </Col>
                  <Col span={8}>
                    <Row className='justify-content-between'>
                      <Col>
                        <p className="site-description-item-profile-p"><Title level={5}>เงื่อนไขเพิ่มเติม</Title></p>
                      </Col>
                      <Col style={{ marginTop: -10 }}>
                        <Button type="primary" onClick={() => this.setMinTurnAndMinCredit()} disabled={this.state.admin_type === 'G' ? true : false}>
                          บันทึก
                        </Button>
                        <br />
                      </Col>
                    </Row>
                    <Row className='justify-content-between'>
                      <Col></Col>
                      <Col>
                        <small className='text-danger'>**ถ้ามีการรับโปรฯเงื่อนไขจะไม่ทำงาน</small>
                      </Col>
                    </Row>
                    <Form name="basic" initialValues={{ remember: true }} style={{ marginTop: 20 }}>
                      <Form.Item label="ติดเทิร์น(รายวัน)" className='m-0'>
                        <InputNumber style={{ width: '100%' }} min={0} onChange={(val) => this.setState({ min_turn: val })} value={this.state.min_turn} />
                      </Form.Item>
                      <span className='text-muted'>ไม่สามารถถอนเงินได้ถ้ายอดเล่นสะสมไม่ถึง</span>
                      <br />
                      <br />
                      <Form.Item label="เครดิตติดตัว" className='m-0'>
                        <InputNumber style={{ width: '100%' }} min={0} onChange={(val) => this.setState({ min_balance: val })} value={this.state.min_balance} />
                      </Form.Item>
                      <span className='text-muted'>ถอนไม่ได้ทั้งหมด ต้องมีเครดิตติดกระเป๋าตามที่ระบุ</span>
                    </Form>
                  </Col>
                </Row>

                {/* โปรโมขั่น / แนะนำเพื่อน */}
                <Divider />
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <p className="site-description-item-profile-p"><Title level={5}>การรับโปรโมชั่น</Title></p>
                    {this.state.userPromotion != null ?
                      <>
                        <Row>
                          <Col span={24}>
                            <DescriptionItem title="โปรโมชั่นที่รับ" content={this.state.userPromotion.discount_name} />
                          </Col>
                          <Col span={24}>
                            <DescriptionItem title="สถานะ" content={<Tag color={this.state.userPromotion.log_discount_status === 'ใช้งานแล้ว' ? 'orange' : 'blue'}>{this.state.userPromotion.log_discount_status}</Tag>} />
                          </Col>
                          <Col span={24}>
                            <DescriptionItem title="วันที่รับโปร" content={`${this.state.userPromotion.promotion_start_date}`} />
                          </Col>
                          {this.state.userPromotion.log_discount_status === 'ใช้งานแล้ว' ?
                            <>
                              <Col span={24}>
                                <DescriptionItem title="เครดิตฟรีที่ได้รับ" content={`${this.state.userPromotion.discount_net_amount} บาท`} />
                              </Col>
                              <Col span={12}>
                                <DescriptionItem title="ติดโปรบังคับ" content={`${this.state.userPromotion.discount_turnover_amount}`} />
                              </Col>
                              <Col span={12}>
                                <DescriptionItem title="ยอดเล่นสะสม" content={`${this.state.userPromotion.totalPlay}`} />
                              </Col>
                            </>
                            : <></>
                          }
                        </Row>
                        {this.state.admin_type === 'S' || this.state.admin_type === 'M' ?
                          <Row>
                            <Col span={12}>
                              <Button block style={{ marginTop: 10, marginLeft: 2 }} onClick={() => this.clearPromotion(this.state.userPromotion.log_discount_id)}>ยกเลิกโปรโมชั่น <small className='text-danger'> (ไม่หักเครดิต ไม่รียูส)</small></Button>
                            </Col>
                            {this.state.userPromotion.log_discount_status === 'ใช้งานแล้ว' ?
                              <>
                                <Col span={12}>
                                  <Button block style={{ marginTop: 10, marginLeft: 8 }} onClick={() => this.clearPromotionV2(this.state.userPromotion.log_discount_id)}>ยกเลิกโปรโมชั่น <small className='text-danger'> (หักเครดิต รียูส)</small></Button>
                                </Col>
                                <Col span={24}>
                                  <small className='text-danger'>**ถ้าไม่รียูส ลูกค้ารับโปรซื้อสปินไว้อาจจะ "โกง" ได้ </small>
                                </Col>
                              </>
                              : <></>
                            }
                            <Col span={24}>
                              {this.state.userPromotion.log_discount_status === 'รอใช้งาน' ?
                                <Button block style={{ marginTop: 10, marginLeft: 3 }} onClick={() => this.usePromotion(this.state.userPromotion.log_discount_id)}>ลูกค้าเติมเงินแล้ว ยืนยันใช้งานโปร</Button>
                                : <></>
                              }
                            </Col>
                          </Row>
                          : <></>
                        }
                      </>
                      :
                      <>
                        <Row>
                          <Col span={24}>
                            <DescriptionItem title="โปรโมชั่นที่รับ" content={'ยังไม่ได้รับโปรโมชั่น'} />
                          </Col>
                        </Row>
                        {this.state.admin_type === 'S' || this.state.admin_type === 'M' ?
                          <Row>
                            <Col span={24}>
                              <Button block style={{ marginTop: 10, marginRight: 3 }} onClick={() => this.useCoupon()}>ใช้โค้ดโปรโมชั่น</Button>
                            </Col>
                          </Row>
                          : <></>
                        }
                      </>
                    }
                    
                    <br/>

                    <Row>
                      <Col span={24}>
                        <DescriptionItem title="ยูสเก่าหลังรับโปร (5 ยูสล่าสุด)" content={this.state.userProfile.user_username_current ? this.state.userProfile.user_username_current.substr(-39) : ''} />
                      </Col>
                    </Row>
            
                  </Col>
                  <Col span={12}>
                    <p className="site-description-item-profile-p"><Title level={5}>ประวัติการแนะนำเพื่อน</Title></p>
                    <Row>
                      <Col span={24}>
                        <DescriptionItem title="โค้ดแนะนำ" content={this.state.userProfile.user_coderefer} />
                      </Col>
                      <Col span={24}>
                        <DescriptionItem
                          title="ผู้แนะนำ"
                          content={this.state.userProfile.user_advisor !== '0' ?
                            `${this.state.userProfile.user_advisor} (${this.state.userProfile.advisor_title})`
                            : 'ไม่มีผู้แนะนำ'}
                        />
                      </Col>
                      {/* <Col span={24}>
                <DescriptionItem title="แนะนำให้ผู้อื่นสมัครวันนี้" content={`${this.state.userProfile.amountRecommendToday} คน`} />
              </Col> */}
                      <Col span={24}>
                        <DescriptionItem title="แนะนำให้ผู้อื่นสมัครทั้งหมด" content={`${this.state.userProfile.amountRecommend} คน`} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* คืนยอดเสีย / คืนยอดเสียแนะนำเพื่อน */}
                <Divider />
                <Row gutter={[16, 16]}>
                  {this.state.returnloss.returnloss_status === 'เปิด' ?
                    <Col span={8}>
                      <p className="site-description-item-profile-p"><Title level={5}>คืนยอดเสีย</Title></p>
                      <Row>
                        <Col span={12}>
                          <DescriptionItem title="ยอดฝากสะสม" content={this.state.returnloss.total_deposit} />
                        </Col>
                        <Col span={12}>
                          <DescriptionItem title="Level" content={this.state.returnloss.returnloss_level} />
                        </Col>
                        {this.state.returnloss.returnloss_type === 'เครดิต' ?
                          <Col span={24}>
                            <DescriptionItem title="เปอร์เซ็นโบนัส" content={<>{this.state.returnloss.returnloss_amount}%</>} />
                          </Col>
                          :
                          <Col span={24}>
                            <DescriptionItem title="เปอร์เซ็นโบนัส" content={`slot ${this.state.returnloss.returnloss_amount_slot}%, casino ${this.state.returnloss.returnloss_amount_baccarat}%`} />
                          </Col>
                        }
                        {/* <Col span={24}>
                          <DescriptionItem title="โบนัสสะสม" content={this.state.returnloss_bonus} />
                        </Col> */}
                      </Row>
                      {/* <small className='text-muted'>ยอดโบนัสจะได้รับอัตโนมัติตามเงื่อนไขกิจกรรม</small>
                      <Button block onClick={() => this.getBonusReturnloss(this.state.userProfile.user_id)}>ตรวจสอบโบนัสคืนยอดเสีย</Button> */}
                    </Col>
                    : null
                  }
                  {this.state.returnlossNetwork.returnloss_network_status === 'เปิด' ?
                    <Col span={8}>
                      <p className="site-description-item-profile-p"><Title level={5}>คืนยอดเสียแนะนำเพื่อน</Title></p>
                      <Row>
                        <Col span={12}>
                          <DescriptionItem title="จำนวนเพื่อนทั้งหมด" content={this.state.returnlossNetwork.total_refer} />
                        </Col>
                        <Col span={12}>
                          <DescriptionItem title="ชั้นที่ 1 Level" content={this.state.returnlossNetwork.returnloss_network_level_x} />
                        </Col>
                        {this.state.returnlossNetwork.returnloss_network_type === 'เครดิต' ?
                          <Col span={24}>
                            <DescriptionItem title="เปอร์เซ็นโบนัส" content={<>{this.state.returnlossNetwork.returnloss_network_amount}%</>} />
                          </Col>
                          :
                          <Col span={24}>
                            <DescriptionItem title="เปอร์เซ็นโบนัส" content={`slot ${this.state.returnlossNetwork.returnloss_network_amount_slot}%, casino ${this.state.returnlossNetwork.returnloss_network_amount_baccarat}%`} />
                          </Col>
                        }
                        {/* <Col span={24}>
                          <DescriptionItem title="โบนัสสะสม" content={this.state.returnloss_network_bonus} />
                        </Col> */}
                      </Row>
                      {/* <small className='text-muted'>ยอดโบนัสจะได้รับอัตโนมัติตามเงื่อนไขกิจกรรม</small>
                      <Button block onClick={() => this.getBonusReturnlossNetwork(this.state.userProfile.user_id)}>ตรวจสอบโบนัสคืนยอดเสียแนะนำเพื่อน</Button> */}
                    </Col>
                    : null
                  }
                  {this.state.returnover.returnover_status === 'เปิด' ?
                    <Col span={8}>
                      <p className="site-description-item-profile-p"><Title level={5}>คืนยอดเล่นสะสม</Title></p>
                      <Row>
                        <Col span={12}>
                          <DescriptionItem title="ยอดฝากสะสม" content={this.state.returnover.total_deposit} />
                        </Col>
                        <Col span={12}>
                          <DescriptionItem title="Level" content={this.state.returnover.returnover_level} />
                        </Col>
                        <Col span={24}>
                          <DescriptionItem title="เปอร์เซ็นโบนัส" content={<>{this.state.returnover.returnover_amount}%</>} />
                        </Col>
                        {/* <Col span={12}>
                          <DescriptionItem title="โบนัสสะสม" content={this.state.returnover_bonus} />
                        </Col>
                        <Col span={12}>
                          <DescriptionItem title="เล่นสะสม" content={this.state.returnover_turnover} />
                        </Col> */}
                      </Row>
                      {/* <small className='text-muted'>ยอดโบนัสจะได้รับอัตโนมัติตามเงื่อนไขกิจกรรม</small>
                      <Button block onClick={() => this.getBonusReturnOver(this.state.userProfile.user_id)}>ตรวจสอบโบนัสคืนยอดเล่นสะสม</Button> */}
                    </Col>
                    : null
                  }
                </Row>

                {/* ประวัติการทำรายการ */}
                <Divider />
                <p className="site-description-item-profile-p"><Title level={5}>ประวัติการทำรายการ</Title></p>
                <Tabs type="card">
                  <Tabs.TabPane tab={<><DollarCircleOutlined />ประวัติการเงิน</>} key="1">
                    <LogCredit user_id={this.state.userProfile.user_id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<><ShopOutlined />ประวัติการรับโปรฯ</>} key="2">
                    <LogPromotion user_id={this.state.userProfile.user_id} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<><UsergroupAddOutlined />ประวัติแนะนำเพื่อน</>} key="3">
                    <LogRecommend user_id={this.state.userProfile.user_id} user_advisor={this.state.userProfile.user_coderefer} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<><TransactionOutlined />ประวัติการคืนยอดเสีย</>} key="4">
                    <LogReturn username={this.state.userProfile.user_username} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={<><SketchOutlined />ประวัติกิจกรรมเสริม</>} key="5">
                    <LogEvent user_id={this.state.userProfile.user_id} />
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
            </>
            : null
        }

        {/* เพิ่มสมาชิก */}
        <Modal
          title={<><Title level={5}><EditOutlined /> เพิ่มสมาชิก</Title></>}
          style={{ top: 20 }}
          open={this.state.isModalAddMemberVisible}
          footer={[]}
          onCancel={() => this.setState({ isModalAddMemberVisible: false })}
          destroyOnClose={false}
          keyboard={false}
          maskClosable={false}
        >
          <Form>
            <Form.Item label="คัดลอกรูปแบบ">
              {this.TagUserCopyList()}
            </Form.Item>

            <Form.Item label="รหัสผ่าน" extra="ความยาว 8 ตัวอักษร (0-9, A-Z, a-z)" required>
              <Input.Password onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_password: e.target.value } })} value={this.state.form_add.user_copy_password} />
            </Form.Item>

            <Form.Item label="เบอร์ติดต่อ" extra="หากไม่ต้องการระบุเบอร์เว้นว่างไว้">
              <Input onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_phone: e.target.value } })} value={this.state.form_add.user_copy_phone} />
            </Form.Item>
            <br />

            <Divider orientation="left">ข้อมูลธนาคาร</Divider>
            <Form.Item label="ธนาคาร">
              <select class="form-select" onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_bank_id: e.target.value } })} value={this.state.form_add.user_copy_bank_id} >
                {this.bankList()}
              </select>
            </Form.Item>

            <Form.Item label="ชื่อบัญชี">
              <Input onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_bank_account: e.target.value } })} value={this.state.form_add.user_copy_bank_account} />
            </Form.Item>

            <Form.Item label="เลขบัญชี" extra="หากไม่ต้องการระบุบัญชีเว้นว่างไว้">
              <Input.Group compact>
                <Input style={{ width: 'calc(100% - 120px)' }} onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_bank_number: e.target.value } })} value={this.state.form_add.user_copy_bank_number} />
                {/* <Button type="primary" onClick={(e) => this.checkBank()}>
                  {this.state.form_add.bank_status === false ?
                    <>ตรวจสอบบัญชี</>
                    :
                    <>แก้ไขเลขบัญชี</>
                  }
                </Button> */}
              </Input.Group>
            </Form.Item>

            <br />
            <Divider orientation="left">เพิ่มเติม</Divider>
            <Form.Item label="เติมเครดิต">
              <InputNumber style={{ width: '100%' }} onChange={(value) => this.setState({ form_add: { ...this.state.form_add, user_copy_credit: value } })} value={this.state.form_add.user_copy_credit} />
            </Form.Item>

            <Form.Item label="เติมเพชร">
              <InputNumber style={{ width: '100%' }} onChange={(value) => this.setState({ form_add: { ...this.state.form_add, user_copy_point: value } })} value={this.state.form_add.user_copy_point} />
            </Form.Item>

            <Form.Item label="โปรโมชั่น (โค้ดโปรโมชั่น)">
              <select class="form-select" onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_promotion: e.target.value } })} value={this.state.form_add.user_copy_promotion}>
                <option key={0} value={''}>ไม่รับโปร</option>
                {this.PromotionList()}
              </select>
            </Form.Item>

            <Form.Item label="ติดเทิร์น(รายวัน)" extra="ไม่สามารถถอนเงินได้ถ้ายอดเล่นสะสมไม่ถึง">
              <InputNumber style={{ width: '100%' }} onChange={(value) => this.setState({ form_add: { ...this.state.form_add, user_copy_min_turn: value } })} value={this.state.form_add.user_copy_min_turn} />
            </Form.Item>


            <Form.Item label="เครดิตติดตัว" extra="ถอนไม่ได้ทั้งหมด ต้องมีเครดิตติดกระเป๋าตามที่ระบุ">
              <InputNumber style={{ width: '100%' }} onChange={(value) => this.setState({ form_add: { ...this.state.form_add, user_copy_min_balance: value } })} value={this.state.form_add.user_copy_min_balance} />
            </Form.Item>


            <Form.Item label="ผู้แนะนำ (โค้ดผู้แนะนำ)" extra="หากไม่มีผู้แนะนำเว้นว่างไว้">
              <Input onChange={(e) => this.setState({ form_add: { ...this.state.form_add, user_copy_advisor: e.target.value } })} value={this.state.form_add.user_copy_advisor} />
            </Form.Item>

            <br />
            <Form.Item>
              <Button block type='primary' onClick={() => this.PostCreateMember()}>สร้างสมาชิก</Button>
            </Form.Item>

            <Form.Item>
              <Button block type='primary' ghost onClick={() => this.postAddUserCopy()}>บันทึกรูปแบบ</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* ปริ้น สมาชิกใหม่ */}
        <Modal
          title={<><Title level={5}><PrinterOutlined /> ปริ้นใบเสร็จ</Title></>}
          style={{ top: 20 }}
          open={this.state.isModalAddMemberPrintVisible}
          footer={[]}
          onCancel={() => this.setState({ isModalAddMemberPrintVisible: false })}
          destroyOnClose={false}
          keyboard={false}
          maskClosable={false}
        >
          <div style={{ width: 250, margin: 'auto' }}>
            <div ref={(el) => (this.componentRef = el)}>
              <div className='text-center printing'>
                <h4>{WEB_NAME}</h4>
                <small>วันที่ {new Date().toLocaleString('sv-SE')}</small>
                <hr />
                <table className='table table-borderless'>
                  <tr>
                    <td style={{ textAlign: 'left', fontWeight: 700 }}>username</td>
                    <td style={{ textAlign: 'right' }}>{this.state.data_print.user_username}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', fontWeight: 700 }}>password</td>
                    <td style={{ textAlign: 'right' }}>{this.state.data_print.user_password}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', fontWeight: 700 }}>ยอดเดิมพัน</td>
                    <td style={{ textAlign: 'right' }}>{this.state.data_print.credit} บาท</td>
                  </tr>
                </table>
                <hr />
                <p className='m-0'>ขอบคุณที่ใช้บริการ</p>
                <p className='m-0'>พนักงานขาย: {this.state.admin_name}</p>
              </div>
            </div>
          </div>
          <br />
          <ReactToPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return <Button href='#' type='primary' ghost block><PrinterOutlined /> ปริ้น</Button>;
            }}
            documentTitle={WEB_NAME}
            content={() => this.componentRef}
          />
          <br />
          <br />
          <Button block type='primary'>ตกลง</Button>
        </Modal>
      </>
    );
  }
}

export default withRouter(member);