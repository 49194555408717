import React, { Component } from 'react';
import { Divider, Row, Col, Switch, InputNumber, Button, Select, Typography, Form, Table, Input, Tag, Modal, Image, Tabs, Space, Alert, Card, Statistic, Upload } from 'antd';
import { SettingOutlined, SearchOutlined, StarFilled } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL } from '../../../../Util/Config'
import x0p from 'x0popup';
import axios from 'axios';
// import TypeGame from '../howto/typeGame';
// import moment from 'moment';
import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    UploadOutlined,
} from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

export default class returnover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnover: {
                returnover_status: 'ปิด',
                returnover_amount: 0,
                returnover_type: 'เครดิต'
            },
            games: [],
            ReturnoverColumns: [
                {
                    title: 'เลเวล',
                    dataIndex: 'returnover_level',
                },
                {
                    title: 'ยอดฝากสะสมขั้นต่ำ',
                    dataIndex: 'returnover_min_deposit',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: '% การคืนยอดเล่นสะสม',
                    dataIndex: 'returnover_amount',
                    render: (text, record) => (
                        <>{text}%</>
                    )
                },
                // {
                //     title: 'โบนัสขั้นต่ำ',
                //     dataIndex: 'returnover_min',
                //     render: (text, record) => (
                //         <>{text} บาท</>
                //     )
                // },
                {
                    title: 'โบนัสสูงสุด',
                    dataIndex: 'returnover_max',
                    render: (text, record) => (
                        <>{text} บาท</>
                    )
                },
                {
                    title: 'icon',
                    dataIndex: 'returnover_icon',
                    render: (text, record) => (
                        <Image src={text.includes('http') ? text : URL + '/public/images/return/returnover/' + text} width={30} />
                    )
                },
                {
                    title: '',
                    dataIndex: 'returnover_id',
                    render: (text, record, index) => (
                        this.state.admin_type === 'G' ? null :
                            record.returnover_level === '1'
                                ?
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnoverEditVisible: true,
                                            returnover_edit_min_deposit_min: 1,
                                            returnover_edit_min_deposit_max: this.state.ReturnoverData[index + 1] !== undefined ? parseInt(this.state.ReturnoverData[index + 1].returnover_min_deposit) - 1 : 1000000,
                                            returnover_edit_amount_min: 0.01,
                                            returnover_edit_amount_max: this.state.ReturnoverData[index + 1] !== undefined ? (parseFloat(this.state.ReturnoverData[index + 1].returnover_amount) - 0.01).toFixed(2) : 100,
                                            dataReturnoverEdit: record
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" disabled><DeleteOutlined /> ลบ</Button>
                                </>
                                :
                                <>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={() => this.setState({
                                            isModalReturnoverEditVisible: true,
                                            returnover_edit_min_deposit_min: parseInt(this.state.ReturnoverData[index - 1].returnover_min_deposit) + 1,
                                            returnover_edit_min_deposit_max: this.state.ReturnoverData[index + 1] !== undefined ? parseInt(this.state.ReturnoverData[index + 1].returnover_min_deposit) - 1 : 1000000,
                                            returnover_edit_amount_min: (parseFloat(this.state.ReturnoverData[index - 1].returnover_amount) + 0.01).toFixed(2),
                                            returnover_edit_amount_max: this.state.ReturnoverData[index + 1] !== undefined ? (parseFloat(this.state.ReturnoverData[index + 1].returnover_amount) - 0.01).toFixed(2) : 100,
                                            dataReturnoverEdit: record,
                                            ReturnLevel: record.returnover_level,
                                        })}>
                                        <EditOutlined /> แก้ไข
                                    </Button>
                                    <Button danger type="link" onClick={() => this.postRemoveReturnover(text)}><DeleteOutlined /> ลบ</Button>
                                </>
                    ),
                },
            ],
            
            ReturnoverData: [],
            ReturnLevel: 1,
            isModalReturnoverEditVisible: false,
            isModalReturnoverAddVisible: false,
            dataReturnoverEdit: {},
            dataReturnoverAdd: {},
            returnover_add_amount_min: null,
            returnover_add_amount_min_slot: null,
            returnover_add_amount_min_baccarat: null,
            returnover_min_deposit: null,
            returnover_edit_min_deposit_min: null,
            returnover_edit_min_deposit_max: 0,
            returnover_edit_amount_min: 0,
            returnover_edit_amount_min_slot: 0,
            returnover_edit_amount_min_baccarat: 0,
            returnover_edit_amount_max: 0,
            returnover_edit_amount_max_slot: 0,
            returnover_edit_amount_max_baccarat: 0,

            columns2: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'ชื่อผู้ใช้งาน',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'ยอดฝากสะสม',
                    dataIndex: 'deposit',
                    sorter: (a, b) => a.deposit - b.deposit,
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} บาท</Tag>
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: 'returnover_amount',
                    sorter: (a, b) => a.returnover_amount - b.returnover_amount,
                    render: (text, record) => (
                        <Tag color='orange'>{text}%</Tag>
                    )
                }
            ],
            data2: [],

            columns3: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'log_returnover_id',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.returnover_id - b.returnover_id,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'ผู้ใช้งาน',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                    render: (text, record) => (
                        <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
                    )
                },
                {
                    title: 'ยอดเล่นสะสม',
                    dataIndex: 'log_returnover_turnover',
                    render: (text, record) => (
                        <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'โบนัสที่ได้รับ',
                    dataIndex: 'log_returnover_amount',
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} โบนัส</Tag>
                    )
                },
                // {
                //     title: 'หมายเหตุ',
                //     dataIndex: 'log_returnover_note',
                //     render: (text, record) => (
                //         text
                //     )
                // },
                {
                    title: 'วัน/เวลา ที่ได้รับโบนัส',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data3: [],
            summary: {},
            admin_type: null,
            admin_token: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken }, () => {
            this.getReturnover()
            this.getHistory()
            this.getUserLevel2Up()
            // this.getSummary()
        })   
    }

    getSummary() {
        fetch(`${URL}/api/v1/returnover/summary`)
            .then((response) => response.json())
            .then((json) => this.setState({
                summary: json.result,
            }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getHistory() {
        fetch(`${URL}/api/v1/returnover/log`, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                data3: json.result,
            })
        })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getUserLevel2Up() {
        fetch(`${URL}/api/v1/returnover/user/level2up`, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    data2: json.result,
                })
            })
            // .then(() => console.log(this.state.data2))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getReturnover() {
        fetch(`${URL}/api/v1/returnover`, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then((response) => response.json())
            .then((json) => {
            this.setState({
                returnover: json.result[0],
                ReturnoverData: json.result,
                returnover_add_amount_min: (parseFloat(json.result[json.result.length - 1].returnover_amount) + 0.01).toFixed(2),
                returnover_add_min_deposit: parseInt(json.result[json.result.length - 1].returnover_min_deposit) + 1,
                dataReturnoverAdd: {
                    ...this.state.dataReturnoverAdd,
                    returnover_min_deposit: parseInt(json.result[json.result.length - 1].returnover_min_deposit) + 1,
                    returnover_amount: (parseFloat(json.result[json.result.length - 1].returnover_amount) + 0.01).toFixed(2),
                },
            })
        })
            // .then(() => console.log(this.state.returnover))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    postUpdate(status) {
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการแก้ไขใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    status: status,
                }
                await axios.post(`${URL}/api/v1/returnover/update/status`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getReturnover()
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    // เปิด-ปิดระบบ
    statusChange(status) {
        this.postUpdate(status ? 'เปิด' : 'ปิด')
    }

    postAddReturnover() {
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const { returnover_level, returnover_min_deposit, returnover_amount, returnover_max, returnover_icon } = this.state.dataReturnoverAdd
                console.log(returnover_level, returnover_min_deposit, returnover_amount, returnover_max, returnover_icon)

                var formData = new FormData();
                formData.append('min_deposit', returnover_level);
                formData.append("amount", returnover_amount);
                formData.append("max", returnover_max);
                formData.append("image", returnover_icon);
                console.log(formData)

                const res = await fetch(`${URL}/api/v1/returnover/add`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnover()
                    this.setState({ isModalReturnoverAddVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postUpdateReturnover() {
        x0p({
            title: 'ยืนยัน!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {

                const { returnover_level, returnover_min_deposit, returnover_amount, returnover_max, returnover_icon } = this.state.dataReturnoverEdit
                console.log(returnover_level, returnover_min_deposit, returnover_amount, returnover_max, returnover_icon)

                const formData = new FormData();
                formData.append('level', this.state.dataReturnoverEdit.returnover_level);
                formData.append("min_deposit", this.state.dataReturnoverEdit.returnover_min_deposit);
                formData.append("amount", this.state.dataReturnoverEdit.returnover_amount);
                formData.append("max", this.state.dataReturnoverEdit.returnover_max);
                formData.append("image", this.state.dataReturnoverEdit.returnover_icon);
                
                console.log(formData)

                const res = await fetch(`${URL}/api/v1/returnover/update`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                  }
                }).then((res) => res.json());

                if (res.status) {
                    this.getReturnover()
                    this.setState({ isModalReturnoverEditVisible: false })
                    x0p('', res.message, 'ok', false);
                } else {
                    x0p('', res.message, 'error', false);
                }
            }
        })
    }

    postRemoveReturnover(returnover_id) {
        x0p({
            title: 'ยืนยันลบ!!',
            text: ``,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    returnover_id: returnover_id
                }
                await axios.post(`${URL}/api/v1/returnover/remove`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getReturnover()
                            x0p('', data.message, 'ok', false);

                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('', 'เกิดข้อผิดพลาด!!', 'error', false);
                    })
            }
        })
    }

    OnChangeType(value) {
        // if (value === 'เล่นได้เสีย') {
        //     this.setState({ returnover: { ...this.state.returnover, returnover_type: value, returnover_amount_day: 1 } })

        // } else {
            this.setState({ returnover: { ...this.state.returnover, returnover_type: value, } })
        // }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเล่นทั้งหมด"
                                value={this.state.summary.total_count}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="แจกโบนัสทั้งหมด"
                                value={this.state.summary.total_amount}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="แต้ม"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="คืนยอดเล่นวันนี้"
                                value={this.state.summary.total_count_today}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title="แจกโบนัสวันนี้"
                                value={this.state.summary.total_amount_today}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="แต้ม"
                            />
                        </Card>
                    </Col>
                </Row>
                <br />

                <Card>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={20} style={{ textAlign: 'right' }}>
                            <Divider orientation="left"><SettingOutlined /> ตั่งค่า คืนยอดเล่นสะสม</Divider>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right', marginTop: 15 }}>
                            สถานะการใช้งาน :  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.returnover.returnover_status === 'เปิด' ? true : false} onChange={(e) => this.statusChange(e)} disabled={this.state.admin_type === 'G' ? true : false} />
                        </Col>
                    </Row>
                    <Row justify="start">
                        <Col span={12}>
                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="เงื่อนไข">
                                    <p className='m-0'>ลูกค้าจะได้รับ <span className='text-danger'>"โบนัส"</span> (เหรียญโบนัส สามารถแลกเป็นเครดิตทีหลัง) ตามช่วงวันที่ระบุ</p>
                                    <p className='m-0'>ระบบจะคำนวณโบนัส ให้สมาชิก ช่วงเวลา 06:00 - 09:00 น.</p>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item
                                    label="ประเภทการคืนยอดเล่นสะสม"
                                    extra="คำนวณจากข้อมูลที่เก็บจาก AG โดยตรง"
                                    >
                                    <Select value={'เล่นสะสม'} onChange={(value) => this.OnChangeType(value)} disabled>
                                        <Option value="เล่นสะสม">ยอดเทิร์นเล่นจริง</Option>
                                        {/* <Option value="เครดิต">ยอดเทิร์นเครดิต</Option> */}
                                    </Select>
                                </Form.Item>
                            </Form>

                            <Form labelCol={{ span: 8 }}>
                                <Form.Item label="การแจกโบนัส" extra={this.state.returnover.returnover_amount_day === "7" ? 'คืนยอดทุกวันอาทิตย์ (อาทิตย์-เสาร์ ที่ผ่านมา)' : 'คืนยอดทุกวัน (ข้อมูลวันก่อน)'}>
                                    <Select 
                                        value={'1'} 
                                        onChange={(value) => this.setState({ returnover: { ...this.state.returnover, returnover_amount_day: value } })}
                                        disabled
                                    >
                                        <Option value="1">รายวัน</Option>
                                        <Option value="7">รายอาทิตย์</Option>
                                    </Select>
                                </Form.Item>
                            </Form>

                            {/* <Form labelCol={{ span: 8 }}>
                                <Form.Item label=" " colon={false}>
                                    <Button type="primary" onClick={() => this.postUpdate()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
                                </Form.Item>
                            </Form> */}
                        </Col>
                    </Row>
                    <Button onClick={() => this.setState({ isModalReturnoverAddVisible: true, ReturnLevel: this.state.ReturnoverData.length + 1 })} style={{ marginRight: 10 }} disabled={this.state.admin_type === 'G' ? true : false}><PlusCircleOutlined /> เพิ่มเลเวล</Button>
                    <br />
                    <br />
                    <Table columns={this.state.ReturnoverColumns} dataSource={this.state.ReturnoverData} />

                    <br />
                    <br />
                    <Tabs defaultActiveKey="2">
                        <Tabs.TabPane tab="ราชื่อสมาชิก" key="1" disabled={this.state.ReturnoverData.length > 1 ? false : true}>
                            <Alert
                                message={<><p>แสดงข้อมูลรายชื่อสมาชิกที่มียอดฝากสะสมมากกว่า {this.state.ReturnoverData.length > 1 ? this.state.ReturnoverData[1].returnover_min_deposit : ''} บาท </p><p>ยอดฝากสะสมเก็บข้อมูลภายใน 1 เดือนเท่านั้น</p></>}
                                type="warning"
                            />
                            <br />
                            <Table columns={this.state.columns2} dataSource={this.state.data2} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="ประวัติการรับยอดเล่นสะสม" key="2">
                            <Alert message="แสดงข้อมูลภายใน 1 เดือนเท่านั้น" type="warning" />
                            <br />
                            <Table columns={this.state.columns3} dataSource={this.state.data3} />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>

                {/* Modal เพิ่มช่วงเลาเวลการคืนยอดเล่นสะสม */}
                <Modal title="เพิ่มช่วงเลาเวลการคืนยอดเล่นสะสม" open={this.state.isModalReturnoverAddVisible} onOk={() => this.postAddReturnover()} onCancel={() => this.setState({ isModalReturnoverAddVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="เลเวล" required>
                            <InputNumber
                                value={this.state.ReturnLevel}
                                onChange={(value) => this.setState({ ReturnLevel: value })}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>ยอดฝากขั้นต่ำ <Tag color="red">ขั้นต่ำ {this.state.returnover_add_min_deposit} บาท</Tag></>} required>
                            <InputNumber
                                defaultValue={this.state.returnover_add_min_deposit}
                                min={this.state.returnover_add_min_deposit}
                                max={1000000}
                                onChange={(value) => this.setState({ dataReturnoverAdd: { ...this.state.dataReturnoverAdd, returnover_min_deposit: value } })}
                                style={{ width: '100%' }}
                                addonAfter="บาท"
                            />
                        </Form.Item>

                        <Form.Item label={<>% การคืนยอดเล่นสะสม <Tag color="red">ขั้นต่ำ {this.state.returnover_add_amount_min}%</Tag></>} required>
                            <InputNumber
                                defaultValue={this.state.returnover_add_amount_min}
                                min={this.state.returnover_add_amount_min}
                                onChange={(value) => this.setState({ dataReturnoverAdd: { ...this.state.dataReturnoverAdd, returnover_amount: value } })}
                                style={{ width: '100%' }}
                                addonAfter="%"
                            />
                        </Form.Item>


                        {/* <Form.Item label={<>โบนัสขั้นต่ำ รับยอดเล่น</>} required>
                            <InputNumber
                                value={this.state.dataReturnoverAdd.returnover_min}
                                onChange={(value) => this.setState({ dataReturnoverAdd: { ...this.state.dataReturnoverAdd, returnover_min: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item> */}

                        <Form.Item label={<>โบนัสสูงสุด รับยอดเล่น</>} required>
                            <InputNumber
                                value={this.state.dataReturnoverAdd.returnover_max}
                                min={this.state.add_bonus_max}
                                onChange={(value) => this.setState({ dataReturnoverAdd: { ...this.state.dataReturnoverAdd, returnover_max: value } })}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                            />
                        </Form.Item>

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                            required
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.gif,.webp'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnoverAdd: { ...this.state.dataReturnoverAdd, returnover_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Modal แก้ไขการคืนยอดเล่นสะสม */}
                <Modal title="แก้ไขการคืนยอดเล่นสะสม" open={this.state.isModalReturnoverEditVisible} onOk={() => this.postUpdateReturnover()} onCancel={() => this.setState({ isModalReturnoverEditVisible: false })}>
                    <Form layout="vertical">
                        <Form.Item label="เลเวล" required>
                            <Input
                                value={this.state.dataReturnoverEdit.returnover_level}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item label={<>ยอดฝากขั้นต่ำ {this.state.dataReturnoverEdit.returnover_level !== '1' ? <><Tag color='red'>ขั้นต่ำ {this.state.returnover_edit_min_deposit_min} บาท</Tag><Tag color='red'>สูงสุด {this.state.returnover_edit_min_deposit_max.toLocaleString()} บาท</Tag></> : ''} </>} required>
                            <InputNumber
                                value={this.state.dataReturnoverEdit.returnover_min_deposit}
                                min={this.state.returnover_edit_min_deposit_min}
                                max={this.state.returnover_edit_min_deposit_max}
                                disabled={this.state.dataReturnoverEdit.returnover_level === '1' ? true : false}
                                style={{ width: '100%' }}
                                addonAfter="คน"
                                onChange={(value) => this.setState({ dataReturnoverEdit: { ...this.state.dataReturnoverEdit, returnover_min_deposit: value } })}
                            />
                        </Form.Item>

                        <Form.Item label={<>% การคืนยอดเล่นสะสม <Tag color='red'>ขั้นต่ำ {this.state.returnover_edit_amount_min}%</Tag> <Tag color='red'>สูงสุด {this.state.returnover_edit_amount_max}%</Tag></>} required>
                            <InputNumber
                                value={this.state.dataReturnoverEdit.returnover_amount}
                                min={this.state.returnover_edit_amount_min}
                                max={this.state.returnover_edit_amount_max}
                                style={{ width: '100%' }}
                                addonAfter="%"
                                onChange={(value) => this.setState({ dataReturnoverEdit: { ...this.state.dataReturnoverEdit, returnover_amount: value } })}
                            />
                        </Form.Item>

                        <Form.Item label="โบนัสสูงสุด การรับยอดเล่นสะสม" required>
                            <InputNumber
                                value={this.state.dataReturnoverEdit.returnover_max}
                                style={{ width: '100%' }}
                                addonAfter="เครดิต"
                                onChange={(value) => this.setState({ dataReturnoverEdit: { ...this.state.dataReturnoverEdit, returnover_max: value } })}
                            />
                        </Form.Item>

                        <Form.Item
                            label="รูป Icon แสดงถึงตำแหน่ง ยศ, แรงค์, เลเวล"
                            style={{ width: '100%' }}
                            extra="ขนาดที่แนะนำ 150×150px"
                            required
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.gif,.webp'
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP/GIF file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ dataReturnoverEdit: { ...this.state.dataReturnoverEdit, returnover_icon: file } })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>

                    </Form>
                </Modal>
            </>
        );
    }
}
