import React, { Component } from 'react';
import { Table, Tag, Typography, DatePicker, Input, Space, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import { URL } from '../../../../Util/Config'
import axios from 'axios';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: {},
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.transaction_id}</small></Text></div>
                    )
                },
                {
                    title: 'สมาชิกผู้ทำรายการ',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'transaction_amount',
                    align: 'right',
                    render: (text, record) => (
                        record.transaction_creditflag === 'C' ? <Tag color='green'>{text} บาท</Tag> : <Tag color='red'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'ประเภทธุรกกรม',
                    dataIndex: 'transaction_creditflag',
                    filterMultiple: false,
                    filters: [
                        {
                            text: 'ฝาก',
                            value: 'CM',
                        },
                        {
                            text: 'ถอน',
                            value: 'DM',
                        },
                        {
                            text: 'แจกเครดิตฟรี',
                            value: 'CF',
                        },
                        {
                            text: 'รอยืนยัน',
                            value: 'รอยืนยัน',
                        },
                    ],
                    onFilter: (value, record) => record.transaction_creditflag.indexOf(value) === 0,
                    render: (text, record) => {
                        switch (text) {
                            case 'C':
                                return <Tag color='green'>ฝาก</Tag>
                            // break;

                            case 'D':
                                return <Tag color='red'>ถอน</Tag>
                            // break;

                            case 'CM':
                                return <Tag color='green'>ฝากมือ</Tag>
                            // break;

                            default:
                                return <Tag color='red'>ถอนมือ</Tag>

                            // break;
                        }
                    }
                },
                {
                    title: 'ธนาคารลูกค้า',
                    dataIndex: 'bank_abbrev_th',
                },
                {
                    title: 'เลขบัญชี',
                    dataIndex: 'user_banknumber',
                    ...this.getColumnSearchProps('user_banknumber'),
                },
                {
                    title: 'สถานะ',
                    dataIndex: 'transaction_status',
                    filters: [
                        {
                            text: 'สำเร็จ',
                            value: 'สำเร็จ',
                        },
                        {
                            text: 'ผิดพลาด',
                            value: 'ผิดพลาด',
                        },
                        {
                            text: 'ยกเลิก',
                            value: 'ยกเลิก',
                        },
                        {
                            text: 'รอยืนยัน',
                            value: 'รอยืนยัน',
                        },
                    ],
                    filterMultiple: false,
                    onFilter: (value, record) => record.transaction_status.indexOf(value) === 0,
                    render: (text, record) => {
                        switch (text) {
                            case 'สำเร็จ':
                                return <Tag color='green'>{text}</Tag>
                            // break;

                            case 'รอยืนยัน':
                                return <Tag color='blue'>{text}</Tag>
                            // break;

                            default:
                                return <Tag color='red'>{text}</Tag>
                            // break;
                        }
                    }
                },
                {
                    title: 'วัน/เวลา ทำรายการ',
                    dataIndex: 'transaction_datetime',
                },
                {
                    title: 'ผู้ทำรายการ',
                    dataIndex: 'admin_fullname',
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'transaction_note',
                }
            ],
            data: [],
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken }, () => {
            this.getData(dateStart, dateEnd)
        })

        
    }

    async getData(start, end) {
        var date = new Date(start).toLocaleDateString('sv-SE')
        const startDate = new Date(`${date} 00:00:00`).toLocaleDateString('sv-SE')
        date = new Date(end).toLocaleDateString('sv-SE')
        const endDate = new Date(`${date} 23:59:59`).toLocaleDateString('sv-SE')

        const body = {
            admin_id: this.props.admin_id,
            startDate: startDate,
            endDate: endDate
        }

        await axios.post(URL + "/api/v1/admin/transaction", body, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then(res => {
                const data = res.data
                this.setState({ data: data.result })
                // console.log(data)
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <RangePicker
                    format={dateFormat}
                    onChange={(value) => this.getData(value[0]._d, value[1]._d)}
                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                />
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 2000 }} rowKey={record => record.transaction_id} />
            </>
        );
    }
}

export default withRouter(index)