import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Card, message, Image } from 'antd';
// import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { URL } from "../../Util/Config";
import axios from 'axios';
import { withRouter } from 'react-router-dom'
// import ReCAPTCHA from "react-google-recaptcha";

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipt_token: '',
    };
  }

  componentDidMount(){
    this.checkLogin();
  }

  async checkLogin(){
    if (!localStorage.getItem('g2fa')) {
      this.props.history.push("/login");
    } 
  }

  login() {
    const admin_id = JSON.parse(localStorage.getItem('g2fa'))
    var config = {
        method: 'post',
        url: `${URL}/api/v1/admin/login/g2fa`,
        headers: { 
            'Content-Type': 'application/json',
        },
        data : { 
            admin_id: admin_id, 
            token: this.state.ipt_token 
        }
    };

    axios(config)
      .then(res => {
        const data = res.data
        if (!data.status) {
            message.error(data.message);
            
        } else {
            message.success(data.message);
            localStorage.removeItem("g2fa");
            localStorage.setItem('user', JSON.stringify({ accessToken: data.accessToken, user: { id: data.result.admin_id,  username: data.result.admin_username, password: data.result.admin_password, name: data.result.admin_fullname, type: data.result.admin_type, rule: data.result.admin_rule } }))
            this.props.history.push("/adminManage/");
        }
    })
    .catch(err => {
        console.log(err)
        message.error("การเชื่อมต่อผิดพลาด!!");
    })
  }

  render() {
    return (
      <>
        <br />
        <Row justify="center">
          <Col  flex="500px">
            <Card className="text-center">
                {/* <h1>{WEB_NAME} Manage</h1> */}
                <Image src="/images/Google-Auth.png" width={100} preview={false}/>
                <br />
                <h5>ยืนยันตัวตน 2 ชั้น Google Authenticator</h5>
                <br />
                <Form onSubmitCapture={(v) => this.login(v)}>
                    <Form.Item
                        // label="2FA : "
                        rules={[{ required: true, message: 'กรุณากรอก username!', type: 'number', min: 6, max: 6 }]}
                    >
                        <Input 
                            size="large" 
                            pattern="[0-9]*"
                            style={{ width: '70%', textAlign: 'center', fontSize: 40 }} 
                            maxLength={6} 
                            minLength={6} 
                            onChange={(e) => this.setState({ ipt_token: e.target.value })} 
                            autoFocus
                        />
                    </Form.Item>

                    <br />
                    <Form.Item >
                        <Button type="primary" htmlType="submit" block shape="round" size="large">Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(login)