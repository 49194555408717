import React, { Component } from 'react';
import { PageHeader, Row, Col, Anchor } from 'antd';
import { WEB_NAME } from '../../../Util/Config';
import TypeGame from './typeGame';
import Basic from './besic';

const { Link } = Anchor;

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'วิธีใช้งาน',
            }
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    // onBack={() => window.history.back()}
                    // title="วิธีใช้งาน"
                    breadcrumb={{ routes }}
                />
                <Row>
                    <Col span={3}>
                        <Anchor affix={false}>
                            <Link href="#basic" title="วิธีใช้งานเบื้องต้น" />
                            <Link href="#typegame" title="ประเภทของค่ายเกม" />
                        </Anchor>
                    </Col>
                    <Col span={21}>
                        <div id='basic'>
                            <Basic />
                        </div>
                        <br/>
                        <div id='typegame'>
                            <TypeGame />
                        </div>
                    </Col>
                </Row>
                
            </>
        );
    }
}
