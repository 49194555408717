import React, { Component } from 'react';
import { Table, Card, Tag, Col, Row, Button, Statistic, Tabs, DatePicker, Input, Typography } from 'antd';
import { URL } from '../../../../Util/Config'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import x0p from 'x0popup';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { TabPane } = Tabs;
var startDate = ''
var endDate = ''

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: {},
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    width: 100,
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.transaction_id}</small></Text></div>
                    )
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'transaction_amount',
                    align: 'right',
                    render: (text, record) => (
                        record.transaction_creditflag === 'C' ? <Tag color='green'>{text} บาท</Tag> : <Tag color='red'>{text} บาท</Tag>
                    )
                },
                {
                    title: 'ประเภทธุรกกรม',
                    dataIndex: 'transaction_creditflag',
                    render: (text, record) => {
                        switch (text) {
                            case 'C':
                                return <Tag color='green'>ฝาก</Tag>
                            // break;

                            case 'D':
                                return <Tag color='red'>ถอน</Tag>
                            // break;

                            case 'CM':
                                return <Tag color='green'>ฝากมือ</Tag>
                            // break;

                            default:
                                return <Tag color='red'>ถอนมือ</Tag>

                            // break;
                        }
                    }
                },
                {
                    title: 'ธนาคารลูกค้า',
                    dataIndex: 'bank_abbrev_th',
                },
                {
                    title: 'เลขบัญชี',
                    dataIndex: 'user_banknumber',
                },
                {
                    title: 'สถานะ',
                    dataIndex: 'transaction_status',
                    filters: [
                        {
                            text: 'สำเร็จ',
                            value: 'สำเร็จ',
                        },
                        {
                            text: 'ผิดพลาด',
                            value: 'ผิดพลาด',
                        },
                        {
                            text: 'ยกเลิก',
                            value: 'ยกเลิก',
                        },
                        {
                            text: 'รอยืนยัน',
                            value: 'รอยืนยัน',
                        },
                    ],
                    filterMultiple: false,
                    onFilter: (value, record) => record.transaction_status.indexOf(value) === 0,
                    render: (text, record) => {
                        switch (text) {
                            case 'สำเร็จ':
                                return <Tag color='green'>{text}</Tag>
                            // break;

                            case 'รอยืนยัน':
                                return <Tag color='blue'>{text}</Tag>
                            // break;

                            default:
                                return <Tag color='red'>{text}</Tag>
                            // break;
                        }
                    }
                },
                {
                    title: 'วัน/เวลา ทำรายการ',
                    dataIndex: 'transaction_datetime',
                },
                {
                    title: 'ผู้ทำรายการ',
                    dataIndex: 'transaction_operator',
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'transaction_note',
                }
            ],
            data: [],
            columns2: [
                {
                    title: 'เมื่อ',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,
                },
                {
                    title: 'ก่อน',
                    dataIndex: 'before_wallet',
                    align: 'right',
                    sorter: (a, b) => a.before_wallet - b.before_wallet,
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'amount',
                    align: 'right',
                    sorter: (a, b) => a.amount - b.amount,
                    render: (text, record) => (
                        text >= 0 ? <Tag color='green'>{text}</Tag> : <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'หลัง',
                    dataIndex: 'after_wallet',
                    align: 'right',
                    sorter: (a, b) => a.after_wallet - b.after_wallet,
                },
                {
                    title: 'หมายเหตุ',
                    dataIndex: 'note',
                },
            ],
            data2: [],

            columns3: [
                {
                    title: 'เมื่อ',
                    dataIndex: 'timestamp',
                    sorter: (a, b) => a.timestamp - b.timestamp,
                },
                {
                    title: 'bet',
                    dataIndex: 'betAmount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag>{text}</Tag>
                    )
                },
                {
                    title: 'win',
                    dataIndex: 'winAmount',
                    align: 'right',
                    render: (text, record) => (
                        text >= 0 ? <Tag color='green'>{text}</Tag> : <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'game',
                    dataIndex: 'gameName',
                }
            ],
            data3: [],

            dataSummary: {},
            user_id: null,
            userCerrent: '',
            startDate: '',
            endDate: '',
        };
    }

    componentDidMount() {
        var user_id = this.props.user_id
        if (user_id) {
            this.setState({ user_id: this.props.user_id })
            this.getData(user_id, null, null);
            this.getDataTranscationSummary(user_id, null, null)
        }
    }

    getData(user_id, start, end) {
        var url = `${URL}/api/v1/transaction/user_id/${user_id}`
        if (start && end) {
            var date = new Date(start).toLocaleDateString('sv-SE')
            const startDate = new Date(`${date} 00:00:00`).toLocaleDateString('sv-SE')
            date = new Date(end).toLocaleDateString('sv-SE')
            const endDate = new Date(`${date} 23:59:59`).toLocaleDateString('sv-SE')

            url = `${URL}/api/v1/transaction/user_id/${user_id}/start/${startDate}/end/${endDate}`
        }
        fetch(url)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result }))
            // .then(() => console.log(this.state.data))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    async setDate2(start, end) {
        var date = new Date(start).toLocaleDateString('sv-SE')
        startDate = new Date(`${date} 00:00:00`).toLocaleString('sv-SE')
        date = new Date(end).toLocaleDateString('sv-SE')
        endDate = new Date(`${date} 23:59:59`).toLocaleString('sv-SE')

        this.getData2(startDate, endDate)
    }

    // ข้อ มูลจาก AG Betflix
    async getData2(startDate, endDate) {
        const body = {
            user_id: this.props.user_id,
            startDate: startDate,
            endDate: endDate,
            username: this.state.userCerrent
        }

        await axios.post(URL + "/api/v1/agent/member/transaction", body)
            .then(res => {
                const data = res.data
                this.setState({ data2: data.result })
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    async setDate3(start, end) {
        // var date = new Date(start).toLocaleDateString('sv-SE')
        startDate = new Date(`${start}`).toLocaleDateString('sv-SE')
        // date = new Date(end).toLocaleDateString('sv-SE')
        endDate = new Date(`${end}`).toLocaleDateString('sv-SE')

        this.getData3(startDate, endDate)
    }

    // ข้อ มูลจาก AG PG
    async getData3(startDate, endDate) {
        const body = {
            user_id: this.props.user_id,
            startDate: startDate,
            endDate: endDate,
            page: 1,
            username: this.state.userCerrent
        }

        await axios.post(URL + "/api/v1/agent-pg/member/report", body)
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.setState({ data3: data.result.records })
                } else {
                    return x0p('', data.message, 'error', false);
                }
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    getDataTranscationSummary(user_id, start, end) {
        var url = `${URL}/api/v1/transaction/summary/user_id/${user_id}`
        if (start && end) {
            var date = new Date(start).toLocaleDateString('sv-SE')
            const startDate = new Date(`${date} 00:00:00`).toLocaleDateString('sv-SE')
            date = new Date(end).toLocaleDateString('sv-SE')
            const endDate = new Date(`${date} 23:59:59`).toLocaleDateString('sv-SE')

            url = `${URL}/api/v1/transaction/summary/user_id/${user_id}/start/${startDate}/end/${endDate}`
        }
        fetch(url)
            .then((response) => response.json())
            .then((json) => this.setState({ dataSummary: json.result }))
            // .then(() => console.log(this.state.data))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };

    render() {
        return (
            <>
                <RangePicker format={'YYYY-MM-DD'} onChange={(value) => {
                    this.getData(this.state.user_id, value[0]._d, value[1]._d)
                    this.getDataTranscationSummary(this.state.user_id, value[0]._d, value[1]._d)
                }} />
                <Button onClick={() => {
                    this.getData(this.state.user_id, null, null)
                    this.getDataTranscationSummary(this.state.user_id, null, null)
                }}>ทั้งหมด</Button>
                <Row gutter={[16, 16]}>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดฝากรวม"
                                value={this.state.dataSummary.total_deposit}
                                valueStyle={{ color: '#3f8600' }}
                                prefix="+"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดถอนรวม"
                                value={this.state.dataSummary.total_withdraw}
                                valueStyle={{ color: '#cf1322' }}
                                prefix="-"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="รายได้สุทธิ"
                                prefix={this.state.dataSummary.income >= 0 ? '+' : ''}
                                value={this.state.dataSummary.income}
                                valueStyle={{ color: this.state.dataSummary.income >= 0 ? '#3f8600' : '#cf1322' }}
                            />
                        </Card>
                    </Col>
                    {/* <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="เครดิตคงเหลือ"
                                prefix={this.state.sumWinlose >= 0 ? '+' : ''}
                                value={this.state.userProfile.balance}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Card>
                    </Col> */}
                </Row>

                <br/>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="ประวัติการเงิน" key="1">
                        <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1300 }} rowKey={record => record.transaction_id} />
                    </TabPane>
                    <TabPane tab="เข้า-ออกเครดิต (Betflix)" key="2">
                        <Input onChange={(e) => this.setState({ userCerrent: e.target.value })} value={this.state.userCerrent} style={{ width: 200, marginRight: 5 }} placeholder='username'/>
                        <RangePicker format={'YYYY-MM-DD'} onChange={(value) => this.setDate2(value[0]._d, value[1]._d)} />
                        <small className='text-danger'>**ค้นหาได้เดือนต่อเดือน ไม่สามารถค้นหาข้ามเดือนได้ค่ะ</small>
                        <Button onClick={() => this.getData2()}>ค้นหา</Button>

                        <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 20 }} rowKey={record => record._id} />
                    </TabPane>
                    <TabPane tab="เข้า-ออกเครดิต (PG)" key="3">
                        <Input onChange={(e) => this.setState({ userCerrent: e.target.value })} value={this.state.userCerrent} style={{ width: 200, marginRight: 5 }} placeholder='username'/>
                        <RangePicker format={'YYYY-MM-DD'} onChange={(value) => this.setDate3(value[0]._d, value[1]._d)} />
                        <Button onClick={() => this.getData3()}>ค้นหา</Button>
                        <Table columns={this.state.columns3} dataSource={this.state.data3} pagination={{ pageSize: 100 }} rowKey={record => record._id} />
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

export default withRouter(index)