import React, { Component } from 'react';
import { PageHeader, Row, Col, Card, Switch, Form, Tabs, Button, Table, Image, Typography, Tag, Modal, Space, Upload, Divider, Radio } from 'antd';
import { URL, WEB_NAME } from '../../../Util/Config'
import { SaveOutlined, EditOutlined, UploadOutlined} from '@ant-design/icons';

import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
//   useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
// import { CSS } from '@dnd-kit/utilities';

import x0p from 'x0popup';
import axios from 'axios';

const { Text } = Typography;

export default class Games extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns1: [
                // {
                //     title: '',
                //     dataIndex: 'sort',
                //     width: 30,
                //     className: 'drag-visible',
                //     render: (text, record) => (
                //         <MenuOutlined />
                //     )
                // },
                {
                  title: '',
                  dataIndex: 'logo',
                  align: 'center',
                  width: 80,
                  render: (text, record) => (
                    <>
                      {text !== '' ?
                        <Image src={text.includes('http') ? text : URL + '/public/images/games/' + text} width={80} style={{ background: '#000' }}/>
                        : <></>
                      }
                    </>
                  ),
                },
                {
                  title: 'name',
                  dataIndex: 'name',
                  width: 300,
                  render: (text, record) => (
                    <Text style={{ width: 2 }}>
                      {text} ({record.game})
                    </Text>
                  )
                },
                {
                  title: 'type',
                  dataIndex: 'type',
                  align: 'right',
                  width: 150,
                  render: (text, record) => (
                    <Tag>{text}</Tag>
                  ),
                },
                {
                    title: 'status',
                    dataIndex: 'status',
                    align: 'right',
                    width: 150,
                    render: (text, record) => (
                      <Tag color={text ? 'green' : 'red'}>{text ? 'เปิด' : 'ปิด'}</Tag>
                    ),
                },
                {
                    title: 'isOpen',
                    dataIndex: 'isOpen',
                    width: 120,
                    render: (text, record) => (
                        <Tag color={text ? 'green' : 'red'}>{text ? 'เปิด' : 'ปิด'}</Tag>
                    ),
                },
                {
                    title: '',
                    dataIndex: '',
                    width: 200,
                    fixed: 'right',
                    render: (text, record) => (
                      <>
                        <Button style={{ marginRight: 5 }} onClick={() => this.postUpdateStatus(record.id, record.isOpen === 1 ? 'ปิด' : 'เปิด')} disabled={this.state.admin_type === 'G' || record.logo_s !== true ? true : false}>{record.isOpen ? 'ปิด' : 'เปิด'} เกมส์</Button>
                        <Button style={{ marginRight: 5 }} type="primary" ghost onClick={() => this.setState({ isModalEditImageVisible: true, game_name: record.name, game_type: record.type, game_id: record.id })} icon={<EditOutlined />} disabled={this.state.admin_type === 'G' || record.logo_s !== true ? true : false}>แก้ไขรูป</Button>
                      </>
                    )
                }
            ],
            data1: [],
            data2: [],
            data3: [],
            isModalEditImageVisible: false,
            game_name: '',
            game_type: '',
            game_id: '',
            game_image: '',
            games_theme: 0,
            switch_play_sport: 'ปิด',
            switch_play_lotto: 'ปิด',
            switch_play_games: 'ปิด',
            switch_play_pg: 'ปิด',
            switch_play_fiver: 'ปิด',
            loading: false,
        };
    }

    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        await this.getDataGames()
        await this.getGeneralConfig()
    }

    // ตั้งค่าทั่วไป
    async getGeneralConfig() {
        fetch(`${URL}/api/v1/config/general`)
            .then((response) => response.json())
            .then((json) => {
                this.setState({
                    switch_play_sport: json.result.config_play_sport,
                    switch_play_lotto: json.result.config_play_lotto,
                    switch_play_games: json.result.config_play_games,
                    switch_play_pg: json.result.config_play_pg,
                    switch_play_fiver: json.result.config_play_fiver,
                })
            })
            .catch((error) => {
                console.log('getTranferConfig', error);
            });
    }

    async getDataGames() {
        this.setState({ loading: true })
        fetch(`${URL}/api/v1/games/all`)
            .then((response) => response.json())
            .then((json) => {
                let dataSlot = json.result.filter(item => item.type.includes('slot'))
                let dataCasino = json.result.filter(item => item.type.includes('casino'))
                let dataSport = json.result.filter(item => item.type.includes('sport'))

                this.setState({ data1: dataSlot, data2: dataCasino, data3: dataSport, loading: false })
            })
            // .then(() => console.log(this.state.data1))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    async editImage(image, id) {
        const formData = new FormData();
        formData.append('image', image);
        formData.append('id', id);
    
        const res = await fetch(`${URL}/api/v1/games/image/update`, {
              method: "POST",
              body: formData,
              headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
              }
        }).then((res) => res.json());
    
        if (res.status) {
            this.getDataGames()
            x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
        } else {
            x0p('', res.message, 'error', false);
        }
    }

    async setImageBasic(theme) {
        if (theme === 0) {
            x0p('', 'กรุณาเลือกธีม!!', 'info', false);
            return
        }

        x0p({
            title: '',
            text: 'รูปเกมทั้งหมดจะถูกเปลี่ยน',
            icon: 'info',
            buttons: [
              {
                type: 'cancel',
                text: 'ยกเลิก',
              },
              {
                type: 'info',
                text: 'ยืนยัน',
                showLoading: true
              }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = { theme: theme }
                await axios.post(`${URL}/api/v1/games/image/update/set`, body, {
                    headers: {
                      'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                .then(res => {
                    const data = res.data
                    if (data.status) {
                        this.getDataGames()
                        this.setState({ isModalThemeVisible: false })
                        x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
                    
                    } else {
                        // console.log(data)
                        x0p('', data.message, 'error', false);
                    }
                })
                .catch(error => {
                    console.log(error)
                    x0p('', 'เกิดข้อผิดพลาด', 'error', false);
                })
            }
        })
    }

    onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            const activeIndex = this.state.data1.findIndex((i) => i.key === active.id);
            const overIndex = this.state.data1.findIndex((i) => i.key === over?.id);
            this.setState({ data1: arrayMove(this.state.data1, activeIndex, overIndex) }) 
        }
    };

    async postUpdatePlay() {
        const body = {
            play_sport: this.state.switch_play_sport, 
            play_lotto: this.state.switch_play_lotto, 
            play_games: this.state.switch_play_games, 
            play_pg: this.state.switch_play_pg, 
            play_fiver: this.state.switch_play_fiver,
        }

        await axios.post(`${URL}/api/v1/config/play/update`, body, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
        .then(res => {
            const data = res.data
            if (data.status) {
                this.getGeneralConfig()
                x0p('สำเร็จ', null, 'ok', false);
            } else {
                x0p('', data.message, 'error', false);
            }
        })
        .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
        })
    }

    async postUpdateStatus(id, status) {
        const body = {
            games_id: id,
            status: status
        }

        await axios.post(`${URL}/api/v1/games/update/status`, body, {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
        .then(res => {
            const data = res.data
            if (data.status) {
                this.getDataGames()
                x0p('สำเร็จ', null, 'ok', false);
            } else {
                x0p('', data.message, 'error', false);
            }
        })
        .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
        })
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'เกมส์',
            }
        ]

        // const RowDrag = (props) => {
        //   const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        //     id: props['data-row-key'],
        //   });
        //   const style = {
        //     ...props.style,
        //     transform: CSS.Transform.toString(
        //       transform && {
        //         ...transform,
        //         scaleY: 1,
        //       },
        //     ),
        //     transition,
        //     cursor: 'move',
        //     ...(isDragging
        //       ? {
        //           position: 'relative',
        //           zIndex: 9999,
        //         }
        //       : {}),
        //   };
        //   return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
        // };

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    // onBack={() => window.history.back()}
                    title="เกมส์"
                    breadcrumb={{ routes }}
                />
                <Card>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" icon={<SaveOutlined />} onClick={() => this.postUpdatePlay()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
                        </Col>
                    </Row>
                    <Form layout="vertical" size="large">
                        <Row gutter={[16, 16]}>
                            <Col span={4}>
                                <Form.Item label={<b>Betflix Lobby</b>}>
                                    สถานะ:  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.switch_play_games === 'เปิด' ? true : false} onChange={(e) => this.setState({ switch_play_games: e ? 'เปิด' : 'ปิด' })} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={<b>Betflix (กีฬา)</b>}>
                                    สถานะ:  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.switch_play_sport === 'เปิด' ? true : false} onChange={(e) => this.setState({ switch_play_sport: e ? 'เปิด' : 'ปิด' })} />
                                </Form.Item>
                            </Col>
                            {/* <Col span={4}>
                                <Form.Item label={<b>หวย</b>}>
                                    สถานะ:  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.switch_play_lotto === 'เปิด' ? true : false} onChange={(e) => this.setState({ switch_play_lotto: e ? 'เปิด' : 'ปิด' })} disabled />
                                </Form.Item>
                            </Col> */}
                            <Col span={4}>
                                <Form.Item label={<b>PG (ปรับแตก)</b>}>
                                    สถานะ:  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.switch_play_pg === 'เปิด' ? true : false} onChange={(e) => this.setState({ switch_play_pg: e ? 'เปิด' : 'ปิด' })} />
                                </Form.Item>
                            </Col>
                            {/* <Col span={4}>
                                <Form.Item label={<b>Fiver</b>}>
                                    สถานะ:  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.switch_play_fiver === 'เปิด' ? true : false} onChange={(e) => this.setState({ switch_play_fiver: e ? 'เปิด' : 'ปิด' })} />
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                </Card>

                <br/>

                <Card>
                    <Row style={{ marginBottom: 20 }}>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button type="primary" icon={''} onClick={() => this.setState({ isModalThemeVisible: true })} disabled={this.state.admin_type === 'G' ? true : false}>ธีมโลโก้เกมส์</Button>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Slot" key="1">
                            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={(e) => this.onDragEnd(e)}>
                                <SortableContext
                                    // rowKey array
                                    items={this.state.data1.map((i) => i.key)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <Table
                                        // components={{
                                        //     body: {
                                        //         row: RowDrag,
                                        //     },
                                        // }}
                                        rowKey="id"
                                        loading={this.state.loading}
                                        columns={this.state.columns1}
                                        dataSource={this.state.data1}
                                        pagination={{ 
                                            current: 1,
                                            pageSize: 100,
                                        }}
                                    />
                                </SortableContext>
                            </DndContext>  
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Casino" key="2">
                            {/* <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={(e) => this.onDragEnd(e)}>
                                <SortableContext
                                    // rowKey array
                                    items={this.state.data1.map((i) => i.key)}
                                    strategy={verticalListSortingStrategy}
                                > */}
                                    <Table
                                        // components={{
                                        //     body: {
                                        //         row: RowDrag,
                                        //     },
                                        // }}
                                        rowKey="id"
                                        loading={this.state.loading}
                                        columns={this.state.columns1}
                                        dataSource={this.state.data2}
                                        pagination={{ 
                                            current: 1,
                                            pageSize: 100,
                                        }}
                                    />
                                {/* </SortableContext>
                            </DndContext>      */}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sport" key="4">
                            {/* <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={(e) => this.onDragEnd(e)}>
                                <SortableContext
                                    // rowKey array
                                    items={this.state.data1.map((i) => i.key)}
                                    strategy={verticalListSortingStrategy}
                                > */}
                                    <Table
                                        // components={{
                                        //     body: {
                                        //         row: RowDrag,
                                        //     },
                                        // }}
                                        rowKey="id"
                                        loading={this.state.loading}
                                        columns={this.state.columns1}
                                        dataSource={this.state.data3}
                                        pagination={{ 
                                            current: 1,
                                            pageSize: 100,
                                        }}
                                    />
                                {/* </SortableContext>
                            </DndContext>      */}
                        </Tabs.TabPane>
                    </Tabs>
                </Card>

                {/* Modal แก้ไขรูป */}
                <Modal title="แก้ไขรูป" open={this.state.isModalEditImageVisible} onOk={() => this.editImage(this.state.game_image, this.state.game_id)} onCancel={() => this.setState({ isModalEditImageVisible: false })}>
                    <h6>เกม: {this.state.game_name}</h6>
                    <h6>ปรเภท: <Tag>{this.state.game_type}</Tag></h6>
                    <br/>
                    <Form layout="vertical">
                        <Space
                            direction="vertical"
                            style={{ width: '100%' }}
                            size="large"
                        >
                            <Upload
                                listType="picture"
                                maxCount={1}
                                accept='.png,.jpg,.jpeg,.webp,.gif'
                                // defaultFileList={this.state.defaultFileList}
                                beforeUpload={(file) => {
                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
                                    if (!isJpgOrPng) {
                                        return x0p('', 'You can only upload JPG/PNG/WEBP file!', 'error', false);
                                    }
                                    const isLt1M = file.size / 1024 / 1024 < 1;
                                    if (!isLt1M) {
                                        return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                    }

                                    this.setState({ game_image: file })
                                    return false
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Space>
                    </Form>
                </Modal>

                {/* ธีมโลโก้เกมส์ */}
                <Modal title="ธีมโลโก้เกมส์" open={this.state.isModalThemeVisible} onOk={() => this.setImageBasic(this.state.games_theme)} onCancel={() => this.setState({ isModalThemeVisible: false })} width={1200}>
                    <Divider orientation="left">Theme Games</Divider>
                        <Radio.Group onChange={(e) => this.setState({ games_theme: e.target.value })} value={this.state.games_theme}>
                            <Radio value={1} className="mx-4">
                                รูปแบบที่ 1
                                <br />
                                <Image src='/images/config/themegames1.png' width={200} />
                            </Radio>
                            <Radio value={2} className="mx-4">
                                รูปแบบที่ 2
                                <br />
                                <Image src='/images/config/themegames2.png' width={200} />
                            </Radio>
                            <Radio value={3} className="mx-4">
                                รูปแบบที่ 3
                                <br />
                                <Image src='/images/config/themegames3.png' width={200} />
                            </Radio>
                            <Radio value={6} className="mx-4">
                                รูปแบบที่ 6
                                <br />
                                <Image src='/images/config/themegames6.png' width={200} />
                            </Radio>
                        </Radio.Group>
                </Modal>
            </>
        );
    }
}
