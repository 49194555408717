import React, { Component } from 'react'
import { Table, Card, Typography, Tag, Input, Space, Button, Tabs, DatePicker, Form } from 'antd';
import { URL } from '../../../Util/Config';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import DateTime from 'node-datetime';

const { Title } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data1: [],
            columns1: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    sorter: (a, b) => a.row_num - b.row_num,
                },
                {
                    title: 'ผู้ใช้งาน',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                    render: (text, record) => (
                        <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
                    )
                },
                {
                    title: 'ยอดฝากรวม',
                    dataIndex: 'deposit',
                    align: 'right',
                    render: (text, record) => (
                        <>
                            <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
                        </>
                    )
                },
                {
                    title: 'ยอดถอนรวม',
                    dataIndex: 'withdraw',
                    align: 'right',
                    render: (text, record) => (
                        <>
                            <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
                        </>
                    )
                },
                {
                    title: 'ได้กำไรจากการเล่น',
                    dataIndex: 'income',
                    align: 'right',
                    render: (text, record) => (
                        <>
                            {text > 0 ?
                                <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
                                :
                                <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
                            }
                        </>
                    )
                },
                {
                    title: 'วัน/เวลา ทำธุรกรรมล่าสุด',
                    dataIndex: 'transaction_datetime',
                }
            ],
            data2: [],
            data3: [],
            data4: [],
        };
    }

    componentDidMount() {
        this.getUserTransactionRanking(undefined, undefined)
    }

    getUserTransactionRanking(startDate, endDate) {
        var url = `${URL}/api/v1/dashboard/user/transaction/ranking`
        if (startDate && endDate) {
            url = url + `/start/${startDate}/end/${endDate}`
        }
        fetch(url)
            .then((response) => response.json())
            .then((json) => this.setState({ data1: json.result.income, data2: json.result.loss, data3: json.result.deposit, data4: json.result.withdraw }))
            // .then(() => console.log(this.state.detailChart))
            .catch((error) => {
                console.log('getUserTransactionRankingIncome', error);
            });
    }

    setTable(startDate, endDate) {
        // console.log(startDate, endDate)
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getUserTransactionRanking(startDate, endDate)
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Card>
                    <Title level={5} >ตารางแรงค์กิ้ง</Title>
                    <p>ข้อมูลรวบรวมจากประวัติการฝากถอน Auto และ Manual ซึ่งอาจจะไม่ตรงกับข้อมูลที่สมาชิกได้ถอนเงินจริงๆ</p>

                    <Form layout="inline">
                        <Form.Item label="ค้นหาประวัติธุรกรรมตามช่วงเวลา"  style={{ width: '50%' }}>
                            <RangePicker
                                style={{ width: '100%' }}
                                // defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                format={dateFormat}
                                onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => this.getData(undefined, undefined)}>
                                ค้นหาทั้งหมด
                            </Button>
                        </Form.Item>
                    </Form>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="ผู้ทำกำไรสูงสุดของเว็บ" key="1">
                            <Table columns={this.state.columns1} dataSource={this.state.data1} pagination={{ pageSize: 5 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                        </TabPane>
                        <TabPane tab="ผู้เสียกำไรสูงสุดของเว็บ" key="2">
                            <Table columns={this.state.columns1} dataSource={this.state.data2} pagination={{ pageSize: 5 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                        </TabPane>
                        <TabPane tab="ผู้มียอดฝากสูงสุดของเว็บ" key="3">
                            <Table columns={this.state.columns1} dataSource={this.state.data3} pagination={{ pageSize: 5 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                        </TabPane>
                        <TabPane tab="ผู้มียอดถอนสูงสุดของเว็บ" key="4">
                            <Table columns={this.state.columns1} dataSource={this.state.data4} pagination={{ pageSize: 5 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        )
    }
}
