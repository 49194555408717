import React, { Component } from 'react';
import { Table, Card, Button, PageHeader, Row, Col, Switch, Tag, Select, InputNumber, Input, Space, Statistic, DatePicker, Typography } from 'antd';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { URL, WEB_NAME } from '../../../../Util/Config'
import axios from 'axios';
import x0p from 'x0popup';
import DateTime from 'node-datetime';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
var dateStart = dateOffset30.format('Y/m/d');
var dateEnd = DateTime.create().format('Y/m/d');

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

export default class daily extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'วันที่',
                    dataIndex: 'daily_day',
                    align: 'center',
                },
                {
                    title: 'ประเภท รางวัล',
                    dataIndex: 'daily_type',
                    align: 'center',
                    render: (text, record, index) => (
                        <>
                            <Select
                                // value={text}
                                defaultValue={text}
                                onChange={(val) => this.updateDailyType(record.daily_id, val)}
                                style={{ width: 300 }}
                            >
                                <Option value="เครดิต">เครดิต</Option>
                                <Option value="เพชร">เพชร</Option>
                                <Option value="ไม่ระบุ">ไม่ระบุ</Option>
                            </Select>
                        </>
                    )
                },
                {
                    title: 'จำนวน รางวัล',
                    dataIndex: 'daily_amount',
                    align: 'center',
                    render: (text, record, index) => (
                        <>
                            <InputNumber
                                // min={record.daily_type === "ไม่ระบุ" ? 0 : 1}
                                // disabled={record.daily_type === "ไม่ระบุ" ? true : false}
                                // value={text}
                                defaultValue={text}
                                style={{ width: 300 }}
                                onChange={(val) => this.setState(prevState => ({
                                    data: this.state.data.map(
                                        obj => (obj.daily_id === record.daily_id ? Object.assign(obj, { daily_amount: val }) : obj)
                                    )
                                }))}
                            />
                        </>

                    )
                },
            ],
            data: [],
            columns2: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    sorter: (a, b) => a.row_num - b.row_num,
                },
                {
                    title: 'ผู้รับรางวัล',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                },
                {
                    title: 'ประเภทรางวัล',
                    dataIndex: 'log_daily_type',
                    filters: [
                        {
                            text: 'เครดิต',
                            value: 'เครดิต',
                        },
                        {
                            text: 'เพชร',
                            value: 'เพชร',
                        },
                        {
                            text: 'ไม่ระบุ',
                            value: 'ไม่ระบุ',
                        },
                    ],
                    onFilter: (value, record) => record.log_daily_type.indexOf(value) === 0,
                    render: (text, record) => (
                        text === 'เครดิต' ?
                            <Tag color='gold'>{text}</Tag>
                            :
                            text === 'เพชร' ?
                                <Tag color='cyan'>{text}</Tag>
                                :
                                <Tag color='red'>{text}</Tag>
                    )
                },
                {
                    title: 'จำนวนที่ได้รับ',
                    dataIndex: 'log_daily_amount',
                    align: 'right',
                    sorter: (a, b) => a.log_daily_amount - b.log_daily_amount,
                    render: (text, record) => (
                        <Tag color='green'>{text}</Tag>
                    )
                },
                {
                    title: 'วัน/เวลาที่รับรางวัล',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data2: [],
            detail: [],
            summary: [],
            amount: 0,
            amount_status: '0',
            admin_type: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.getData()
        this.getDataStatus()
        this.setTable(dateStart, dateEnd)
        this.getDataLogSummary()
    }

    setTable(startDate, endDate) {
        // console.log(startDate, endDate)
        var dt = DateTime.create(startDate);
        startDate = dt.format('Y-m-d');
        dt = DateTime.create(endDate);
        endDate = dt.format('Y-m-d');

        this.getDataLog(startDate, endDate)
    }

    getData() {
        fetch(`${URL}/api/v1/daily/all`)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result }))
            // .then(() => console.log(this.state.data))
            .catch((error) => {
                console.log('fetch log failed', error);
            });
    }

    getDataStatus() {
        fetch(`${URL}/api/v1/daily/event`)
            .then((response) => response.json())
            .then((json) => this.setState({ status: json.result.event_status, amount: json.result.event_amount, amount_status: json.result.event_amount === '0' ? '0' : '1' }))
            // .then(() => console.log(this.state.status, this.state.amount, this.state.amount_status))
            .catch((error) => {
                console.log('fetch log failed', error);
            });
    }

    getDataLogSummary() {
        fetch(`${URL}/api/v1/daily/log/summary`)
            .then((response) => response.json())
            .then((json) => this.setState({ summary: json.result }))
            // .then(() => console.log(this.state.summary))
            .catch((error) => {
                console.log('fetch log failed', error);
            });
    }

    getDataLog(startDate, endDate) {
        fetch(`${URL}/api/v1/daily/log/start/${startDate}/end/${endDate}`)
            .then((response) => response.json())
            .then((json) => this.setState({ data2: json.result.list, detail: json.result.summary }))
            // .then(() => console.log(this.state.detail))
            .catch((error) => {
                console.log('fetch log failed', error);
            });
    }

    // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ FILTER ----------------------------------------------------------- 

    async updateStatus(status) {
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการ "${status === true ? 'เปิด' : 'ปิด'}" ใช้งานล็อกอินรายวันใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    status: status === true ? 'เปิด' : 'ปิด'
                }
                await axios.post(`${URL}/api/v1/daily/update/status`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.setState({ status: status === true ? 'เปิด' : 'ปิด' })
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    updateDaily() {
        x0p({
            title: `ยืนยัน`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    daily_data: this.state.data,
                    amount: this.state.amount,
                }
                await axios.post(URL + "/api/v1/daily/update/all", body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        var data = res.data
                        if (data.status) {
                            this.getData()
                            x0p('สำเร็จ!!', null, 'ok', false);
                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('การเชื่อมต่อผิดพลาด updateDaily', null, 'error', false);
                    })
            }
        })
    }

    updateDailyType(index, value) {
        this.setState(prevState => ({
            data: this.state.data.map(
                obj => (obj.daily_id === (index) ? Object.assign(obj, { daily_type: value }) : obj)
            )
        }))

        if (value === 'ไม่ระบุ') {
            this.setState(prevState => ({
                data: this.state.data.map(
                    obj => (obj.daily_id === (index) ? Object.assign(obj, { daily_amount: 0 }) : obj)
                )
            }))
        }
    }

    setCardSummary() {
        let list = this.state.summary.map((item, index) =>
            <Col flex="1 1 250px">
                <Card>
                    <Statistic
                        title={item.unit === 'ไม่ระบุ' ? `${item.unit}ทั้งหมด` : `${item.unit}ที่แจกทั้งหมด`}
                        value={item.amount}
                        valueStyle={{ color: '#531CAA' }}
                        suffix={item.unit === 'ไม่ระบุ' ? 'ครั้ง' : item.unit}
                    />
                </Card>
            </Col>
        );
        return (list)
    }

    setDetail() {
        let list = this.state.detail.map((item, index) =>
            <Col span={8}>
                <DescriptionItem title={item.unit} content={<><Text style={{ color: '#EF9900' }}>{item.amount}</Text> {item.unit === 'ไม่ระบุ' ? 'ครั้ง' : item.unit}</>} />
            </Col>
        );
        return (list)
    }

    render() {
        return (
            <>
                <Row className='justify-content-between' style={{ marginBottom: 20 }}>
                    <Col>
                        <h6>เงื่อนไขการรับรางวัล</h6>
                        <p>ลูกค้าจะรับรางวัลตามวันที่กำหนดได้ที่หน้าเว็บ ไม่สามารถรับย้อนหลังได้ และรับรางวัลได้ 1 ครั้ง/วันเท่านั้น</p>
                        <p>ลูกค้า <Select value={this.state.amount_status} style={{ width: 150 }} onChange={(value) => this.setState({ amount_status: value, amount: value })}>
                            {/* <Option value="บาท">บาท</Option> */}
                            <Option value="0">ไม่ต้องฝากเงิน</Option>
                            <Option value="1">ต้องฝากเงินก่อน</Option>
                        </Select> รับรางวัลได้ {this.state.amount_status === '0' ?
                            <></>
                            :
                            <>
                                ต้องฝากขั้นต่ำ <InputNumber
                                    value={this.state.amount}
                                    min={1}
                                    onChange={(value) => this.setState({ amount: value })}
                                /> บาท
                            </>
                            }
                        </p>
                        <p><strong>ประเภทรางวัล</strong> <Tag color="gold">เครดิต</Tag>= ยอดเงินในระบบเกมหรือเครดิต (ใช้ในการเล่นเกมได้), <Tag color="cyan">เพชร</Tag>= แต้มหรือยอดเงินสมมุติ (ใช้เล่นเกมไม่ได้)</p>
                    </Col>
                    <Col style={{ textAlign: 'right', marginTop: 15 }}>
                        สถานะการใช้งาน :  <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" checked={this.state.status === 'เปิด' ? true : false} onChange={(e) => this.updateStatus(e)} disabled={this.state.admin_type === 'G' ? true : false}/>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" icon={<SaveOutlined />} onClick={() => this.updateDaily()} disabled={this.state.admin_type === 'G' ? true : false}>บันทึก</Button>
                    </Col>
                </Row>
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 31 }} scroll={{ x: 1000 }} rowKey={record => record.log_id}/>

                <br />
                <Row gutter={[16, 16]}>
                    {/* <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="จำนวนที่รับรางวัลทั้งหมด"
                                value={this.state.data2.length}
                                valueStyle={{ color: '#531CAA' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col> */}
                    {this.setCardSummary()}
                </Row>
                <br />
                
                <h5>ประวัติการรับรางวัล</h5>
                <RangePicker
                    style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                    format={dateFormat}
                    onChange={(e) => this.setTable(e[0]._d, e[1]._d)} />
                <br />
                <Row>
                    {this.setDetail()}
                </Row>
                <br />
                <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
            </>
        );
    }
}
