import React, { Component } from 'react'
import { Table, Card, Input, Button, Space, PageHeader, Tag } from 'antd';
import { URL, WEB_NAME } from '../../../Util/Config'
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'master_id',
                    defaultSortOrder: 'descend',
                    width: 150,
                    sorter: (a, b) => a.master_id - b.master_id,
                    render: (text, record) => (
                        <>
                            {text} <Tag>{record.row_num}</Tag>
                        </>
                    ),
                },
                {
                    title: 'username',
                    dataIndex: 'master_username',
                    width: 300,
                    ...this.getColumnSearchProps('master_username'),
                },
                {
                    title: 'วัน/เวลาที่เพิ่ม',
                    dataIndex: 'created_at',
                },
                {
                    title: '',
                    dataIndex: '',
                    render: (text, record) => (
                        <Button>
                            <Link to={`/adminManage/master/detail/${record.master_username}`}>
                            รายละเอียด
                            </Link>
                        </Button>
                    ),
                }
            ],
            data: [],
        };
    }

    componentDidMount() {
        this.getData()
    }
    
    getData(){
        fetch(`${URL}/api/v1/master/all`)
          .then((response) => response.json())
          .then((json) => this.setState({ data: json.result }))
        //   .then(() => console.log(this.state.data))
          .catch((error) => {
            console.log('fetch log failed', error);
          });
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                ref={node => {
                    this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                    confirm({ closeDropdown: false });
                    this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                    });
                    }}
                >
                    Filter
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        const routes = [
            {
              breadcrumbName: WEB_NAME,
            },
            {
              breadcrumbName: 'รายชื่อเซียน',
            },
        ]

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="รายชื่อเซียน"
                    breadcrumb={{ routes }}
                    // subTitle="This is a subtitle"
                />
                <Card>
                    <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.row_num}/>
                </Card>
            </>
        )
    }
}

export default withRouter(index)