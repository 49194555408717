import React, { Component } from 'react';
import { Row, Col, Form, Input, Button, Radio, Image } from 'antd';
import { URL } from '../../../Util/Config';
import { SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';

export default class turnover extends Component {
  constructor(props) {
    super(props);
    this.state = {
        configSMS: {
            sms_otp_status: false
        }
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

    await this.getDataSMS()
  }

  async getDataSMS() {
    fetch(`${URL}/api/v1/sms`)
        .then((response) => response.json())
        .then((json) => {
            if (json.status) {
                this.setState({ configSMS: json.result })
            } 
        })
        // .then(() => console.log(this.state.configSMS))
        .catch((error) => {
            console.log('getTranferConfig', error);
        });
  }

  async postUpdateSMS() {
    var body = {
        token: this.state.configSMS.sms_token,
        status: this.state.configSMS.sms_otp_status,
    }

    await axios.post(`${URL}/api/v1/sms/update`, body, {
        headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
        }
    })
        .then(res => {
            const data = res.data
            if (data.status) {
                this.getDataSMS()
                x0p('สำเร็จ', null, 'ok', false);
            } else {
                x0p('', data.message, 'error', false);
            }
        })
        .catch(error => {
            console.log(error)
            x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
        })
  }

  render() {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Button type='primary' icon={<SaveOutlined />} onClick={() => this.postUpdateSMS()}>บันทึก</Button>
                    <br/>
                    <br/>
                    <Form.Item label="สถานะ OTP ก่อนสมัคร">
                        <Radio.Group onChange={(e) => this.setState({ configSMS: { ...this.state.configSMS, sms_otp_status: e.target.value } })} value={this.state.configSMS.sms_otp_status}>
                            <Radio value={'ปิด'}>ปิด</Radio>
                            <Radio value={'เปิด'}>เปิด</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="TOKEN">
                        <Input style={{ width: 300 }} value={this.state.configSMS.sms_token} onChange={(e) => this.setState({ configSMS: { ...this.state.configSMS, sms_token: e.target.value } })} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <h4>วิธีติดตั้ง TOKEN</h4>
                    {/* <Button type='primary' block className='mb-4' href='https://godysms.com/' target='_blank'>สมัคร</Button> */}
                    <p>1. เข้าไปที่เมนู APIs คลิ๊กที่ปุ่ม "จัดการ API TOKEN"</p>
                    <Image src='/images/sms/token1.png' width={'100%'} className='mb-4' preview={false}/>
                    <p>2. กรอกข้อมุลแล้วกด "สร้าง TOKEN"</p>
                    <Image src='/images/sms/token2.png' width={'100%'} className='mb-4' preview={false}/>
                    <p>3. นำ TOKEN ที่ได้มากรอกในระบบ auto แล้วกด "ตกลง"</p>
                    <Image src='/images/sms/token3.png' width={'100%'} className='mb-4' preview={false}/>
                </Col>
            </Row>
        </>
    );
  }
}
