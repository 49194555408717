import React, { Component } from 'react';
import { Table, Input, Button, Space, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { URL } from '../../../../Util/Config'
import axios from 'axios';

const { Text } = Typography;

export default class promotion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.log_id}</small></Text></div>
                    )
                },
                {
                    title: 'ชื่อผู้ทำรายการ',
                    dataIndex: 'operator',
                    width: 150,
                    ...this.getColumnSearchProps('operator'),
                },
                {
                    title: 'เหตุการณ์ ....',
                    dataIndex: 'log_event',
                    width: 500,
                    ...this.getColumnSearchProps('log_event'),
                },
                {
                    title: 'วันที่ทำรายการ',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                },
            ],
            data: [],
        };
    }

    componentDidMount() {
        if (this.props.admin_id) {
            this.getData()
        }
    }

    async getData() {
        var body = {
            admin_id: this.props.admin_id,
            type_id: 3
        }
        await axios.post(URL + "/api/v1/log/type", body)
            .then(res => {
                const data = res.data
                this.setState({ data: data.result })
                // console.log(data)
            })
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 


    render() {
        return (
            <>
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
            </>
        );
    }
}
