import React, { Component } from 'react'
import { Tabs, PageHeader } from 'antd';
import { WEB_NAME } from '../../../../Util/Config'
import Network from './network'
import Returnloss from './returnloss';
import Returnover from './returnover';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'โปรโมชั่น',
            },
            {
                breadcrumbName: 'Ranking (คืนยอด)',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Ranking (คืนยอด)"
                    breadcrumb={{ routes }}
                />

                <Tabs defaultActiveKey="1" type="card">
                    <Tabs.TabPane tab="คืนยอดเสีย" key="1">
                        <Returnloss />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="คืนยอดเล่นสะสม" key="2">
                        <Returnover />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="คืนยอดเสียแนะนำเพื่อน (ลิ้งค์รับทรัพย์)" key="3">
                        <Network />
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}
