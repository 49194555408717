import React, { Component } from 'react';

export default class turnover extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
        <>
            <p>ผลประกอบการประจำเดือน</p>
        </>
    );
  }
}
