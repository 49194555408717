import React, { Component } from 'react';
import { message, Card, Table, Tag, PageHeader, Descriptions } from 'antd';
import { URL, WEB_NAME } from '../../../../Util/Config'
// import { ExclamationCircleOutlined, FileSearchOutlined } from '@ant-design/icons';
import { formateDateTimeDMYT } from '../../../../Util/formatDate';
import { withRouter } from 'react-router'

class coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalCouponVisible: false,
      discount: {},
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'coupon_id',
          // defaultSortOrder: 'descend',
          // sorter: (a, b) => a.coupon_id - b.coupon_id,
        },
        {
          title: 'Code',
          dataIndex: 'coupon_code',
        },
        {
          title: 'สถานะ',
          dataIndex: 'coupon_status',
          render: (text, record) => (
            text === 'เปอร์เซนต์' ? <Tag color="red">{text}</Tag> : <Tag color="green">{text}</Tag>
          ),
        },
      ],
      data: [],
    };
  }

  componentDidMount(){
    if (this.props.match.params.id) {
        this.getData()
    } else {
        message.error('ไม่มีข้อมูล coupon')
        this.props.history.push("/adminManage/promotion/discount");
    }
  }

  getData(){
    var id = this.props.match.params.id
    fetch(`${URL}/api/v1/coupon/by/discount/${id}`)
      .then((response) => response.json())
      .then((json) => this.setState({ data: json.result.coupon, discount: json.result.discount }))
      .then(() => console.log(this.state.data))
      .then(() => console.log(this.state.discount))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  showCoupon(){
    this.setState({ isModalCouponVisible: true })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'โปรโมชั่น',
      },
      {
        breadcrumbName: 'รายการส่วนลด',
      },
      {
        breadcrumbName: 'รายการคูปอง',
      },
    ]
    return (
      <>
        <PageHeader
          className="site-page-header"
          onBack={() => window.history.back()}
          title="รายการคูปอง"
          breadcrumb={{ routes }}
        />
        <Card>
          <Descriptions title="ส่วนลด">
            <Descriptions.Item label="ชื่อ">{this.state.discount.discount_name}</Descriptions.Item>
            <Descriptions.Item label="จำนวน">{this.state.discount.discount_amount}</Descriptions.Item>
            <Descriptions.Item label="ประเภท">{this.state.discount.discount_type}</Descriptions.Item>
            <Descriptions.Item label="วัน/เวลาที่เริ่ม">{this.state.discount.discount_start_date ? formateDateTimeDMYT(this.state.discount.discount_start_date) : ''}</Descriptions.Item>
            <Descriptions.Item label="วัน/เวลาที่สิ้นสุด">{this.state.discount.discount_end_date ? formateDateTimeDMYT(this.state.discount.discount_end_date) : ''}</Descriptions.Item>
          </Descriptions>
          <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} rowKey={record => record.coupon_id}/>
        </Card>
      </>
    );
  }
}

export default withRouter(coupon);