import React, { Component } from 'react'
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom'
import Returnloss from './returnloss';
import ReturnNetwork from './returnNetwork';
import Returnover from './returnover';

class returnloss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ''
        }
    }

    componentDidMount() {
        if (this.props.username) {
            this.setState({ username: this.props.username })
        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    render() {
        return (
            <>
                <Tabs type="card">
                    <Tabs.TabPane tab="คืนยอดเสีย" key="1">
                        <Returnloss username={this.props.username} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="คืนยอดเสียแนะนำเพื่อน" key="3">
                        <ReturnNetwork username={this.props.username} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="คืนยอดเล่นสะสม" key="2">
                        <Returnover username={this.props.username} />
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}

export default withRouter(returnloss)