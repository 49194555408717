import React, { Component } from 'react'
import { Table, Card, Input, Button, Space, Row, Col, PageHeader, Tag, DatePicker, Modal, Statistic } from 'antd';
import moment from 'moment';
import { URL, WEB_NAME } from '../../../Util/Config'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal2Visible: false,
            columns: [
                {
                    key: 'master_log_id',
                    title: 'ลำดับ',
                    dataIndex: 'master_log_id',
                    width: 150,
                    // defaultSortOrder: 'descend',
                    sorter: (a, b) => a.master_log_id - b.master_log_id,
                    render: (text, record) => (
                        <>
                            {text} <Tag>{record.row_num}</Tag>
                        </>
                    ),
                },
                {
                    title: 'วัน/เวลา',
                    dataIndex: 'master_log_date',
                    width: 150,
                    sorter: (a, b) => a.master_log_date - b.master_log_date,
                    ...this.getColumnSearchProps('master_log_date'),
                },
                {
                    title: 'รวมยอดได้เสียของลูกค้า',
                    dataIndex: 'master_log_winlose',
                    render: (text) => (
                        parseFloat(text) > 0 ? 
                        <Tag color="green">{text}</Tag>
                        : 
                        <Tag color="red">{text}</Tag>
                    ),
                },
                {
                    title: 'จำนวนสมาชิก',
                    dataIndex: 'master_log_amount',
                    render: (text) => (
                        <Tag color="orange">{text}</Tag>
                    ),
                },
                {
                    title: '',
                    dataIndex: '',
                    render: (text, record) => (
                        <Button key={record.master_log_id} onClick={() => this.getDataDetail(record.master_log_detail)}>รายละเอียด</Button>
                    ),
                }
            ],
            data: [],
            winlose: 0,
            username: '',
            
            columns2: [
                {
                    key: 'user_id',
                    title: 'id',
                    width: 150,
                    dataIndex: 'user_id',
                    sorter: (a, b) => a.user_id - b.user_id,
                },
                {
                    title: 'username',
                    dataIndex: 'user_username',
                    width: 200,
                    ...this.getColumnSearchProps('user_username'),
                },
                {
                    title: 'ยอดแพ้ชนะ',
                    dataIndex: 'winlose',
                    sorter: (a, b) => a.winlose - b.winlose,
                    render: (text) => (
                        parseFloat(text) > 0 ? 
                        <Tag color="green">{text}</Tag>
                        : 
                        <Tag color="red">{text}</Tag>
                    ),
                }
            ],
            data2: [],
        };
    }

    componentDidMount(){
        if (this.props.match.params.username) {
            this.setState({ username: this.props.match.params.username })
            const dateNow = new Date().getTime()
            this.getData(this.props.match.params.username, dateNow, dateNow)
        }
    }
    
    getData(value, dateStart, dateEnd) {
        const username = value ? value : this.state.username
        const start =  new Date(dateStart).toLocaleDateString('en-CA')
        const end = new Date(dateEnd).toLocaleDateString('en-CA')

        if (!dateStart || !dateEnd) return console.log('ไม่มีข้อมูล')
        
        fetch(`${URL}/api/v1/master/${username}/start/${start}/end/${end}`)
          .then((response) => response.json())
          .then((json) => this.setState({ data: json.result, winlose: json.sum_winlose }))
        //   .then(() => console.log(this.state.data))
          .catch((error) => {
            console.log('fetch log failed', error);
          });
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                ref={node => {
                    this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                    confirm({ closeDropdown: false });
                    this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                    });
                    }}
                >
                    Filter
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    setTime(e) {
        if (e) {
            const dateStart = e[0].valueOf()
            const dateEnd = e[1].valueOf()

            this.getData(this.state.username, dateStart, dateEnd)
        }
    }

    getDataDetail(value) {
        this.setState({ modal2Visible: true, data2: value ? JSON.parse(value) : [] })
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'รายชื่อเซียน',
            },
            {
                breadcrumbName: 'รายละเอียด',
            },
        ]

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="รายละเอียด"
                    breadcrumb={{ routes }}
                    // subTitle="This is a subtitle"
                />
                <Card>
                    <Row justify="space-between">
                        <Col>
                            <h3>{this.state.username}</h3>
                        </Col>
                        <Col>
                            <Statistic
                                title="สรุปยอดรวมได้เสียลูกค้า"
                                value={this.state.winlose}
                                valueStyle={{ color: this.state.winlose < 0 ? 'red' : 'green', textAlign: 'right', fontSize: 40 }}
                                // suffix="บาท"
                            />
                        </Col>
                    </Row>
                    
                    <RangePicker
                        defaultValue={[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]}
                        format={dateFormat}
                        style={{ marginBottom: 10 }}
                        onChange={(e) => this.setTime(e)}
                    />
                    <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.row_num}/>
                </Card>

                <Modal
                    title="รายละเอียด"
                    style={{ top: 20 }}
                    width={1000}
                    open={this.state.modal2Visible}
                    onOk={() => this.setState({ modal2Visible: false })}
                    onCancel={() => this.setState({ modal2Visible: false })}
                >
                    <Table columns={this.state.columns2} dataSource={this.state.data2} pagination={{ pageSize: 25 }} scroll={{ x: 1000 }} rowKey={record => record.user_id}/>
                </Modal>
            </>
        )
    }
}

export default withRouter(index)
