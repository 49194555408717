import React, { Component } from 'react'
import { Table, Input, Button, Space, Tag, Row, Col, Card, Statistic, Tabs, Typography } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { URL } from '../../../../../Util/Config'
import { withRouter } from 'react-router-dom'

const { Text } = Typography;

class returnloss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    defaultSortOrder: 'descend',
                    width: 100,
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.log_returnover_id}</small></Text></div>
                    )
                },
                {
                    title: 'ยอดเล่นสะสม (ที่ระบบเก็บ)',
                    dataIndex: 'log_returnover_turnover',
                    render: (text, record) => (
                        <Tag color='red'>{text} turnover</Tag>
                    )
                },
                {
                    title: 'คืนยอดเล่น %',
                    dataIndex: 'log_returnover_percent',
                    render: (text, record) => (
                        <Tag>{text}</Tag>
                    )
                },
                {
                    title: 'โบนัสที่ได้รับ',
                    dataIndex: 'log_returnover_amount',
                    render: (text, record) => (
                        <Tag color='green'>{text.toLocaleString()} โบนัส</Tag>
                    )
                },
                {
                    title: 'วัน/เวลา ที่ได้รับโบนัส',
                    dataIndex: 'created_at',
                    ...this.getColumnSearchProps('created_at'),
                }
            ],
            data: [],
            total_returnover_turnover: '',
            total_returnover_amount: '',
            userProfile: {}
        }
    }

    componentDidMount() {
        if (this.props.username) {
            this.getTotalReturnover(this.props.username)
            this.getReturnover(this.props.username)
            // this.setProfile()
        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    getTotalReturnover(username) {
        fetch(`${URL}/api/v1/returnover/total/username/${username}`)
            .then((response) => response.json())
            .then((json) => this.setState({ total_returnover_turnover: json.result.total_returnover_turnover, total_returnover_amount: json.result.total_returnover_amount }))
            // .then(() => console.log('total_returnover_turnover : ', this.state.total_returnover_turnover, 'total_returnover_amount : ', this.state.total_returnover_amount))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getReturnover(username) {
        fetch(`${URL}/api/v1/returnover/username/${username}`)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result }))
            // .then(() => console.log('data : ', this.state.data))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    // setProfile() {
    //     const userDetail = JSON.parse(localStorage.getItem('userDetail'))
    //     this.setState({ userProfile: userDetail })
    // }

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดเล่นทั้งหมด (ที่ระบบเก็บ)"
                                value={this.state.total_returnover_turnover ? this.state.total_returnover_turnover : 0}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="Turnover"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดโบนัสที่ได้รับคืนทั้งหมด"
                                value={this.state.total_returnover_amount ? this.state.total_returnover_amount : 0}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="โบนัส"
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} rowKey={record => record.log_returnover_id} />
            </>
        )
    }
}

export default withRouter(returnloss)