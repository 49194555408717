import React, { Component } from 'react';
import { Card, Divider, Row, Col, Image } from 'antd';
// import { URL } from '../../../Util/Config'
import { QuestionCircleOutlined } from '@ant-design/icons';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <Card>
                    <Divider orientation="left"><h5><QuestionCircleOutlined /> วิธีใช้งานเบื้องต้น</h5></Divider>
                    <h6>เริ่มใช้งานระบบครั้งแรก</h6>
                    <pre>{`1. ตั้งค่าระบบ  ฝาก-ถอน ขั้นต่ำ ที่เมนูตั้งค่า
2. ตั้งค่าไลน์แอดมิน ที่เมนูตั้งค่า -> ตั้งค่าหน้าเว็บ
3. ตั้งค่าทรูวอลเล็ต ที่เมนูตั้งค่า -> ตั้งค่าระบบหลังบ้าน
4. สร้างยูสให้แอดมินคนล่ะยูส (ห้ามใช้ซ้ำกัน) ที่เมนู บันทึก staff -> รายชื่อผู้ใช้งาน

`}</pre>
<h6>การใช้งานพื้นฐาน</h6>
<pre>{`1. ฝาก-ถอน (ผ่านแอดมิน)
เมนูรายชื่อสมาชิก -> รายละเอียด -> ฝาก-ถอน

2. ตั้งโปรโมชั่น/คืนยอดเสีย อื่นๆ
เมนูโปรโมชั่น

3. เปลี่ยนรูปแบบ สี โลโก้ ไอคอน ต่างๆ
เมนูตั้งค่า -> ตั้งค่าหน้าเว็บ

4. ตั้งประกาศหน้าเว็บ
เมนูประกาศ
   
`}</pre>  
<h6>ปัญหาที่พบบ่อยครั้ง</h6>
<pre>{`1. ฝากไม่เข้าระบบ มีหลายสาเหตุ
 - ลูกค้าฝากผิดบัญชี สามารถยืนยีนรายการได้หน้าฝากรออนุมัติ
 - ลูกค้าฝากไม่เข้า หน้ารายการฝากรออนุมัติไม่ขึ้น ให้ตรวจสอบประวัติจากธนาคารหากมีข้อมูลการฝากมาจริงๆจะขึ้นรายการแสดง แต่ช่วงดึก ธนาคารจะล้าช้า 
   ถ้าช้ามากเกินไปให้ฝากให้ลูกค้าก่อนได้ (อย่าลืมตรวจสอบสลิปนะครับ) แล้วมายกเลิกที่หลังที่หน้าฝากรออนุมัติ เมื่อข้อมูลธนาคารเข้าแล้ว 
   ไม่ต้องกังวลยอดไม่เบิ้ล (แต่ต้องเติมเครดิตตรงกับที่ฝากจริงๆนะครับ)
2. ประวัติการเล่นล่าช้า ตรวจสอบยอดเทิร์นช้า ซึ่งเป็นกับระบบ AGENT ทำได้แค่แจ้งเรื่องไม่สามารถแก้ไขได้ทันที หากลูกค้าต้องการถอนแต่ติดโปรฯ ให้ยกเลิกโปรฯแล้วทำรายการปกติ
`}</pre>

<br/>
<h6>การใช้งานหน้าเว็บ</h6>
                    <Row className='text-center'>
                        <Col span={12}>
                            <Image src={`/images/wm-34541927-2.jpg`} width={300}/>
                            <p>วิธีใช้งานสมัครสมาชิก</p>
                        </Col>
                        {/* <Col span={12}>
                            <Image src={`/images/wm-34541927-3.jpg`} width={450}/>
                            <p>วิธีใช้ฝากเงินผ่านธนาคาร</p>
                        </Col> */}
                    </Row>
                </Card>
            </>
        );
    }
}
