import React, { Component } from 'react'
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom'
import Point from './point'
import Bonus from './bonus'
import Wheel from './wheel'
import Daily from './daily'

class point extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: ''
        }
    }

    componentDidMount() {
        if (this.props.user_id) {
            this.setState({ user_id: this.props.user_id })

        } else {
            this.props.history.push("/adminManage/member/member");
        }
    }

    render() {
        return (
            <>
                <Tabs type="card">
                    <Tabs.TabPane tab="เพชร (แต้มสะสม)" key="1">
                        <Point user_id={this.props.user_id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="โบนัส (แต้มสะสม)" key="2">
                        <Bonus user_id={this.state.user_id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="มินิเกม กงล้อ" key="3">
                        <Wheel user_id={this.state.user_id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="เช็คอินรายวัน" key="4">
                        <Daily user_id={this.state.user_id} />
                    </Tabs.TabPane>
                </Tabs>
            </>
        )
    }
}

export default withRouter(point)