import React, { Component } from 'react';
import { PageHeader, Tabs } from 'antd';
import { WEB_NAME } from '../../../../Util/Config';
import SCB from './scb'
import Truewallet from './truewallet'

export default class affiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'ประวัติธุรกรรมทางการเงินจากธนาคาร',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="ประวัติธุรกรรมทางการเงินจากธนาคาร"
                    breadcrumb={{ routes }}
                    // subTitle="This is a subtitle"
                />

                <Tabs type="card">
                    <Tabs.TabPane tab="ประวัติธนาคาร SCB" key="item-1">
                        <SCB />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ประวัติธนาคาร KBank" key="item-2" disabled>
                        {/* <KBANK /> */}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ประวัติ True Wallet" key="item-3">
                        <Truewallet />
                    </Tabs.TabPane>
                </Tabs>;
            </>
        );
    }
}
