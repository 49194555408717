import React, { Component } from 'react';
import { Table, Button, Tag, message, Image } from 'antd';
import { FileSearchOutlined, StarFilled } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';
import { URL, URL_IMAGE, URL_IMAGE1 } from '../../../../Util/Config';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'ลำดับ',
          dataIndex: 'transaction_id',
          render: (text, record) => (
            <>
              {record.row_num}
              <Tag>
                {text}
              </Tag>
            </>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'user_username',
          // ...this.getColumnSearchProps('user_username'),
          render: (text, record) => (
            <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
          )
        },
        {
          title: 'ชื่อ-นามสกุล',
          dataIndex: 'user_name',
          // ...this.getColumnSearchProps('user_name'),
          // sorter: (a, b) => a.user_name.length - b.user_name.length,
          render: (text, record) => (
            record.transaction_status === 'สำเร็จ' ?
              text
              :
              record.transaction_remark
          )
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'transaction_amount',
          align: 'right',
          render: (text, record) => (
            <Tag color='green'>
              {text} บาท
            </Tag>
          )
        },
        {
          title: 'ธนาคารลูกค้า',
          dataIndex: 'bank_abbrev_en',
          align: 'center',
          render: (text, record) => (
            <Image src={`/images/logo_bank/${text}.png`} onerror="this.src='/images/image-not.webp'" width={30} style={{ backgroundColor: 'black' }} />
          )
        },
        {
          title: 'เลขบัญชี',
          dataIndex: 'user_banknumber',
          // ...this.getColumnSearchProps('user_banknumber'),
        },
        {
          title: 'สลิปโอนเงิน',
          dataIndex: 'transaction_note',
          align: 'center',
          render: (text, record) => (
            <Image src={`${URL_IMAGE1}/${text}`} width={30} style={{ backgroundColor: 'black' }} />
          )
        },
        {
          title: 'วัน/เวลา ที่ส่งสลิป',
          dataIndex: 'transaction_datetime',
        },
        {
          title: 'ยืนยันการรายฝาก',
          dataIndex: '',
          width: 280,
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
              <>
                <Button type="primary" style={{ marginRight: 5 }} onClick={() => this.postDepositSubmit(record)} icon={<FileSearchOutlined />}>
                  ยืนยันฝาก
                </Button>
                {/* {record.status_member === 'ไม่เป็นสมาชิก' ?  */}
                <Button danger onClick={() => this.postDepositCancel(record)} icon={<FileSearchOutlined />}>ยกเลิกฝาก</Button>
                {/* : <></>
                        } */}
              </>
          )
        }
      ],
      data: [],
      admin_type: null
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_name: user.user.name }, () => {
      this.getData()
    })
  }

  async getData() {
    await axios.get(URL + "/api/v3/transfer/deposit/wait/slip", {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.setState({ data: data.result })
        }
      })
      .catch(error => {
        console.log(error)
        message.error('การเชื่อมต่อผิดพลาด!!');
      })
  }

  // ยืนยีน
  postDepositSubmit(value) {
    const { user_username, user_name, user_id, transaction_amount, transaction_id, user_userID, transaction_note } = value
    x0p({
      title: 'ยืนยัน!!',
      text: `ฝากเครดิตให้ ${user_username} (${user_name}) \nจำนวน ${transaction_amount} บาท`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          transaction_id: transaction_id,
          user_id: user_id,
        }
        await axios.post(URL + "/api/v2/transfer/deposit/wait/submit", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            // console.log(data)
            if (data.status) {
              this.getData()
              this.messageAlert(user_userID, `ทำรายการฝากเงินสำเร็จ!!\nจำนวน ${transaction_amount} บาท`)
              this.postDelImage(transaction_note)
              x0p('ทำรายการฝากเงินสำเร็จ!!', null, 'ok', false);

            } else {
              x0p('ไม่สามารถทำรายการได้!!', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด deposit/submit!!', null, 'error', false);
          })
      }
    })
  }

  async messageAlert(user_userID, ms) {
    var body = {
      userID: user_userID, // userID Line
      message: ms
    }
    await axios.post(`${URL}/api/v1/line/messages/push`, body)
      .then(res => {
        console.log('ส่งข้อความให้ลูกค้าแล้ว')
      })
      .catch(error => {
        console.log(error);
        alert('การเชื่อมต่อผิดพลาด Post User')
      })
  }

  // ยกเลิก
  postDepositCancel(value) {
    const { transaction_id, transaction_amount, transaction_remark, user_id, transaction_note } = value
    x0p({
      title: 'ยืนยัน!!',
      text: `คุณต้องการ "ยกเลิก" การฝากเครดิตให้สมาชิกใช่มั้ย??`,
      icon: 'info',
      buttons: [
        {
          type: 'cancel',
          text: 'ยกเลิก',
        },
        {
          type: 'info',
          text: 'ยืนยัน',
          showLoading: true
        }
      ]
    }).then(async (alert) => {
      if (alert.button === 'info') {
        const body = {
          transaction_id: transaction_id,
          transaction_note: `(รายการ รอยืนยัน) ${this.state.admin_name} ไม่ยืนยันการฝากจำนวน ${transaction_amount} บาท รายการ ${transaction_remark}`,
          user_id: user_id
        }
        await axios.post(URL + "/api/v2/transfer/deposit/wait/cancel", body, {
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        })
          .then(res => {
            const data = res.data
            if (data.status) {
              this.getData()
              this.setState({ isModalVisible: false })
              this.postDelImage(transaction_note)
              x0p('ทำรายยกเลิกการฝากสำเร็จ!!', null, 'ok', false);
            } else {
              x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
            }
          })
          .catch(error => {
            console.log(error)
            x0p('การเชื่อมต่อผิดพลาด!! deposit/wait/cancel', null, 'error', false);
          })
      }
    })
  }

  // ลบรูปหลัง ยืนยัน ข้อมูล
  async postDelImage(image) {
    const body = { image }
    await axios.post(URL_IMAGE + "/api/slip/delete", body)
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <>
        <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1400 }} rowKey={record => record.row_num} />
      </>
    );
  }
}
