import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  PageHeader,
  Statistic,
  Tag,
  Input,
  Tooltip,
  Button,
  Typography,
} from "antd";
import { URL, WEB_NAME, URL_IMAGE } from "../../../Util/Config";
import DateTime from "node-datetime";
import { CopyOutlined } from "@ant-design/icons";
import { withRouter } from 'react-router'

const { Text } = Typography;
const { Search } = Input;

// const dateFormat = "YYYY/MM/DD";
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30);
// var dateStart = dateOffset30.format("Y/m/d");
// var dateEnd = DateTime.create().format("Y/m/d");

class member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {
        P_id: '',
        P_username: '',
        P_coderefer: '',
      },
      accessToken: '',
      searchText: '',
      loading1: false,
      data: [],
      amountUserAll: 0,
      columns: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          width: 150,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
            title: 'ชื่อผู้ใช้งาน',
            dataIndex: 'title',
        },
        {
          title: "สถานะ",
          dataIndex: "user_status",
          render: (text, record) => (
            text === 'ปกติ' ? 
                <Tag color="green">{text}</Tag>
              :
                <Tag color="red">{text}</Tag>
          )
        },
        {
          title: "วัน/เวลา สมัคร",
          dataIndex: "created_at",
        },
        {
          title: 'ใช้งานล่าสุด',
          dataIndex: 'user_online',
          render: (text, record) => (
            new Date(text).toLocaleString('sv-SE')
          )
        },
        {
          title: "รายละเอียด",
          dataIndex: "",
        },
      ],
      pagination: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('P_user'))
    
    this.setState({ accessToken: user.P_accessToken })
    this.setState({ partner: user.P_user }, () => {
      this.getData()
    })
  }

  async getData(params = {}) {
    
      if (params.pagination === undefined) {
        params = this.state.pagination
      }
      this.setState({ loading1: true })
      var url = `${URL}/api/v1/user/partner/under/${this.state.partner.P_coderefer}/page/${params.pagination.current}`
      if (this.state.searchText !== '') {
        url = url + `/search/${this.state.searchText}`
      }
      // if (this.state.star) {
      //   url = url + `/star/${this.state.star}`
      // }
      fetch(url, {
        headers: {
          'Authorization': `Bearer ${this.state.accessToken}`,
        }
      })
        .then((response) => response.json())
        .then(({ status, result, total }) => {
          if (!status) {
            localStorage.clear();
            this.props.history.push("/login");
          } else {
            this.setState({
              data: result,
              loading1: false,
              amountUserAll: total,
              pagination: {
                pagination: {
                  ...params.pagination,
                  total: total,
                }
              },
            })
          }
        })
        .catch((error) => {
          console.log('fetch data failed', error);
        });
  };

  onSearch() {
    this.getData({
      pagination: {
        current: 1,
        pageSize: 20,
      }
    })
  }

  handleTableChange(newPagination, filters, sorter) {
    this.getData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: "รายชื่อสมาชิก",
      },
    ];

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายชื่อสมาชิก"
          breadcrumb={{ routes }}
        />
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Commission"
                value={this.state.partner.P_percent}
                valueStyle={{ color: "#227BFE" }}
                suffix="%"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="สมาชิกทั้งหมด"
                value={this.state.amountUserAll}
                valueStyle={{ color: "#3f8600" }}
                suffix="คน"
              />
            </Card>
          </Col>

          {/* <Col span={6}>
            <Card>
              <Statistic
                title="สมาชิกใหม่ 30 ย้อนหลัง"
                value={this.state.amountRegister30DayAgo}
                valueStyle={{ color: "#cf1322" }}
                suffix="คน"
              />
            </Card>
          </Col>

          <Col span={6}>
            <Card>
              <Statistic
                title="สมาชิกใหม่วันนี้"
                value={this.state.amountRegisterToday}
                valueStyle={{ color: "#cf1322" }}
                suffix="คน"
              />
            </Card>
          </Col> */}
        </Row>

        <br />

        <Card>
          ลิ้งแนะนำ
          <Input.Group size="large" compact style={{ marginTop: 10 }}>
            <Input
              style={{ width: "calc(100% - 40px)" }}
              value={`${URL_IMAGE}/register/general?&coderefer=${this.state.partner.P_coderefer}`}
              disabled
            />
            <Tooltip title="Copy URL">
              <Button
                icon={<CopyOutlined />}
                size="large"
                onClick={() =>
                  navigator.clipboard.writeText(`${URL_IMAGE}/register/general?&coderefer=${this.state.partner.P_coderefer}`)
                }
              />
            </Tooltip>
          </Input.Group>
          <br />
          <br />

          <Row justify="space-between">
            <Col>
            </Col>
            <Col>
              <Search placeholder="ค้นหาข้อมูล ชื่อ, ยูส, เบอร์ติดต่อ, เลขบัญชี" value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} style={{ width: 400 }} onSearch={() => this.onSearch()} />
            </Col>
          </Row>
          <br />

          <Table
            columns={this.state.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination.pagination}
            scroll={{ x: 1000 }}
            loading={this.state.loading1}
            rowKey={(record) => record.row_num}
            onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
          />
        </Card>
      </>
    );
  }
}

export default withRouter(member);