import React, { Component } from 'react'
import { Row, Col, Alert, Space, Button } from 'antd';
import { URL } from '../../../Util/Config';
import { Link } from "react-router-dom";

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amountWithdrawError: '',
            amountDepositError: '',
            admin: {
                rule: ''
            }
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin: user.user })
        if (this.state.admin.rule.includes('1')) {
            this.getAmountWithdrawError()
        }
        if (this.state.admin.rule.includes('2')) {
            this.getAmountDepositError()
        }
    }

    getAmountWithdrawError() {
        fetch(`${URL}/api/v1/transaction/error/withdraw/amount`)
            .then((response) => response.json())
            .then((json) => this.setState({ amountWithdrawError: json.result }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    getAmountDepositError() {
        fetch(`${URL}/api/v1/transaction/error/deposit/amount`)
            .then((response) => response.json())
            .then((json) => this.setState({ amountDepositError: json.result }))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    }

    render() {
        return (
            <>
                <Row gutter={[16, 16]}>
                    {this.state.admin.rule.includes('1') ?
                        <Col flex="1 1 250px">
                            {this.state.amountWithdrawError > 0 ?
                                <Alert
                                    message="รายการแจ้งการถอนเครดิต"
                                    description={`มีรายการแจ้งถอนเครดิตจำนวน ${this.state.amountWithdrawError} รายการ`}
                                    type="warning"
                                    showIcon
                                    action={
                                        <Space>
                                            <Link to={`/adminManage/error/withdraw`}>
                                                <Button type="ghost" danger>
                                                    รายการ
                                                </Button>
                                            </Link>
                                        </Space>
                                    }
                                />
                                : <></>
                            }
                        </Col>
                        : <></>
                    }
                    {this.state.admin.rule.includes('2') ?
                        <Col flex="1 1 250px">
                            {this.state.amountDepositError > 0 ?
                                <Alert
                                    message="รายการแจ้งฝากผิดพลาด"
                                    description={`มีรายการแจ้งฝากผิดพลาดจำนวน ${this.state.amountDepositError} รายการ`}
                                    type="warning"
                                    showIcon
                                    action={
                                        <Space>
                                            <Link to={`/adminManage/error/deposit`}>
                                                <Button type="ghost" danger>
                                                    รายการ
                                                </Button>
                                            </Link>
                                        </Space>
                                    }
                                />
                                : <></>
                            }
                        </Col>
                        : <></>
                    }
                </Row>
            </>
        )
    }
}
