import React, { Component } from 'react';
import { PageHeader, Tabs, Row, Col, Card, Statistic, DatePicker } from 'antd';
import { URL, WEB_NAME } from '../../../../Util/Config';
import History from './history'
import Promotion from './discount'
import Affiliate from './affiliate'
import DateTime from 'node-datetime';
import moment from 'moment';
import axios from 'axios';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD HH:mm:ss';
var dateStart = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 00:00:00`).format('Y/m/d H:M:S');
var dateEnd = DateTime.create(`${new Date().toLocaleDateString('sv-SE')} 23:59:59`).format('Y/m/d H:M:S');

export default class affiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary: {}
        }
    }

    componentDidMount() {
        this.getData(dateStart, dateEnd)
    }

    async getData(start, end) {    
        var body = {
            start: start,
            end: end
        }
        await axios.post(`${URL}/api/v1/discount/summary`, body)
        .then(res => {
            var data = res.data
            if (data.status) {
                this.setState({ summary: data.result })
            } else console.log(data)
        })
        .catch((error) => {
            console.log('fetch data failed', error);
        });
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'โปรโมชั่น',
            },
            {
                breadcrumbName: 'รายการโปรโมชั่น',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="รายการโปรโมชั่น"
                    breadcrumb={{ routes }}
                />

                <RangePicker
                    format={dateFormat}
                    onChange={(e) => this.getData(new Date(e[0]._d).toLocaleString('sv-SE'), new Date(e[1]._d).toLocaleString('sv-SE'))}
                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                    showTime={{ hideDisabledOptions: true }}
                />
                <br/>
                <br/>
                <Row gutter={[16, 16]}>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="แจกโปร"
                                value={this.state.summary.total_amount}
                                valueStyle={{ color: '#1990FF' }}
                                suffix="ครั้ง"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดฝากแรก"
                                value={this.state.summary.total_deposit}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="แจกเครดิตโปรฯ"
                                value={this.state.summary.total_bonus}
                                valueStyle={{ color: '#EF9900' }}
                                suffix="เครดิต"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="ยอดถอนโปรฯ"
                                value={this.state.summary.total_withdraw}
                                valueStyle={{ color: '#CF1321' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                    <Col flex="1 1 250px">
                        <Card>
                            <Statistic
                                title="กำไรโปรฯ"
                                value={this.state.summary.total_income}
                                valueStyle={{ color: this.state.summary.total_income > 0 ? 'green' : 'red' }}
                                suffix="บาท"
                            />
                        </Card>
                    </Col>
                </Row>
                <br />

                <Tabs type="card">
                    <Tabs.TabPane tab="รายการโปรโมชั่น" key="item-1">
                        <Promotion />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ประวัติการรับโปรโมชั่น" key="item-2">
                        <History />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="แนะนำเพื่อนรับโปร" key="item-3">
                        <Affiliate />
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    }
}
