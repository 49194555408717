import React, { Component } from 'react';
import { Table, Card, Tag, Alert, message } from 'antd';
import { URL } from '../../../../Util/Config';
import axios from 'axios';

export default class bank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'วัน/เวลา โอนเงิน',
                    dataIndex: 'date_time',
                    // render: (text, record) => (
                    //     <p>{formateDateTime2digit(text)}</p>
                    // )
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'amount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag>{text}</Tag>
                    )
                },
                {
                    title: 'ประเภท',
                    dataIndex: 'type',
                },
                {
                    title: 'ข้อสังเกต',
                    dataIndex: 'original_action',
                    render: (text, record) => (
                        <>{record.transaction_reference_id} {record.sub_title ? `(${record.sub_title})`: '' } {record.original_type}, {text}</>
                    )
                }
            ],
            data: [],
            pageNumber: 1
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken }, () => {
            this.getData()
        })
    }


    async getData() {
        await axios.get(URL + "/api/v1/truewallet/transaction", {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then(res => {
                var data = res.data
                if (data.status) {
                    this.setState({ data: data.result.activities })
                } else {
                    message.error(data.message)
                }
            })
            .catch(error => {
                console.log(error)
                message.error('เกิดข้อผิดพลาด!!')
            })
    }

    render() {
        return (
            <>
                <Card>
                    <Alert
                        message="แจ้งเตือน"
                        description="ข้อมูลจากธนาคารโดยตรงอ้างอิงจากแอพ True Money Wallet ดึงได้สูงสุด 20 รายการ"
                        type="warning"
                        showIcon
                    />
                    <br />
                    <Table columns={this.state.columns} dataSource={this.state.data}  pagination={{ pageSize: 20 }} scroll={{ x: 1000 }} rowKey={record => record.date_time} />
                </Card>
            </>
        );
    }
}
