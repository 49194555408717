import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Card, message, Tabs } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { URL, WEB_NAME } from "../../Util/Config";
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipt_username: '',
      ipt_password: '',
      reCaptcha: '',
      captcha: null,
    };
  }

  componentDidMount(){
    this.getIPAddress();
    this.checkLogin();
  }

  async getIPAddress(){
    await axios.get(`https://api.ipify.org/?format=json`)   
        .then(res => {
            // console.log(res.data.ip)
            localStorage.setItem('ip', res.data.ip)
        })
  }

  async checkLogin(){
    if (localStorage.getItem('user')) {
      this.props.history.push("/adminManage");
    } else if  (localStorage.getItem('P_user')) {
      this.props.history.push("/partner");
    } else {
      localStorage.clear();
    }
  }

  login() {
    if (!this.state.reCaptcha) return message.error('กรุณายืนยันตัวตนว่าไม่ใช่บอท');
     
    var config = {
        method: 'post',
        url: `${URL}/api/v1/admin/login`,
        headers: { 
            'Content-Type': 'application/json',
        },
        data : JSON.stringify({ 
          "username": this.state.ipt_username, 
          "password": this.state.ipt_password, 
          "reCaptcha": this.state.reCaptcha,
          "IPAddress": localStorage.getItem('ip')
        })
    };

    axios(config)
      .then(res => {
        const data = res.data
        if (!data.status) {
            message.error(data.message);
            this.resetCaptcha()
        } else {
            if (data.G2FA) {
              localStorage.setItem('g2fa', data.result[0].admin_id)
              this.props.history.push("/humanverify");
            } else {
              message.success(data.message);
              localStorage.setItem('user', JSON.stringify({ accessToken: data.accessToken, user: { id: data.result[0].admin_id,  username: data.result[0].admin_username, name: data.result[0].admin_fullname, type: data.result[0].admin_type, rule: data.result[0].admin_rule } }))
              this.props.history.push("/adminManage/");
            }
        }
    })
    .catch(err => {
        console.log(err)
        message.error("การเชื่อมต่อผิดพลาด!!");
        this.resetCaptcha()
    })
  }

  loginPartner() {
    if (!this.state.reCaptcha) return message.error('กรุณายืนยันตัวตนว่าไม่ใช่บอท');
     
    var config = {
        method: 'post',
        url: `${URL}/api/v1/user/partner/login`,
        headers: { 
            'Content-Type': 'application/json',
        },
        data : JSON.stringify({ 
          "username": this.state.ipt_partner_username, 
          "password": this.state.ipt_partner_password, 
          "reCaptcha": this.state.reCaptcha,
          "IPAddress": localStorage.getItem('ip')
        })
    };

    axios(config)
      .then(res => {
        const data = res.data
        console.log(data)
        if (!data.status) {
          message.error(data.message);
          this.resetCaptcha()
        } else {
          console.log('successs')
          message.success(data.message);
          localStorage.setItem('P_user', JSON.stringify({ P_accessToken: data.accessToken, P_user: { id: data.result.user_id,  P_username: data.result.user_username, P_name: data.result.user_name, P_coderefer: data.result.user_coderefer, P_percent: data.result.user_percent } }))
          this.props.history.push("/partner");
        }
    })
    .catch(err => {
        console.log(err)
        message.error("การเชื่อมต่อผิดพลาด!!");
        this.resetCaptcha()
    })
  }

  setCaptchaRef = (ref) => {
    if (ref) {
      return this.state.captcha = ref;
    }
  };

  resetCaptcha = () => {
    // maybe set it till after is submitted
    this.state.captcha.reset();
  }

  render() {
    return (
      <>
        <br />
        <Tabs
          defaultActiveKey="1"
          // onChange={onChange}
          style={{ color: "white" }}
          items={[
            {
              label: `Manager`,
              key: '1',
              children: (
                <Row justify="center">
                  <Col flex="500px" className='text-center'>
                    <Card>
                        <div className='text-center'>
                          <h1>{WEB_NAME}</h1>
                          <br />
                          <h5>SIGN IN</h5>
                        </div>
                        <Form 
                          layout={'vertical'}
                          onSubmitCapture={this.login.bind(this)}>
                            <Form.Item
                                label="USERNAME : "
                                name="username"
                                rules={[{ required: true, message: 'กรุณากรอก username!' }]}
                            >
                                <Input size="large" prefix={<UserOutlined />} onChange={(e) => this.setState({ ipt_username: e.target.value })} autoFocus placeholder='Enter username'/>
                            </Form.Item>

                            <Form.Item
                                label="PASSWORD : "
                                name="password"
                                rules={[{ required: true, message: 'กรุณากรอก password!' }]}
                            >
                                <Input.Password htmlType="passwrod" size="large" prefix={<LockOutlined />} onChange={(e) => this.setState({ ipt_password: e.target.value })} placeholder='Enter password'/>
                            </Form.Item>
                            
                            <ReCAPTCHA
                              ref={(r) => this.setCaptchaRef(r) }
                              sitekey="6LeutskeAAAAALyr1OxzpjHCXHxfAjPVPCvjyhKy"
                              onChange={(e) => this.setState({ reCaptcha: e })}
                            />
                            <br/>

                            <Form.Item >
                                <Button type="primary" htmlType="submit" block shape="round" size="large">Log In</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <br/>
                    <a href="https://t.me/fxike" className='text-secondary font-weight-lighter'>Create by AutoGaming.me</a>
                  </Col>
                </Row>
              ),
            },
            {
              label: `Partner`,
              key: '2',
              children: (
                <Row justify="center">
                  <Col flex="500px" className='text-center'>
                    <Card>
                        <div className='text-center'>
                          <h1>{WEB_NAME}</h1>
                          <br />
                          <h5>SIGN IN (Partner)</h5>
                        </div>
                        <Form 
                          layout={'vertical'}
                          onSubmitCapture={this.loginPartner.bind(this)}>
                            <Form.Item
                                label="USERNAME : "
                                name="username"
                                rules={[{ required: true, message: 'กรุณากรอก username!' }]}
                            >
                                <Input size="large" prefix={<UserOutlined />} onChange={(e) => this.setState({ ipt_partner_username: e.target.value })} autoFocus placeholder='Enter username'/>
                            </Form.Item>

                            <Form.Item
                                label="PASSWORD : "
                                name="password"
                                rules={[{ required: true, message: 'กรุณากรอก password!' }]}
                            >
                                <Input.Password htmlType="passwrod" size="large" prefix={<LockOutlined />} onChange={(e) => this.setState({ ipt_partner_password: e.target.value })} placeholder='Enter password'/>
                            </Form.Item>
                            
                            <ReCAPTCHA
                              ref={(r) => this.setCaptchaRef(r) }
                              sitekey="6LeutskeAAAAALyr1OxzpjHCXHxfAjPVPCvjyhKy"
                              onChange={(e) => this.setState({ reCaptcha: e })}
                            />
                            <br/>

                            <Form.Item >
                                <Button type="primary" htmlType="submit" block shape="round" size="large">Log In</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <br/>
                    <a href="https://t.me/fxike" className='text-secondary font-weight-lighter'>Create by AutoGaming.me</a>
                  </Col>
                </Row>
              ),
            }
          ]}
        />
        
      </>
    );
  }
}

export default withRouter(login)