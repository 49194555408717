import React, { Component } from 'react';
import { PageHeader, Tabs } from 'antd';
import { WEB_NAME } from '../../../../Util/Config'
import Ranking from '../../dashboard/ranking'
import Created from './created'

export default class affiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'รายการสมาชิกทั้งหมด',
            },
            {
                breadcrumbName: 'สถิติข้อมูลสามาชิก',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title="สถิติข้อมูลสมาชิก"
                    breadcrumb={{ routes }}
                />

                <Tabs type="card">
                    <Tabs.TabPane tab="ตารางแรงค์กิ้ง" key="item-1">
                        <Ranking />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="สถิติสมาชิกตามวันที่สมัคร" key="item-2">
                        <Created />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="สถิติการเล่นเกม" key="item-4" disabled>
                        ค่ายเกมที่มีการเข้าบ่อยที่สุด, เกมที่มีการเข้าบ่อยที่สุด
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    }
}
