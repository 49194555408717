// ค้นหาตามวันที่สมัครสมาชิก
import React, { Component } from 'react';
import { Table, Tag, Typography, DatePicker, Input, Space, Button, Card, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, StarFilled } from '@ant-design/icons';
import { URL } from '../../../../Util/Config'
import DateTime from 'node-datetime';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30)
// var dateStart = dateOffset30.format('Y/m/d');
// var dateEnd = DateTime.create().format('Y/m/d');

export default class affiliate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'row_num',
                    sorter: (a, b) => a.row_num - b.row_num,
                    render: (text, record) => (
                        <div>{text} <Text code><small>{record.user_id}</small></Text></div>
                    )
                },
                {
                    title: 'ผู้ใช้งาน',
                    dataIndex: 'title',
                    ...this.getColumnSearchProps('title'),
                    render: (text, record) => (
                        <p>{text} {record.user_tag === '1' ? <StarFilled style={{ color: 'orange' }} /> : ''}</p>
                    )
                },
                {
                    title: 'ยอดฝากรวม',
                    dataIndex: 'deposit',
                    align: 'right',
                    sorter: (a, b) => a.deposit - b.deposit,
                    render: (text, record) => (
                        <>
                            <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
                        </>
                    )
                },
                {
                    title: 'ยอดถอนรวม',
                    dataIndex: 'withdraw',
                    align: 'right',
                    sorter: (a, b) => a.withdraw - b.withdraw,
                    render: (text, record) => (
                        <>
                            <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
                        </>
                    )
                },
                {
                    title: 'ได้กำไรจากการเล่น',
                    dataIndex: 'income',
                    align: 'right',
                    sorter: (a, b) => a.income - b.income,
                    render: (text, record) => (
                        <>
                            {text > 0 ?
                                <Tag color='green'>{text ? text.toLocaleString() : 0} บาท</Tag>
                                :
                                <Tag color='red'>{text ? text.toLocaleString() : 0} บาท</Tag>
                            }
                        </>
                    )
                },
                {
                    title: 'จำนวนวัน ทำธุรกรรมล่าสุด',
                    dataIndex: 'last_day',
                    align: 'right',
                    sorter: (a, b) => a.last_day - b.last_day,
                    render: (text, record) => (
                        <>
                            {text} วัน
                        </>
                    )
                }
            ],
        }
    }

    componentDidMount() {
        this.getData(undefined, undefined)
    }

    getData(start, end) {
        var url = `${URL}/api/v1/user/statistics`
        if (start && end) {
            var date = new Date(start).toLocaleDateString('sv-SE')
            const startDate = new Date(`${date} 00:00:00`).toLocaleDateString('sv-SE')
            date = new Date(end).toLocaleDateString('sv-SE')
            const endDate = new Date(`${date} 23:59:59`).toLocaleDateString('sv-SE')

            url = url + `/start/${startDate}/end/${endDate}`
        }
        fetch(url)
            .then((response) => response.json())
            .then((json) => this.setState({ data: json.result }))
            // .then(() => console.log(this.state.detailChart))
            .catch((error) => {
                console.log('getData', error);
            });
    }

    // ------------------------------------------------------------------ START FILTER ----------------------------------------------------------- 
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    // ------------------------------------------------------------------ END FILTER ----------------------------------------------------------- 

    render() {
        return (
            <>
                <Card>
                    <Form layout="inline">
                        <Form.Item label="ค้นหาตามวันที่สมัคร" style={{ width: '50%' }}>
                            <RangePicker
                                style={{ width: '100%' }}
                                // defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                format={dateFormat}
                                onChange={(e) => this.getData(e[0]._d, e[1]._d)} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => this.getData(undefined, undefined)}>
                                ค้นหาทั้งหมด
                            </Button>
                        </Form.Item>
                    </Form>
                    <br />
                    <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 10 }} scroll={{ x: 1000 }} rowKey={record => record.row_num} />
                </Card>

            </>
        );
    }
}
