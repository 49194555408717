import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  PageHeader,
  Statistic,
  DatePicker,
  Tag,
  Input,
  Tooltip,
  Button,
  message,
  Typography,
} from "antd";
import { URL, WEB_NAME, URL_IMAGE } from "../../../Util/Config";
import DateTime from "node-datetime";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30);
var dateStart = dateOffset30.format("Y/m/d");
var dateEnd = DateTime.create().format("Y/m/d");

export default class summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {
        P_id: '',
        P_username: '',
        P_coderefer: '',
      },
      sum: {
        deposit: 0,
        withdraw: 0,
        income: 0,
      },
      percent: 0,
      pagination: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
      data: [],
      columns: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          width: 120,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
            title: 'ชื่อผู้ใช้งาน',
            dataIndex: 'title',
            //   ...this.getColumnSearchProps("title"),
            //   render: (text, record) => <p></p>,
        },
        {
          title: "ยอดฝากรวม",
          dataIndex: "deposit",
          align: "right",
          render: (text, record) => (
            <>
              <Tag color="green">{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          ),
        },
        {
          title: "ยอดถอนรวม",
          dataIndex: "withdraw",
          align: "right",
          render: (text, record) => (
            <>
              <Tag color="red">{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          ),
        },
        {
          title: "กำไรรวม",
          dataIndex: "income",
          align: "right",
          render: (text, record) => (
            <>
               {text > 0 ? (
                <Tag color="green">{text ? text.toLocaleString() : 0} บาท</Tag>
              ) : (
                <Tag color="red">{text ? text.toLocaleString() : 0} บาท</Tag>
              )}
            </>
          ),
        },
        {
          title: `หุ้นส่วน`,
          dataIndex: "income",
          align: "right",
          props: {
            style: { background: "#FAECC6" },
          },
          render: (text, record) => (
            <>
              {((parseFloat(text) * this.state.percent) / 100) > 0 ? (
                <Tag color="green">{((parseFloat(text) * this.state.percent) / 100).toLocaleString()} บาท</Tag>
              ) : (
                <Tag color="red">{((parseFloat(text) * this.state.percent) / 100).toLocaleString()} บาท</Tag>
              )}
            </>
          ),
        },
        {
          title: "บริษัท",
          dataIndex: "income",
          align: "right",
          render: (text, record) => (
            <>
              {parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100) > 0 ? (
                <Tag color="green">{(parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100)).toLocaleString()} บาท</Tag>
              ) : (
                <Tag color="red">{(parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100)).toLocaleString()} บาท</Tag>
              )}
            </>
          ),
        },
      ],
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('P_user'))
    this.setState({ partner: user.P_user })
    this.setState({ percent: user.P_user.P_percent }, () => this.setDate(dateStart, dateEnd) )
  }

  setDate(startDate, endDate, params = {}) {
    dateStart = startDate;
    dateEnd = endDate;

    var dt = DateTime.create(startDate);
    startDate = dt.format("Y-m-d");
    dt = DateTime.create(endDate);
    endDate = dt.format("Y-m-d");

    this.postWinlose(startDate, endDate, params);
  }

  async postWinlose(start, end, params = {}) {
    if (params.pagination === undefined) {
      params = this.state.pagination
    } 
    this.setState({ loading1: true })

    let url = URL + `/api/v1/user/partner/winlose/userid/${this.state.partner.id}/page/${params.pagination.current}/start/${start}/end/${end}`
    let body = {
      type: 'partner'
    }
    await axios.post(url, body)
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ data: data.result });
          this.setState({ sum: data.sum })
          this.setState({
            loading1: false,
            pagination: {
              pagination: {
                ...params.pagination,
                total: data.sum.user_total,
              }
            },
          })
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("การเชื่อมต่อผิดพลาด partner winlose");
      });
  }

  handleTableChange(newPagination, filters, sorter) {
    this.setDate(dateStart, dateEnd, {
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: "ได้เสีย",
      },
    ];

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="ได้เสีย"
          breadcrumb={{ routes }}
        />
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Commission"
                value={this.state.percent}
                valueStyle={{ color: "#227BFE" }}
                suffix="%"
              />
            </Card>
          </Col>
        </Row>

        <br />

        <Card>
          ลิ้งแนะนำ
          <Input.Group size="large" compact style={{ marginTop: 10 }}>
            <Input
              style={{ width: "calc(100% - 40px)" }}
              value={`${URL_IMAGE}/register/general?&coderefer=${this.state.partner.P_coderefer}`}
              disabled
            />
            <Tooltip title="Copy URL">
              <Button
                icon={<CopyOutlined />}
                size="large"
                onClick={() =>
                  navigator.clipboard.writeText(`${URL_IMAGE}/register/general?&coderefer=${this.state.partner.P_coderefer}`)
                }
              />
            </Tooltip>
          </Input.Group>
          <br />
          <br />
          <RangePicker
            style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
            defaultValue={[
              moment(dateStart, dateFormat),
              moment(dateEnd, dateFormat),
            ]}
            format={dateFormat}
            onChange={(e) => this.setDate(e[0]._d, e[1]._d)}
          />

          <Table
            columns={this.state.columns}
            dataSource={this.state.data}
            pagination={this.state.pagination.pagination}
            scroll={{ x: 1000 }}
            loading={this.state.loading1}
            rowKey={(record) => record.row_num}
            onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    ทั้งหมด:
                  </Table.Summary.Cell>

                  {/* ฝาก */}
                  <Table.Summary.Cell index={1}>
                    {parseFloat(this.state.sum.deposit, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* ถอน */}
                  <Table.Summary.Cell index={2}>
                    {parseFloat(this.state.sum.withdraw, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* กำไร */}
                  <Table.Summary.Cell index={3}>
                    {parseFloat(this.state.sum.income, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* หุ้นส่วน */}
                  <Table.Summary.Cell index={4}>
                    {((parseFloat(this.state.sum.income) * this.state.percent) / 100).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* บริษัท */}
                  <Table.Summary.Cell index={5}>
                  {(parseFloat(this.state.sum.income) - ((parseFloat(this.state.sum.income) * this.state.percent) / 100)).toLocaleString()}
                  </Table.Summary.Cell>

                  
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </Card>
      </>
    );
  }
}
