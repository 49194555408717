import React, { Component } from "react";
import DateTime from "node-datetime";
import { withRouter } from 'react-router'

import Summary from './summary';

// const dateFormat = "YYYY/MM/DD";
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30);
// var dateStart = dateOffset30.format("Y/m/d");
// var dateEnd = DateTime.create().format("Y/m/d");

class member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {
        id: '',
        user_coderefer: ''
      }
    };
  }

  async componentDidMount() {
    // const user = JSON.parse(localStorage.getItem('P_user'))
    
    // this.setState({ accessToken: user.P_accessToken })
    // this.setState({ partner: user.P_user }, () => {
    // //   this.getData()
    // })
  }

  render() {
    return (
      <>
        <Summary coderefer={this.props.coderefer} />
        <div style={{ marginTop: 50 }}></div>
      </>
    );
  }
}

export default withRouter(member);