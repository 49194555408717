// ประกาศ

import React, { Component } from 'react';
import axios from 'axios';
import { Card, PageHeader, Form, Input, Button, Table, Typography, Row, Col, Modal, Image, Divider, Tooltip, Select, Upload, Space, Tag, Radio } from 'antd';
import { DeleteOutlined, PlusOutlined, SaveOutlined, LinkOutlined, CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { URL, URL_IMAGE, WEB_NAME } from "../../Util/Config";
import x0p from 'x0popup';

const { Title } = Typography;
const { Option } = Select;

export default class promotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_channel: [],
      columns_channel: [
        {
          title: 'ลำดับ',
          dataIndex: 'row_num',
          render: (text, record) => (
            text
          )
        },
        {
          title: 'ช่องทาง',
          dataIndex: 'channel_name',
          render: (text, record) => (
            text
          )
        },
        {
          title: '',
          dataIndex: 'channel_id',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
            <Button danger type="link" onClick={() => this.removeChannel(text)}><DeleteOutlined /> ลบ</Button>
          ),
        }
      ],
      columns_announce_notify: [
        {
          title: 'รูป',
          dataIndex: 'notice_detail',
          render: (text, record) => (
            <Image src={text.includes('http') ? text : URL + '/public/images/notice/' + text } width={50} />
          )
        },
        {
          title: 'ลิ้งค์',
          dataIndex: 'notice_link',
          render: (text, record) => (
            text
          )
        },
        {
          title: 'หน้าที่แสดง',
          dataIndex: 'notice_type',
          render: (text, record) => {
            if (text == '2') {
              return (<Tag>'หน้าหลัก'</Tag>);
            } else if (text == '6') {
              return (<Tag>'หน้าล็อกอิน'</Tag>)
            } else if (text == '7') {
              return (<Tag>'หน้าฝาก-ถอน'</Tag>)
            } else if (text == '8') {
              return (<Tag>'หน้าโปรโมชั่น'</Tag>)
            } else {
              return (<></>)
            }
          }
        },
        {
          title: '',
          dataIndex: 'notice_id',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
            <Button danger type="link" onClick={() => this.removeAnnounc(text)}><DeleteOutlined /> ลบ</Button>
          ),
        }
      ],
      isModalAddChannelVisible: false,
      isModalGenerateChannelVisible: false,
      ipt_channel: '',
      sl_channel: '',
      sl_register: 'general',
      data_announce_notify: [],
      columns_announce_advert: [
        {
          title: 'รูป',
          dataIndex: 'notice_detail',
          render: (text, record) => (
            <Image src={text.includes('http') ? text : URL + '/public/images/notice/' + text } width={50} />
          )
        },
        {
          title: 'ลิ้งค์',
          dataIndex: 'notice_link',
          render: (text, record) => (
            text
          )
        },
        {
          title: '',
          dataIndex: 'notice_id',
          render: (text, record) => (
            this.state.admin_type === 'G' ? null :
            <Button danger type="link" onClick={() => this.removeAnnounc(text)}><DeleteOutlined /> ลบ</Button>
          ),
        }
      ],
      data_announce_advert: [],
      formAdd: {
        detail: '',
        link: '',
        type: ''
      },
      formEdit: {
        id: '',
        detail: '',
        link: ''
      },
      announce_header: '',
      movie: {
        movie_icon: '',
        movie_link: ''
      },
      admin_type: null
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

    this.getDataChannel()
    this.getDataNotice()
  }

  async getDataChannel() {
    fetch(`${URL}/api/v1/channel/all`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({ data_channel: json.result })
      })
      .then((json) => console.log())
      .catch((error) => {
        console.log('getDataChannel', error);
      });
  }

  async addChanel(name) {
    const body = {
      channel_name: name
    }
    await axios.post(URL + "/api/v1/channel/add", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.getDataChannel()
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
        } else {
          x0p('', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
      })
  }

  async removeChannel(id) {
    fetch(`${URL}/api/v1/channel/remove/${id}`, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then((res) => {
        var data = res.data
        if (res.status) {
          this.getDataChannel()
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);

        } else {
          x0p('', data.message, 'error', false);
        }
      })
      .catch((error) => {
        console.log(error);
        x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
      });
  }

  async getDataNotice() {
    fetch(`${URL}/api/v1/notice/`)
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          announce_header: json.result.header.notice_detail,
          movie: { movie_icon: json.result.movie.notice_detail, movie_link: json.result.movie.notice_link },
          data_announce_notify: json.result.notify,
          data_announce_advert: json.result.advert
        })
      })
      .then((json) => console.log())
      .catch((error) => {
        console.log('getDataNotice', error);
      });
  }

  async addAnnounc(detail, link, type) {
    const formData = new FormData();
    formData.append('image', detail);
    formData.append('link', link);
    formData.append('type', type);

    const res = await fetch(`${URL}/api/v1/notice/add`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
    }).then((res) => res.json());

    if (res.status) {
      this.getDataNotice()
      this.setState({ isModalAddVisible: false })
      x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
    } else {
      x0p('', res.message, 'error', false);
    }
  }

  async removeAnnounc(id) {
    fetch(`${URL}/api/v1/notice/remove/${id}`, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then((response) => response.json())
      .then((res) => {
        var data = res.data
        if (res.status) {
          this.setState({ isModalAddVisible: false })
          this.getDataNotice()
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);

        } else {
          x0p('', data.message, 'error', false);
        }
      })
      .catch((error) => {
        console.log(error);
        x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
      });
  }

  async editAnnouce(id, detail, link) {
    const body = {
      id: id,
      detail: detail,
      link: link,
    }
    await axios.post(URL + "/api/v1/notice/update", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.getDataNotice()
          this.setState({ isModalAddVisible: false })
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
        } else {
          x0p('', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
      })
  }

  async editAnnouceText(detail) {
    const body = {
      detail: detail,
    }
    await axios.post(URL + "/api/v1/notice/update/text", body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          this.getDataNotice()
          x0p('ทำรายการสำเร็จ!!', null, 'ok', false);
        } else {
          x0p('', data.message, 'error', false);
        }
      })
      .catch(error => {
        console.log(error)
        x0p('การเชื่อมต่อผิดพลาด', null, 'error', false);
      })
  }

  setChannelList() {
    let list = this.state.data_channel.map((item, index) =>
      <Option value={item.channel_name}>{item.channel_name}</Option>
    );
    return (list)
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: 'ประกาศหน้าเว็บ และช่องทางสมัคร',
      },
    ]

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="ประกาศหน้าเว็บ และช่องทางสมัคร"
          breadcrumb={{ routes }}
        />

        <Divider orientation="left"><Title level={4}>ช่องทางการสมัคร</Title></Divider>
        <Card>
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Button type="primary" icon={<LinkOutlined />} onClick={() => this.setState({ isModalGenerateChannelVisible: true })} disabled={this.state.admin_type === 'G' ? true : false}>สร้างลิ้งเพื่อแชร์เว็บ</Button>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ isModalAddChannelVisible: true })} disabled={this.state.admin_type === 'G' ? true : false}>เพิ่มช่องทาง</Button>
            </Col>
          </Row>
          <Table columns={this.state.columns_channel} dataSource={this.state.data_channel} size="small" />
        </Card>
        <br />
        <br />

        <Divider orientation="left"><Title level={4}>ประกาศหน้าเว็บ</Title></Divider>
        <Card>
          <Form.Item
            label="ข้อความ ประกาศ"
          >
            <Input.Group compact>
              <Input style={{ width: 'calc(100% - 200px)' }} value={this.state.announce_header} onChange={(e) => this.setState({ announce_header: e.target.value })} />
              <Button type="primary" onClick={() => this.editAnnouceText(this.state.announce_header)} disabled={this.state.admin_type === 'G' ? true : false}><SaveOutlined /> บันทึก</Button>
            </Input.Group>
          </Form.Item>
          
          {/* <Form.Item label="เว็บหนัง">
            <Form layout='vertical'>
              <Input.Group compact>
                <Form.Item label="ลิ้งเว็บ" extra="หากไม่ต้องการแสดงหน้าเว็บให้ใส่ค่าว่าง" style={{ width: 'calc(50% - 100px)', marginLeft: 10, marginRight: 10 }}>
                  <Input placeholder="" value={this.state.movie.movie_link} onChange={(e) => this.setState({ movie: { ...this.state.movie, movie_link: e.target.value } })} />
                </Form.Item>
                <Button type="primary" onClick={() => this.editAnnouce(2, this.state.movie.movie_icon, this.state.movie.movie_link)} disabled={this.state.admin_type === 'G' ? true : false}><SaveOutlined /> บันทึก</Button>
              </Input.Group>
            </Form>
          </Form.Item> */}

          <br />
          <br />
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Title level={5}>ประกาศแจ้งเตือน (ขนาดที่แนะนำ 500x500, 800x800, 1040x1040)</Title>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ isModalAddVisible: true, formAdd: { ...this.state.formAdd, type: 2 } })} disabled={this.state.admin_type === 'G' ? true : false}>เพิ่ม</Button>
            </Col>
          </Row>
          <Table columns={this.state.columns_announce_notify} dataSource={this.state.data_announce_notify} />

          <br />
          <br />
          <br />
          <Row style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Title level={5}>ประกาศแบนเนอร์ (ขนาดที่แนะนำ 800x300)</Title>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => this.setState({ isModalAddVisible: true, formAdd: { ...this.state.formAdd, type: 3 } })} disabled={this.state.admin_type === 'G' ? true : false}>เพิ่ม</Button>
            </Col>
          </Row>
          <Table columns={this.state.columns_announce_advert} dataSource={this.state.data_announce_advert} />
        </Card>

        {/* Modal เพิ่ม */}
        <Modal title="เพิ่ม" open={this.state.isModalAddVisible} onOk={() => this.addAnnounc(this.state.formAdd.detail, this.state.formAdd.link, this.state.formAdd.type)} onCancel={() => this.setState({ isModalAddVisible: false })}>

          <Form layout="vertical">
            <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
              size="large"
            >
              <Upload
                listType="picture"
                maxCount={1}
                accept='.png,.jpg,.jpeg,.gif,.webp'
                // defaultFileList={this.state.defaultFileList}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif';
                  if (!isJpgOrPng) {
                    return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                  }
                  const isLt1M = file.size / 1024 / 1024 < 1;
                  if (!isLt1M) {
                    return x0p('', 'Image must smaller than 1MB!', 'error', false);
                  }

                  this.setState({ formAdd: { ...this.state.formAdd, detail: file } })
                  return false
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Space>
            <br/>
            <br/>
            <Form.Item label="Link">
              <Input value={this.state.formAdd.link} onChange={(e) => this.setState({ formAdd: { ...this.state.formAdd, link: e.target.value } })} />
            </Form.Item>

            <Form.Item label="หน้าที่แสดง">
              {this.state.formAdd.type === 2 || this.state.formAdd.type === 6 || this.state.formAdd.type === 7 || this.state.formAdd.type === 8 ? <>
                <Radio.Group value={this.state.formAdd.type} onChange={(e) => this.setState({ formAdd: { ...this.state.formAdd, type: e.target.value } })}>
                  <Space direction="vertical">
                    <Radio value={2}>หน้าหลัก</Radio>
                    <Radio value={6}>หน้าล็อคอิน</Radio>
                    <Radio value={7}>หน้าฝาก-ถอน</Radio>
                    <Radio value={8}>หน้าโปรโมชั่น</Radio>
                  </Space>
                </Radio.Group>
              </> : null}
            </Form.Item>

          </Form>

        </Modal>

        {/* Modal เพิ่มช่องทาง */}
        <Modal title="เพิ่มช่องทาง" open={this.state.isModalAddChannelVisible} onOk={() => this.addChanel(this.state.ipt_channel)} onCancel={() => this.setState({ isModalAddChannelVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="ช่องทาง" extra="ใส่ข้อมูลภาษาอังกฤษเท่านั้น (a-z, A-Z, 0-9)" required>
              <Input defaultValue='' onChange={(e) => this.setState({ ipt_channel: e.target.value })} />
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal generate ลิ้งแชร์ */}
        <Modal title="เพิ่มช่องทาง" open={this.state.isModalGenerateChannelVisible} footer={[]} onCancel={() => this.setState({ isModalGenerateChannelVisible: false })}>
          <Form layout="vertical">
            <Form.Item label="สร้างลิ้งเพื่อแชร์" required>
              <Select value={this.state.sl_channel} style={{ width: 'calc(50% - 20px)' }} onChange={(e) => this.setState({ sl_channel: e })}>
                <Option value=''>ไม่ระบุ</Option>
                {this.setChannelList()}
              </Select>
              <Select value={this.state.sl_register} style={{ width: 'calc(50% - 20px)' }} onChange={(e) => this.setState({ sl_register: e })}>
                <Option value='general'>สมัครแบบปกติ</Option>
                <Option value='line'>สมัครแบบไลน์</Option>
              </Select>
              <Input.Group compact style={{ marginTop: 10 }}>
                <Input
                  style={{ width: 'calc(100% - 40px)' }}
                  value={this.state.sl_channel !== '' ? `${URL_IMAGE}/register/${this.state.sl_register}?channel=${this.state.sl_channel}` : `${URL_IMAGE}/register/${this.state.sl_register}`}
                  disabled
                />
                <Tooltip title="Copy URL">
                  <Button icon={<CopyOutlined />} onClick={() => navigator.clipboard.writeText(this.state.sl_channel !== '' ? `${URL_IMAGE}/register/${this.state.sl_register}?channel=${this.state.sl_channel}` : `${URL_IMAGE}/register/${this.state.sl_register}`)} />
                </Tooltip>
              </Input.Group>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
