import React, { Component } from 'react';
import { Card, Divider, Avatar } from 'antd';
import { URL } from '../../../Util/Config'
import { DribbbleOutlined } from '@ant-design/icons';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            games: []
        };
    }

    componentDidMount() {
        this.getGames()
    }

    getGames() {
        fetch(`${URL}/api/v1/agent/member/play/providerlist`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
        })
          .then((response) => response.json())
          .then((json) => this.setState({ games: json.result }))
        //   .then(() => console.log(this.state.games))
          .catch((error) => {
            console.log('fetch data failed', error);
          });
    }
    
    gamesSlot() {
        let list = this.state.games.map((item, index) => {
            if (item.type.includes('slot')) {
                return <Avatar src={item.logo} style={{ backgroundColor: 'black', margin: 5 }} size={70} />
            } else return null
        });
        return (list)
    }

    gamesCasino() {
        let list = this.state.games.map((item, index) => {
            if (item.type.includes('casino')) {
                return <Avatar src={item.logo} style={{ backgroundColor: 'black', margin: 5 }} size={70} />
            } else return null
        });
        return (list)
    }

    render() {
        return (
            <>
                <Card>
                    <Divider orientation="left"><h5><DribbbleOutlined /> ประเภทของค่ายเกม</h5></Divider>

                    <h5>หมวดสล็อต</h5>
                    {this.gamesSlot()}

                    <br />
                    <br />
                    <br />
                    <h5>หมวดคาสิโน (บาคาร่า)</h5>
                    {this.gamesCasino()}
                </Card>
            </>
        );
    }
}
