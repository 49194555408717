import React, { Component } from 'react';
import { Card, PageHeader, Tabs } from 'antd';
import { URL, WEB_NAME } from '../../../../Util/Config';
import Setting from './setting';

const { TabPane } = Tabs;

export default class turnover extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

    async componentDidMount() { 

    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'กิจกรรมเสริม',
            },
            {
                breadcrumbName: 'เพชร (แต้มสะสม)',
            },
        ]
        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="เพชร (แต้มสะสม)"
                    breadcrumb={{ routes }}
                />

                <Card>
                    <Tabs type="card">
                        <TabPane tab="ตั้งค่า" key="1">
                            <Setting />
                        </TabPane>
                        <TabPane tab="ร้านค้าแลกของ" key="2" disabled>
                        </TabPane>
                    </Tabs>
                </Card>
            </>
        );
    }
}
