import React, { Component } from 'react';
import { Card, Row, Col, PageHeader, Divider, Typography, Form, Input, Button, Image, Space, Upload, Radio } from 'antd';
import { URL, WEB_NAME } from '../../../Util/Config';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';

// const { Step } = Steps;
const { Text, Title } = Typography;
// const { Option } = Select;

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configGeneral: {
                config_icon: '',
                config_logo: '',
                config_bg_login: '',
                config_bg_main: '',
                config_icon_menu_1: '',
                config_icon_menu_2: '',
                config_icon_menu_3: '',
                config_icon_menu_4: '',
                config_icon_menu_5: '',
                config_icon_menu_6: '',
                config_icon_menu_7: '',
                config_icon_menu_8: '',
                config_icon_menu_9: '',
                config_icon_menu_10: '',
            },
            admin_type: null,
            admin_token: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken })

        this.getGeneralConfig()
    }

    // ตั้งค่าทั่วไป
    getGeneralConfig() {
        fetch(`${URL}/api/v1/config/general`)
            .then((response) => response.json())
            .then((json) => this.setState({ configGeneral: json.result }))
            .then(() => console.log(this.state.configGeneral))
            .catch((error) => {
                console.log('getTranferConfig', error);
            });
    }

    // แก้ไขตั้งค่า หน้าเว็บ
    postUpdateGeneralConfig() {
        x0p({
            title: 'ยืนยันแก้ไขข้อมูล!!',
            text: `คุณต้องการแก้ไขข้อมูลตั้งค่าฝาก-ถอนใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const { config_webname, config_color_primary, config_color_primaroo, config_theme, config_theme_login } = this.state.configGeneral

                const body = {
                    webname: config_webname,
                    color_primary: config_color_primary,
                    color_primaroo: config_color_primaroo,
                    theme: config_theme,
                    theme_login: config_theme_login
                }

                await axios.post(`${URL}/api/v1/config/font/update`, body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getGeneralConfig()
                            x0p('สำเร็จ', null, 'ok', false);
                        } else {
                            x0p('', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('เกิดข้อผิดพลาด!!', null, 'error', false);
                    })
            }
        })
    }

    // อัพเดท รูป 
    async postUpdateImage(type, name, image) {
        console.log('postUpdateImage')
        const formData = new FormData();
        formData.append('name', name);
        formData.append("image", image);
        formData.append("type", type);

        const res = await fetch(`${URL}/api/v1/config/update/image`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${this.state.admin_token}`,
          }
        }).then((res) => res.json());

        if (res.status) {
            this.getGeneralConfig()
            x0p('สำเร็จ', null, 'ok', false);
        } else {
            x0p('', res.message, 'error', false);
        }
    }

    render() {
        const routes = [
            {
                breadcrumbName: WEB_NAME,
            },
            {
                breadcrumbName: 'ตั้งค่า',
            },
            {
                breadcrumbName: 'ตั้งค่าหน้าเว็บ',
            },
        ]

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    // title="ตั้งค่าหน้าเว็บ"
                    breadcrumb={{ routes }}
                // subTitle="This is a subtitle"
                />

                <Row gutter={[16, 16]}>
                    <Col span={21}>
                        <Divider orientation="left"><Title level={4}>ตั้งค่า หน้าเว็บ</Title></Divider>
                    </Col>
                </Row>

                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={21}>
                            {/* <Divider orientation="left"><Title level={4}>ตั้งค่า หน้าเว็บ</Title></Divider> */}
                        </Col>
                        <Col span={2} style={{ marginTop: 20 }}>
                            <Button type="primary" icon={<SaveOutlined />} onClick={() => this.postUpdateGeneralConfig()} disabled={this.state.admin_type === 'G' ? true : false}> บันทึก</Button>
                        </Col>
                    </Row>

                    <Form layout="vertical" size="large">
                        <Form.Item label="ชื่อเว็บ">
                            <Input style={{ width: 300 }} value={this.state.configGeneral.config_webname} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_webname: e.target.value } })} />
                        </Form.Item>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    label={<>สีเว็บหลัก &nbsp;&nbsp;<a href='https://cssgradient.io' target='_blank'>(เว็บ code สี)</a> &nbsp;&nbsp;<Text underline mark onClick={() => this.setState({ visableModalImageColor: true })}>วิธีตั้งสี คลิก</Text></>}
                                    extra="สามารถใช้ linear-gradient(..., ...) หรือ url('...') เท่าน้น"
                                >
                                    <Input.Group compact>
                                        <Input value={this.state.configGeneral.config_color_primary} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_color_primary: e.target.value } })} style={{ width: 'calc(100% - 100px)' }} />
                                        <div style={{ backgroundImage: this.state.configGeneral.config_color_primary, width: 40, height: 40 }}></div>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={<>สีเว็บรอง &nbsp;&nbsp;<a href='https://cssgradient.io' target='_blank'>(เว็บ code สี)</a> &nbsp;&nbsp;<Text underline mark onClick={() => this.setState({ visableModalImageColor: true })}>วิธีตั้งสี คลิก</Text></>}
                                    extra="สามารถใช้เป็นโค้ดสีหรือโค้ดrgb เท่านั้น เช่น #cccccc, #000000, rgb(0,158,114), rgb(34,193,195)"
                                >
                                    <Input.Group compact>
                                        <Input value={this.state.configGeneral.config_color_primaroo} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_color_primaroo: e.target.value } })} style={{ width: 'calc(100% - 100px)' }} />
                                        <div style={{ backgroundColor: this.state.configGeneral.config_color_primaroo, width: 40, height: 40 }}></div>
                                    </Input.Group>
                                </Form.Item>
                            </Col>
                            <Image
                                // width={200}
                                style={{ display: 'none' }}
                                // src="https://sv1.picz.in.th/images/2022/09/08/aPjnF9.png"
                                preview={{
                                    visible: this.state.visableModalImageColor,
                                    src: '/images/color/set.png',
                                    onVisibleChange: (value) => {
                                        this.setState({ visableModalImageColor: false })
                                    },
                                }}
                            />
                        </Row>
                        <br />

                        <Divider orientation="left">Theme page</Divider>
                        <Radio.Group onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_theme: e.target.value } })} value={parseInt(this.state.configGeneral.config_theme)}>
                            <Radio value={1} className="mx-4 mb-4">
                                รูปแบบที่ 1
                                <br />
                                <Image src='/images/config/theme1.png' width={200} />
                            </Radio>
                            <Radio value={2} className="mx-4 mb-4">
                                รูปแบบที่ 2
                                <br />
                                <Image src='/images/config/theme2.png' width={200} />
                            </Radio>
                            <Radio value={3} className="mx-4 mb-4">
                                รูปแบบที่ 3
                                <br />
                                <Image src='/images/config/theme3.png' width={200} />
                            </Radio>
                            <Radio value={4} className="mx-4 mb-4">
                                รูปแบบที่ 4
                                <br />
                                <Image src='/images/config/theme4.png' width={200} />
                            </Radio>
                            <Radio value={5} className="mx-4 mb-4" disabled>
                                รูปแบบที่ 5 (ระบบหวย)
                                <br />
                                <Image src='/images/config/theme5.png' width={200} />
                            </Radio>
                            <Radio value={6} className="mx-4 mb-4">
                                รูปแบบที่ 6
                                <br />
                                <Image src='/images/config/theme6.png' width={200} />
                            </Radio>
                            <Radio value={7} className="mx-4 mb-4">
                                รูปแบบที่ 7
                                <br />
                                <Image src='/images/config/theme7.png' width={200} />
                            </Radio>

                            <Radio value={8} className="mx-4 mb-4">
                                รูปแบบที่ 8
                                <br />
                                <Image src='/images/config/theme8.png' width={200} />
                            </Radio>

                            <Radio value={9} className="mx-4 mb-4">
                                รูปแบบที่ 9
                                <br />
                                <Image src='/images/config/theme9.png' width={200} />
                            </Radio>

                        </Radio.Group>
                        <br />
                        <br />
                        <br />

                        <Divider orientation="left">Theme page login</Divider>
                        <Radio.Group onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_theme_login: e.target.value } })} value={parseInt(this.state.configGeneral.config_theme_login)}>
                            <Radio value={1} className="mx-4">
                                รูปแบบที่ 1
                                <br />
                                <Image src='/images/config/themelogin1.png' width={200} />
                            </Radio>
                            <Radio value={2} className="mx-4">
                                รูปแบบที่ 2
                                <br />
                                <Image src='/images/config/themelogin2.png' width={200} />
                            </Radio>
                            <Radio value={3} className="mx-4">
                                รูปแบบที่ 3
                                <br />
                                <Image src='/images/config/themelogin3.png' width={200} />
                            </Radio>
                        </Radio.Group>
                        <br />
                        <br />
                        <br />
                    </Form>
                </Card>

                <br/>
                <br/>
                
                <Card>
                    <Form layout="vertical" size="large">
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="icon" className='text-center' extra="แนะนำให้ใช้ขนาด 800×800px, 300×300px หรือ สี่เหลี่ยนจัตุรัส">
                                    <Image src={this.state.configGeneral.config_icon.includes('http') ? this.state.configGeneral.config_icon : URL + "/public/images/config/" + this.state.configGeneral.config_icon} height={150} />
                                    <br/>
                                    <br/>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="logo" className='text-center' extra="แนะนำให้ใช้ขนาด 1200×405px, 150×45px, 800×270 หรือ สี่เหลี่ยนผืนผ้า">
                                    <Image src={this.state.configGeneral.config_logo.includes('http') ? this.state.configGeneral.config_logo : URL + "/public/images/config/" + this.state.configGeneral.config_logo} height={150} />
                                    <br/>
                                    <br/>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.logo}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('logo', 'logo', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_logo} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_logo: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="background หน้าล็อกอิน" className='text-center'>
                                    <Image src={this.state.configGeneral.config_bg_login.includes('http') ? this.state.configGeneral.config_bg_login : URL + "/public/images/config/" + this.state.configGeneral.config_bg_login} height={150} />
                                    <br/>
                                    <br/>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.bg_login}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('bg', 'bg_login', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_bg_login} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_bg_login: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="background หน้าหลัก" className='text-center'>
                                    <Image src={this.state.configGeneral.config_bg_main.includes('http') ? this.state.configGeneral.config_bg_main : URL + "/public/images/config/" + this.state.configGeneral.config_bg_main} height={150} />
                                    <br/>
                                    <br/>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.bg_main}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('bg', 'bg_main', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_bg_main} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_bg_main: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                        </Row>

                        <br/>
                        <br/>

                        <Divider orientation="left">รูป Icon เมนู</Divider>
                        <p className='text-muted'>**แนะนำให้ใช้ขนาด 50×50px, 80×80px หรือ สี่เหลี่ยนจัตุรัส</p>
                        <Row gutter={[16, 16]}>
                            <Col span={6} className='text-center'>
                                <Image src={this.state.configGeneral.config_icon_menu_1.includes('http') ? this.state.configGeneral.config_icon_menu_1 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_1} height={45} />
                                <Form.Item label="เล่นเกม สล็อต บาร์" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_1}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_1', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_1} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_1: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_2.includes('http') ? this.state.configGeneral.config_icon_menu_2 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_2} height={45} />
                                <Form.Item label="ประวัติการเงิน" className='text-center'>
                                <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_2}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_2', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_2} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_2: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_3.includes('http') ? this.state.configGeneral.config_icon_menu_3 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_3} height={45} />
                                <Form.Item label="โปรโมชั่น" className='text-center'>
                                <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_3}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_3', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_3} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_3: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_4.includes('http') ? this.state.configGeneral.config_icon_menu_4 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_4} height={45} />
                                <Form.Item label="แนะนำเพื่อน" className='text-center'>
                                <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_4}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_4', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_4} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_4: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_5.includes('http') ? this.state.configGeneral.config_icon_menu_5 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_5} height={45} />
                                <Form.Item label="คืนยอดเสีย" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_5}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_5', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_5} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_5: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_6.includes('http') ? this.state.configGeneral.config_icon_menu_6 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_6} height={45} />
                                <Form.Item label="ข้อมุลส่วนตัว" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_6}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_6', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_6} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_6: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_7.includes('http') ? this.state.configGeneral.config_icon_menu_7 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_7} height={45} />
                                <Form.Item label="กงล้อนำโชค" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_7}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_7', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_7} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_7: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_8.includes('http') ? this.state.configGeneral.config_icon_menu_8 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_8} height={45} />
                                <Form.Item label="ล็อคอินรายวัน" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_8}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_8', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_8} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_8: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_9.includes('http') ? this.state.configGeneral.config_icon_menu_9 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_9} height={45} />
                                <Form.Item label="ฝาก" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_9}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_9', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_9} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_9: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6} className='text-center'>
                            <Image src={this.state.configGeneral.config_icon_menu_10.includes('http') ? this.state.configGeneral.config_icon_menu_10 : URL + "/public/images/config/" + this.state.configGeneral.config_icon_menu_10} height={45} />
                                <Form.Item label="ถอน" className='text-center'>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        size="large"
                                        >
                                        <Upload
                                            listType="file"
                                            maxCount={1}
                                            accept='.png,.jpg,.jpeg,.gif,.webp'
                                            // defaultFileList={this.state.defaultConfig.icon_menu_10}
                                            beforeUpload={(file) => {
                                                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/webp';
                                                if (!isJpgOrPng) {
                                                  return x0p('', 'You can only upload JPG/PNG file!', 'error', false);
                                                }
                                                const isLt1M = file.size / 1024 / 1024 < 1;
                                                if (!isLt1M) {
                                                  return x0p('', 'Image must smaller than 1MB!', 'error', false);
                                                }

                                                this.postUpdateImage('icon', 'icon_menu_10', file)
                                                return false
                                            }}
                                        >
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </Space>
                                    {/* <Input value={this.state.configGeneral.config_icon_menu_10} onChange={(e) => this.setState({ configGeneral: { ...this.state.configGeneral, config_icon_menu_10: e.target.value } })} /> */}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}