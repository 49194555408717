import React, { Component } from 'react';
import { Table, Button, Tag, Alert, Select, Modal, message, Form, Tooltip, Image, Spin, AutoComplete, Input } from 'antd';
import { FileSearchOutlined, QuestionCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import x0p from 'x0popup';
import { URL } from '../../../../Util/Config';

export default class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            user: null,
            text: null,
            filter: false,
            record: {},
            columns: [
                {
                    title: 'ลำดับ',
                    dataIndex: 'transaction_id',
                    render: (text, record) => (
                        <>
                            {record.row_num}
                            <Tag>
                                {text}
                            </Tag>
                        </>
                    )
                },
                {
                    title: 'วัน/เวลาที่ทำรายการ',
                    dataIndex: 'transaction_datetime',
                    width: 280,
                },
                {
                    title: 'เข้าบัญชี',
                    width: 100,
                    dataIndex: 'transaction_channel',
                    render: (text, record) => (
                        <Image src={`/images/logo_bank/${text}.png`} fallback="/images/image-not.webp" width={30} style={{ backgroundColor: 'black' }} alt="My Happy SVG" />
                    )
                },
                {
                    title: 'ข้อสังเกต',
                    dataIndex: 'transaction_remark',
                    width: 350,
                    render: (text, record) => (
                        <>
                            {text} {record.transaction_note ? <Tooltip placement="top" title={record.transaction_note}><QuestionCircleFilled /></Tooltip> : null}
                        </>
                    )
                },
                {
                    title: 'จำนวนเงิน',
                    dataIndex: 'transaction_amount',
                    align: 'right',
                    render: (text, record) => (
                        <Tag color='green'>
                            {text} บาท
                        </Tag>
                    )
                },
                // {
                //     title: 'สถานะ',
                //     dataIndex: 'transaction_status',
                //     render: (text, record) => (
                //         <Tag color='blue'>
                //             {text}
                //         </Tag>
                //     )
                // },
                // {
                //     title: 'สถานะสมาชิก',
                //     dataIndex: 'status_member',
                //     render: (text, record) => (
                //         <>
                //             {text === 'เป็นสมาชิก' ?
                //                 <Tag color='gold'>
                //                     {text}
                //                 </Tag>
                //                 :
                //                 <Tag color='red'>
                //                     {text}
                //                 </Tag>
                //             }
                //         </>
                //     )
                // },
                {
                    title: 'ยืนยันการรายฝาก',
                    dataIndex: '',
                    width: 280,
                    render: (text, record) => (
                        this.state.admin_type === 'G' ? null :
                            <>
                                <Button
                                    type="primary"
                                    style={{ marginRight: 5 }}
                                    onClick={() => {
                                        this.setState({ isModalVisible: true, user: null })
                                        this.setListUser(record)
                                    }}
                                    icon={<FileSearchOutlined />}
                                    disabled={this.state.admin_type === 'G' ? true : false}
                                >
                                    ยืนยันฝาก
                                </Button>
                                {/* {record.status_member === 'ไม่เป็นสมาชิก' ?  */}
                                <Button danger onClick={() => this.depositCancel(record)} icon={<FileSearchOutlined />} disabled={this.state.admin_type === 'G' ? true : false}>ยกเลิกฝาก</Button>
                                {/* : <></>
                        } */}
                            </>
                    )
                }
            ],
            data: [],
            admin_type: null,
            loading: false,
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (!user) return this.props.history.push("/adminManage/login");
        this.setState({ admin_type: user.user.type, admin_token: user.accessToken, admin_name: user.user.name }, () => {
            this.getData()
        })
    }

    async getData() {
        await axios.get(URL + "/api/v2/transfer/deposit/wait", {
            headers: {
                'Authorization': `Bearer ${this.state.admin_token}`,
            }
        })
            .then(res => {
                var data = res.data
                if (data.status) {
                    this.setState({ data: data.result })
                }
            })
            .catch(error => {
                console.log(error)
                message.error('การเชื่อมต่อผิดพลาด!!');
            })
    }

    async setListUser(record) {
        this.setState({ record, userList: [], user: '' })
        this.getUserFindByTransaction(record.transaction_id)
    }

    selectUserlist() {
        let list = []
        for (let i = 0; i < this.state.userList.length; i++) {
            const item = this.state.userList[i];
            
            let value = `${item.user_id + ', ' + item.title}`
            list.push({ value })
        }

        return (list)
    }

    async getUserFindByTransaction(transaction_id) {
        this.setState({ loading: true })
        var config = {
            method: 'post',
            url: `${URL}/api/v1/user/username/transaction`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: { transaction_id }
        };

        axios(config)
            .then(res => {
                var data = res.data
                if (data.status) {
                    this.setState({ 
                        userList: data.result, 
                        filter: data.result.length > 0 ? false : true,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error)
                message.error('การเชื่อมต่อผิดพลาด!!');
            })
    }

    async getUserFindByFilter(text) {
        if (text != '') {
            await axios.get(`${URL}/api/v1/user/page/1/search/${text}`, {
                headers: {
                    'Authorization': `Bearer ${this.state.admin_token}`,
                }
            })
                .then(res => {
                    var data = res.data
                    if (data.status) {
                        this.setState({ userList: data.result })
                    }
                })
                .catch(error => {
                    console.log(error)
                    message.error('การเชื่อมต่อผิดพลาด!!');
                })
        }
    }

    async depositSubmit() {
        const { user, record } = this.state

        if (!user) {
            message.error('กรุณาเลือกสมาชิกที่ทำรายการ!!');

        } else {
            const { transaction_amount, transaction_id } = record
            const user_id = user.split(',')[0]
            const user_username = user.split(',')[1]
            const user_name = user.split(',')[2]
            // const user_phone = user.split(',')[3]

            if (!user_username) {
                // console.log('user_username', user_username)
                return x0p('', 'ข้อมูลไม่ถูกต้อง กรุณาเลือกบัญชีใหม่!!', 'error', false);
            }
            if (!user_name) {
                return x0p('', 'ข้อมูลไม่ถูกต้อง กรุณาเลือกบัญชีใหม่!!', 'error', false);
            } 

            x0p({
                title: '',
                text: `ฝากเครดิตให้ ${user_username}, ${user_name} \nจำนวน ${transaction_amount} บาท`,
                icon: 'info',
                buttons: [
                    {
                        type: 'cancel',
                        text: 'ยกเลิก',
                    },
                    {
                        type: 'info',
                        text: 'ยืนยัน',
                        showLoading: true
                    }
                ]
            }).then(async (alert) => {
                if (alert.button === 'info') {
                    const body = {
                        transaction_id: transaction_id,
                        user_id: user_id,
                    }
                    await axios.post(URL + "/api/v2/transfer/deposit/wait/submit", body, {
                        headers: {
                            'Authorization': `Bearer ${this.state.admin_token}`,
                        }
                    })
                        .then(res => {
                            const data = res.data
                            // console.log(data)
                            if (data.status) {
                                this.getData()
                                this.setState({ isModalVisible: false })
                                // this.messageAlert(user_userID, `ทำรายการฝากเงินสำเร็จ!!\nจำนวน ${transaction_amount} บาท`)
                                x0p('ทำรายการฝากเงินสำเร็จ!!', null, 'ok', false);
                            } else {
                                x0p('ไม่สามารถทำรายการได้!!', data.message, 'error', false);
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            x0p('การเชื่อมต่อผิดพลาด deposit/submit!!', null, 'error', false);
                        })
                }
            })
        }
    }

    async depositCancel(value) {
        const { transaction_id, transaction_amount, transaction_remark } = value
        x0p({
            title: 'ยืนยัน!!',
            text: `คุณต้องการ "ยกเลิก" การฝากเครดิตให้สมาชิกใช่มั้ย??`,
            icon: 'info',
            buttons: [
                {
                    type: 'cancel',
                    text: 'ยกเลิก',
                },
                {
                    type: 'info',
                    text: 'ยืนยัน',
                    showLoading: true
                }
            ]
        }).then(async (alert) => {
            if (alert.button === 'info') {
                const body = {
                    transaction_id: transaction_id,
                    transaction_note: `(รายการ รอยืนยัน) ${this.state.admin_name} ไม่ยืนยันการฝากจำนวน ${transaction_amount} บาท รายการ ${transaction_remark}`,
                }
                await axios.post(URL + "/api/v2/transfer/deposit/wait/cancel", body, {
                    headers: {
                        'Authorization': `Bearer ${this.state.admin_token}`,
                    }
                })
                    .then(res => {
                        const data = res.data
                        if (data.status) {
                            this.getData()
                            this.setState({ isModalVisible: false })
                            x0p('ทำรายยกเลิกการฝากสำเร็จ!!', null, 'ok', false);
                        } else {
                            x0p('เกิดข้อผิดพลาด!!', data.message, 'error', false);
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        x0p('การเชื่อมต่อผิดพลาด!! deposit/wait/cancel', null, 'error', false);
                    })
            }
        })
    }

    async messageAlert(user_userID, ms) {
        var body = {
            userID: user_userID, // userID Line
            message: ms
        }
        await axios.post(`${URL}/api/v1/line/messages/push`, body)
            .then(res => {
                console.log('ส่งข้อความให้ลูกค้าแล้ว')
            })
            .catch(error => {
                console.log(error);
                alert('การเชื่อมต่อผิดพลาด Post User')
            })
    }

    // ตรวจสอบการฝาก
    async checkDeposit() {
        x0p({
            title: 'ระบบกำลังตรวจสอบ',
            text: '',
            animationType: 'slideDown',
            icon: 'info',
            buttons: [],
        });
        await axios.get(URL + "/api/v1/transfer/deposit/admin/check")
            .then(res => {
                const data = res.data
                if (data.status) {
                    this.getData()
                    x0p('', data.message, 'ok');
                } else {
                    x0p('', data.message, 'error');
                }
            })
            .catch(error => {
                console.log(error)
                x0p('การเชื่อมต่อผิดพลาด!!', 'checkDeposit', 'error');
            })
    }

    render() {
        return (
            <>
                {/* <Row style={{ marginBottom: 20 }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" icon={<RetweetOutlined />} onClick={() => this.checkDeposit()}>ตรวจสอบการฝาก</Button>
                    </Col>
                </Row> */}
                <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 20 }} scroll={{ x: 1400 }} rowKey={record => record.row_num} />

                {/* ยืนยันทำรายการฝาก */}
                <Modal title="ยืนยันทำรายการฝาก" width={600} open={this.state.isModalVisible} onOk={() => this.depositSubmit()} onCancel={() => this.setState({ isModalVisible: false })}>
                    <Spin spinning={this.state.loading}>
                        <Alert
                            message="ระบุบัญชีสมาชิก!!"
                            description={`โอนเงินให้สมาชิกจำนวน ${this.state.record.transaction_amount} บาท`}
                            type="warning"
                            style={{ marginBottom: 20 }}
                        />
                        <Form.Item extra="สามารถค้นหาได้โดยการพิมพ์ ยูส, ชื่อ หรือเบอร์">
                            <AutoComplete
                                placeholder="กรุณาเลือกบัญชีสมาชิก"
                                options={this.selectUserlist()}
                                onChange={(e) => this.setState({ user: e })}
                            >
                                <Input.Search 
                                    placeholder="กรุณาเลือกบัญชีสมาชิก" 
                                    onSearch={(e) => {
                                        this.getUserFindByFilter(e)    
                                        if (e === '') {
                                            this.getUserFindByTransaction(this.state.record.transaction_id)
                                        }
                                        this.setState({ text: e })
                                    }}
                                    value={this.state.text}
                                />
                            </AutoComplete>
                        </Form.Item>
                    </Spin>
                </Modal>
            </>
        );
    }
}
