import React, { Component } from 'react';
import { Table, Tooltip, Row, Col, Radio, Button, Space, Tag, Modal, message, Form, Typography, InputNumber, Input } from 'antd';
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, QrcodeOutlined } from '@ant-design/icons';
import { URL } from '../../../../Util/Config';
import { withRouter } from 'react-router'
import axios from 'axios';
import x0p from 'x0popup';

const { Paragraph, Text, Title } = Typography;

class discountuse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: {},
      columns: [
        {
            title: 'ลำดับ',
            dataIndex: 'coupon_id',
            // defaultSortOrder: 'descend',
            width: 30,
            sorter: (a, b) => a.row_num - b.row_num,
        },
        {
          title: 'CODE',
          dataIndex: 'coupon_code',
          width: 150,
          render: (text, record) => (
            record.coupon_id === 1 ? <Text>{text}</Text> :
            <Text><Paragraph copyable>{text}</Paragraph></Text>
          )
        },
        {
          title: 'หมายเหตุ',
          dataIndex: 'coupon_note',
          width: 150,
          render: (text, record) => (
            record.coupon_id === 1 ? <Tag color='cyan'>ใช้ได้ 1 ครั้งต่อ 1 โค้ด</Tag> :
            record.row_num === 1 ? <Tag color='cyan'>โค้ดหลักกับใช้หน้าเว็บ</Tag> :
            <Tag>{text}</Tag>
          )
        },
        {
          title: 'Limit (ครั้ง)',
          dataIndex: 'coupon_limit',
          width: 80,
          render: (text, record) => (
            text === 0 ? 
            <Tag color="orange">ไม่จำกัด</Tag>
            :
            <Tag color="green">{text}</Tag>
          ),
        },
        {
          title: 'ใช้งานแล้ว (ครั้ง)',
          dataIndex: 'used',
          width: 80,
          render: (text, record) => (
            <Tag color="orange">{text}</Tag>
          ),
        },
        {
          title: '',
          dataIndex: 'coupon_id',
          fixed: 'right',
          width: 30,
          render: (text, record) => (
            text === 1 ? <></> :
            <Tooltip title="แก้ไข">
                <Button 
                    style={{ marginRight: 5 }} 
                    type="primary" 
                    shape="circle" 
                    onClick={() => { 
                      this.setState({ 
                          edit_coupon_limit_sl: record.coupon_limit === 0 ? 0 : 1,
                          edit_coupon_id: text, 
                          edit_coupon_code: record.coupon_code, 
                          edit_coupon_note: record.coupon_note, 
                          edit_coupon_limit: record.coupon_limit,
                          isModalEditVisible: true, 
                      })
                    }}
                    icon={<EditOutlined />}
                />
            </Tooltip>
          ),
        }
      ],
      data: [],
      isModalAddVisible: false,
      add_coupon_limit: 2,
      add_coupon_limit_sl: 1,
      add_coupon_note: '',
      isModalEditVisible: false,
      edit_coupon_limit_sl: 1,
      edit_coupon_id: 0,
      edit_coupon_code: '',
      edit_coupon_limit: 2,
      edit_coupon_note: '',
      isModalCodeRandomVisible: false,
      admin_type: '',
      admin_token: '',
      random_code: ''
    };
  }

  componentDidMount() {
    if (this.props.discount_id) {
      const user = JSON.parse(localStorage.getItem('user'))
      this.setState({ admin_type: user.user.type, admin_token: user.accessToken }, () => {
        this.getData()
      })
    } else {
      message.error('ไม่มีข้อมูล ส่วนลดนี้')
      this.props.history.push("/adminManage/promotion/discount");
    }
  }

  async getData() {
    var id = this.props.discount_id
    await axios.get(`${URL}/api/v1/coupon/by/discount/${id}`)
      .then(res => {
        var data = res.data
        // console.log(data.result)
        if (data.status) {      
            this.setState({ data: data.result.coupon })
        } else console.log(data)
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async postAddCode() {
    let limit = this.state.add_coupon_limit
    if (this.state.add_coupon_limit_sl === 0) {
        limit = 0
    }

    const body = {
        coupon_limit: limit,
        coupon_note: this.state.add_coupon_note,
        discount_id: this.props.discount_id
    }

    await axios.post(`${URL}/api/v1/discount/coupon/add`, body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {
          console.log(data)
          if (this.state.add_coupon_note === 'RANDOM') {
            this.setState({ isModalCodeRandomVisible: true, random_code: data.result.coupon_code })
          } else {
            this.getData()
            this.setState({ isModalAddVisible: false })
            x0p('', data.message, 'ok', false);
          }
        } else {
            // console.log(data)
            x0p('', data.message, 'error', false);
        }
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  async postEditCode() {
    let limit = this.state.edit_coupon_limit
    if (this.state.edit_coupon_limit_sl === 0) {
        limit = 0
    }

    const body = {
        coupon_id: this.state.edit_coupon_id,
        coupon_note: this.state.edit_coupon_note,
        coupon_limit: limit
    }

    await axios.post(`${URL}/api/v1/discount/coupon/update`, body, {
      headers: {
        'Authorization': `Bearer ${this.state.admin_token}`,
      }
    })
      .then(res => {
        var data = res.data
        if (data.status) {      
          this.getData()
          this.setState({ isModalEditVisible: false })
          x0p('', data.message, 'ok', false);
        } else {
            // console.log(data)
            x0p('', data.message, 'error', false);
        }
      })
      .catch((error) => {
        console.log('fetch data failed', error);
      });
  }

  render() {
    return (
      <>
        <Row style={{ marginBottom: 20 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button 
                type="danger" 
                icon={<QrcodeOutlined />} 
                style={{ marginRight: 5 }}
                onClick={() => { 
                  this.setState({ add_coupon_limit: 1, add_coupon_note: 'RANDOM' }, () => this.postAddCode())
                }}
                disabled={this.state.admin_type === 'G' ? true : false}
              >Random</Button>
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={() => {
                  this.setState({ isModalAddVisible: true })
                }}
                disabled={this.state.admin_type === 'G' ? true : false}
              >เพิ่มโค้ด</Button>
            </Col>
          </Row>
        <Table columns={this.state.columns} dataSource={this.state.data} pagination={{ pageSize: 25 }} rowKey={record => record.row_num} />

        {/* เพิ่ม */}
        {this.state.isModalAddVisible ?
            <Modal
                title="เพิ่มโค้ด"
                open={this.state.isModalAddVisible}
                style={{ top: 20 }}
                onCancel={() => this.setState({ isModalAddVisible: false })}
                onOk={() => this.postAddCode()}
            >
                <Form layout="vertical">
                    <Form.Item label="Limit (ครั้ง)">
                        <Radio.Group onChange={(e) => this.setState({ add_coupon_limit_sl: e.target.value })} value={this.state.add_coupon_limit_sl}>
                            <Space direction="vertical">
                                <Radio value={1}>ระบุเอง</Radio>
                                <Radio value={0}><Tag color="orange">ไม่จำกัด</Tag></Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    { this.state.add_coupon_limit_sl === 1 ? 
                        <Form.Item>
                            <InputNumber value={this.state.add_coupon_limit} min={2} max={9999999} style={{ width: '100%' }} onChange={(value) => this.setState({ add_coupon_limit: value })} />
                        </Form.Item>
                        : <></>
                    }
                    <Form.Item label="หมายเหตุ">
                      <Input value={this.state.add_coupon_note} onChange={(e) => this.setState({ add_coupon_note: e.target.value })}/>
                    </Form.Item>
                </Form>
            </Modal>
            : null
        }

        {/* แก้ไข */}
        {this.state.isModalEditVisible ?
            <Modal
                title="แก้ไขโค้ด"
                open={this.state.isModalEditVisible}
                style={{ top: 20 }}
                onCancel={() => this.setState({ isModalEditVisible: false })}
                onOk={() => this.postEditCode()}
            >
                <Title level={3}>{this.state.edit_coupon_code}</Title>
                <Form layout="vertical">
                    <Form.Item label="Limit (ครั้ง)">
                        <Radio.Group onChange={(e) => this.setState({ edit_coupon_limit_sl: e.target.value })} value={this.state.edit_coupon_limit_sl}>
                            <Space direction="vertical">
                                <Radio value={1}>ระบุเอง</Radio>
                                <Radio value={0}><Tag color="orange">ไม่จำกัด</Tag></Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    { this.state.edit_coupon_limit_sl > 0 ? 
                        <Form.Item>
                            <InputNumber value={this.state.edit_coupon_limit} min={2} max={9999999} style={{ width: '100%' }} onChange={(value) => this.setState({ edit_coupon_limit: value })} />
                        </Form.Item>
                        : <></>
                    }
                    <Form.Item label="หมายเหตุ">
                      <Input value={this.state.edit_coupon_note} onChange={(e) => this.setState({ edit_coupon_note: e.target.value })}/>
                    </Form.Item>
                </Form>
            </Modal>
            : null
        }

        {this.state.isModalCodeRandomVisible ? 
          <Modal
              title="Code Random"
              open={this.state.isModalCodeRandomVisible}
              style={{ top: 20 }}
              footer={[]}
              onCancel={() => this.setState({ isModalCodeRandomVisible: false })}
              // onOk={() => this.postEditCode()}
          >
              {/* <Title level={3}>Random :</Title>  */}
              <Title level={2}><Paragraph copyable>{this.state.random_code}</Paragraph></Title>
          </Modal>
        :<></>}
      </>
    );
  }
}
export default withRouter(discountuse);