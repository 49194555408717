import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  PageHeader,
  Table,
  Tag,
  DatePicker,
  Modal,
  Form,
  Input,
  Typography,
  Select,
  message,
  Tooltip,
  Statistic,
  Switch
} from "antd";
import { EditOutlined, PlusCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { URL, URL_IMAGE, WEB_NAME } from "../../../Util/Config";
import DateTime from "node-datetime";
import moment from "moment";
import axios from "axios";
import x0p from "x0popup";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

const dateFormat = "YYYY/MM/DD";
var dateOffset30 = DateTime.create();
dateOffset30.offsetInDays(-30);
var dateStart = dateOffset30.format("Y/m/d");
var dateEnd = DateTime.create().format("Y/m/d");

export default class summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalAddMemberVisible: false,
      isModalReportMemberVisible: false,
      isModalUnderMemberVisible: false,
      isModalShare: false,
      coderefer: '',
      searchUnderMember: '',
      sum: {
        deposit: 0,
        withdraw: 0,
        income: 0,
      },
      form_add: {
        user_id: "",
        user_password: "",
        user_name: "",
        user_percent: "0",
      },
      type: "",
      percent: 0,
      paginationUnderMember: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
      pagination: {
        pagination: {
          current: 1,
          pageSize: 20,
        }
      },
      user_id: 0,
      data: [],
      columns: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          width: 150,
          sorter: (a, b) => a.row_num - b.row_num,
        },
        {
          title: "username",
          dataIndex: "user_username",
        },
        {
          title: "note",
          dataIndex: "title",
          //   ...this.getColumnSearchProps("title"),
          // render: (text, record) => <p></p>,
        },
        {
          title: "%",
          dataIndex: "user_percent",
          //   ...this.getColumnSearchProps("title"),
          // render: (text, record) => <p></p>,
        },
        {
          title: "สถานะ",
          dataIndex: "user_status",
          render: (text, record) => (
            text === "ระงับ" ?
            <Tag color="red">ระงับ</Tag>
            : <Tag color="green">ปกติ</Tag>
          )
        },
        {
          title: "รายละเอียด",
          dataIndex: "",
          //   ...this.getColumnSearchProps("title"),
          render: (text, record) => (
            <>
              <Button ghost type="primary" style={{ marginRight: 5 }} onClick={() => 
                this.setState({ coderefer: record.user_coderefer, isModalShare: true })
              }>
                ลิ้งแชร์
              </Button>
              <Button
                type="primary" 
                style={{ marginRight: 5 }}
                onClick={() => {
                  this.setState({ isModalUnderMemberVisible: true, coderefer: record.user_coderefer })
                  this.getDataMember(record.user_coderefer)
                }}
              >
                ยูสใต้สาย
              </Button>
              <Button
                type="primary"
                style={{ marginRight: 5 }}
                onClick={() => this.getDetail(record.user_id, record.user_percent)}
              >
                ได้เสีย
              </Button>
              <Button danger onClick={() => this.updateMember(record)}>
                แก้ไข
              </Button>
            </>
          ),
        },

        // {
        //   title: "วัน/เวลา ทำธุรกรรมล่าสุด",
        //   dataIndex: "transaction_datetime",
        // },
      ],

      data2: [],
      columns2: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          width: 120,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
            title: 'ชื่อผู้ใช้งาน',
            dataIndex: 'title',
            //   ...this.getColumnSearchProps("title"),
            //   render: (text, record) => <p></p>,
        },
        {
          title: "ยอดฝากรวม",
          dataIndex: "deposit",
          align: "right",
          render: (text, record) => (
            <>
              <Tag color="green">{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          ),
        },
        {
          title: "ยอดถอนรวม",
          dataIndex: "withdraw",
          align: "right",
          render: (text, record) => (
            <>
              <Tag color="red">{text ? text.toLocaleString() : 0} บาท</Tag>
            </>
          ),
        },
        {
          title: "กำไรรวม",
          dataIndex: "income",
          align: "right",
          render: (text, record) => (
            <>
               {text > 0 ? (
                <Tag color="green">{text ? text.toLocaleString() : 0} บาท</Tag>
              ) : (
                <Tag color="red">{text ? text.toLocaleString() : 0} บาท</Tag>
              )}
            </>
          ),
        },
        {
          title: `หุ้นส่วน`,
          dataIndex: "income",
          align: "right",
          props: {
            style: { background: "#FAECC6" },
          },
          render: (text, record) => (
            <>
              {((parseFloat(text) * this.state.percent) / 100) > 0 ? (
                <Tag color="green">{((parseFloat(text) * this.state.percent) / 100).toLocaleString()} บาท</Tag>
              ) : (
                <Tag color="red">{((parseFloat(text) * this.state.percent) / 100).toLocaleString()} บาท</Tag>
              )}
            </>
          ),
        },
        {
          title: "บริษัท",
          dataIndex: "income",
          align: "right",
          render: (text, record) => (
            <>
              {parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100) > 0 ? (
                <Tag color="green">{(parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100)).toLocaleString()} บาท</Tag>
              ) : (
                <Tag color="red">{(parseFloat(text) - ((parseFloat(text) * this.state.percent) / 100)).toLocaleString()} บาท</Tag>
              )}
            </>
          ),
        },
      ],

      data3: [],
      columns3: [
        {
          title: "ลำดับ",
          dataIndex: "row_num",
          width: 120,
          render: (text, record) => (
            <div>{text} <Text code><small>{record.user_id}</small></Text></div>
          )
        },
        {
          title: 'ชื่อผู้ใช้งาน',
          dataIndex: 'title',
        },
        {
          title: 'วันที่สมัคร',
          dataIndex: 'created_at',
        },
      ],

      loading1: false,
      loading2: false,
      loading3: false
    };
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    this.setState({ loading1: true })
    await axios
      .get(URL + "/api/v1/user/partner")
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ loading1: false, data: data.result });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("การเชื่อมต่อผิดพลาด partner all");
      });
  }

  async PostCreatePartner() {
    const body = {
      password: this.state.form_add.user_password,
      name: this.state.form_add.user_name,
      percent: this.state.form_add.user_percent,
    };

    await axios
      .post(URL + "/api/v1/user/partner/add", body, {
        headers: {
          Authorization: `Bearer ${this.state.admin_token}`,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ isModalAddMemberVisible: false });
          this.getData();
          x0p("สำเร็จ", null, "ok", false);
        } else {
          x0p("", data.message, "error", false);
        }
      })
      .catch((error) => {
        console.log(error);
        // this.setState({ loading1: false })
        x0p("", "การเชื่อมต่อผิดพลาด PostCreatePartner", "error", false);
      });
  }

  async updateMember(value) {
    this.setState({
      form_add: {
        user_id: value.user_id,
        user_name: value.user_name,
        user_password: "",
        user_percent: value.user_percent,
        user_status: value.user_status,
      },
      isModalAddMemberVisible: true,
      type: "update",
    });
  }

  async PostUpdateMember() {
    const body = {
      user_id: this.state.form_add.user_id,
      password: this.state.form_add.user_password,
      name: this.state.form_add.user_name,
      percent: this.state.form_add.user_percent,
      status: this.state.form_add.user_status,
    };
    await axios
      .post(URL + "/api/v1/user/partner/update", body, {
        headers: {
          Authorization: `Bearer ${this.state.admin_token}`,
        },
      })
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ isModalAddMemberVisible: false });
          this.getData()
          x0p("สำเร็จ", null, "ok", false);
        } else {
          x0p("", data.message, "error", false);
        }
      })
      .catch((error) => {
        console.log(error);
        // this.setState({ loading1: false })
        x0p("", "การเชื่อมต่อผิดพลาด PostUpdateMember", "error", false);
      });
  }

  async getDetail(user_id, percent) {
    this.setState({ percent: percent })
    this.setState({ user_id: user_id })
    this.setState({ isModalReportMemberVisible: true }, () => { this.setDate(user_id, dateStart, dateEnd) });
  }

  setDate(user_id, startDate, endDate, params = {}) {
    dateStart = startDate;
    dateEnd = endDate;

    var dt = DateTime.create(startDate);
    startDate = dt.format("Y-m-d");
    dt = DateTime.create(endDate);
    endDate = dt.format("Y-m-d");

    this.postWinlose(user_id, startDate, endDate, params);
  }

  async postWinlose(user_id, start, end, params = {}) {
    if (params.pagination === undefined) {
      params = this.state.pagination
    } 
    this.setState({ loading1: true })
    let url = URL + `/api/v1/user/partner/winlose/userid/${user_id}/page/${params.pagination.current}/start/${start}/end/${end}`
    let body = {
      type: 'partner'
    }
    await axios.post(url, body)
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ data2: data.result });
          this.setState({ sum: data.sum })
          this.setState({
            loading1: false,
            pagination: {
              pagination: {
                ...params.pagination,
                total: data.sum.user_total,
              }
            },
          })
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("การเชื่อมต่อผิดพลาด partner winlose");
      });
  }

  handleTableChange(newPagination, filters, sorter) {
    this.setDate(this.state.user_id, dateStart, dateEnd, {
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  handleTableUnderMemberChange(newPagination, filters, sorter) {
    this.getDataMember(this.state.coderefer, {
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  async getDataMember(coderefer, params = {}) {
    this.setState({ loading3: true })
    if (params.pagination === undefined) {
      params = this.state.paginationUnderMember
    }

    var url = `${URL}/api/v1/user/partner/under/${coderefer}/page/${params.pagination.current}`
    if (this.state.searchUnderMember !== '') {
      url = url + `/search/${this.state.searchUnderMember}`
    }

    await axios
      .get(url)
      .then((res) => {
        var data = res.data;
        if (data.status) {
          this.setState({ 
            loading3: false, 
            data3: data.result, 
            paginationUnderMember: {
              pagination: {
                ...params.pagination,
                total: data.total,
              }
            },
            dataUnderMember: data.total 
          });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("การเชื่อมต่อผิดพลาด partner getDataMember");
      });
  }

  onSearch() {
    this.getDataMember(this.state.coderefer, {
      pagination: {
        current: 1,
        pageSize: 20,
      }
    })
  }

  render() {
    const routes = [
      {
        breadcrumbName: WEB_NAME,
      },
      {
        breadcrumbName: "รายชื่อพันธมิตร",
      },
    ];

    const pt = () => {
      let list = [];
      for (let i = 100; i > 0; i--) {
        // const el = array[i];
        list.push({ value: i, label: `${i}%` });
      }
      return list;
    };

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="รายชื่อพันธมิตร"
          breadcrumb={{ routes }}
        />
        <button
          className="btn btn-success btn-sm"
          onClick={() => {
            this.setState({ isModalAddMemberVisible: true, type: "add" });
          }}
        >
          <PlusCircleOutlined /> เพิ่มพันธมิตร
        </button>
        <br />
        <br />
        <Card>
          {/* <RangePicker
            style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
            defaultValue={[
              moment(dateStart, dateFormat),
              moment(dateEnd, dateFormat),
            ]}
            format={dateFormat}
            onChange={(e) => this.setChart(e[0]._d, e[1]._d)}
          /> */}
          <Table
            columns={this.state.columns}
            dataSource={this.state.data}
            pagination={{ pageSize: 25 }}
            loading={this.state.loading1}
            scroll={{ x: 1000 }}
            rowKey={(record) => record.row_num}
          />
        </Card>

        {/* เพิ่มสมาชิก แก้ไข */}
        <Modal
          title={
            <>
              <Title level={5}>
                <EditOutlined />{" "}
                {this.state.type === "add" ? "เพิ่มสมาชิก" : "แก้ไข"}
              </Title>
            </>
          }
          style={{ top: 20 }}
          open={this.state.isModalAddMemberVisible}
          footer={[]}
          onCancel={() => this.setState({ isModalAddMemberVisible: false })}
          destroyOnClose={false}
          keyboard={false}
          maskClosable={false}
        >
          <Form>
            <Form.Item
              label="รหัสผ่าน"
              extra="ความยาว 8 ตัวอักษร (0-9, A-Z, a-z)"
              required
            >
              <Input.Password
                onChange={(e) =>
                  this.setState({
                    form_add: {
                      ...this.state.form_add,
                      user_password: e.target.value,
                    },
                  })
                }
                value={this.state.form_add.user_password}
              />
              {this.state.type === "update" ? (
                <small className="text-danger">
                  หากไม่ต้องการเปลี่ยนไม่ต้องระบุ
                </small>
              ) : (
                ""
              )}
            </Form.Item>

            <Form.Item
              label="ชื่อ (เบอร์ติดต่อ)"
              extra="หากไม่ต้องการระบุเว้นว่างไว้"
            >
              <Input
                onChange={(e) =>
                  this.setState({
                    form_add: {
                      ...this.state.form_add,
                      user_name: e.target.value,
                    },
                  })
                }
                value={this.state.form_add.user_name}
              />
            </Form.Item>

            <Form.Item label="%" extra="">
              <Select
                defaultValue={this.state.form_add.user_percent}
                style={{ width: 120 }}
                onChange={(value) =>
                  this.setState({
                    form_add: {
                      ...this.state.form_add,
                      user_percent: value,
                    },
                  })
                }
                options={pt()}
              />
            </Form.Item>

            {this.state.type !== "add" ? 
              <Form.Item label="สถานะ">
                <Switch checkedChildren="ปกติ" unCheckedChildren="ระงับ" 
                  checked={this.state.form_add.user_status === 'ระงับ' ? false : true} 
                  onChange={(value) => {
                    console.log(this.state.form_add, value)
                    this.setState({
                      form_add: {
                        ...this.state.form_add,
                        user_status: value ? 'ปกติ' : 'ระงับ',
                      },
                    })
                  }} 
                />
              </Form.Item>
            : <></>}
            <br />

            <Form.Item>
              <Button
                block
                type="primary"
                onClick={() => {
                  if (this.state.type === "add") {
                    this.PostCreatePartner();
                  } else {
                    this.PostUpdateMember();
                  }
                }}
              >
                {this.state.type === "add" ? "สร้างสมาชิก" : "แก้ไข"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* ยูสใต้สาย */}
        { this.state.isModalUnderMemberVisible ?
          <Modal
            title={
              <>
                <Title level={5}>ยูสใต้สาย</Title>
              </>
            }
            style={{ top: 20 }}
            open={this.state.isModalUnderMemberVisible}
            footer={[]}
            onCancel={() => this.setState({ isModalUnderMemberVisible: false })}
            destroyOnClose={false}
            keyboard={false}
            maskClosable={false}
            width={1000}
          >
            <Row gutter={[16, 16]}>
              <Col flex="1 1 250px">
                <Card>
                  <Statistic
                    title="สมาชิกทั้งหมด"
                    value={this.state.dataUnderMember}
                    valueStyle={{ color: '#4385F4' }}
                    suffix="คน"
                  />
                </Card>
              </Col>
            </Row>
            <br/>
            <Row justify="space-between">
              <Col>
              </Col>
              <Col>
                <Search placeholder="ค้นหาข้อมูล ชื่อ, ยูส, เบอร์ติดต่อ, เลขบัญชี" value={this.state.searchUnderMember} onChange={(e) => this.setState({ searchUnderMember: e.target.value })} style={{ width: 400 }} onSearch={() => this.onSearch()} />
              </Col>
            </Row>
            <br />
            <Table
              columns={this.state.columns3}
              dataSource={this.state.data3}
              pagination={this.state.paginationUnderMember.pagination}
              scroll={{ x: 1000 }}
              loading={this.state.loading3}
              rowKey={(record) => record.row_num}
              onChange={(newPagination, filters, sorter) => this.handleTableUnderMemberChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
            />
          </Modal>
          : <></>
        }

        {/* ได้เสีย */}
        <Modal
          title={
            <>
              <Title level={5}>ได้เสีย</Title>
            </>
          }
          style={{ top: 20 }}
          open={this.state.isModalReportMemberVisible}
          footer={[]}
          onCancel={() => this.setState({ isModalReportMemberVisible: false })}
          destroyOnClose={false}
          keyboard={false}
          maskClosable={false}
          width={1000}
        >
          <RangePicker
            style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
            defaultValue={[
              moment(dateStart, dateFormat),
              moment(dateEnd, dateFormat),
            ]}
            format={dateFormat}
            onChange={(e) => this.setDate(this.state.user_id, e[0]._d, e[1]._d)}
          />

          <Table
            columns={this.state.columns2}
            dataSource={this.state.data2}
            pagination={this.state.pagination.pagination}
            scroll={{ x: 1000 }}
            loading={this.state.loading1}
            rowKey={(record) => record.row_num}
            onChange={(newPagination, filters, sorter) => this.handleTableChange({ ...newPagination, pageSize: 20 }, filters, sorter)}
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    ทั้งหมด:
                  </Table.Summary.Cell>

                  {/* ฝาก */}
                  <Table.Summary.Cell index={1}>
                    {parseFloat(this.state.sum.deposit, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* ถอน */}
                  <Table.Summary.Cell index={2}>
                    {parseFloat(this.state.sum.withdraw, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* กำไร */}
                  <Table.Summary.Cell index={3}>
                    {parseFloat(this.state.sum.income, 2).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* หุ้นส่วน */}
                  <Table.Summary.Cell index={4}>
                    {((parseFloat(this.state.sum.income) * this.state.percent) / 100).toLocaleString()}
                  </Table.Summary.Cell>

                  {/* บริษัท */}
                  <Table.Summary.Cell index={5}>
                  {(parseFloat(this.state.sum.income) - ((parseFloat(this.state.sum.income) * this.state.percent) / 100)).toLocaleString()}
                  </Table.Summary.Cell>

                  
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </Modal>

        {/* ลิ้งแชร์ */}
        <Modal
          title="ลิ้งแชร์"
          open={this.state.isModalShare}
          onCancel={() => this.setState({ isModalShare: false })}
        >
            <Input.Group size="large" compact style={{ marginTop: 10 }}>
              <Input
                style={{ width: "calc(100% - 40px)" }}
                value={`${URL_IMAGE}/register/general?coderefer=${this.state.coderefer}`}
                disabled
              />
              <Tooltip title="Copy URL">
                <Button
                  icon={<CopyOutlined />}
                  size="large"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${URL_IMAGE}/register/general?coderefer=${this.state.coderefer}`
                    )
                  }
                />
              </Tooltip>
            </Input.Group>
        </Modal>
      </>
    );
  }
}

